import {
    Typography,
    Menu,
    MenuItem,
    Box
} from '@material-ui/core';
import React, { useContext } from 'react';
import useStyles from './style';
import OwnerAdminNavBar from './OwnerAdminNavBar';
import BusinessIcon from '@material-ui/icons/Business';
import HelpCenterIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CarIcon from '@material-ui/icons/DirectionsCar';
import MyObjects from './MyObjects';
import MyCars from './MyCars';
import MyRestaurants from './MyRestaurants';
import MyToursAndExcursions from './MyToursAndExcursions';
import MyEvents from './MyEvents';
import ContactQuestions from './ContactQuestions';
import Settings from './Settings';
import AddNewObject from './AddNewObject';
import AddNewRestaurant from './AddNewRestaurant';
import { logoutUserAction, checkJwtTokenAction, emptyJwtTokenAction } from '../actions/users_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import SuperAdminPanel from './SuperAdminPanel';
import AddNewCar from './AddNewCar';
import AddNewEvent from './AddNewEvent';
import MapIcon from '@material-ui/icons/Map';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddNewTourAndExcursion from './AddNewTourAndExusion';

const OwnerAdminPanel = () => {

    const { loggedUserData, setLoggedUserData } = useContext(GlobalContext);

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(()=>{
        if(JSON.stringify(loggedUserData) === '{}'){
        setLoggedUserData({ ...JSON.parse(localStorage.getItem('loggedUserData')) });
        }
    },[loggedUserData])    

    const handleLogout = () => {
        dispatch(emptyJwtTokenAction());
        dispatch(logoutUserAction());
        setLoggedUserData({});
        navigate('/login');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [showMyObjects, setShowMyObjects] = React.useState(true);
    const [showMyRestaurants, setShowMyRestaurants] = React.useState(false);
    const [showContactQuestions, setShowContactQuestions] = React.useState(false);
    const [showSettings, setShowSettings] = React.useState(false);
    const [showAddNewObject, setShowAddNewObject] = React.useState(false);
    const [showAddNewRestaurant, setShowAddNewRestaurant] = React.useState(false);
    const [showMyCars, setShowMyCars] = React.useState(false);
    const [showAddNewCar, setShowAddNewCar] = React.useState(false);
    const [showMyToursAndExcursions, setShowMyToursAndExcursions] = React.useState(false);
    const [showAddNewTour, setShowAddNewTour] = React.useState(false);
    const [showMyEvents, setShowMyEvents] = React.useState(false);
    const [showAddNewEvent, setShowAddNewEvent] = React.useState(false);


    const switchActivePanel = (panelName) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        switch (panelName) {
            case 'myObjects':
                resetActivePanelState();
                break;
            case 'myRestaurants':
                setShowMyObjects(false);
                setShowMyRestaurants(true);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'contactQuestions':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(true);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'settings':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(true);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'addNewObject':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(true);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'addNewRestaurant':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(true);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'myCars':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(true);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'showAddNewCar':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(true);
                setShowMyToursAndExcursions(false);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'myToursAndExcursions':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(true);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'addNewTour':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowAddNewTour(true);
                setShowMyEvents(false);
                setShowAddNewEvent(false);
                break;
            case 'myEvents':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowAddNewTour(false);
                setShowMyEvents(true);
                setShowAddNewEvent(false);
                break;
            case 'addNewEvent':
                setShowMyObjects(false);
                setShowMyRestaurants(false);
                setShowContactQuestions(false);
                setShowSettings(false);
                setShowAddNewObject(false);
                setShowAddNewRestaurant(false);
                setShowMyCars(false);
                setShowAddNewCar(false);
                setShowMyToursAndExcursions(false);
                setShowAddNewTour(false);
                setShowMyEvents(false);
                setShowAddNewEvent(true);
                break;
            default: resetActivePanelState();
        }
    };

    const resetActivePanelState = () => {
        setShowMyObjects(true);
        setShowMyRestaurants(false);
        setShowContactQuestions(false);
        setShowSettings(false);
        setShowAddNewObject(false);
        setShowAddNewRestaurant(false);
        setShowMyCars(false);
        setShowAddNewCar(false);
        setShowMyToursAndExcursions(false);
        setShowAddNewTour(false);
        setShowMyEvents(false);
        setShowAddNewEvent(false);
    };

    const DropDownMenu = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem onClick={() => { alert('LOGOUT!'); }}>Odjavi se</MenuItem>
            </Menu>
        );
    };

    const SidePanel = () => {
        return (
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <div className={classes.sidePanel}>

                    <ul style={{ listStyleType: 'none' }} className={classes.sidePanelList}>
                        <li
                            onClick={() => { switchActivePanel('myObjects'); }}
                            className={showMyObjects ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>

                            <BusinessIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Moji objekti </Typography>

                        </li>
                        <li
                            onClick={() => { switchActivePanel('myRestaurants'); }}
                            className={showMyRestaurants ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>
                            <StorefrontIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Moji restorani </Typography>

                        </li>
                        <li
                            onClick={() => { switchActivePanel('myCars'); }}
                            className={showMyCars ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>
                            <CarIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Rent a car </Typography>

                        </li>
                        <li
                            onClick={() => { switchActivePanel('myToursAndExcursions'); }}
                            className={showMyToursAndExcursions ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>
                            <MapIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Ture I Ekskurzije </Typography>

                        </li>
                        <li
                            onClick={() => { switchActivePanel('myEvents'); }}
                            className={showMyEvents ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>
                            <CalendarTodayIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Događaji </Typography>

                        </li>
                        <li
                            onClick={() => { switchActivePanel('contactQuestions'); }}
                            className={showContactQuestions ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>
                            <HelpCenterIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Upiti </Typography>

                        </li>
                        <li
                            onClick={() => { switchActivePanel('settings'); }}
                            className={showSettings ? `${classes.listItem} ${classes.listItemActive}` : classes.listItem}>
                            <SettingsIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Podešavanja </Typography>

                        </li>
                        <li
                            onClick={handleLogout}
                            className={classes.listItem}>
                            <ExitToAppIcon fontSize='medium' style={{ marginRight: '7px' }} />
                            <Typography variant='body1'> Odjava </Typography>

                        </li>
                    </ul>
                </div>
            </Box>
        )
    };

    const ShowCurrentActivePanel = () => {
        const possiblePanels = [showMyObjects, showMyRestaurants, showContactQuestions,
            showSettings, showAddNewObject, showAddNewRestaurant,
            showMyCars, showAddNewCar, showMyToursAndExcursions, showAddNewTour,
            showMyEvents, showAddNewEvent];
        const possiblePanelsText = ['showMyObjects', 'showMyRestaurants', 'showContactQuestions',
            'showSettings', 'showAddNewObject', 'showAddNewRestaurant',
            'showMyCars', 'showAddNewCar', 'showMyToursAndExcursions', 'showAddNewTour',
            'showMyEvents', 'showAddNewEvent'];
        const findIndex = possiblePanels.findIndex(panel => panel);
        switch (possiblePanelsText[findIndex]) {
            case 'showMyObjects': return <MyObjects switchActivePanel={switchActivePanel} />
            case 'showMyRestaurants': return <MyRestaurants switchActivePanel={switchActivePanel} />
            case 'showContactQuestions': return <ContactQuestions />
            case 'showSettings': return <Settings
                loggedUserData={loggedUserData}
                setLoggedUserData={setLoggedUserData}
                switchActivePanel={switchActivePanel} />
            case 'showAddNewObject': return <AddNewObject switchActivePanel={switchActivePanel} />
            case 'showAddNewRestaurant': return <AddNewRestaurant switchActivePanel={switchActivePanel} />
            case 'showMyCars': return <MyCars switchActivePanel={switchActivePanel} />
            case 'showAddNewCar': return <AddNewCar switchActivePanel={switchActivePanel} />
            case 'showMyToursAndExcursions': return <MyToursAndExcursions switchActivePanel={switchActivePanel} />
            case 'showAddNewTour': return <AddNewTourAndExcursion switchActivePanel={switchActivePanel} />
            case 'showMyEvents': return <MyEvents switchActivePanel={switchActivePanel} />
            case 'showAddNewEvent': return <AddNewEvent switchActivePanel={switchActivePanel} />
            default: return <MyObjects />
        };
    };

    const Panel = () => {
        return (
            <div className={classes.bigPanelDiv}>
                <SidePanel />
                <div className={classes.activePanel}>
                    <ShowCurrentActivePanel />
                </div>
            </div>
        );
    };


    return (
        <>
            <OwnerAdminNavBar
                loggedUserData={loggedUserData}
                setLoggedUserData={setLoggedUserData}
                handleLogout={handleLogout}
                switchActivePanel={switchActivePanel}
                setAnchorEl={setAnchorEl} />
            <Panel />
            <DropDownMenu />
        </>
    );
};

export default OwnerAdminPanel