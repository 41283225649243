import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, Slide, Divider, LinearProgress, Grid } from '@material-ui/core';
import useStyles from './style';
import { AlertTitle, Alert } from '@material-ui/lab';
import { Carousel } from 'react-responsive-carousel';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { previewRestaurantAction } from '../actions/restaurants_actions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const SuperAdminPreviewRestaurant = ({ previewRestaurantId, show, setShow }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow({
            show: false,
            previewRestaurantId: null
        });
    };

    const previewRestaurantSelector = useSelector(state => state.previewRestaurantState);
    const { loading, previewRestaurantData: data } = previewRestaurantSelector;

    React.useEffect(() => {
        if (previewRestaurantId !== null) {
            dispatch(previewRestaurantAction(previewRestaurantId));
        }
    }, [previewRestaurantId]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ margin: 'auto' }}>
                <AlertTitle>Greška</AlertTitle>
                Greška u prikazivanju podataka restorana
            </Alert>
        );
    };

    return (
        <Dialog
            fullScreen
            open={show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#003171' }} >
                <Toolbar className={classes.previewToolbar}>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                        Pregled podataka restorana
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CancelIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {
                loading
                    ?
                    <div style={{
                        width: "50%",
                        margin: 'auto'
                    }}>
                        <Typography variant='h5'>Učitavanje podataka...</Typography>
                        <LinearProgress color='primary' />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data?.success
                            ?
                            <div className={classes.previewDiv}>
                                <div className={classes.previewGallery}>
                                    {
                                        data.restaurant.photos.length > 0
                                            ?
                                            <Carousel
                                                emulateTouch
                                                className={classes.carousel}
                                                swipeable
                                                showStatus={false}>
                                                {
                                                    data.restaurant.photos.map((photo, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <img src={process.env.REACT_APP_UPLOADS_PATH + photo} alt='Restaurant' />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                            :
                                            <Typography variant='h3'>
                                                Nema slika
                                            </Typography>
                                    }

                                </div>
                                <div className={classes.previewInfo}>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Ime</strong> </Typography>
                                        <Typography variant='h6'>{data.restaurant.name}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Adresa</strong> </Typography>
                                        <Typography variant='h6'>{data.restaurant.address}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Sajt</strong> </Typography>
                                        <Typography variant='h6'>{data.restaurant.web_site}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Opis</strong> </Typography>
                                        <Typography variant='subtitle1'>{data.restaurant.description}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Radno vrijeme</strong> </Typography>
                                        <Typography variant='subtitle1'>{data.restaurant.working_hours}</Typography>
                                    </div>

                                </div>
                            </div>
                            :
                            <ShowError />
                        :
                        null

            }
        </Dialog>
    );
};

export default SuperAdminPreviewRestaurant;