import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    infoSectionDiv : {
       // border : '2px solid black',
        padding : '15px',
        width : '85%',
        margin:'auto',
        marginTop : '30px',
        [theme.breakpoints.down('xs')]: {
            // padding : '10px',
            width : '90%',
        }
    },
    infoCard : {
        //border : '1px solid black',
        textAlign : 'center',
        [theme.breakpoints.down('xs')]: {
            
        }
    }
}));

export default useStyles;