import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        //border : '2px solid red',
        margin: 'auto',
        width: '96%',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            //border : '1px solid red',
            marginTop: '0px',
            padding: '0px',
        }
    },
    mainSection: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '15px'
    },
    firstSection: {
        //border : '2px solid black'
    },
    secondSection: {
        //border : '2px solid black'
    },
    objectInfo: {
        //border : '2px solid blue',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    titleRatingAddress: {
        //border : '2px solid green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    titleRating: {
        //border : '2px solid purple',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {

    },
    rating: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    address: {
        //border : '2px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    priceInfo: {
        //border : '2px solid orange'
    },
    shareSaveDiv: {
        //border : '5px solid red',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '58px'
        //height : '100%'
    },
    shareSave: {
        //border : '2px solid orangered',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '46%'
    },
    shareSaveParts: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    objectCardPrice: {
        display: 'flex',
        flexDirection: "column",
    },
    objectCardPriceFrom: {
        marginBottom: '-3px'
    },
    objectCardPriceInfo: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        //border : '1px solid red',
        height: '45px'
    },
    gallerySection: {
        height: '450px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        //transition: "all .5s ease-in-out",
        cursor: 'pointer',
        position: 'relative'
    },
    contactDataDiv: {
        //border : '3px solid red',
        marginTop: '20px',
    },
    sectionTitleDiv: {
        borderLeft: '5px solid #f79800',
        marginBottom: '20px',
        marginTop: '20px'
    },
    sectionTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#0ca6d8',
        textTransform: 'uppercase',
        marginLeft: '10px',
    },
    ownerPhotoAndNameMain: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '15px'
    },
    ownerPhoneData: {


    },
    phoneNumber: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px'
    },
    equipmentDiv: {
        //border : '2px solid blue',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
    },
    equipmentItemDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f7f9fb',
        border: '1px solid #cccccc',
        width: '121px',
        height: '90px',
        gap: '10px'
    },
    contactFormDiv: {
        border: '6px solid purple',
        padding: '10px',
        backgroundColor: '#f7f9fb',
        border: '2px solid #cccccc'
    },
    contactFormTitle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
    },
    field: {
        "& .MuiOutlinedInput-root": {
            backgroundColor: 'white',
            "&.Mui-focused fieldset": {
                borderColor: '#0F79A1',
            }
        }
    },
    menu: {
        "& .MuiPaper-root": {
            padding: '6px',
            width: '300px',
            marginTop: '13px',
        }
    },
    formField: {
        border: '1px solid #D2D7D3',
        backgroundColor: 'white',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px',
        width: '100%',
    },
    galleryButton: {
        position: 'absolute',
        right: '15px',
        bottom: '15px',
        backgroundColor: 'white',
        borderRadius: '6px',
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '190px',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        transition: "all .5s ease-in-out",
    },
    menu: {
        "& .MuiPaper-root": {
            padding: '6px',
            height: '250px',
            marginTop: '13px',
        }
    },
    tablesDiv: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gap: '10px',
        width: '80%'
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        '& $td,th': {
            border: '1px solid #cccccc',
            textAlign: 'center',
            padding: '8px'
        },
        '& $th': {
            backgroundColor: '#f7f9fb'
        },
        '& $td': {
            fontWeight: 'bold'
        }
    },
    additionalEquipmentDiv: {
        //border : '2px solid red',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        }
    },
    additionalEquipmentItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
}));


export default useStyles;