import { Grid, Paper, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { registerValidator } from '../services/validator';
import { useDispatch, useSelector } from 'react-redux';
import { registerAction } from '../actions/users_actions';
import { emptyLoginDataAction } from '../actions/users_actions';
import { loginAction } from '../actions/users_actions';

const initialRegisterData = {
    name: '',
    surname: '',
    avatar: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: ''
};

const Register = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [registerData, setRegisterData] = React.useState(initialRegisterData);

    const [imagePreview, setImagePreview] = React.useState(null);

    const [possibleErrors, setPossibleErrors] = React.useState(null);

    const loginSelectorData = useSelector(state => state.login);
    const { loading, userLoginData } = loginSelectorData;

    const handleChange = (e) => {
        setRegisterData({
            ...registerData,
            [e.target.name]: e.target.value
        });
    };

    const handleAvatarChange = (e) => {
        const avatar = e.target.files[0];
        if (avatar) {
            setRegisterData({
                ...registerData,
                avatar
            });
            setImagePreview(URL.createObjectURL(avatar))
        };
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        const errors = registerValidator(registerData);
        if (errors.length !== 0) {
            setPossibleErrors([...errors]);
        } else {
            setPossibleErrors(null);
            const formData = new FormData();

            formData.append('name', registerData.name);
            formData.append('surname', registerData.surname);
            formData.append('phone', registerData.phone);
            formData.append('email', registerData.email);
            formData.append('password', registerData.password);
            formData.append('confirmPassword', registerData.confirmPassword);
            formData.append('avatar', registerData.avatar);

            dispatch(loginAction(formData,'register'));

        };
    };

    React.useEffect(() => {
        if (typeof userLoginData !== 'undefined') {
            if (userLoginData?.emailOrPhone) {
                dispatch(emptyLoginDataAction());
                navigate('/ownerAdminPanel');
            }
        }
    }, [userLoginData]);

    return (
        <div className={classes.loginDiv}>
            <div className={classes.backButton}>
                <Button
                    onClick={()=>{ navigate("/"); }} 
                    size='large' 
                    variant='contained' 
                    disableElevation style={{ backgroundColor : '#0F79A1', color : 'white' }}>
                    Nazad
                </Button>
            </div>
            <Paper elevation={7} className={classes.form}>
                <div className={classes.loginTitle}>
                    <LockOutlinedIcon style={{ color : '#0F79A1' }} />
                    <Typography component="h1" variant="h5">
                        Registracija Petrovac
                    </Typography>
                </div>

                {
                    possibleErrors
                        ?
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            <AlertTitle>Error</AlertTitle>
                            {
                                possibleErrors.map((err, index) => {
                                    return (
                                        <Typography key={index} variant='subtitle2' style={{ marginBottom: '5px' }}>
                                            {err}
                                        </Typography>
                                    )
                                })
                            }
                        </Alert>
                        :
                        null
                }

                {
                    typeof userLoginData !== 'undefined'
                        ?
                        !userLoginData?.success
                            ?
                            <Alert severity="error" style={{ marginBottom: '20px' }}>
                                <AlertTitle>Greška</AlertTitle>
                                Greška u registraciji :
                                {userLoginData?.message}
                            </Alert>
                            :
                            null
                        :
                        null
                }

                <form onSubmit={handleRegisterSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="name"
                                required
                                label="Ime"
                                name="name"
                                type="text"
                                value={registerData.name}
                                onChange={handleChange}
                                inputProps={{
                                    minLength: 2
                                }}

                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="surname"
                                required
                                label="Prezime"
                                name="surname"
                                type="text"
                                value={registerData.surname}
                                onChange={handleChange}
                                inputProps={{
                                    minLength: 2
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="phone"
                                label="Telefon"
                                inputProps={{
                                    minLength: 6
                                }}
                                name="phone"
                                type="text"
                                value={registerData.phone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                value={registerData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="password"
                                label="Šifra"
                                required
                                name="password"
                                type="password"
                                inputProps={{
                                    minLength: 6
                                }}
                                helperText="Minimum 6 karatkera"
                                value={registerData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="confirmPassword"
                                label="Ponovite šifru"
                                required
                                name="confirmPassword"
                                type="password"
                                helperText="Minimum 6 karatkera"
                                inputProps={{
                                    minLength: 6
                                }}
                                value={registerData.confirmPassword}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button
                                variant="outlined"
                                component="label"
                                
                            >
                                Dodaj sliku
                                <AddIcon style={{ color : '#0F79A1' }}/>
                                <input
                                    accept='image/*'
                                    onChange={handleAvatarChange}
                                    type="file"
                                    hidden
                                />
                            </Button>
                            <div className={classes.previewImg}>
                                {
                                    imagePreview
                                        ?
                                        [
                                            <img key={1} src={imagePreview} width={200} alt='Avatar' style={{ borderRadius: '10px' }} />,
                                            <div key={2} className={classes.removeAvatar}>
                                                <CancelIcon
                                                    onClick={() => {
                                                        setRegisterData({
                                                            ...registerData,
                                                            avatar: ''
                                                        });
                                                        setImagePreview(null);
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                    fontSize='large'
                                                     />
                                            </div>
                                        ]
                                        :
                                        null
                                }
                            </div>
                            <Typography variant='caption' color='textSecondary'>
                                Nije obavezno*
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                loading
                                    ?
                                    <div style={{ textAlign: 'center' }}>
                                        <CircularProgress size={130} />
                                    </div>
                                    :
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        type='submit'
                                        style={{ backgroundColor : '#0F79A1', color : 'white' }}>
                                        Registruj se
                                    </Button>
                            }

                        </Grid>
                        <div className={classes.loginAdditional}>
                            <Typography
                                onClick={() => { navigate('/login'); }}
                                color='primary'
                                style={{ cursor: 'pointer' }}>
                                Imate nalog? Prijavite se.
                            </Typography>
                        </div>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
};

export default Register;