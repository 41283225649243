import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    bigReservationDiv: {
        position: 'relative',
        width: '100%',
        height: '101vh',
        marginTop: '-85px',
        //backgroundImage: `url(${process.env.PUBLIC_URL + 'petrovac3.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.5)',
        transition: 'all 0.5s ease-in-out',
        [theme.breakpoints.down('970')]: {
            height: '40vh'
        }
    },
    innerContainer: {
        //border : '1px solid transparent',
        //border : '4px solid red',
        padding : '5px',
        width: '82%',
        margin: 'auto',
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('970')]: {
            //border : '3px solid green',
            //top : "35%",
            position : 'static',
            //top: '0',
            left: '0',
            transform: 'none',
            width : '100%',
            padding : '0px',
            //border : '2px solid red'
        }
    },
    textSlider : {
        width : '90%',
        margin : 'auto',
        textAlign : 'center',
        color : 'white',
        position: 'absolute',
        bottom: '6%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgba(0,0,0,0.3)',
        //borderRadius : '6px',
        borderLeft : '5px solid #f79800',
        // transition : 'opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94)'
    },
    sliderLines : {
        position  :'absolute',
        bottom: '0',
        width : '80%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        //border : '2px solid red',
        padding : '10px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-around',
        alignItems : 'center'
    },
    line : {
        height : '6px',
        width : '40px',
        backgroundColor : 'gray'
    },
    datePickerDiv: {
        //border : '1px solid red',
        width: '80%',
        margin: 'auto',
        padding: '15px',
       //marginTop: '30px',
        backgroundColor: '#ECF0F1',
        borderRadius: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: '100%',
            borderRadius : '0px',
            //padding : '20px',
            //border : '2px solid red'
        }
    },
    pickerItem : {
        //border : '1px solid red',
        cursor : 'pointer',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        [theme.breakpoints.down('xs')]: {
            //border : '1px solid red',
            width : '100%',
            justifyContent : 'flex-start',
            padding : '5px',
            marginBottom : '10px',
        }
    },
    dropdownImg : {
        marginRight : '10px',
        cursor : 'pointer',
        color: '#000000'
    },
    pickerText : {
        //border : '1px solid green',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'flex-start',
        alignItems : 'flex-start'
    },
    searchButton : {
        backgroundColor: '#f79800', 
        color: 'white', 
        borderRadius: '20px', 
    },
    test: {
        display: 'flex',
        flexDirection: 'column'
    },
    personNumPicker: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            marginTop: '10%'
        }
    },
    buttonSearch: {
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginTop: '8%'
        }
    },
    infoSection: {
        //border : '2px solid yellow',
        width: '87%',
        margin: '0 auto',
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            width : '98%',
        }
    },
    infoPart: {
        //border : '2px solid green',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },
    infoImg: {
        width: '85px',
        marginRight: '15px'
    },
    menu : {
        "& .MuiPaper-root": {
            padding : '6px',
            height : '250px',
            marginTop : '13px',
          }
    },
    menuItem : {
        //border : '2px solid green',
    },
    mobileGridSection : {
        margin: 'auto',
        padding: '5px',
        backgroundColor: '#ECF0F1',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRadius : '0px'
        },
        [theme.breakpoints.between('500','970')]: {
            //border : '2px solid red',
            width : 'calc( 100% - 16px ) '
        }
    },
    mobileFormField : {
        border : '1px solid #D2D7D3',
        backgroundColor : 'white',
        borderRadius : '10px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        padding : '10px',
        width : '100%',
        cursor : 'pointer'
    }
}));

export default useStyles;