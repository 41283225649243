import { Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { homePhotos } from './homePhotos';
import directContactImage from '../assets/photos/directContact.png';
import eventCalendar from '../assets/photos/eventCalendar.png';
import savings from '../assets/photos/savings.png';
import ReservationForm from './ReservationForm';

const sliderImages = ['001.jpg', '002.jpg', '003.jpg', '004.jpg', '005.jpg']

const ReservationSection = () => {

  const { t } = useTranslation();

  const sliderTexts = [t("info_section_title1"), t("info_section_title2"), t("info_section_title3")];

  const classes = useStyles();

  const [currentPhotoIndex, setCurrentPhotoIndex] = React.useState(0);

  const [currentSliderText, setCurrentSliderText] = React.useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('970'), { noSsr: true });

  React.useEffect(() => {

    const interval = setInterval(() => {
      setCurrentPhotoIndex((prev) => (currentPhotoIndex === homePhotos.length - 1 ? 0 : prev + 1));
    }, 3000);

    return () => clearInterval(interval);

  }, [currentPhotoIndex]);

  React.useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentSliderText((prev) => (currentSliderText === sliderTexts.length - 1 ? 0 : prev + 1));
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isMobile, currentSliderText]);

  const InfoSection = () => {
    return (
      <div className={classes.infoSection}>
        <div className={classes.infoPart}>
          <img className={classes.infoImg} src={directContactImage} alt='Info' />
          <Typography variant='subtitle2'>
            {t("info_section_title1")}
          </Typography>
        </div>
        <div className={classes.infoPart}>
          <img className={classes.infoImg} src={eventCalendar} alt='Info' />
          <Typography variant='subtitle2'>
            {t("info_section_title2")}
          </Typography>
        </div>
        <div className={classes.infoPart}>
          <img className={classes.infoImg} src={savings} alt='Info' />
          <Typography variant='subtitle2'>
            {t("info_section_title3")}
          </Typography>
        </div>
      </div>
    )
  };

  return (
    <div className={classes.bigReservationDiv} style={{
      backgroundImage: `url(${'/sliderImages/' + sliderImages[currentPhotoIndex]})`,
    }}>
      {
        !isMobile
          ?
          <>
            <ReservationForm isMobile={isMobile} />
            <InfoSection />
          </>
          :
          <>
            <div className={classes.textSlider}>
              <Typography variant='subtitle1' style={{ marginLeft: '5px' }}>
                <strong>
                  {sliderTexts[currentSliderText]}
                </strong>
              </Typography>
            </div>
            <div className={classes.sliderLines}>
              {
                sliderImages.map((img, index) => {
                  return (
                    <div
                      onClick={() => {
                        setCurrentPhotoIndex(index);
                        setCurrentSliderText((prevText) => {
                          switch (index) {
                            case 3:
                              return 0;
                            case 4:
                              return 1;
                            default: return index;
                          }
                        });
                      }}
                      style={{ backgroundColor: index === currentPhotoIndex ? '#0F79A1' : 'gray' }}
                      className={classes.line} key={index}>
                    </div>
                  )
                })
              }
            </div>
          </>
      }
    </div>
  )
}

export default ReservationSection;
