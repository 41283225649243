import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    restaurantBigDiv: {
        //border: '4px solid black',
        width: '88%',
        margin: 'auto',
        marginTop: '10px',
        padding: '15px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop  :'0px',
            padding : '0px',
        }
    },
    nameAndBasicInfo: {
        //border : "4px solid yellow",
        //padding: '5px'
    },
    infoSection: {
        //border : '2px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    address: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        //marginTop : '10px'
    },
    types: {
        display: 'flex',
        flexDirection: 'row'
    },
    type: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #D2D7D3',
        padding: '5px',
        borderRadius: '6px',
        marginRight: '10px'
    },
    share: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: '15px'
    },
    desktopGallery: {
        //border : '2px solid red',
        marginTop: '10px',
        marginBottom: '25px',
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '0.5rem'
    },
    desktopGalleryBigPhoto: {
        //border : '2px solid green',
        height: '480px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
    },
    desktopGallerySmallPhotos: {
        //border : '2px solid purple',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '0.5rem',
    },
    desktopGalleryOneSmallPhoto: {
        //border : '2px solid blue',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        '&:hover': {
            //transform: 'scale(1.05)',
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
    },
    galleryAndContact: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: "100%",
        }
    },
    gallery: {
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    backButton : {
        boxShadow: "0 2px 2px #888888",
        position : 'absolute',
        top : '15px',
        left : '10px',
        padding : '8px',
        backgroundColor : 'white',
        borderRadius : '50%',
        display : 'flex',
        justifyContent  :'center',
        alignItems : 'center'
    },
    shareButton : {
        boxShadow: "0 2px 2px #888888",
        position : 'absolute',
        top : '15px',
        right : '10px',
        padding : '8px',
        backgroundColor : 'white',
        borderRadius : '50%',
        display : 'flex',
        justifyContent  :'center',
        alignItems : 'center'
    },
    capacityMobile : {
        position : 'absolute',
        top : '50%',
        right : '0',
        padding : '8px',
        backgroundColor : 'white',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        borderTopLeftRadius : '6px',
        borderBottomLeftRadius : '6px',
    },
    imageCounter : {
        position : 'absolute',
        bottom : '15px',
        right : '10px',
        width : '40px',
        padding : '5px',
        borderRadius : '6px',
        color : 'white',
        background: 'rgba(0,0,0,0.7)',
    },
    mainInfoAddress : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center'
    },
    carousel: {


    },
    contact: {
        //border: '2px solid black',
        width: "45%",
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    isPopularMark: {
        backgroundColor: "#0F79A1",
        position: 'absolute',
        top: '0',
        left: '0',
        padding: '5px',
        color: 'white'
    },
    otherDataDiv: {
        //border : '4px solid blue',
        marginTop: '20px',
        padding: '5px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        }
    },
    ownerInfo: {
        //border : '1px solid black',
        padding: '10px',
    },
    infoTitle: {
        border: '1px solid #0F79A1',
        backgroundColor: 'white',
        padding: '5px',
        textAlign: 'center',
        borderRadius: '7px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    ownerPhotoAndNameMain: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '15px',
        marginTop : '15px'
    },
    phoneNumber: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px'
    },
    description: {
        //border : '1px solid black',
        padding: '10px'
    },
    equipment: {
        //border : '1px solid black',
        padding: '10px'
    },
    items: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem',
        margin: 'auto',
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '99%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem'
        }
    },
    equipment_item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '5px'
    },
    capacity: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mapDiv: {
        //border : '2px solid red'
    },
    map: {
        height: '400px',
        borderRadius: '6px',
        zIndex :'1'
    },
    field: {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: '#0F79A1'
            }
        }
    },
    desktopData: {
        //border: '2px solid red',
        display : 'grid',
        gridTemplateColumns : '2fr 1fr',
        gap : '1rem'
    },
    desktopDataInfo: {
        //border: '2px solid blue',
        display : 'grid',
        gridTemplateColumns : '1fr',
        gap : '1rem'
        
    },
    desktopDataFormInfo: {
        //border: '2px solid green',
        display : 'flex',
        flexDirection : 'column'
    },
    desktopDataForm: {
        //border: '3px solid purple'
    },
    desktopDataOwnerInfo: {
        //border: '2px solid orange',
        marginTop : '20px'
    }
}));

export default useStyles;