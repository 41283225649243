
export const addNewCarReducer = (newCar = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_CAR':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_CAR':
        case 'ERROR_ADD_NEW_CAR':
            return {
                loading: false,
                newCar: action.payload
            }
        default: return newCar
    }
};

export const getOwnerCarsTableReducer = (ownerCarsTable = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_OWNER_CARS_TABLE_DATA':
            return {
                loading: true
            }
        case 'SUCCESS_GET_OWNER_CARS_TABLE_DATA':
        case 'ERROR_GET_OWNER_CARS_TABLE_DATA':
            return {
                loading: false,
                ownerCarsTable: action.payload
            }
        default:
            return ownerCarsTable
    };
};

export const getCarDataForEditReducer = (carDataForEdit = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_CAR_DATA_FOR_EDIT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_CAR_DATA_FOR_EDIT':
        case 'ERROR_GET_CAR_DATA_FOR_EDIT':
            return {
                loading: false,
                carDataForEdit: action.payload
            }
        default: return carDataForEdit
    }
};

export const updateCarDataReducer = (updateCarData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_CAR_DATA':
            return {
                loading_update: true
            }
        case 'SUCCESS_UPDATE_CAR_DATA':
        case 'ERROR_UPDATE_CAR_DATA':
            return {
                loading_update: false,
                updateCarData: action.payload
            }
        default: return updateCarData
    }
};

export const previewCarReducer = (previewCar = {}, action) => {
    switch (action.type) {
        case 'REQUEST_PREVIEW_CAR':
            return {
                loading: true
            }
        case 'SUCCESS_PREVIEW_CAR':
        case 'ERROR_PREVIEW_CAR':
            return {
                loading: false,
                previewCar: action.payload
            }
        default: return previewCar
    }
};

export const getAllCarsReducer = (allCarsData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ALL_CARS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ALL_CARS':
        case 'ERROR_GET_ALL_CARS':
            return {
                loading: false,
                allCarsData: action.payload
            }
        default: return allCarsData
    }
};

export const deleteCarReducer = (carDelete = {}, action) => {
    switch (action.type) {
        case 'REQUEST_DELETE_CAR':
            return {
                loading: true
            }
        case 'SUCCESS_DELETE_CAR':
        case 'ERROR_DELETE_CAR':
            return {
                loading: false,
                carDelete: action.payload
            }
        default: return carDelete
    }
};

export const getCarDataReducer = (carData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_CAR_DATA':
            return {
                loading: true
            }
        case 'SUCCESS_GET_CAR_DATA':
        case 'ERROR_GET_CAR_DATA':
            return {
                loading: false,
                carData: action.payload
            }
        default: return carData
    }
};

export const sendCarQuestionReducer = (carQuestion = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SEND_CAR_QUESTION':
            return {
                loading: true
            }
        case 'SUCCESS_SEND_CAR_QUESTION':
        case 'ERROR_SEND_CAR_QUESTION':
            return {
                loading: false,
                carQuestion: action.payload
            }
        default: return carQuestion
    }
};

