import React from 'react';
import useStyles from './style';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Dialog,
    Toolbar,
    AppBar,
    TextField,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Snackbar,
    Button,
    Typography,
    IconButton,
    Grid,
    Paper,
    MenuItem,
    Select,
    InputLabel,
    FormLabel,
    CircularProgress,
    LinearProgress
} from '@material-ui/core';
import { Slide } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { getAccObjectDataToEditAction } from '../actions/acc_objects_actions';
import { updateAccObjectAction, emptyUpdateAccObjectState, deleteAccUnitAction } from '../actions/acc_objects_actions';
import EditAccUnitDialog from './EditAccUnitDialog';
import AddAccUnitDialog from './AddAccUnitDialog';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const accObjectInitialData = {
    objectName: '',
    objectDescription: '',
    objectPhotoGallery: [],
    objectLatitude: '',
    objectLongitude: '',
    objectAddress: '',
    objectCityCenterDistance: '',
    objectBeachDistance: '',
    objectStoreDistance: '',
    objectRestaurantDistance: '',
    objectPriceType: '',
    objectPrice: '',
    objectContactOnlyByPhone: false,
    objectPhoneNumbers: [],
    objectEmailAddress: '',
    objectWebsite: '',
    objectCategory: '',
    objectEquipment: {
        internet: false,
        parking: false,
        air_conditioner: false,
        seaview: false,
        pool: false,
        restaurant: false,
        reception: false,
        baby_crib: false,
        disability_friendly: false
    },
    objectAccUnits: [],
    objectAdvancePayment: '',
    objectAdvanceAmount: '',
    objectNonRefundableAdvance: '',
    objectPaymentData: '',
    photosToRemove: [],
    accObjectId: ''
};

const objectPhoneNumbers = [
    {
        phone_number: '',
        viber: false,
        whatsapp: false
    },
    {
        phone_number: '',
        viber: false,
        whatsapp: false
    },
    {
        phone_number: '',
        viber: false,
        whatsapp: false
    }
]

const priceTypes = ['Cijena na upit', 'Po osobi', 'Noćenje'];

const accommodationCategory = ['1', '2', '3', '4', '5'];

const equipment = [
    'Internet',
    'Parking',
    'Klima',
    'Pogled na more',
    'Bazen',
    'Restoran',
    'Recepcija',
    'Krevetac za bebe',
    'Prilagođenost invalidima',
];

const advancePercentage = ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];

const EditAccObjectDialog = ({ editState, setEditState }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [editUnitState, setEditUnitState] = React.useState({
        show: false,
        accUnitId: null
    });

    const [addNewUnitState, setAddNewUnitState] = React.useState({
        show: false,
        accObjectId: null
    });

    const [showSnackBar, setShowSnackBar] = React.useState({
        show: false,
        message: '',
        variant: 'info'
    });

    const [accObjectData, setAccObjectData] = React.useState(accObjectInitialData);

    const [imageGallery, setImageGallery] = React.useState([]);

    const accObjectUpdateSelector = useSelector(state => state.accObjectUpdateState);
    const { loading_update, updateAccObject } = accObjectUpdateSelector;

    const accObjectToEditSelector = useSelector(state => state.accObjectDataEditState);
    const { loading, accObjectEditData } = accObjectToEditSelector;

    const deleteAccUnitSelector = useSelector(state => state.deleteAccUnitState);
    const { loading_delete_unit, deleteAccUnit } = deleteAccUnitSelector;

    const handleClose = () => {
        setEditState({
            show: false,
            objectId: null
        });
        dispatch(emptyUpdateAccObjectState());
    };

    const handleCloseSnackBar = () => {
        setShowSnackBar({
            show: false,
            message: '',
            variant: 'info'
        });
    };

    const handleImageChange = async (e) => {
        if (e.target.files) {
            const filesArray = await Promise.all(Array.from(e.target.files).map(async (file) => {
                return {
                    photo_id: null,
                    path: await readFileAsBase64(file),
                    photo_originalname: file.name,
                    is_main : false
                }
            }));
            setAccObjectData((prevData) => {
                return {
                    ...prevData,
                    objectPhotoGallery: prevData.objectPhotoGallery.concat(filesArray)
                }
            });
            setImageGallery((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleRemoveImageFromGallery = (index, photo_id) => {
        setAccObjectData((prevData) => {
            return {
                ...prevData,
                objectPhotoGallery: prevData.objectPhotoGallery.filter((photo, photoIndex) => photoIndex !== index),
                photosToRemove: [...prevData.photosToRemove, photo_id !== 'null' ? photo_id : null]
            }
        });
        setImageGallery((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    React.useEffect(() => {
        if (editState.objectId !== null) {
            dispatch(getAccObjectDataToEditAction(editState.objectId));
        }
    }, [editState]);

    React.useEffect(() => {
        if (accObjectEditData?.success && typeof accObjectEditData !== 'undefined' ) {
            const data = { ...accObjectEditData?.data };
            delete data.accObjectEquipment.id;
            delete data.accObjectEquipment.acc_object;
            if (data.accObjectPhones.length > 0) {
                for (let i = 0; i < data.accObjectPhones.length; i++) {
                    objectPhoneNumbers[i] = { ...data.accObjectPhones[i] };
                }
            }
            setAccObjectData({
                ...accObjectData,
                objectEquipment: { ...data.accObjectEquipment },
                objectName: data.accObject.name,
                objectDescription: data.accObject.description,
                objectAddress: data.accObject.address,
                objectLatitude: data.accObject.latitude,
                objectLongitude: data.accObject.longitude,
                objectBeachDistance: data.accObject.to_beach,
                objectStoreDistance: data.accObject.to_store,
                objectRestaurantDistance: data.accObject.to_restaurant,
                objectCityCenterDistance: data.accObject.to_city_center,
                objectPriceType: +data.accObject.price_type - 1,
                objectPrice: data.accObject.minimal_price_from,
                objectContactOnlyByPhone: data.accObject.contact_only_by_phone,
                objectPhoneNumbers: [...objectPhoneNumbers],
                objectEmailAddress: data.accObject.contact_email,
                objectWebsite: data.accObject.web_site,
                objectCategory: data.accObject.category,
                objectAdvancePayment: data.accObject.advance_payment,
                objectAdvanceAmount: data.accObject.advance_amount,
                objectNonRefundableAdvance: data.accObject.non_refundable_advance,
                objectPaymentData: data.accObject.payment_data,
                objectPhotoGallery: [...data.accObjectPhotos],
                accObjectId: data.accObject.id,
                objectAccUnits: [...data.accUnits]
            });
            setImageGallery([...data.accObjectPhotos]);
        }
    }, [accObjectEditData]);

    React.useEffect(() => {
        if (typeof updateAccObject !== 'undefined') {
            if (!updateAccObject.success) {
                setShowSnackBar({
                    show: true,
                    message: updateAccObject.message,
                    variant: 'error'
                });
            } else {
                setShowSnackBar({
                    show: true,
                    message: 'Podaci uspješno ažurirani',
                    variant: 'success'
                });
            }
        }
        if (typeof deleteAccUnit !== 'undefined') {
            if (!deleteAccUnit.success) {
                setShowSnackBar({
                    show: true,
                    message: deleteAccUnit.message,
                    variant: 'error'
                });
            } else {
                setShowSnackBar({
                    show: true,
                    message: 'Uklonjena smještajna jedinica',
                    variant: 'success'
                });
            }
        }
    }, [updateAccObject, deleteAccUnit]);

    function AddMarkerToClick() {

        useMapEvents({
            click(e) {
                setAccObjectData({
                    ...accObjectData,
                    objectLatitude: e.latlng.lat,
                    objectLongitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[accObjectData.objectLatitude, accObjectData.objectLongitude]} />

    };

    const handleChange = (e) => {
        setAccObjectData({
            ...accObjectData,
            [e.target.name]: e.target.value
        });
    };

    const getKeyName = (index) => {
        return Object.keys(accObjectData.objectEquipment)[index];
    };

    const handleSubmit_Osnovni_Podaci = (e) => {
        e.preventDefault();
        const payload = {
            name: accObjectData.objectName,
            description: accObjectData.objectDescription,
            photoGalleryData: accObjectData.objectPhotoGallery,
            deletedPhotosIds: accObjectData.photosToRemove,
            accObjectId: accObjectData.accObjectId
        }
        dispatch(updateAccObjectAction(payload, 0));
    };

    const handleSubmit_Podaci_O_Lokaciji = (e) => {
        e.preventDefault();
        const payload = {
            latitude: accObjectData.objectLatitude,
            longitude: accObjectData.objectLongitude,
            address: accObjectData.objectAddress,
            to_beach: accObjectData.objectBeachDistance,
            to_restaurant: accObjectData.objectRestaurantDistance,
            to_store: accObjectData.objectStoreDistance,
            to_city_center: accObjectData.objectCityCenterDistance,
            accObjectId: accObjectData.accObjectId
        }
        dispatch(updateAccObjectAction(payload, 1));
    };

    const handleSubmit_Ostali_Podaci = (e) => {
        e.preventDefault();
        const fixedPhoneNumbers = accObjectData.objectPhoneNumbers.map((phoneData) => {
            return {
                ...phoneData,
                whatsapp: Boolean(phoneData.whatsapp),
                viber: Boolean(phoneData.viber)
            }
        })
        const payload = {
            price_type: +accObjectData.objectPriceType + 1,
            minimal_price_from: accObjectData.objectPrice,
            contact_only_by_phone: Boolean(accObjectData.objectContactOnlyByPhone),
            updatedPhoneNumbers: fixedPhoneNumbers,
            contact_email: accObjectData.objectEmailAddress,
            web_site: accObjectData.objectWebsite,
            category: accObjectData.objectCategory,
            accObjectId: accObjectData.accObjectId
        };
        dispatch(updateAccObjectAction(payload, 2));
    };

    const handleSubmit_Oprema_Objekta = (e) => {
        e.preventDefault();
        const payload = {
            internet: Boolean(accObjectData.objectEquipment.internet),
            parking: Boolean(accObjectData.objectEquipment.parking),
            air_conditioner: Boolean(accObjectData.objectEquipment.air_conditioner),
            seaview: Boolean(accObjectData.objectEquipment.seaview),
            pool: Boolean(accObjectData.objectEquipment.pool),
            restaurant: Boolean(accObjectData.objectEquipment.restaurant),
            reception: Boolean(accObjectData.objectEquipment.reception),
            baby_crib: Boolean(accObjectData.objectEquipment.baby_crib),
            disability_friendly: Boolean(accObjectData.objectEquipment.disability_friendly),
            accObjectId: accObjectData.accObjectId
        }
        dispatch(updateAccObjectAction(payload, 3));
    };

    const handleSubmit_Uslovi_Rezervacije = (e) => {
        e.preventDefault();
        const payload = {
            advance_payment: Boolean(accObjectData.objectAdvancePayment),
            advance_amount: accObjectData.objectAdvanceAmount,
            non_refundable_advance: Boolean(accObjectData.objectNonRefundableAdvance),
            payment_data: accObjectData.objectPaymentData,
            accObjectId: accObjectData.accObjectId
        };
        dispatch(updateAccObjectAction(payload, 4));
    };

    const handleDeleteUnit = (accUnitId) => {
        dispatch(deleteAccUnitAction({ accUnitId }));
    };

    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };

    return (
        <Dialog
            fullScreen
            open={editState.show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#03A678' }}>
                <Toolbar className={classes.accUnitToolbar}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CancelIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Uredi objekat
                        </Typography>
                    </div>
                </Toolbar>
                {
                    loading_update || loading_delete_unit
                        ?
                        <div>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        null
                }
            </AppBar>
            {
                loading
                    ?
                    <div style={{ width: '90%', margin: 'auto', marginTop: '20%', textAlign: 'center' }}>
                        <CircularProgress size={200} />
                    </div>
                    :
                    typeof accObjectEditData !== 'undefined'
                        ?
                        accObjectEditData.success
                            ?
                            <div className={classes.editObjectDiv}>

                                <form onSubmit={handleSubmit_Osnovni_Podaci}>
                                    <Paper elevation={7} className={classes.addNewObject_section1}>
                                        <div className={classes.sectionTitle} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography variant='h5'>Osnovni podaci</Typography>
                                            <Button
                                                variant='contained'
                                                style={{ backgroundColor: '#26A65B', color: 'white' }}
                                                type='submit'>
                                                Promijeni
                                            </Button>
                                        </div>
                                        <Grid container spacing={4} className={classes.section1Form}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    id="objectName"
                                                    label="Naziv objekta"
                                                    name="objectName"
                                                    type="text"
                                                    value={accObjectData.objectName}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    label="Opis objekta"
                                                    fullWidth
                                                    variant="outlined"
                                                    multiline
                                                    name='objectDescription'
                                                    value={accObjectData.objectDescription}
                                                    onChange={handleChange}
                                                    minRows={6}
                                                    maxRows={8}
                                                    required
                                                    type='text'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                                                <div className={classes.section1GalleryTitle}>
                                                    <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        color='primary'
                                                    >
                                                        <AddIcon fontSize='small' />
                                                        <input
                                                            id='dugme'
                                                            type="file"
                                                            onChange={handleImageChange}
                                                            multiple
                                                            accept='image/*'
                                                            hidden
                                                        />
                                                    </Button>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                                        Izabrana glavna fotografija
                                                    </Typography>
                                                    {
                                                        checkSelectedImage(accObjectData.objectPhotoGallery) !== -1
                                                            ?
                                                            <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                                                            :
                                                            <CloseIcon color='error' fontSize='large' />
                                                    }
                                                </div>
                                                <div className={classes.section1ImagePreviewDiv}>
                                                    {
                                                        imageGallery.map((photo, index) => {
                                                            return (
                                                                <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                                    <img
                                                                        onClick={() => {
                                                                            const gallery = accObjectData.objectPhotoGallery.map((photo, indexG) => {
                                                                                if (index === indexG) {
                                                                                    photo.is_main = true;
                                                                                    return photo
                                                                                } else {
                                                                                    photo.is_main = false;
                                                                                    return photo;
                                                                                }
                                                                            });
                                                                            setAccObjectData((prevData) => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    objectPhotoGallery: [...gallery]
                                                                                }
                                                                            });
                                                                        }}
                                                                        src={photo.photo_id === null ? photo.path : process.env.REACT_APP_UPLOADS_PATH + photo.path}
                                                                        width={230}
                                                                        alt={`Object ${index}`}
                                                                        style={{
                                                                            borderRadius: '10px',
                                                                            cursor: 'pointer',
                                                                            border: checkSelectedImage(accObjectData.objectPhotoGallery) === index ? '4px solid green' : '1px solid transparent'
                                                                        }} />
                                                                    <div key={2} className={classes.editImage} >
                                                                        <IconButton onClick={() => { handleRemoveImageFromGallery(index, photo.photo_id); }}>
                                                                            <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Paper>
                                </form>
                                <form onSubmit={handleSubmit_Podaci_O_Lokaciji}>
                                    <Paper elevation={7} className={classes.addNewObject_section2}>
                                        <div className={classes.sectionTitle} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography variant='h5'>Podaci o lokaciji</Typography>
                                            <Button
                                                variant='contained'
                                                style={{ backgroundColor: '#26A65B', color: 'white' }}
                                                type='submit'>
                                                Promijeni
                                            </Button>
                                        </div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={12} md={12}>

                                                <MapContainer
                                                    center={[42.207232, 18.943100]}
                                                    zoom={16}
                                                    tap={false}
                                                    className={classes.map}
                                                >
                                                    <TileLayer
                                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />

                                                    <AddMarkerToClick />
                                                </MapContainer>

                                            </Grid>

                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectLatitude"
                                                    label="Latituda"
                                                    name="objectLatitude"
                                                    value={accObjectData.objectLatitude}
                                                    onChange={handleChange}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectLongitude"
                                                    label="Longituda"
                                                    name="objectLongitude"
                                                    value={accObjectData.objectLongitude}
                                                    onChange={handleChange}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectAddress"
                                                    label="Adresa"
                                                    name="objectAddress"
                                                    value={accObjectData.objectAddress}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectCityCenterDistance"
                                                    label="Do centra grada (m)"
                                                    value={accObjectData.objectCityCenterDistance}
                                                    onChange={handleChange}
                                                    name="objectCityCenterDistance"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectBeachDistance"
                                                    label="Do plaže (m)"
                                                    value={accObjectData.objectBeachDistance}
                                                    onChange={handleChange}
                                                    name="objectBeachDistance"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectStoreDistance"
                                                    label="Do prodavnice (m)"
                                                    value={accObjectData.objectStoreDistance}
                                                    onChange={handleChange}
                                                    name="objectStoreDistance"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectRestaurantDistance"
                                                    label="Do restorana (m)"
                                                    value={accObjectData.objectRestaurantDistance}
                                                    onChange={handleChange}
                                                    name="objectRestaurantDistance"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </form>
                                <form onSubmit={handleSubmit_Ostali_Podaci}>
                                    <Paper elevation={7} className={classes.addNewObject_section3}>
                                        <div className={classes.sectionTitle} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography variant='h5'>Ostali podaci</Typography>
                                            <Button
                                                variant='contained'
                                                style={{ backgroundColor: '#26A65B', color: 'white' }}
                                                type='submit'>
                                                Promijeni
                                            </Button>
                                        </div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={6} md={3}>
                                                <InputLabel id="demo-simple-select-label">Tip cijene</InputLabel>
                                                <Select
                                                    fullWidth
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    name='objectPriceType'
                                                    value={accObjectData.objectPriceType}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {
                                                        priceTypes.map((priceType, index) => {
                                                            return (
                                                                <MenuItem value={index} key={index}>
                                                                    {priceType}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item xs={6} md={9}>
                                                <TextField
                                                    disabled={accObjectData.objectPriceType === 0 || accObjectData.objectPriceType === '' ? true : false}
                                                    fullWidth
                                                    variant="outlined"
                                                    required={accObjectData.objectPriceType === 0 || accObjectData.objectPriceType === '' ? true : false}
                                                    id="objectPrice"
                                                    label="Cijena u eurima"
                                                    name="objectPrice"
                                                    value={accObjectData.objectPrice}
                                                    onChange={handleChange}
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={accObjectData.objectContactOnlyByPhone}
                                                                    onChange={(e) => {
                                                                        setAccObjectData({
                                                                            ...accObjectData,
                                                                            objectContactOnlyByPhone: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!accObjectData.objectContactOnlyByPhone}
                                                                    onChange={(e) => {
                                                                        setAccObjectData({
                                                                            ...accObjectData,
                                                                            objectContactOnlyByPhone: false
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>

                                            {
                                                accObjectData.objectPhoneNumbers.map((phone, index) => {
                                                    return (
                                                        <Grid item xs={12} md={12} key={index}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                required={index === 0 && accObjectData.objectContactOnlyByPhone ? true : false}
                                                                id="numOfGrownups"
                                                                label={`Broj telefona ${index + 1}`}
                                                                name="phoneNum"
                                                                type="text"
                                                                value={accObjectData.objectPhoneNumbers[index].phone_number}
                                                                onChange={(e) => {
                                                                    setAccObjectData((prevData) => {
                                                                        prevData.objectPhoneNumbers[index].phone_number = e.target.value;
                                                                        return { ...prevData }
                                                                    });
                                                                }}
                                                            />
                                                            <FormControl component="fieldset">
                                                                <FormGroup row>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={accObjectData.objectPhoneNumbers[index].viber}
                                                                                onChange={(e) => {
                                                                                    setAccObjectData((prevData) => {
                                                                                        prevData.objectPhoneNumbers[index].viber = e.target.checked;
                                                                                        return { ...prevData }
                                                                                    });
                                                                                }}
                                                                            />
                                                                        }
                                                                        label="Viber"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={accObjectData.objectPhoneNumbers[index].whatsapp}
                                                                                onChange={(e) => {
                                                                                    setAccObjectData((prevData) => {
                                                                                        prevData.objectPhoneNumbers[index].whatsapp = e.target.checked;
                                                                                        return { ...prevData }
                                                                                    });
                                                                                }}
                                                                            />
                                                                        }
                                                                        label="Whatsapp"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                })
                                            }

                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectEmailAddress"
                                                    label="Email adresa na koju primate poruke"
                                                    name="objectEmailAddress"
                                                    type="text"
                                                    value={accObjectData.objectEmailAddress}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectWebsite"
                                                    label="Link vaseg web sajta"
                                                    name="objectWebsite"
                                                    type="text"
                                                    value={accObjectData.objectWebsite}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <InputLabel id="demo-simple-select-label-cat">Kategorizacija</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-label-cat"
                                                    value={accObjectData.objectCategory}
                                                    name='objectCategory'
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    <MenuItem value={0}>
                                                        Nije kategorisan
                                                    </MenuItem>
                                                    {
                                                        accommodationCategory.map((category, index) => {
                                                            return (
                                                                <MenuItem value={index + 1} key={index}>
                                                                    {category}
                                                                    <StarIcon fontSize='small' style={{ color: 'gold' }} />
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </form>
                                <form onSubmit={handleSubmit_Oprema_Objekta}>
                                    <Paper elevation={7} className={classes.addNewObject_section4}>
                                        <div className={classes.sectionTitle} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography variant='h5'>Oprema objekta</Typography>
                                            <Button
                                                variant='contained'
                                                style={{ backgroundColor: '#26A65B', color: 'white' }}
                                                type='submit'>
                                                Promijeni
                                            </Button>
                                        </div>
                                        <div className={classes.equipmentDiv}>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    <div className={classes.equipment}>
                                                        {
                                                            equipment.map((item, index) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={
                                                                                    accObjectData.objectEquipment[getKeyName(index)]
                                                                                }
                                                                                onChange={(e) => {
                                                                                    const keyName = getKeyName(index);
                                                                                    const updatedEquipment = {
                                                                                        ...accObjectData.objectEquipment,
                                                                                        [keyName]: e.target.checked
                                                                                    };
                                                                                    setAccObjectData((prevData) => {
                                                                                        return {
                                                                                            ...prevData,
                                                                                            objectEquipment: updatedEquipment
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={item}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </Paper>
                                </form>
                                <Paper elevation={7} className={classes.addNewObject_section4}>
                                    <div className={classes.sectionTitle}><Typography variant='h5'>Smještajne jedinice</Typography></div>
                                    <div className={classes.section2MapDiv} style={{ textAlign: 'center' }}>
                                        <Button
                                            onClick={() => {
                                                setAddNewUnitState({
                                                    show: true,
                                                    accObjectId: accObjectData.accObjectId
                                                });
                                            }}
                                            variant='contained'
                                            style={{ color: '#317589' }}>
                                            <AddIcon />
                                            Dodaj
                                        </Button>
                                    </div>
                                    <div className={classes.accUnits}>
                                        {
                                            accObjectData.objectAccUnits.map((unit, index) => {
                                                return (
                                                    <div className={classes.singleUnit} key={index}>
                                                        <Typography variant='subtitle1'>{unit.unit_name}</Typography>
                                                        <IconButton
                                                            onClick={() => {
                                                                setEditUnitState({
                                                                    show: true,
                                                                    accUnitId: unit.unit_id
                                                                });
                                                            }}
                                                            style={{ marginLeft: '10px', color: '#26A65B' }}>
                                                            <EditIcon fontSize='medium' />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                handleDeleteUnit(unit.unit_id);
                                                            }}
                                                            style={{ marginLeft: '10px', color: '#DC3023' }}>
                                                            <CancelIcon fontSize='medium' />
                                                        </IconButton>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Paper>
                                <form onSubmit={handleSubmit_Uslovi_Rezervacije}>
                                    <Paper elevation={7} className={classes.addNewObject_section4}>
                                        <div className={classes.sectionTitle} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography variant='h5'>Uslovi rezervacije</Typography>
                                            <Button
                                                variant='contained'
                                                style={{ backgroundColor: '#26A65B', color: 'white' }}
                                                type='submit'>
                                                Promijeni
                                            </Button>
                                        </div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={12} md={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Avans obavezan?</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(accObjectData.objectAdvancePayment)}
                                                                    onChange={(e) => {
                                                                        setAccObjectData({
                                                                            ...accObjectData,
                                                                            objectAdvancePayment: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={accObjectData.objectAdvancePayment === '' ? false : !Boolean(accObjectData.objectAdvancePayment)}
                                                                    onChange={(e) => {
                                                                        setAccObjectData({
                                                                            ...accObjectData,
                                                                            objectAdvancePayment: false,
                                                                            objectAdvanceAmount: ''
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <InputLabel id="demo-simple-select-label">Iznos avansa</InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={accObjectData.objectAdvancePayment ? false : true}
                                                    required={accObjectData.objectAdvancePayment ? true : false}
                                                    labelId="demo-simple-select-label"
                                                    value={accObjectData.objectAdvanceAmount}
                                                    name='objectAdvanceAmount'
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {
                                                        advancePercentage.map((percent, index) => {
                                                            return (
                                                                <MenuItem value={percent} key={index}>
                                                                    {percent} &#37;
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Avans nepovratan kod otkazivanja?</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={accObjectData.objectAdvancePayment ? false : true}
                                                                    checked={Boolean(accObjectData.objectNonRefundableAdvance)}
                                                                    onChange={(e) => {
                                                                        setAccObjectData({
                                                                            ...accObjectData,
                                                                            objectNonRefundableAdvance: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={accObjectData.objectAdvancePayment ? false : true}
                                                                    checked={accObjectData.objectNonRefundableAdvance === '' ? false : !Boolean(accObjectData.objectNonRefundableAdvance)}
                                                                    onChange={(e) => {
                                                                        setAccObjectData({
                                                                            ...accObjectData,
                                                                            objectNonRefundableAdvance: false
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    label="Podaci za uplatu"
                                                    fullWidth
                                                    variant="outlined"
                                                    multiline
                                                    minRows={6}
                                                    maxRows={8}
                                                    required
                                                    type='text'
                                                    name='objectPaymentData'
                                                    value={accObjectData.objectPaymentData}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </form>

                                <div className={classes.addNewObjectButtons}>
                                    <Button

                                        variant='text'
                                        style={{ color: '#DC3023' }}>
                                        <CancelIcon />
                                        Odustani
                                    </Button>
                                </div>
                            </div>
                            :
                            <Alert severity="error" style={{ marginTop: '20%' }}>
                                <AlertTitle>Greška</AlertTitle>
                                Doslo je do greske
                            </Alert>
                        :
                        null
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                open={showSnackBar.show}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}>
                <Alert
                    variant='filled'
                    onClose={handleCloseSnackBar}
                    severity={showSnackBar.variant}
                    sx={{ width: '100%' }}>
                    {showSnackBar.message}
                </Alert>
            </Snackbar>
            <EditAccUnitDialog editUnitState={editUnitState} setEditUnitState={setEditUnitState} />
            <AddAccUnitDialog
                show={addNewUnitState.show}
                addNewUnitState={addNewUnitState}
                setAddNewUnitState={setAddNewUnitState}
                editUnit={false} />
        </Dialog>
    );
}

export default EditAccObjectDialog