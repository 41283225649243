import { DialogContent, Dialog, Typography, Divider, IconButton, CircularProgress } from '@material-ui/core';
import React from 'react';
import useStyle from './style';
import { AlertTitle, Alert } from '@material-ui/lab';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { Carousel } from 'react-responsive-carousel';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/PeopleAlt';
import { showAccUnitAction } from '../actions/acc_objects_actions';
import { useTranslation } from 'react-i18next';
import unitEquipment from './acc_unit_equipment';

const UnitDialogue = ({ unitDialogue, setShow, isMobile, currentLanguage }) => {

    const dispatch = useDispatch();
    const classes = useStyle();

    const { t } = useTranslation();

    const handleClose = () => {
        setShow({
            show: false,
            unitId: null
        });
    };

    const showAccUnitSelector = useSelector(state => state.showAccUnitState);
    const { loading, showAccUnitData: data } = showAccUnitSelector;

    React.useEffect(() => {
        if (unitDialogue.unitId !== null) {
            dispatch(showAccUnitAction(unitDialogue.unitId));
        }
    }, [unitDialogue]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>{t("error")}</AlertTitle>
                {t("acc_unit_page_error_message")}
            </Alert>
        );
    };

    return (
        <Dialog
            onClose={handleClose}
            open={unitDialogue.show}
            maxWidth="xl"
            fullWidth
            fullScreen={isMobile}>
            {
                loading
                    ?
                    <div style={{
                        width: '80%',
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '250px'
                    }}>
                        <CircularProgress size={150} style={{ color: '#0F79A1' }} thickness={7} />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data?.success
                            ?
                            <>
                                <div className={classes.unitTitle}>
                                    <Typography variant='h5'>
                                        {data.accUnitData.name}
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CancelIcon fontSize='medium' style={{ color: '#C3272B' }} />
                                    </IconButton>
                                </div>
                                <DialogContent className={classes.unitDiv}>
                                    <div className={classes.unitPhotoGallery}>
                                        <Carousel
                                            showThumbs={false}
                                            dynamicHeight
                                            // axis='vertical'
                                            emulateTouch
                                            className={classes.carouselUnit}
                                            swipeable
                                            showStatus={false}>
                                            {
                                                data.accUnitData.accUnitPhotos.length > 0
                                                    ?
                                                    data.accUnitData.accUnitPhotos.map((image, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + image}')` }}
                                                                className={classes.imageDiv}>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div>
                                                        <img src={noPhotoAvailable} alt='No Photo' />
                                                    </div>
                                            }
                                        </Carousel>
                                    </div>
                                    <div className={classes.unitInfoSection}>
                                        <div>

                                            <Typography variant='subtitle1' className={classes.unitInfo}>
                                                <strong style={{ marginRight: '5px' }}>{t("acc_object_page_maximum")}</strong>
                                                {data.accUnitData.max_occupancy} {t("acc_object_unit_persons")}
                                                <PeopleIcon style={{ marginLeft: '5px', color: '#0F79A1' }} fontSize='small' />
                                            </Typography>
                                            <Typography variant='subtitle1' className={classes.unitInfo}>
                                                <strong style={{ marginRight: '5px' }}>{t("acc_object_page_num_of_rooms")}</strong>
                                                {data.accUnitData.num_of_rooms}
                                                <RoomIcon style={{ marginLeft: '5px', color: '#0F79A1' }} fontSize='small' />
                                            </Typography>
                                            <Typography variant='subtitle1' className={classes.unitInfo}>
                                                <strong style={{ marginRight: '5px' }}>{t("acc_object_page_area")}</strong>
                                                {data.accUnitData.area}
                                                &#13217;
                                            </Typography>
                                        </div>
                                        <Divider style={{ marginTop: '7px', marginBottom: '7px' }}></Divider>
                                        <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: '7px' }}> {t("acc_object_page_equipment")} </Typography>
                                        <div className={classes.unitEquipment}>
                                            {
                                                Object.entries(data.accUnitData.accUnitEquipment).map((item, index) => {
                                                    return (
                                                        <div key={index} className={classes.singleItem}>

                                                            {
                                                                item[1]
                                                                    ?
                                                                    <DoneIcon fontSize='small' color='primary' />
                                                                    :
                                                                    <CloseIcon fontSize='small' color='secondary' />
                                                            }
                                                            <Typography variant='subtitle2'> {unitEquipment[currentLanguage][index]} </Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </DialogContent>
                            </>
                            :
                            <ShowError />
                        :
                        null
            }
        </Dialog>
    )
}

export default UnitDialogue;