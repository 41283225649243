

export const addNewRestaurantReducer = (newRestaurantData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_RESTAURANT':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_RESTAURANT':
        case 'ERROR_ADD_NEW_RESTAURANT':
            return {
                loading: false,
                newRestaurantData: action.payload
            }
        default: return newRestaurantData;
    }
};

export const getRestaurantsTableReducer = (restaurantsTableData = [], action) => {
    switch (action.type) {
        case 'REQUEST_GET_RESTAURANTS_TABLE_DATA':
            return {
                loading: true
            }
        case 'SUCCESS_GET_RESTAURANTS_TABLE_DATA':
        case 'ERROR_GET_RESTAURANTS_TABLE_DATA':
            return {
                loading: false,
                restaurantsTableData: action.payload
            }
        default: return restaurantsTableData;
    }
};

export const deleteRestaurantReducer = (deleteRestaurantData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_DELETE_RESTAURANT':
            return {
                loading: true
            }
        case 'SUCCESS_DELETE_RESTAURANT':
        case 'ERROR_DELETE_RESTAURANT':
            return {
                loading: false,
                deleteRestaurantData: action.payload
            }
        default: return deleteRestaurantData;
    }
};

export const getRestaurantDataEditReducer = (restaurantEditData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_RESTAURANT_EDIT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_RESTAURANT_EDIT':
        case 'ERROR_GET_RESTAURANT_EDIT':
            return {
                loading: false,
                restaurantEditData: action.payload
            }
        default: return restaurantEditData;
    }
};

export const updateRestaurantReducer = (updateRestaurantData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_RESTAURANT':
            return {
                loading_update: true
            }
        case 'SUCCESS_UPDATE_RESTAURANT':
        case 'ERROR_UPDATE_RESTAURANT':
            return {
                loading_update: false,
                updateRestaurantData: action.payload
            }
        default: return updateRestaurantData;
    }
};

export const previewRestaurantReducer = (previewRestaurantData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_PREVIEW_RESTAURANT':
            return {
                loading: true
            }
        case 'SUCCESS_PREVIEW_RESTAURANT':
        case 'ERROR_PREVIEW_RESTAURANT':
            return {
                loading: false,
                previewRestaurantData: action.payload
            }
        default: return previewRestaurantData;
    }
};

export const getAllRestaurantsReducer = (allRestaurantsData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ALL_RESTAURANTS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ALL_RESTAURANTS':
        case 'ERROR_GET_ALL_RESTAURANTS':
            return {
                loading: false,
                allRestaurantsData: action.payload
            }
        default: return allRestaurantsData;
    };
};

export const makeReservationReducer = (makeReservationData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_MAKE_RESERVATION':
            return {
                loading: true
            }
        case 'SUCCESS_MAKE_RESERVATION':
        case 'ERROR_MAKE_RESERVATION':
            return {
                loading: false,
                makeReservationData: action.payload
            }
        default: return makeReservationData;
    };
};

export const getRestaurantReducer = (getRestaurantData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_RESTAURANT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_RESTAURANT':
        case 'ERROR_GET_RESTAURANT':
            return {
                loading: false,
                getRestaurantData: action.payload
            }
        default: return getRestaurantData;
    };
};