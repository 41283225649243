import React from 'react';
import {
  Dialog,
  Toolbar,
  AppBar,
  Typography,
  Button,
  IconButton,
  Paper,
  Grid,
  FormLabel,
  TextField,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Snackbar,
  LinearProgress,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import { Slide } from '@material-ui/core';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { getEventDataForEditAction, updateEventAction } from '../actions/events_actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const snackBarInitialState = {
  show: false,
  severity: 'info',
  snackTitle: '',
  snackMessage: ''
};

const newEventInitialData = {
  name: '',
  address: '',
  latitude: '',
  longitude: '',
  type: '',
  date: null,
  time: '',
  price: '',
  contact_only_by_phone: true,
  email_for_reservation: '',
  website: '',
  imageGallery: [],
  phoneNumbers: [],
  photosToRemove: [],
};

const eventTypes = ['Muzika', 'Sport', 'Kultura'];

const phoneNumbers = [
  {
    phoneNumber: '',
    viber: false,
    whatsapp: false
  },
  {
    phoneNumber: '',
    viber: false,
    whatsapp: false
  },
  {
    phoneNumber: '',
    viber: false,
    whatsapp: false
  }
];

const phones = [1, 2, 3];

const EditEventDialog = ({ editEventState, setEditEventState }) => {

  const classes = useStyles();

  const dispatch = useDispatch();

  const [eventData, setEventData] = React.useState(newEventInitialData);

  const [imageGalleryToBeDisplayed, setImageGalleryToBeDisplayed] = React.useState([]);

  const [showSnackBar, setSnackBar] = React.useState({
    show: false,
    variant: 'info',
    message: ''
  });

  const handleCloseSnackBar = () => {
    setSnackBar({
      show: false,
      variant: 'info',
      message: ''
    });
  };

  const eventDataForEditSelector = useSelector(state => state.getEventDataForEditState);
  const { loading, eventDataForEdit } = eventDataForEditSelector;

  const updateEventSelector = useSelector(state => state.updateEventState);
  const { loading_update, updateEvent } = updateEventSelector;

  const handleClose = () => {
    setEditEventState({
      show: false,
      eventId: null
    });
  };

  const handleImageChange = async (e) => {
    if (e.target.files) {
      const filesArray = await Promise.all(Array.from(e.target.files).map(async (file) => {
        return {
          photo_id: null,
          path: await readFileAsBase64(file),
          photo_originalname: file.name,
          is_main: false
        }
      }));
      setEventData((prevData) => {
        return {
          ...prevData,
          imageGallery: prevData.imageGallery.concat(filesArray)
        }
      });
      setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.concat(filesArray));
    }
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      }
    });
  };

  const handleRemoveImageFromGallery = (index, photo_id) => {
    setEventData((prevData) => {
      return {
        ...prevData,
        imageGallery: prevData.imageGallery.filter((photo, photoIndex) => photoIndex !== index),
        photosToRemove: [...prevData.photosToRemove, photo_id !== null ? photo_id : null]
      }
    });
    setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
  };

  React.useEffect(() => {
    if (editEventState.eventId !== null) {
      dispatch(getEventDataForEditAction(editEventState.eventId));
    }
  }, [editEventState]);

  React.useEffect(() => {
    if (eventDataForEdit?.success) {

      const loadedData = { ...eventDataForEdit.event };

      if (loadedData.eventPhoneNumbers.length > 0) {
        for (let i = 0; i < loadedData.eventPhoneNumbers.length; i++) {
          phoneNumbers[i] = { ...loadedData.eventPhoneNumbers[i] };
        }
      }

      setEventData({
        ...eventData,
        eventId: loadedData.id,
        name: loadedData.name,
        date : loadedData.date,
        time : loadedData.time,
        address: loadedData.address,
        contact_only_by_phone: loadedData.contact_only_by_phone,
        email_for_reservation: loadedData.email_for_reservation,
        latitude: loadedData.latitude,
        longitude: loadedData.longitude,
        website: loadedData.website,
        price: loadedData.price,
        type: eventTypes.findIndex(type => type === loadedData.type),
        phoneNumbers,
        imageGallery: [...loadedData.eventPhotoGallery]
      });
      setImageGalleryToBeDisplayed([...loadedData.eventPhotoGallery])

    }
  }, [eventDataForEdit]);


  React.useEffect(() => {
    if (typeof updateEvent !== 'undefined') {
      if (updateEvent?.success) {
        setSnackBar({
          show: true,
          variant: 'success',
          message: 'Podaci ažurirani'
        })
      } else {
        setSnackBar({
          show: false,
          variant: 'error',
          message: 'Greška u ažuriranju'
        });
      }
    }
  }, [updateEvent]);

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value
    });
  };

  function AddMarkerToClick() {
    useMapEvents({
      click(e) {
        setEventData({
          ...eventData,
          latitude: e.latlng.lat,
          longitude: e.latlng.lng
        });
      }
    });
    return <Marker position={[eventData.latitude, eventData.longitude]} />
  };

  const handleUpdateEvent = (e) => {
    e.preventDefault();
    dispatch(updateEventAction(eventData));
  };


  const checkSelectedImage = (gallery) => {
    const index = gallery.findIndex((photo) => photo.is_main);
    return index;
  };

  return (
    <Dialog
      fullScreen
      open={editEventState.show}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#006442' }}>
        <Toolbar className={classes.accUnitToolbar}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CancelIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Ažuriranje događaja
            </Typography>
          </div>
          <div>
            <Button
              form="myForm"
              type='submit'
              style={{ backgroundColor: '#26A65B', color: 'white' }}
              variant='contained'>
              Sačuvaj
            </Button>
          </div>
        </Toolbar>
        {
          loading_update
            ?
            <div>
              <LinearProgress color='secondary' />
            </div>
            :
            null
        }
      </AppBar>


      {
        loading
          ?
          <div style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>
            <CircularProgress size={150} />
          </div>
          :
          typeof eventDataForEdit !== 'undefined'
            ?
            eventDataForEdit.success
              ?
              <div className={classes.editObjectDiv}>

                <form onSubmit={handleUpdateEvent} className={classes.addNewObjectDiv} id='myForm'>
                  <Paper elevation={7} className={classes.addNewObject_section1}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o događaju</Typography></div>
                    <Grid container spacing={4} className={classes.section1Form}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          required
                          label="Naziv događaja"
                          name="name"
                          fullWidth
                          type="text"
                          value={eventData.name}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          label="Cijena po osobi"
                          name="price"
                          value={eventData.price}
                          onChange={handleChange}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                          <DatePicker
                            required
                            inputVariant='outlined'
                            fullWidth
                            color='secondary'
                            disablePast
                            format="dd/MM/yyyy"
                            disableToolbar
                            autoOk
                            variant='dialog'
                            label="Datum"
                            value={eventData.date}
                            onChange={(e) => {
                              setEventData((oldData) => {
                                return {
                                  ...oldData,
                                  date: e
                                }
                              })
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DateRangeTwoToneIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputLabel id="demo">Vrijeme</InputLabel>
                        <TextField
                          labelId="demo"
                          variant="standard"
                          required
                          //label="Vrijeme"
                          name="time"
                          fullWidth
                          type="time"
                          value={eventData.time}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputLabel id="demo-simple-select-label">Tip</InputLabel>
                        <Select
                          fullWidth
                          required
                          labelId="demo-simple-select-label"
                          name='type'
                          value={eventData.type}
                          onChange={handleChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {
                            eventTypes.map((eventType, index) => {
                              return (
                                <MenuItem value={index} key={index}>
                                  {eventType}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </Grid>


                      <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                        <div className={classes.section1GalleryTitle}  >
                          <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                          <Button
                            variant="contained"
                            component="label"
                            color='primary'
                          >
                            <AddIcon fontSize='small' />
                            <input
                              id='dugme'
                              type="file"
                              onChange={handleImageChange}
                              multiple
                              accept='image/*'
                              hidden
                            />
                          </Button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography style={{ marginLeft: '10px' }} variant='h6'>
                            Izabrana glavna fotografija
                          </Typography>
                          {
                            checkSelectedImage(eventData.imageGallery) !== -1
                              ?
                              <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                              :
                              <CloseIcon color='error' fontSize='large' />
                          }
                        </div>
                        <div className={classes.section1ImagePreviewDiv}>
                          {
                            imageGalleryToBeDisplayed.map((photo, index) => {
                              return (
                                <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                  <img
                                    onClick={() => {
                                      const gallery = eventData.imageGallery.map((photo, indexG) => {
                                        if (index === indexG) {
                                          photo.is_main = true;
                                          return photo
                                        } else {
                                          photo.is_main = false;
                                          return photo;
                                        }
                                      });
                                      setEventData((prevData) => {
                                        return {
                                          ...prevData,
                                          imageGallery: [...gallery]
                                        }
                                      });
                                    }}
                                    src={photo.photo_id === null ? photo.path : process.env.REACT_APP_UPLOADS_PATH + photo.path}
                                    width={210}
                                    alt={`Object ${index}`}
                                    style={{
                                      borderRadius: '10px',
                                      cursor: 'pointer',
                                      border: checkSelectedImage(eventData.imageGallery) === index ? '4px solid green' : '1px solid transparent'
                                    }} />
                                  <div key={2} className={classes.editImage} >
                                    <IconButton onClick={() => { handleRemoveImageFromGallery(index, photo.photo_id); }}>
                                      <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                    </IconButton>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </Grid>

                    </Grid>
                  </Paper>

                  <Paper elevation={7} className={classes.addNewObject_section2}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                      <Grid item xs={12} md={12}>

                        <MapContainer
                          center={[42.207232, 18.943100]}
                          zoom={16}
                          tap={false}
                          className={classes.map}
                        >
                          <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />

                          <AddMarkerToClick />
                        </MapContainer>

                      </Grid>

                      <Grid item xs={6} md={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="objectLatitude"
                          label="Latituda"
                          name="latitude"
                          value={eventData.latitude}
                          onChange={handleChange}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="objectLongitude"
                          label="Longituda"
                          name="longitude"
                          value={eventData.longitude}
                          onChange={handleChange}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="objectAddress"
                          label="Adresa"
                          name="address"
                          value={eventData.address}
                          onChange={handleChange}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Paper>


                  <Paper elevation={7} className={classes.addNewObject_section3}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                      <Grid item xs={12} md={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eventData.contact_only_by_phone}
                                  onChange={(e) => {
                                    setEventData({
                                      ...eventData,
                                      contact_only_by_phone: true
                                    });
                                  }}
                                />
                              }
                              label="Da"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!eventData.contact_only_by_phone}
                                  onChange={(e) => {
                                    setEventData({
                                      ...eventData,
                                      contact_only_by_phone: false
                                    });
                                  }}
                                />
                              }
                              label="Ne"
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      {
                        phones.map((phone, index) => {
                          return (
                            <Grid item xs={12} md={12} key={index}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                required={index === 0 && eventData.contact_only_by_phone ? true : false}
                                id="numOfGrownups"
                                label={`Broj telefona ${phone}`}
                                name="phoneNum"
                                type="text"
                                value={eventData.phoneNumbers[index]?.phoneNumber}
                                onChange={(e) => {
                                  setEventData((prevData) => {
                                    prevData.phoneNumbers[index].phoneNumber = e.target.value;
                                    return { ...prevData }
                                  });
                                }}
                              />
                              <FormControl component="fieldset">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={Boolean(eventData.phoneNumbers[index]?.viber)}
                                        onChange={(e) => {
                                          setEventData((prevData) => {
                                            prevData.phoneNumbers[index].viber = e.target.checked;
                                            return { ...prevData }
                                          });
                                        }}
                                      />
                                    }
                                    label="Viber"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={Boolean(eventData.phoneNumbers[index]?.whatsapp)}
                                        onChange={(e) => {
                                          setEventData((prevData) => {
                                            prevData.phoneNumbers[index].whatsapp = e.target.checked;
                                            return { ...prevData }
                                          });
                                        }}
                                      />
                                    }
                                    label="Whatsapp"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          )
                        })
                      }

                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="restaurantEmail"
                          label="Email adresa"
                          name="email_for_reservation"
                          type="text"
                          value={eventData.email_for_reservation}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="restaurantWebSite"
                          label="Link vaseg web sajta"
                          name="website"
                          type="text"
                          value={eventData.website}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>

                  </Paper>

                </form>
              </div>
              :
              <Alert severity="error" style={{ marginTop: '20%' }}>
                <AlertTitle>Greška</AlertTitle>
                Doslo je do greske
              </Alert>
            :
            null
      }


      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
        open={showSnackBar.show}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}>
        <Alert
          variant='filled'
          onClose={handleCloseSnackBar}
          severity={showSnackBar.variant}
          sx={{ width: '100%' }}>
          {showSnackBar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EditEventDialog;