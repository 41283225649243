import React from 'react';
import {
    TextField,
    Grid,
    InputAdornment,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    LinearProgress,
    Typography,
    Menu
} from '@material-ui/core';
import useStyles from './style';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { sendQuestionAction, sendMultipleQuestionAction } from '../actions/acc_objects_actions';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import telegramIcon from '../assets/photos/telegram.svg';
import dropdown from '../assets/photos/dropdown.svg';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from "@material-ui/core/styles";
import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { sendCarQuestionAction } from '../actions/rent_a_car_actions';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const locations = [
    'Aerodrom Podgorica',
    'Aerodrom Tivat',
    'Ulcinj',
    'Dobre Vode',
    'Bar',
    'Sutomore',
    'Čanj',
    'Buljarica',
    'Petrovac',
    'Reževići',
    'Sveti Stefan',
    'Rafailovići',
    'Bečići',
    'Budva',
    'Krimovica',
    'Radanovići',
    'Kotor',
    'Tivat',
    'Dobrota',
    'Perast',
    'Risan',
    'Bijela',
    'Kumbor',
    'Herceg Novi',
    'Igalo',
    'Cetinje',
    'Danilovgrad',
    'Nikšić',
    'Podgorica',
    'Andrijevica',
    'Berane',
    'Bijelo Polje',
    'Gusinje',
    'Kolašin',
    'Mojkovac',
    'Petnjica',
    'Plav',
    'Pljevlja',
    'Plužine',
    'Rožaje',
    'Šavnik',
    'Žabljak'];

const initialContactData = {
    pickUpDate: null,
    dropOffDate: null,
    pickUpLocation: '',
    dropOffLocation: '',
    additionalEquipment: [],
    contactName: '',
    contactSurname: '',
    contactEmail: '',
    contactPhoneNumber: '',
    contactQuestionMessage: ''
};

const additionalEquipment = [
    {
        itemName: 'Sjedište za djecu',
        itemId: 0
    },
    {
        itemName: 'GPS navigacija',
        itemId: 1
    },
    {
        itemName: 'Wifi 4G mobile router',
        itemId: 2
    },
    {
        itemName: 'Osiguranje',
        itemId: 3
    },
    {
        itemName: 'Dostava vozila',
        itemId: 4
    },
    {
        itemName: 'Depozit',
        itemId: 5
    },
];

const CarContactForm = ({ carId }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [contactData, setContactData] = React.useState(initialContactData);

    const carQuestionSelector = useSelector(state => state.carQuestionState);
    const { loading, carQuestion } = carQuestionSelector;

    const [showAdditionalEquipmentDialog, setShowAdditionalEquipmentDialog] = React.useState(false);

    const [openPickUpDate, setOpenPickUpDate] = React.useState(false);
    const [openDropOffDate, setOpenDropOffDate] = React.useState(false);

    const [pickUpLocationAnchor, setPickUpLocationAnchor] = React.useState(null);
    const [dropOffLocationAnchor, setDropOffLocationAnchor] = React.useState(null);

    const handleOpenPickUpLocationAnchor = (event) => {
        setPickUpLocationAnchor(event.currentTarget);
    }

    const handleClosePickUpLocationAnchor = () => {
        setPickUpLocationAnchor(null);
    };

    const handleOpenDropOffLocationAnchor = (event) => {
        setDropOffLocationAnchor(event.currentTarget);
    }

    const handleCloseDropOffLocationAnchor = () => {
        setDropOffLocationAnchor(null);
    };

    const handleChange = (e) => {
        setContactData({
            ...contactData,
            [e.target.name]: e.target.value
        });
    };

    const handleContactFormSubmit = (e) => {
        e.preventDefault();

        const carQuestionData = {
            ...contactData,
            pickUpDate : format(contactData.pickUpDate, 'dd/MM/yyyy'),
            dropOffDate : format(contactData.dropOffDate, 'dd/MM/yyyy'),
            additionalEquipment : contactData.additionalEquipment.toString(),
            car : carId
        }

        dispatch(sendCarQuestionAction({carQuestionData}));
    };

    React.useEffect(() => {
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if (fsData) {
            setContactData({
                ...contactData,
                pickUpDate: fsData.arrivalDate,
                dropOffDate: fsData.departureDate,
            });
        }
    }, []);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>{t("error")}</AlertTitle>
                {t("message_form_error_message")}
            </Alert>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success" style={{ marginBottom: '20px' }}>
                <AlertTitle>{t("message_success")}</AlertTitle>
                {t("message_success_message")}
            </Alert>
        );
    };

    const ShowLoading = () => {
        return (
            <div style={{ margin: '20px' }}>
                <Typography variant='caption' style={{ color: '#0F79A1' }}>{t("message_sending_message")}</Typography>
                <LinearProgress style={{ backgroundColor: '#0F79A1' }} />
            </div>
        );
    };


    return (
        <>

            <div className={classes.contactFormDiv}>
                <div className={classes.contactFormTitle}>
                    <img src={telegramIcon} width='30' alt='Telegram' style={{ marginRight: '5px' }} />
                    <Typography style={{ fontSize: '20px', fontWeight: 'bolder', color: '#245d8d' }}>
                        POŠALJI REZERVACIJU
                    </Typography>
                </div>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        typeof carQuestion !== 'undefined'
                            ?
                            carQuestion?.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                }
                <form onSubmit={handleContactFormSubmit} >
                    <Grid container spacing={2} justifyContent='center' alignItems='center'>
                        <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                            <Grid item md={6} xs={6}>
                                <div
                                    onClick={handleOpenPickUpLocationAnchor}
                                    className={classes.formField}
                                    style={{ cursor: 'pointer' }} >
                                    <div>
                                        <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Preuzimanje</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                contactData.pickUpLocation
                                                    ?
                                                    contactData.pickUpLocation
                                                    :
                                                    'Izaberi lokaciju'
                                            }
                                        </Typography>
                                    </div>
                                    <img
                                        src={dropdown}
                                        alt='Dropdown'
                                        width={20}
                                        className={classes.dropdownImg} />
                                </div>
                                <Menu
                                    className={classes.menu}
                                    anchorEl={pickUpLocationAnchor}
                                    open={Boolean(pickUpLocationAnchor)}
                                    onClose={handleClosePickUpLocationAnchor}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                >
                                    {
                                        locations.map((location, index) => {
                                            return (
                                                <MenuItem
                                                    className={classes.menuItem}
                                                    onClick={() => {
                                                        setContactData({
                                                            ...contactData,
                                                            pickUpLocation: location
                                                        });
                                                        handleClosePickUpLocationAnchor();
                                                    }}
                                                    key={index}>
                                                    <Typography variant='subtitle2'>
                                                        {location}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Menu>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <div
                                    onClick={() => {
                                        setOpenPickUpDate(open => !open);
                                    }}
                                    className={classes.formField}
                                    style={{ cursor: 'pointer' }} >
                                    <div>
                                        <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum dolaska</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                contactData.pickUpDate
                                                    ?
                                                    typeof contactData.pickUpDate === 'string'
                                                        ?
                                                        format(parseISO(contactData.pickUpDate), 'dd/MM/yyyy')
                                                        :
                                                        format(contactData.pickUpDate, 'dd/MM/yyyy')
                                                    :
                                                    'Izaberi datum'
                                            }
                                        </Typography>
                                    </div>
                                    <DateRangeTwoToneIcon />
                                </div>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DatePicker
                                        style={{ display: 'none' }}
                                        open={openPickUpDate}
                                        required
                                        color='primary'
                                        disablePast
                                        format="dd/MM/yyyy"
                                        disableToolbar
                                        autoOk
                                        variant='dialog'
                                        emptyLabel='Izaberi datum'
                                        value={contactData.pickUpDate}
                                        onChange={(e) => {
                                            setContactData({
                                                ...contactData,
                                                pickUpDate: e
                                            });
                                            setOpenPickUpDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenPickUpDate(false);
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <div
                                    onClick={handleOpenDropOffLocationAnchor}
                                    className={classes.formField}
                                    style={{ cursor: 'pointer' }} >
                                    <div>
                                        <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Vraćanje</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                contactData.dropOffLocation
                                                    ?
                                                    contactData.dropOffLocation
                                                    :
                                                    'Izaberi lokaciju'
                                            }
                                        </Typography>
                                    </div>
                                    <img
                                        src={dropdown}
                                        alt='Dropdown'
                                        width={20}
                                        className={classes.dropdownImg} />
                                </div>
                                <Menu
                                    className={classes.menu}
                                    anchorEl={dropOffLocationAnchor}
                                    open={Boolean(dropOffLocationAnchor)}
                                    onClose={handleCloseDropOffLocationAnchor}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                >
                                    {
                                        locations.map((location, index) => {
                                            return (
                                                <MenuItem
                                                    className={classes.menuItem}
                                                    onClick={() => {
                                                        setContactData({
                                                            ...contactData,
                                                            dropOffLocation: location
                                                        });
                                                        handleCloseDropOffLocationAnchor();
                                                    }}
                                                    key={index}>
                                                    <Typography variant='subtitle2'>
                                                        {location}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Menu>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <div
                                    onClick={() => {
                                        if (contactData.pickUpDate) {
                                            setOpenDropOffDate(open => !open);
                                        } else {
                                            alert('Prvo odaberite datum dolaska.');
                                        }
                                    }}
                                    className={classes.formField}
                                    style={{ cursor: 'pointer' }} >
                                    <div>
                                        <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum odlaska</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                contactData.dropOffDate
                                                    ?
                                                    typeof contactData.dropOffDate === 'string'
                                                        ?
                                                        format(parseISO(contactData.dropOffDate), 'dd/MM/yyyy')
                                                        :
                                                        format(contactData.dropOffDate, 'dd/MM/yyyy')
                                                    :
                                                    'Izaberi datum'
                                            }
                                        </Typography>

                                    </div>
                                    <DateRangeTwoToneIcon />
                                </div>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DatePicker
                                        style={{ display: 'none' }}
                                        open={openDropOffDate}
                                        disabled={contactData.pickUpDate ? false : true}
                                        required={contactData.pickUpDate ? true : false}
                                        minDate={contactData.pickUpDate}
                                        color='primary'
                                        disablePast
                                        format="dd/MM/yyyy"
                                        disableToolbar
                                        autoOk
                                        variant='dialog'
                                        emptyLabel='Izaberi datum'
                                        value={contactData.dropOffDate}
                                        onChange={(e) => {
                                            setContactData({
                                                ...contactData,
                                                dropOffDate: e
                                            });

                                            setOpenDropOffDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenDropOffDate(false);
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={12}>
                            <div
                                onClick={() => { setShowAdditionalEquipmentDialog(true); }}
                                style={{ cursor: 'pointer' }}
                                className={classes.formField}>
                                <div>
                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Dodatna oprema</strong></Typography>
                                    <Typography variant={contactData.additionalEquipment.length ? 'caption' : 'subtitle2'} style={{ color: '#333333' }}>
                                        {
                                            contactData.additionalEquipment.length
                                                ?
                                                contactData.additionalEquipment.map((item, index) => {
                                                    if (contactData.additionalEquipment.length - 1 === index) {
                                                        return item
                                                    } else {
                                                        return item + ', '
                                                    }
                                                })
                                                :
                                                'Izaberi dodatnu opremu'
                                        }
                                    </Typography>
                                </div>
                                <img
                                    src={dropdown}
                                    alt='Dropdown'
                                    width={28}
                                    className={classes.dropdownImg} />
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactName"
                                label={t("message_form_name")}
                                name="contactName"
                                type="text"
                                value={contactData.contactName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactSurname"
                                label={t("message_form_surname")}
                                name="contactSurname"
                                type="text"
                                value={contactData.contactSurname}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactEmail"
                                label="Email"
                                name="contactEmail"
                                type="email"
                                value={contactData.contactEmail}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactPhoneNumber"
                                label={t("message_form_phone")}
                                name="contactPhoneNumber"
                                type="text"
                                value={contactData.contactPhoneNumber}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                label='Poruka...'
                                fullWidth
                                className={classes.field}
                                variant="outlined"
                                multiline
                                minRows={3}
                                maxRows={5}
                                name='contactQuestionMessage'
                                type='text'
                                value={contactData.contactQuestionMessage}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Button
                                disableElevation
                                fullWidth
                                variant='contained'
                                type='submit'
                                style={{ backgroundColor: '#f79800', color: 'white', fontSize: '18px', fontWeight: 'bold' }} >
                                {t("message_form_send_button")}
                            </Button>
                        </Grid>

                    </Grid>
                </form>
                <Dialog
                    fullWidth
                    open={showAdditionalEquipmentDialog}
                    onClose={() => { setShowAdditionalEquipmentDialog(false); }}>
                    <DialogTitle>Dodatna oprema</DialogTitle>
                    <DialogContent className={classes.additionalEquipmentDiv}>
                        {
                            additionalEquipment.map((eqItem, index) => {
                                return (
                                    <div className={classes.additionalEquipmentItem} key={index}>
                                        {
                                            contactData.additionalEquipment.findIndex((item) => item === eqItem.itemName) !== -1
                                                ?
                                                <CheckedIcon
                                                    onClick={() => {
                                                        setContactData((prevData) => {
                                                            const arr = [...prevData.additionalEquipment.filter(item => item !== eqItem.itemName)];
                                                            return {
                                                                ...prevData,
                                                                additionalEquipment: arr
                                                            }
                                                        });
                                                    }}
                                                    style={{ marginRight: '5px', cursor: 'pointer', color: '#0F79A1' }}
                                                    fontSize='large' />
                                                :
                                                <UncheckedIcon
                                                    onClick={() => {
                                                        setContactData((prevData) => {
                                                            const arr = [...prevData.additionalEquipment, eqItem.itemName];
                                                            return {
                                                                ...prevData,
                                                                additionalEquipment: arr
                                                            }
                                                        })
                                                    }}
                                                    style={{ marginRight: '5px', cursor: 'pointer', color: '#0F79A1' }}
                                                    fontSize='large' />
                                        }

                                        <Typography variant='subtitle1'>
                                            {eqItem.itemName}
                                        </Typography>
                                    </div>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setShowAdditionalEquipmentDialog(false); }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default CarContactForm;