import {
    Typography,
    useTheme,
    useMediaQuery,
    Tooltip,
    Avatar,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress,
    LinearProgress,
    IconButton,
    Box
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyle from './style';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import StarIcon from '@material-ui/icons/Star';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { accObjectEquipmentData } from './acc_object_equipment_data';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import AppsIcon from '@material-ui/icons/Apps';
import { contactOwnerPhotos } from './owner_info_data';
import RecommendedApartments from '../RecommendedApartments/RecommendedApartments';
import UnitDialogue from './UnitDialogue';
import ContactForm from './ContactForm';
import FavoriteIcon from '@material-ui/icons/Favorite';
import backArrow from '../assets/photos/left-arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { showAccObjectAction, emptySendQuestionData } from '../actions/acc_objects_actions';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../App';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/PeopleAlt';
import { useTranslation } from 'react-i18next';
import share from '../assets/photos/share.png';
import telefon from '../assets/photos/telefon.svg';
import website from '../assets/photos/website.svg';
import Lightbox from 'react-image-lightbox';
import SquareMeter from '@material-ui/icons/AspectRatio';
import { useNavigate } from 'react-router-dom';

const MobileView = ({ data }) => {

    const { favoriteAccObjects, setFavoriteAccObjects, currentLanguage } = useContext(GlobalContext);

    const formRef = React.useRef();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [currentLightBoxPhotoIndex, setCurrentLightBoxPhotoIndex] = React.useState(0);
    const [lightBoxOpen, setLightBoxOpen] = React.useState(false);

    const [showUnitDialogue, setShowUnitDialogue] = React.useState({
        show: false,
        unitId: null
    });

    const [accUnitChoice, setAccUnitChoice] = React.useState('');

    const getPriceType = (priceType, price) => {
        switch (priceType) {
            case 3:
                return (
                    <>
                        <Typography variant={!isMobile ? 'h4' : 'h5'} style={{ color: "#ce0b0b" }}>
                            <strong>&euro;{price}</strong>&nbsp;
                        </Typography>
                        <Typography variant={!isMobile ? 'h4' : 'h5'} >
                            / {t("acc_object_page_per_night")}
                        </Typography>
                    </>
                )
            case 2:
                return (
                    <>
                        <Typography variant={!isMobile ? 'h4' : 'h5'} style={{ color: "#ce0b0b" }}>
                            <strong>&euro;{price}</strong>&nbsp;
                        </Typography>
                        <Typography variant={!isMobile ? 'h4' : 'h5'} >
                            / {t("acc_object_page_per_person")}
                        </Typography>
                    </>
                )
            case 1:
                return (
                    <Typography variant={!isMobile ? 'h4' : 'h5'}>
                        {t("acc_object_page_on_request")}
                    </Typography>
                )
            default: return (
                <Typography variant={!isMobile ? 'h4' : 'h5'}>
                    {t("acc_object_page_on_request")}
                </Typography>
            )
        }
    };

    const checkFavorite = (id) => {
        return favoriteAccObjects.find(favId => favId === id) ? true : false;
    };

    const numOfStars = () => {
        if (data.accObjectData.category === 0) {
            return null;
        } else {
            const stars = [];
            for (let i = 0; i < data.accObjectData.category; i++) {
                stars.push(
                    <StarIcon fontSize='medium' key={i} />
                )
            }
            return stars;
        }
    };

    const ShowLightBox = () => {
        return (
            <>
                {
                    lightBoxOpen
                        ?
                        <Lightbox
                            //animationDisabled={true}
                            animationDuration={0}
                            mainSrc={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[currentLightBoxPhotoIndex]}
                            nextSrc={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[(currentLightBoxPhotoIndex + 1) % data.accObjectData.accObjectPhotos.length]}
                            prevSrc={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[(currentLightBoxPhotoIndex + data.accObjectData.accObjectPhotos.length - 1) % data.accObjectData.accObjectPhotos.length]}
                            onCloseRequest={() => { setLightBoxOpen(false); }}
                            onMovePrevRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + data.accObjectData.accObjectPhotos.length - 1) % data.accObjectData.accObjectPhotos.length)
                            }
                            onMoveNextRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + 1) % data.accObjectData.accObjectPhotos.length)
                            }
                        />
                        :
                        null
                }
            </>

        )
    };

    const DescriptionAndEquipmentSectionMobile = () => {
        return (
            <div className={classes.descriptionAndEquipment}>

                <div className={classes.description}>

                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            {t("acc_object_page_description")}
                        </Typography>
                    </div>
                    <Typography variant='subtitle2'>
                        {data.accObjectData.description}
                    </Typography>
                </div>


                <div className={classes.equipmentDiv}>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            {t("acc_object_page_equipment")}
                        </Typography>
                    </div>
                    <div className={classes.equipment}>
                        {
                            accObjectEquipmentData[currentLanguage].map((item, index) => {
                                const flag = data.accObjectData.accObjectEquipment[item.equipmentName];
                                if (flag) {
                                    return (
                                        <div className={classes.oneItem} key={index}>
                                            <img width={23} src={item.icon} alt="Icon" style={{ marginRight: '7px' }} />
                                            <Typography variant='subtitle2'>
                                                {item.nameToDisplay}
                                            </Typography>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>

            </div>

        )
    };

    const ContactOwnerSectionMobile = () => {
        return (
            <div className={classes.contactWithOwner}>

                <div className={classes.contactInfo}>
                    <div className={classes.contactWithOwnerInfo}>

                        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '15px' }}>
                            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                {t("acc_object_page_main_data")}
                            </Typography>
                        </div>

                        {/* Podaci o vlasniku */}
                        <div className={classes.ownerPhotoAndName}>
                            <div className={classes.ownerPhotoAndNameMain}>
                                <Avatar src={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.ownerData.avatar} style={{ width: '60px', height: '60px', marginRight: '11px' }} />
                                <div>
                                    <Typography style={{ color: 'gray' }} variant='subtitle2' > {t("acc_object_page_owner_name")} </Typography>
                                    <Typography variant='subtitle1' > {data.accObjectData.ownerData.name} {data.accObjectData.ownerData.surname}  </Typography>
                                </div>
                            </div>
                        </div>
                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                        {/* Brojevi telefona */}
                        <div className={classes.ownerPhoneData}>
                            {
                                data.accObjectData.accObjectPhones.map((phone, index) => {
                                    return (
                                        <div className={classes.phoneNumber} key={index}>
                                            <img src={telefon} alt="Phone" height={20} style={{ marginRight: '5px' }} />
                                            <Typography variant='subtitle1'> {phone.phone_number} </Typography>
                                            {phone.viber ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                            {phone.whatsapp ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <img src={website} alt="Website" height={20} style={{ marginRight: '5px' }} />
                            <Typography variant='subtitle1'>{data.accObjectData.web_site} </Typography>
                        </div>
                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                        {/* Uslovi rezervacije i podaci o uplati */}
                        <div>
                            <div style={{ borderLeft: '3px solid #f79800', marginBottom: '15px' }}>
                                <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                    Uslovi rezervacije
                                </Typography>
                            </div>
                            {
                                data.accObjectData.advance_payment
                                    ?
                                    <div>
                                        <Typography variant='subtitle1'>
                                            {t("acc_object_page_advance_payment")}: {data.accObjectData.advance_amount} %
                                        </Typography>
                                        {
                                            data.accObjectData.non_refundable_advance
                                                ?
                                                <Typography variant='subtitle1'>
                                                    {t("acc_object_page_advance_non_refundable")}
                                                </Typography>
                                                :
                                                <Typography variant='subtitle1'>
                                                    {t("acc_object_page_advance_refundable")}
                                                </Typography>
                                        }
                                    </div>
                                    :
                                    null
                            }
                            <Accordion elevation={0}>
                                <AccordionSummary>
                                    <Typography color="primary">{t("acc_object_page_payment_data")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant='subtitle2'>
                                        {
                                            data.accObjectData.payment_data
                                        }
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                    </div>
                </div>

                <div className={classes.contactWithOwnerForm}>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            {t("acc_object_page_owner_contact")}
                        </Typography>
                    </div>

                    <span ref={formRef} ></span>
                    <ContactForm
                        accUnitChoice={accUnitChoice}
                        accObject={data.accObjectData.id}
                        accUnits={data.accObjectData.accUnits} />
                </div>
            </div>
        );
    };

    const ObjectUnitsSection = () => {
        return (
            <>
                {
                    data.accObjectData.accUnits.length > 0
                        ?
                        <>

                            <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                                <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                    {t("acc_object_page_acc_units")}
                                </Typography>
                            </div>

                            <div className={classes.units}>

                                {
                                    data.accObjectData.accUnits.map((unit, index) => {
                                        return (
                                            <div className={classes.singleUnit} key={index}
                                                onClick={() => {
                                                    setShowUnitDialogue({
                                                        show: true,
                                                        unitId: unit.id
                                                    });
                                                }}>

                                                <div className={classes.singleUnitPhotoAndInfo}>
                                                    <div
                                                        style={{ backgroundImage: `url('${unit.photoPreview.length > 0 ? process.env.REACT_APP_UPLOADS_PATH + unit.photoPreview : noPhotoAvailable}')` }}
                                                        className={classes.singleUnitPhoto}>
                                                    </div>
                                                    <div className={classes.singleUnitInfo}>
                                                        <Typography variant='h5'>{unit.name}</Typography>
                                                        <Typography variant='subtitle1' className={classes.unitInfo}>
                                                            <PeopleIcon style={{ marginRight: '5px', color: "#0F79A1" }} fontSize='small' />
                                                            <strong>{unit.max_occupancy} osobe</strong>
                                                        </Typography>
                                                        <Typography variant='subtitle1' className={classes.unitInfo}>
                                                            <RoomIcon style={{ marginRight: '5px', color: "#0F79A1" }} fontSize='small' />
                                                            <strong>{unit.num_of_rooms} sobe</strong>
                                                        </Typography>
                                                        <Typography variant='subtitle1' className={classes.unitInfo}>
                                                        <SquareMeter style={{ marginRight: '5px', color: "#0F79A1" }} fontSize='small' />
                                                            <strong>{unit.area} &#13217;</strong>
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className={classes.singleUnitButton}>
                                                    <Button
                                                        disableElevation
                                                        fullWidth
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setAccUnitChoice(unit.name);
                                                            window.scrollTo({ behavior: 'smooth', top: formRef.current.offsetTop - 87 })
                                                        }}
                                                        variant='contained'
                                                        style={{ backgroundColor: "#f79800", color: 'white' }}>
                                                        {t("acc_object_page_choose_button")}
                                                    </Button>
                                                </div>

                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </>
                        :
                        null
                }

            </>
        )
    };

    const MapSection = () => {
        return (
            <>
                <div style={{ marginTop: '25px' }}>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            Lokacija
                        </Typography>
                    </div>
                    <div className={classes.mapDivInfo}>
                        <div className={classes.mapDivInfoDistance}>
                            <LocationOnTwoToneIcon fontSize='small' />
                            <Typography variant='subtitle2'>
                                {t("acc_object_page_city_center")}: {data.accObjectData.to_city_center}m
                            </Typography>
                        </div>
                        <div className={classes.mapDivInfoDistance}>
                            <LocationOnTwoToneIcon fontSize='small' />
                            <Typography variant='subtitle2'>
                                {t("acc_object_page_beach")}: {data.accObjectData.to_beach}m
                            </Typography>
                        </div>
                        <div className={classes.mapDivInfoDistance}>
                            <LocationOnTwoToneIcon fontSize='small' />
                            <Typography variant='subtitle2'>
                                {t("acc_object_page_restaurant")}: {data.accObjectData.to_restaurant}m
                            </Typography>
                        </div>
                        <div className={classes.mapDivInfoDistance}>
                            <LocationOnTwoToneIcon fontSize='small' />
                            <Typography variant='subtitle2'>
                                {t("acc_object_page_store")}: {data.accObjectData.to_store}m
                            </Typography>
                        </div>
                    </div>

                    <MapContainer
                        center={[data.accObjectData.latitude, data.accObjectData.longitude]}
                        zoom={16}
                        tap={false}
                        className={classes.map}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[data.accObjectData.latitude, data.accObjectData.longitude]}>
                            <Popup>
                                <Typography variant='h5'>
                                    {data.accObjectData.name}
                                </Typography>
                                <Typography variant='h6'>
                                    {data.accObjectData.address}
                                </Typography>
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </>
        )
    };

    const BottomBar = () => {
        return (
            <div className={classes.bottomBar}>
                <div className={classes.bottomBarPrice}>
                    {getPriceType(data.accObjectData.price_type, data.accObjectData.minimal_price_from)}
                </div>
                <div className={classes.bottomBarButton}>
                    <Button
                        onClick={() => {
                            window.scrollTo({ behavior: 'smooth', top: formRef.current.offsetTop - 87 })
                        }}
                        size='medium'
                        disableElevation
                        variant='contained'
                        style={{ backgroundColor: "#f79800", color: 'white', borderRadius: '12px' }} >
                        Rezerviši
                    </Button>
                </div>
            </div>
        );
    };

    const MobileGallery = () => {
        return (
            <div className={classes.galleryAndMap}>
                <div className={classes.imageGalleryDiv}>
                    <Carousel
                        showThumbs={false}
                        emulateTouch
                        showIndicators={false}
                        showArrows={false}
                        className={classes.carousel}
                        swipeable
                        showStatus={false}>
                        {
                            data.accObjectData.accObjectPhotos.length > 0
                                ?
                                data.accObjectData.accObjectPhotos.map((image, index) => {
                                    return (
                                        <div
                                            className={classes.divCarousel}
                                            style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + image}')` }}
                                            key={index}
                                            onClick={() => {
                                                setLightBoxOpen(true);
                                                setCurrentLightBoxPhotoIndex(index);
                                            }}>
                                            <div className={classes.imageCounter}>
                                                <Typography variant='caption' >{index + 1} / {data.accObjectData.accObjectPhotos.length}</Typography>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div>
                                    <img src={noPhotoAvailable} alt='No Photo' />
                                </div>
                        }
                    </Carousel>
                    <div className={classes.backButton} onClick={() => { navigate(-1) }}>
                        <img src={backArrow} height={17} alt='Back' />
                    </div>
                    <div className={classes.shareAndFavButtons}>
                        <div className={classes.shareFavOneBut}>
                            <img src={share} height={20} alt='Back' />
                        </div>
                        <div className={classes.shareFavOneBut}>
                            {
                                checkFavorite(data.accObjectData.id)
                                    ?
                                    <FavoriteIcon
                                        onClick={() => {
                                            const updatedFav = favoriteAccObjects.filter(favId => favId !== data.accObjectData.id);
                                            setFavoriteAccObjects([...updatedFav]);
                                            localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                                        }}
                                        fontSize='small'
                                        style={{ cursor: 'pointer', color: '#ce0b0b' }} />
                                    :
                                    <FavoriteBorderIcon
                                        onClick={() => {
                                            const updatedFav = [...favoriteAccObjects, data.accObjectData.id];
                                            setFavoriteAccObjects([...updatedFav]);
                                            localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                                        }}
                                        fontSize='small'
                                        style={{ cursor: 'pointer', color: '#ce0b0b' }} />

                            }
                        </div>
                    </div>
                </div>
                <Box sx={{
                    display: { xs: 'block', md: 'none' },
                    marginTop: '15px',
                    width: '95%',
                    margin: 'auto'
                }} >
                    <div className={classes.mobileTitle}>
                        <Typography variant='h5' style={{ marginRight: '5px' }}>
                            <strong>{data.accObjectData.name}</strong>
                        </Typography>
                        <div style={{ color: 'gold', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {numOfStars()}
                        </div>
                    </div>
                    <div className={classes.mainInfoAddress}>
                        <LocationOnTwoToneIcon fontSize='small' />
                        <Typography variant='body1'>
                            {data.accObjectData.address}
                        </Typography>
                    </div>
                </Box>
            </div>
        )
    }

    return (
        <>
            <MobileGallery />
            <DescriptionAndEquipmentSectionMobile />
            <ContactOwnerSectionMobile />
            <div style={{ padding: '5px' }}>
                <ObjectUnitsSection />
                <MapSection />
            </div>
            <BottomBar />
            <UnitDialogue
                unitDialogue={showUnitDialogue}
                setShow={setShowUnitDialogue}
                isMobile={isMobile}
                currentLanguage={currentLanguage} />
            <ShowLightBox />
        </>
    )
}

export default MobileView;
