import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationMN from './locales/mn/translationMN.json';
import translationEN from './locales/en/translationEN.json';
import translationRU from './locales/ru/translationRU.json';
import translationDE from './locales/de/translationDE.json';
import translationIT from './locales/it/translationIT.json';
import translationFR from './locales/fr/translationFR.json';
import translationAL from './locales/al/translationAL.json';
import translationHU from './locales/hu/translationHU.json';
import translationSI from './locales/si/translationSI.json';
import translationRO from './locales/ro/translationRO.json';
import translationCZ from './locales/cz/translationCZ.json';
import translationSE from './locales/se/translationSE.json';
import translationPL from './locales/pl/translationPL.json';

const resources = {
    me: {
        translation : translationMN
    },
    en: {
        translation : translationEN
    },
    ru: {
        translation : translationRU
    },
    de: {
        translation : translationDE
    },
    it: {
        translation : translationIT
    },
    fr: {
        translation : translationFR
    },
    al: {
        translation : translationAL
    },
    hu: {
        translation : translationHU
    },
    si: {
        translation : translationSI
    },
    ro: {
        translation : translationRO
    },
    cz: {
        translation : translationCZ
    },
    se: {
        translation : translationSE
    },
    pl: {
        translation : translationPL
    },
};

const languages = ['me','en','ru','de','it','fr','al','hu','si','ro','cz','se','pl'];

i18next.use(initReactI18next).init({
    resources,
    lng : languages[JSON.parse(localStorage.getItem('currentLanguage'))] || 'me' ,
    keySeparator : false,
    interpolation : {
        escapeValue : false
    }
});

export default i18next;