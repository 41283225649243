import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, TextField, ThemeProvider } from '@material-ui/core';
import { Grid, Typography, MenuItem, useTheme, useMediaQuery, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { AlertTitle, Alert } from '@material-ui/lab';
import backPhoto from '../assets/homePhotos/001.jpg';
import { Menu } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { format, parseISO } from 'date-fns';
import dropdown from '../assets/photos/dropdown.svg';
import { useTranslation } from 'react-i18next';
import { lookupAccObjectsAction, getAccObjectsByFiltersAction } from '../actions/acc_objects_actions';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import Location from '@material-ui/icons/LocationOn';
import ObjectCard from './ObjectCard';
import StarIcon from '@material-ui/icons/Star';
import EquipmentFilterDialog from './EquipmentFilterDialog';
import { accObjectEquipmentData } from '../Accommodation Object/acc_object_equipment_data';
import { useContext } from 'react';
import { GlobalContext } from '../App';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const searchObjectsInitialData = {
    arrivalDate: null,
    departureDate: null,
    numOfPeople: ''
};

const equipmentFilterInitialData = [...accObjectEquipmentData].map((dataLang) => {
    return dataLang.map((items) => {
        return {
            ...items,
            status: false
        }
    });
});

const AllObjects = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const { currentLanguage } = useContext(GlobalContext);

    const markerElements = React.useRef(new Array());

    // 0 -> all objects ... 1 -> map + all objects
    const [activePanel, setActivePanel] = React.useState(0);

    const [objectsSearchData, setObjectsSearchData] = React.useState(searchObjectsInitialData);

    const [openArrivalDate, setOpenArrivalDate] = React.useState(false);
    const [openDepartureDate, setOpenDepartureDate] = React.useState(false);

    const [numOfPeopleAnchor, setNumOfPeopleAnchor] = React.useState(null);
    const [categoryAnchor, setCategoryAnchor] = React.useState(null);

    const [categoryType, setCategoryType] = React.useState(null);
    const [equipmentFilter, setEquipmentFilter] = React.useState(equipmentFilterInitialData);

    const [openEquipmentFilter, setOpenEquipmentFilter] = React.useState(false);

    const [openNumOfGuestsDialog, setOpenNumOfGuestsDialog] = React.useState(false);

    const [openCategoryDialog, setOpenCategoryDialog] = React.useState(false);

    const lookupAccObjectsSelector = useSelector(state => state.lookupAccObjectsState);
    const { loading, lookupAccObjectsData: data } = lookupAccObjectsSelector;

    React.useEffect(() => {
        //dispatch(lookupAccObjectsAction());
        const fsData = JSON.parse(localStorage.getItem('objectsSearchData'));
        if (fsData) {
            setObjectsSearchData({
                ...objectsSearchData,
                arrivalDate: fsData.arrivalDate,
                departureDate: fsData.departureDate,
                numOfPeople: fsData.num_of_people
            });
        }
    }, []);

    React.useEffect(() => {
        if (activePanel === 0) {
            markerElements.current = [];
        }
    }, [activePanel]);

    React.useEffect(() => {
        markerElements.current = [];
    }, [data]);

    React.useEffect(() => {
        const equipment = equipmentFilter[currentLanguage].filter((eqItem) => eqItem.status).map((eqItem) => eqItem.equipmentName);
        if (categoryType !== 0 || equipmentFilter !== null) {
            dispatch(getAccObjectsByFiltersAction({ categoryType, equipment }));
        }
    }, [categoryType, equipmentFilter]);

    const ShowError = () => {
        return <Alert severity="error" style={{ marginTop: '20px' }}>
            <AlertTitle>Greška</AlertTitle>
            Greška u prikazivanju objekata
        </Alert>
    };

    const handleReservationFormSubmit = (e) => {
        e.preventDefault();
    };
    

    const handleOpenAnchor = (event) => {
        setNumOfPeopleAnchor(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setNumOfPeopleAnchor(null);
    };

    const handleOpenCategoryAnchor = (event) => {
        setCategoryAnchor(event.currentTarget);
    };

    const objectHoverOpen = (index) => {
        markerElements.current[index].openPopup();
    };

    const objectHoverClose = (index) => {
        markerElements.current[index].closePopup();
    };

    const ObjectsList = () => {
        return (
            <div className={classes.objectsListDiv}>
                {
                    data?.allObjects.length > 0
                        ?
                        data?.allObjects.map((object, index) => {
                            return (
                                <ObjectCard
                                    cardHeight='340px'
                                    cardWidth='335px'
                                    imageHeight='180px'
                                    key={index}
                                    objectData={object} />
                            )
                        })
                        :
                        <Typography variant='h5'>
                            Trenutno nema smještaja
                        </Typography>
                }
            </div>
        )
    };

    const ObjectsMapList = () => {
        return (
            <div className={classes.objectsMapAndListDiv}>
                <div className={classes.mapDiv}>
                    <MapContainer
                        center={[42.206169, 18.943187]}
                        zoom={15}
                        tap={false}
                        className={classes.map}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {
                            data?.allObjects.map((object, index) => {
                                return (
                                    <Marker
                                        ref={(element) => {
                                            markerElements.current.push(element)
                                        }}
                                        eventHandlers={{
                                            mouseover: (e) => {
                                                e.target.openPopup();
                                            },
                                            mouseout: (e) => {
                                                e.target.closePopup();
                                            }
                                        }}
                                        key={index}
                                        position={[object.latitude, object.longitude]}>
                                        <Popup>
                                            <img width='200' src={process.env.REACT_APP_UPLOADS_PATH + object.photo} style={{ borderRadius: '8px' }} />
                                            <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                {object.name}
                                            </Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Location fontSize='small' style={{ color: '#0ca6d8', marginRight: '5px' }} />
                                                <Typography variant='subtitle2'>
                                                    {object.address}
                                                </Typography>
                                            </div>
                                        </Popup>
                                    </Marker>
                                )
                            })
                        }
                    </MapContainer>
                </div>
                <div className={classes.objectsListMap}>
                    {
                        data?.allObjects.map((object, index) => {
                            return (
                                <ObjectCard
                                    objectHoverOpen={objectHoverOpen}
                                    objectHoverClose={objectHoverClose}
                                    index={index}
                                    size='map'
                                    cardHeight='285px'
                                    cardWidth='205px'
                                    imageHeight='135px'
                                    key={index}
                                    objectData={object} />
                            )
                        })
                    }
                </div>
            </div>
        )
    };

    const getPanel = () => {
        if (activePanel === 0 || isMobile) return <ObjectsList />
        if (activePanel === 1) return <ObjectsMapList />
    };

    return (
        <>
            <div className={classes.navigation} style={{ backgroundImage: `url(${backPhoto}` }}>
                <div className={classes.innerContainer}>
                    {
                        !isMobile
                            ?
                            <form onSubmit={handleReservationFormSubmit}>

                                <div className={classes.datePickerDiv}>

                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <div className={classes.pickerItem}
                                            onClick={() => {
                                                setOpenArrivalDate(open => !open);
                                            }}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum dolaska</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        objectsSearchData.arrivalDate
                                                            ?
                                                            typeof objectsSearchData.arrivalDate === 'string'
                                                                ?
                                                                format(parseISO(objectsSearchData.arrivalDate), 'dd/MM/yyyy')
                                                                :
                                                                format(objectsSearchData.arrivalDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>

                                            </div>
                                        </div>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                style={{ display: 'none' }}
                                                open={openArrivalDate}
                                                required
                                                color='primary'
                                                disablePast
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                autoOk
                                                // variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                emptyLabel='Izaberi datum'
                                                value={objectsSearchData.arrivalDate}
                                                onChange={(e) => {
                                                    setObjectsSearchData({
                                                        ...objectsSearchData,
                                                        arrivalDate: e
                                                    });
                                                    setOpenArrivalDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenArrivalDate(false);
                                                }}
                                            // InputProps={{
                                            //   disableUnderline: true
                                            // }}
                                            />
                                        </ThemeProvider>

                                        <div
                                            onClick={() => {
                                                if (objectsSearchData.arrivalDate) {
                                                    setOpenDepartureDate(open => !open);
                                                } else {
                                                    alert('Prvo izaberite datum dolaska');
                                                }
                                            }}
                                            className={classes.pickerItem}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum odlaska</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        objectsSearchData.departureDate
                                                            ?
                                                            typeof objectsSearchData.departureDate === 'string'
                                                                ?
                                                                format(parseISO(objectsSearchData.departureDate), 'dd/MM/yyyy')
                                                                :
                                                                format(objectsSearchData.departureDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>
                                            </div>
                                        </div>

                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                open={openDepartureDate}
                                                style={{ display: 'none' }}
                                                disabled={objectsSearchData.arrivalDate ? false : true}
                                                required={objectsSearchData.arrivalDate ? true : false}
                                                minDate={objectsSearchData.arrivalDate}
                                                disableToolbar
                                                disablePast
                                                format="dd/MM/yyyy"
                                                autoOk
                                                emptyLabel='Izaberi datum'
                                                //variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                value={objectsSearchData.departureDate}
                                                onChange={(e) => {
                                                    setObjectsSearchData({
                                                        ...objectsSearchData,
                                                        departureDate: e
                                                    });
                                                    setOpenDepartureDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDepartureDate(false);
                                                }}
                                            />
                                        </ThemeProvider>

                                    </MuiPickersUtilsProvider>

                                    <div className={classes.pickerItem} onClick={handleOpenAnchor}>
                                        <img
                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Broj gostiju</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    objectsSearchData.numOfPeople
                                                        ?
                                                        objectsSearchData.numOfPeople
                                                        :
                                                        'Izaberi'
                                                }
                                            </Typography>

                                        </div>
                                    </div>

                                    <Menu
                                        className={classes.menu}
                                        anchorEl={numOfPeopleAnchor}
                                        open={Boolean(numOfPeopleAnchor)}
                                        onClose={handleCloseAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            [...Array(30)].map((el, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setObjectsSearchData({
                                                                ...objectsSearchData,
                                                                numOfPeople: index + 1
                                                            });
                                                            handleCloseAnchor();
                                                        }}

                                                        key={index}>
                                                        <Typography variant='subtitle1'>
                                                            {index + 1}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>


                                    <div className={classes.buttonSearch}>
                                        <Button
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation
                                            size='medium' >
                                            <SearchIcon fontSize='small' />
                                            {t("search_section_title")}
                                        </Button>
                                    </div>

                                </div>
                            </form>
                            :
                            <form onSubmit={handleReservationFormSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.mobileGridSection}>
                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <Grid item xs={6}>
                                            <div className={classes.mobileFormField}
                                                onClick={() => {
                                                    setOpenArrivalDate(open => !open);
                                                }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>{t("home_form_arrival")}</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            objectsSearchData.arrivalDate
                                                                ?
                                                                typeof objectsSearchData.arrivalDate === 'string'
                                                                    ?
                                                                    format(parseISO(objectsSearchData.arrivalDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(objectsSearchData.arrivalDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <DatePicker
                                                    style={{ display: 'none' }}
                                                    open={openArrivalDate}
                                                    required
                                                    color='primary'
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    disableToolbar
                                                    autoOk
                                                    variant='dialog'
                                                    emptyLabel='Izaberi datum'
                                                    value={objectsSearchData.arrivalDate}
                                                    onChange={(e) => {
                                                        setObjectsSearchData({
                                                            ...objectsSearchData,
                                                            arrivalDate: e
                                                        });
                                                        setOpenArrivalDate(false);
                                                    }}
                                                    onClose={() => {
                                                        setOpenArrivalDate(false);
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                onClick={() => {
                                                    if (objectsSearchData.arrivalDate) {
                                                        setOpenDepartureDate(open => !open);
                                                    } else {
                                                        alert('Prvo izaberite datum dolaska');
                                                    }
                                                }}
                                                className={classes.mobileFormField}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>{t("home_form_departure")}</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            objectsSearchData.departureDate
                                                                ?
                                                                typeof objectsSearchData.departureDate === 'string'
                                                                    ?
                                                                    format(parseISO(objectsSearchData.departureDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(objectsSearchData.departureDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <DatePicker
                                                open={openDepartureDate}
                                                style={{ display: 'none' }}
                                                disabled={objectsSearchData.arrivalDate ? false : true}
                                                required={objectsSearchData.arrivalDate ? true : false}
                                                minDate={objectsSearchData.arrivalDate}
                                                disableToolbar
                                                disablePast
                                                format="dd/MM/yyyy"
                                                autoOk
                                                emptyLabel='Izaberi datum'
                                                //variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                value={objectsSearchData.departureDate}
                                                onChange={(e) => {
                                                    setObjectsSearchData({
                                                        ...objectsSearchData,
                                                        departureDate: e
                                                    });
                                                    setOpenDepartureDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDepartureDate(false);
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <Grid item xs={12}>
                                        <div className={classes.mobileFormField} onClick={() => { setOpenNumOfGuestsDialog(true); }}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>{t("home_form_num_of_guests")}</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        objectsSearchData.numOfPeople
                                                            ?
                                                            objectsSearchData.numOfPeople
                                                            :
                                                            t("home_form_choose")
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            size='medium' >
                                            <SearchIcon fontSize='large' />
                                            {t("search_section_title")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                    }

                </div>

            </div>
            <div className={classes.objectsDiv}>
                <div className={classes.objectsDivNavigation}>
                    <div className={classes.listMapDiv}>
                        <div className={classes.listMap} onClick={() => { setActivePanel(0); }}>
                            <ListIcon style={{
                                color: activePanel === 0 ? '#f79800' : '#999999',
                                marginRight: '5px'
                            }} />
                            <Typography
                                style={{
                                    color: activePanel === 0 ? '#245d8d' : '#999999',
                                    fontWeight: 'bold',
                                    borderBottom: activePanel === 0 ? '2px solid #dd0000' : 'none',
                                }}
                                variant='subtitle2'>
                                LISTA SMJEŠTAJA
                            </Typography>
                        </div>
                        <div className={classes.listMap} onClick={() => { setActivePanel(1); }}>
                            <Location style={{
                                color: activePanel === 1 ? '#f79800' : '#999999',
                                marginRight: '5px'
                            }} />
                            <Typography
                                style={{
                                    color: activePanel === 1 ? '#245d8d' : '#999999',
                                    fontWeight: 'bold',
                                    borderBottom: activePanel === 1 ? '2px solid #dd0000' : 'none',
                                }}
                                variant='subtitle2'>
                                MAPA SMJEŠTAJA
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.categoryEqDiv}>
                        <div
                            className={classes.catEq}
                            onClick={(e) => {
                                if (isMobile) { setOpenCategoryDialog(true); }
                                else { handleOpenCategoryAnchor(e); }
                            }}>
                            <Typography
                                style={{ fontWeight: 'bold', color: '#999999', marginRight: '8px' }}
                                variant='subtitle2'>
                                KATEGORIZACIJA
                            </Typography>
                            <img width='21' src={dropdown} />
                        </div>
                        <Menu
                            // PaperProps={{ style: { minWidth: 200, maxWidth: 200, }, }}
                            anchorEl={categoryAnchor}
                            open={Boolean(categoryAnchor)}
                            onClose={() => {
                                setCategoryAnchor(null);
                            }}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            {
                                [...Array(6)].map((category, index) => {
                                    return (
                                        index === 0
                                            ?
                                            <MenuItem
                                                onClick={() => {
                                                    setCategoryType(null);
                                                    setCategoryAnchor(null);
                                                }}
                                                key={index}>
                                                <Typography variant='subtitle1'>
                                                    Sve
                                                </Typography>
                                            </MenuItem>
                                            :
                                            <MenuItem
                                                onClick={() => {
                                                    setCategoryType(index);
                                                    setCategoryAnchor(null);
                                                }}
                                                key={index}
                                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <StarIcon fontSize='medium' style={{ color: 'gold', marginRight: '5px' }} />
                                                <Typography variant='subtitle1'>{index}</Typography>
                                            </MenuItem>
                                    )
                                })
                            }
                        </Menu>
                        <div className={classes.catEq} onClick={() => { setOpenEquipmentFilter(true); }}>
                            <Typography
                                style={{ fontWeight: 'bold', color: '#999999', marginRight: '8px' }}
                                variant='subtitle2'>
                                OPREMLJENOST
                            </Typography>
                            <img width='21' src={dropdown} />
                        </div>
                    </div>
                </div>
                <div>
                    {
                        loading
                            ?
                            <div>
                                <LinearProgress variant='indeterminate' />
                            </div>
                            :
                            typeof data !== 'undefined'
                                ?
                                data.success
                                    ?
                                    getPanel()
                                    :
                                    <ShowError />
                                :
                                null
                    }
                </div>
            </div>
            <Dialog
                fullWidth
                open={openNumOfGuestsDialog}
                onClose={() => { setOpenNumOfGuestsDialog(false); }}>
                <DialogTitle>{t("home_form_num_of_guests")}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Unesite broj gostiju"
                        type="number"
                        value={objectsSearchData.numOfPeople}
                        onChange={(e) => {
                            setObjectsSearchData({
                                ...objectsSearchData,
                                numOfPeople: e.target.value
                            })
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenNumOfGuestsDialog(false); }}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={openCategoryDialog}
                onClose={() => { setOpenCategoryDialog(false); }}>
                <DialogTitle>Kategorija</DialogTitle>
                <DialogContent>
                    {
                        [...Array(6)].map((category, index) => {
                            return (
                                index === 0
                                    ?
                                    <MenuItem
                                        style={{  backgroundColor : categoryType === null ? '#BDC3C7' : 'white' }}
                                        onClick={() => {
                                            setCategoryType(null);
                                            setOpenCategoryDialog(false); 
                                        }}
                                        key={index}>
                                        <Typography variant='subtitle1'>
                                            Sve
                                        </Typography>
                                    </MenuItem>
                                    :
                                    <MenuItem
                                        onClick={() => {
                                            setCategoryType(index);
                                            setOpenCategoryDialog(false);
                                        }}
                                        key={index}
                                        style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            alignItems: 'center',
                                            backgroundColor : index === categoryType ? '#BDC3C7' : 'white'
                                        }}>
                                        <StarIcon fontSize='medium' style={{ color: 'gold', marginRight: '5px' }} />
                                        <Typography variant='subtitle1'>{index}</Typography>
                                    </MenuItem>
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenCategoryDialog(false); }}>Ok</Button>
                </DialogActions>
            </Dialog>
            <EquipmentFilterDialog
                open={openEquipmentFilter}
                setOpen={setOpenEquipmentFilter}
                equipmentFilter={equipmentFilter}
                setEquipmentFilter={setEquipmentFilter}
                currentLanguage={currentLanguage}
            />
        </>
    );
};

export default AllObjects;

