import React from 'react';
import { Dialog, DialogTitle, Button, DialogActions, DialogContent, CircularProgress, Typography, LinearProgress } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import { AlertTitle, Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { superAdminPreviewAccUnitPhotosAction } from '../actions/acc_objects_actions';
import useStyles from './style';

const SuperAdminPreviewAccUnitPhotos = ({ show, setShow, accUnitId, accUnitName }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const previewAccUnitPhotosSelector = useSelector(state => state.previewAccUnitPhotosState);
    const { loading, previewAccUnitPhotosData: data } = previewAccUnitPhotosSelector;

    const handleClose = () => {
        setShow({
            show: false,
            accUnitId: null,
            accUnitName: null
        });
    };

    React.useEffect(() => {
        if (accUnitId !== null) {
            dispatch(superAdminPreviewAccUnitPhotosAction(accUnitId));
        }
    }, [accUnitId]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>Greška</AlertTitle>
                Greška u prikazivanju slika smještajne jedinice
            </Alert>
        );
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={show}
            onClose={handleClose}
        >
            <DialogTitle>{accUnitName}</DialogTitle>
            <DialogContent>
                <div className={classes.previewAccUnitPhotos}>
                    {
                        loading
                            ?
                            <div style={{ width : '80%', margin : 'auto' }}>
                                <LinearProgress color='primary' />
                            </div>
                            :
                            typeof data !== 'undefined'
                                ?
                                data?.success
                                    ?
                                    data.accUnitPhotos.length > 0
                                        ?
                                        <Carousel
                                            emulateTouch
                                            className={classes.carouselUnit}
                                            swipeable
                                            showStatus={false}>
                                            {
                                                data.accUnitPhotos.map((photo, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img style={{ borderRadius : '10px' }} src={process.env.REACT_APP_UPLOADS_PATH + photo} alt='Accommodation Unit' />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                        :
                                        <Typography variant='h4'>Nema slika</Typography>
                                    :
                                    <ShowError />
                                :
                                null
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SuperAdminPreviewAccUnitPhotos