import { combineReducers } from "redux";

import {
    registerReducer,
    verifyEmailReducer,
    loginUserReducer,
    checkJwtTokenReducer,
    logoutUserReducer,
    updateUserDataReducer,
    checkSuperAdminReducer,
    superAdminGetAllUsersReducer,
    superAdminUpdateUserReducer,
    getPasswordResetLinkReducer,
    resetPasswordReducer,
    checkRegularAdminReducer
} from './users_reducers';
import {
    addNewAccObjectReducer,
    getAccObjectsTableDataReducer,
    getAccObjectDataToEditReducer,
    updateAccObjectReducer,
    deleteAccObjectReducer,
    getAccUnitDataToEditReducer,
    updateAccUnitReducer,
    addNewAccUnitForExistingAccObjectReducer,
    deleteAccUnitReducer,
    superAdminGetAccObjectsReducer,
    getPopularAccObjectsReducer,
    showAccObjectReducer,
    showAccUnitReducer,
    getFavoritesReducer,
    sendQuestionReducer,
    getQuestionsForOwnerReducer,
    lookupAccObjectsReducer,
    superAdminPreviewAccObjectReducer,
    superAdminPreviewAccUnitPhotosReducer,
    reservationAccObjectsDisplayReducer
} from "./acc_objects_reducers";

import {
    addNewRestaurantReducer,
    getRestaurantsTableReducer,
    deleteRestaurantReducer,
    getRestaurantDataEditReducer,
    updateRestaurantReducer,
    previewRestaurantReducer,
    getAllRestaurantsReducer,
    makeReservationReducer,
    getRestaurantReducer
} from './restaurants_reducers';

import {
    getOwnerCarsTableReducer,
    addNewCarReducer,
    getCarDataForEditReducer,
    updateCarDataReducer,
    previewCarReducer,
    getAllCarsReducer,
    deleteCarReducer,
    getCarDataReducer,
    sendCarQuestionReducer
} from "./rent_a_car_reducers";

import {
    addNewTourReducer, deleteTourReducer, getAllToursReducer, getTourDataForEditReducer, getToursTableReducer, previewTourReducer, updateTourReducer
} from './tours_reducers';
import { addNewEventReducer, deleteEventReducer, getAllEventsReducer, getEventDataForEditReducer, getEventsTableReducer, previewEventReducer, updateEventReducer } from "./events_reducers";

export default combineReducers({
    register: registerReducer,
    verifyEmail: verifyEmailReducer,
    login: loginUserReducer,
    jwtCheck: checkJwtTokenReducer,
    superAdminCheck: checkSuperAdminReducer,
    regularAdminCheck: checkRegularAdminReducer,
    logout: logoutUserReducer,
    updateUserState: updateUserDataReducer,
    addNewAccObject: addNewAccObjectReducer,
    accObjectsTableState: getAccObjectsTableDataReducer,
    accObjectDataEditState: getAccObjectDataToEditReducer,
    accObjectUpdateState: updateAccObjectReducer,
    deleteAccObjectState: deleteAccObjectReducer,
    accUnitDataEditState: getAccUnitDataToEditReducer,
    updateAccUnitState: updateAccUnitReducer,
    newAccUnitState: addNewAccUnitForExistingAccObjectReducer,
    deleteAccUnitState: deleteAccUnitReducer,
    superAdminAccObjectsState: superAdminGetAccObjectsReducer,
    popularAccObjectsState: getPopularAccObjectsReducer,
    showAccObjectState: showAccObjectReducer,
    showAccUnitState: showAccUnitReducer,
    favoritesState: getFavoritesReducer,
    sendQuestionState: sendQuestionReducer,
    getQuestionsForOwnerState: getQuestionsForOwnerReducer,
    lookupAccObjectsState: lookupAccObjectsReducer,
    previewAccObjectState: superAdminPreviewAccObjectReducer,
    previewAccUnitPhotosState: superAdminPreviewAccUnitPhotosReducer,
    newRestaurantState: addNewRestaurantReducer,
    restaurantsTableState: getRestaurantsTableReducer,
    deleteRestaurantState: deleteRestaurantReducer,
    getRestaurantDataEditState: getRestaurantDataEditReducer,
    updateRestaurantState: updateRestaurantReducer,
    previewRestaurantState: previewRestaurantReducer,
    getAllRestaurantsState: getAllRestaurantsReducer,
    makeReservationState: makeReservationReducer,
    getRestaurantState: getRestaurantReducer,
    reservationDataDisplayState: reservationAccObjectsDisplayReducer,
    getOwnerCarsTableState: getOwnerCarsTableReducer,
    addNewCarState: addNewCarReducer,
    carEditState: getCarDataForEditReducer,
    updateCarState: updateCarDataReducer,
    previewCarState: previewCarReducer,
    superAdminAllUsersState: superAdminGetAllUsersReducer,
    superAdminUpdateUserState: superAdminUpdateUserReducer,
    getAllCarsState: getAllCarsReducer,
    addNewTourState: addNewTourReducer,
    toursTableState: getToursTableReducer,
    getTourDataForEditState: getTourDataForEditReducer,
    updateTourState: updateTourReducer,
    deleteTourState: deleteTourReducer,
    previewTourState: previewTourReducer,
    getAllToursState: getAllToursReducer,
    deleteCarState: deleteCarReducer,
    getCarDataState: getCarDataReducer,
    eventsTableState : getEventsTableReducer,
    addNewEventState : addNewEventReducer,
    getEventDataForEditState: getEventDataForEditReducer,
    updateEventState: updateEventReducer,
    deleteEventState: deleteEventReducer,
    previewEventState: previewEventReducer,
    getAllEventsState: getAllEventsReducer,
    getPasswordResetLinkState : getPasswordResetLinkReducer,
    resetPasswordState : resetPasswordReducer,
    carQuestionState : sendCarQuestionReducer
});