import React from 'react';
import useStyle from './style';
import { useDispatch, useSelector } from 'react-redux';
import { showAccObjectAction, emptySendQuestionData } from '../actions/acc_objects_actions';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useTheme, useMediaQuery, Typography, LinearProgress, Button, Tooltip, Avatar, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import share from '../assets/photos/share.svg';
import StarIcon from '@material-ui/icons/Star';
import LocationIcon from '@material-ui/icons/LocationOn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import Lightbox from 'react-image-lightbox';
import telefon from '../assets/photos/telefon.svg';
import website from '../assets/photos/website.svg';
import AppsIcon from '@material-ui/icons/Apps';
import { getCarDataAction } from '../actions/rent_a_car_actions';
import CarContactForm from './CarContactForm';
import { contactOwnerPhotos } from '../Accommodation Object/owner_info_data';
import carDoor from '../assets/carDataPhotos/carDoor.png';
import carShift from '../assets/carDataPhotos/carShift.png';
import carFuel from '../assets/carDataPhotos/carFuel.png';
import carYear from '../assets/carDataPhotos/carYear.png';
import carEngine from '../assets/carDataPhotos/carEngine.png';
import carSeat from '../assets/carDataPhotos/carSeat.png';
import carHorse from '../assets/carDataPhotos/carHorse.png';
import carType from '../assets/carDataPhotos/carType.png';
import { carEquipment } from './carEquipment';
import CarMobileView from './CarMobileView';


const ShowCar = () => {

    const { favoriteCars, setFavoriteCars, currentLanguage } = useContext(GlobalContext);

    const classes = useStyle();
    const dispatch = useDispatch();
    const { carId } = useParams();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const showCarDataSelector = useSelector(state => state.getCarDataState);
    const { loading, carData: data } = showCarDataSelector;

    const [currentLightBoxPhotoIndex, setCurrentLightBoxPhotoIndex] = React.useState(0);
    const [lightBoxOpen, setLightBoxOpen] = React.useState(false);

    React.useEffect(() => {
        dispatch(getCarDataAction(carId));
        // dispatch(emptySendQuestionData());
    }, [carId]);

    const ShowLightBox = () => {
        return (
            <>
                {
                    lightBoxOpen
                        ?
                        <Lightbox
                            //animationDisabled={true}
                            animationDuration={0}
                            mainSrc={process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[currentLightBoxPhotoIndex]}
                            nextSrc={process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[(currentLightBoxPhotoIndex + 1) % data.car.carPhotos.length]}
                            prevSrc={process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[(currentLightBoxPhotoIndex + data.car.carPhotos.length - 1) % data.car.carPhotos.length]}
                            onCloseRequest={() => { setLightBoxOpen(false); }}
                            onMovePrevRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + data.car.carPhotos.length - 1) % data.car.carPhotos.length)
                            }
                            onMoveNextRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + 1) % data.car.carPhotos.length)
                            }
                        />
                        :
                        null
                }
            </>

        )
    };

    const SectionTitle = ({ title }) => {
        return (
            <div className={classes.sectionTitleDiv}>
                <Typography className={classes.sectionTitle}>
                    {title}
                </Typography>
            </div>
        )
    };

    const checkFavorite = (id) => {
        return favoriteCars.find(favId => favId === id) ? true : false;
    };

    return (
        <>
            <div className={classes.mainDiv}>
                {
                    loading
                        ?
                        <div style={{ marginTop: '50px' }}>
                            <LinearProgress />
                        </div>
                        :
                        typeof data !== 'undefined'
                            ?
                            data.success
                                ?
                                (
                                    isMobile
                                        ?
                                        <CarMobileView data={data} />
                                        :
                                        <div className={classes.mainSection}>
                                            <div className={classes.firstSection}>
                                                <div className={classes.objectInfo}>

                                                    <div className={classes.title}>
                                                        <Typography variant='h4' style={{ fontWeight: 'bolder', color: '#333333', marginRight: '5px' }}>
                                                            {data.car.manufacturer} {data.car.model} - {data.car.engine}
                                                        </Typography>
                                                    </div>

                                                    <div className={classes.objectCardPrice} >

                                                        <div className={classes.objectCardPriceFrom}>
                                                            <Typography style={{ color: '#a1a1a1', fontWeight: 'bold', fontSize: '11px' }}>
                                                                CIJENA OD
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.objectCardPriceInfo}>

                                                            <div>
                                                                <Typography style={{ color: "#dd0000", fontSize: '30px', fontWeight: 'bolder' }}>
                                                                    {data.car.one_to_three_days},00
                                                                </Typography>
                                                            </div>
                                                            <div style={{ marginLeft: '5px' }}>
                                                                <div>
                                                                    <Typography style={{ color: '#f8ac33', fontSize: '13px', fontWeight: 'bolder', marginBottom: '-4px' }}>EUR</Typography>
                                                                </div>
                                                                <div>
                                                                    <Typography style={{ color: '#a1a1a1', fontSize: '13px' }}>
                                                                        po danu
                                                                    </Typography>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setLightBoxOpen(true);
                                                        setCurrentLightBoxPhotoIndex(0);
                                                    }}
                                                    style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[0]}')` }}
                                                    className={classes.gallerySection}>
                                                    <div
                                                        onClick={() => {
                                                            setCurrentLightBoxPhotoIndex(0);
                                                            setLightBoxOpen(true);
                                                        }}
                                                        className={classes.galleryButton}>
                                                        <AppsIcon fontSize='medium' />
                                                        <Typography variant='subtitle1'>
                                                            <strong>Prikaži sve fotografije</strong>
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className={classes.equipmentDiv}>

                                                    {
                                                        carEquipment.map((equipmentItem, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={classes.equipmentItemDiv}>
                                                                    <img
                                                                        style={{
                                                                            filter: 'invert(26%) sepia(96%) saturate(421%) hue-rotate(164deg) brightness(98%) contrast(88%)'
                                                                        }}
                                                                        width='35'
                                                                        src={equipmentItem.icon}
                                                                        alt='Eq' />
                                                                    <Typography variant='subtitle2'>
                                                                        {data.car[equipmentItem.propName]} {equipmentItem.additionalText ?? equipmentItem.additionalText}
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>

                                                <div>
                                                    <SectionTitle title='cijenovnik' />
                                                    <div className={classes.tablesDiv}>
                                                        <div className={classes.singleTableDiv}>
                                                            <table className={classes.table}>
                                                                <tr>
                                                                    <th>
                                                                        <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                                                                            BROJ DANA
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                                                                            CIJENA
                                                                        </Typography>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>1 do 3 dana</td>
                                                                    <td>{data.car.one_to_three_days}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>4 do 7 dana</td>
                                                                    <td>{data.car.four_to_seven_days}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>8 do 15 dana</td>
                                                                    <td>{data.car.eight_to_fifteen_days}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>16 do 30 dana</td>
                                                                    <td>{data.car.sixteen_to_thirty_days}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>31 do 45 dana</td>
                                                                    <td>{data.car.thirtyone_to_fortyfive_days}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>45+ dana</td>
                                                                    <td>{data.car.fortyfive_plus_days}&euro; / dan</td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                        <div className={classes.singleTableDiv}>
                                                            <table className={classes.table}>
                                                                <tr>
                                                                    <th>
                                                                        <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                                                                            DODATNO
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                                                                            CIJENA
                                                                        </Typography>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sjedište za djecu</td>
                                                                    <td>{data.car.child_seat}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>GPS navigacija</td>
                                                                    <td>{data.car.gps_navigation}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Wifi 4G mobile router</td>
                                                                    <td>{data.car.wifi_4g_mobile_router}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Osiguranje</td>
                                                                    <td>{data.car.insurance}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Dostava vozila</td>
                                                                    <td>{data.car.deposit}&euro; / dan</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Depozit</td>
                                                                    <td>{data.car.vehicle_delivery}&euro; / dan</td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <SectionTitle title='uslovi rezervacije' />
                                                    <div style={{ marginLeft: '10px' }}>
                                                        <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
                                                            <strong>Uslovi i pravila</strong> <br></br>
                                                            Minimum 21 godina starosti <br></br>
                                                            Minimum 2 godine posjedovanja vozačke dozvole <br></br>
                                                            Obavezna identifikacija - lična karta ili pasoš <br></br>
                                                            Minimalna dužina najma je 1 dan (24h) <br></br>
                                                            Vrijeme preuzimanja i vraćanja vozila moraju biti isti. Tolerišemo 60 minuta kašnjenja <br></br>
                                                            Po isteku prekoračenja od 60 minuta, zadržavamo pravo da naplatimo još jedan dan najma <br></br>
                                                        </Typography>

                                                        <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
                                                            <strong>Preuzimanje i vraćanje vozila</strong> <br></br>
                                                            Vozilo se može preuzeti i vratiti u jednoj od naših poslovnica a moguća je i dostava odnosno vraćanje izvan poslovnice (hotel, kućna adresa i sl.).
                                                            Takođe,vozilo možete preuzeti i vratiti i izvan radnog vremena.
                                                        </Typography>

                                                        <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
                                                            <strong>Depozit</strong> <br></br>
                                                            Klijent je u obavezi da ostavi depozit u visini 200 do 500€ u zavisnosti od kategorije vozila. Ukoliko se utvrdi da vozilo ima novo oštećenje u trenutku vraćanja,
                                                            depozit će biti zadržan u onolikom iznosu koliko se klijent prema ugovoru o najmu zadužuje za oštećenje.
                                                        </Typography>

                                                        <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
                                                            <strong>Načini plaćanja</strong> <br></br>
                                                            Najam vozila se plaća prlikom preuzimanja vozila gotovinom, kreditnom karticom (Visa, Mastercard, Diners, Amex), iii uplatom na račun (pravna lica).
                                                        </Typography>

                                                        <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
                                                            <strong>Saobraćajne kazne</strong> <br></br>
                                                            Klijent je dužan da plati sve saobraćajne/parking kazne koje su nastale za vrijeme trajanja najma.
                                                        </Typography>


                                                        <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
                                                            <strong>Podrška na putu</strong> <br></br>
                                                            Ukoliko odlučite da iznajmite jedno od vozila iz našeg voznog parka, imaćete podršku 24/7 na teritoriji cijele Evrope.
                                                        </Typography>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className={classes.secondSection}>
                                                <div className={classes.shareSaveDiv}>
                                                    <div className={classes.shareSave}>
                                                        <div className={classes.shareSaveParts}>
                                                            <img width='22' src={share} alt='Share' style={{ marginRight: '7px', cursor: 'pointer' }} />
                                                            <Typography style={{ color: '#245d8d' }}>PODIJELI</Typography>
                                                        </div>
                                                        <div className={classes.shareSaveParts}>
                                                            {
                                                                checkFavorite(data.car.id)
                                                                    ?
                                                                    <Tooltip title={`${t("acc_object_page_remove_from_wishes")}`}>
                                                                        <FavoriteIcon
                                                                            onClick={() => {
                                                                                const updatedFav = favoriteCars.filter(favId => favId !== data.car.id);
                                                                                setFavoriteCars([...updatedFav]);
                                                                                localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                                                                            }}
                                                                            fontSize='medium'
                                                                            style={{ cursor: 'pointer', color: '#245d8d', marginRight: '7px' }} />
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title={`${t("acc_object_page_add_to_wishes")}`}>
                                                                        <FavoriteBorderIcon
                                                                            onClick={() => {
                                                                                const updatedFav = [...favoriteCars, data.car.id];
                                                                                setFavoriteCars([...updatedFav]);
                                                                                localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                                                                            }}
                                                                            fontSize='medium'
                                                                            style={{ cursor: 'pointer', color: '#245d8d', marginRight: '7px' }} />
                                                                    </Tooltip>
                                                            }
                                                            <Typography style={{ color: '#245d8d' }}>
                                                                SAČUVAJ
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <CarContactForm carId={carId} />
                                                <div className={classes.contactDataDiv}>
                                                    <SectionTitle title='kontakt podaci' />
                                                    <div className={classes.ownerPhotoAndName}>
                                                        <div className={classes.ownerPhotoAndNameMain}>
                                                            <Avatar src={process.env.REACT_APP_UPLOADS_PATH + data.car.ownerData.avatar} style={{ width: '50px', height: '50px', marginRight: '11px' }} />
                                                            <div>
                                                                <Typography style={{ color: 'gray' }} variant='subtitle2' > {t("acc_object_page_owner_name")} </Typography>
                                                                <Typography variant='subtitle1' > {data.car.ownerData.name} {data.car.ownerData.surname}  </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                                    <div className={classes.ownerPhoneData}>
                                                        {
                                                            data.car.carPhones.map((phone, index) => {
                                                                return (
                                                                    <div className={classes.phoneNumber} key={index}>
                                                                        <img src={telefon} alt="Phone" height={20} style={{ marginRight: '15px' }} />
                                                                        <Typography variant='subtitle1'> {phone.phone_number} </Typography>
                                                                        {phone.viber ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                                                        {phone.whatsapp ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img src={website} alt="Website" height={20} style={{ marginRight: '15px' }} />
                                                        <Typography variant='subtitle1'>{data.car.website} </Typography>
                                                    </div>

                                                </div>
                                            </div>
                                            <ShowLightBox />
                                        </div>
                                )
                                :
                                <Alert severity="error">
                                    <AlertTitle>{t("error")}</AlertTitle>
                                    Greška u prikazivanju auta
                                </Alert>
                            :
                            null
                }
            </div>
        </>
    );
};

export default ShowCar;