import React from 'react';
import {
    TextField,
    Grid,
    InputAdornment,
    Select,
    MenuItem,
    InputLabel,
    Button,
    LinearProgress,
    Typography,
    Menu
} from '@material-ui/core';
import useStyles from './style';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { sendQuestionAction, sendMultipleQuestionAction } from '../actions/acc_objects_actions';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import telegramIcon from '../assets/photos/telegram.svg';
import dropdown from '../assets/photos/dropdown.svg';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from "@material-ui/core/styles";

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const initialContactData = {
    contactArrivalDate: null,
    contactDepartureDate: null,
    contactAccUnit: '',
    contactNumOfGrownups: '',
    contactNumOfKids: '',
    contactName: '',
    contactSurname: '',
    contactEmail: '',
    contactPhoneNumber: '',
    contactQuestionMessage: ''
};

const ContactForm = ({ accUnits, fav, accObject, accObjectsIds, accUnitChoice }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [contactData, setContactData] = React.useState(initialContactData);

    const sendQuestionSelector = useSelector(state => state.sendQuestionState);
    const { loading, sendQuestionData } = sendQuestionSelector;

    const [openArrivalDate, setOpenArrivalDate] = React.useState(false);
    const [openDepartureDate, setOpenDepartureDate] = React.useState(false);

    const [selectAccUnitAnchor, setSelectAccUnitAnchor] = React.useState(null);

    const handleOpenAnchor = (event) => {
        setSelectAccUnitAnchor(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setSelectAccUnitAnchor(null);
    };

    const handleChange = (e) => {
        setContactData({
            ...contactData,
            [e.target.name]: e.target.value
        });
    };

    const handleContactFormSubmit = (e) => {
        e.preventDefault();
        if (contactData.contactArrivalDate === null || contactData.contactDepartureDate === null) {
            alert('Izaberite datume.')
        } else {
            if (!fav) {
                const questionData = {
                    ...contactData,
                    contactArrivalDate: format(contactData.contactArrivalDate, 'dd/MM/yyyy'),
                    contactDepartureDate: format(contactData.contactDepartureDate, 'dd/MM/yyyy'),
                    accObject: accObject ? accObject : null
                }
                dispatch(sendQuestionAction({
                    questionData
                }));
            } else {
                const multipleQuestionData = {
                    ...contactData,
                    contactArrivalDate: format(contactData.contactArrivalDate, 'dd/MM/yyyy'),
                    contactDepartureDate: format(contactData.contactDepartureDate, 'dd/MM/yyyy'),
                    accObjectsIds
                }
                dispatch(sendMultipleQuestionAction({
                    multipleQuestionData
                }));
            }
        }
    };

    React.useEffect(() => {
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if (fsData) {
            setContactData({
                ...contactData,
                contactArrivalDate: fsData.arrivalDate,
                contactDepartureDate: fsData.departureDate,
                contactNumOfGrownups: fsData.num_of_people
            });
        }
    }, []);

    React.useEffect(() => {
        if (accUnitChoice) {
            setContactData({
                ...contactData,
                contactAccUnit: accUnitChoice
            })
        }
    }, [accUnitChoice]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>{t("error")}</AlertTitle>
                {t("message_form_error_message")}
            </Alert>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success" style={{ marginBottom: '20px' }}>
                <AlertTitle>{t("message_success")}</AlertTitle>
                {t("message_success_message")}
            </Alert>
        );
    };

    const ShowLoading = () => {
        return (
            <div style={{ margin: '20px' }}>
                <Typography variant='caption' style={{ color: '#0F79A1' }}>{t("message_sending_message")}</Typography>
                <LinearProgress style={{ backgroundColor: '#0F79A1' }} />
            </div>
        );
    };

    return (
        <>

            <div className={classes.contactFormDiv}>
                <div className={classes.contactFormTitle}>
                    <img src={telegramIcon} width='30' alt='Telegram' style={{ marginRight: '5px' }} />
                    <Typography style={{ fontSize: '20px', fontWeight: 'bolder', color: '#245d8d' }}>
                        POŠALJI REZERVACIJU
                    </Typography>
                </div>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        typeof sendQuestionData !== 'undefined'
                            ?
                            sendQuestionData?.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                }
                <form onSubmit={handleContactFormSubmit} >
                    <Grid container spacing={2} justifyContent='center' alignItems='center'>
                        <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                            <Grid item md={6} xs={6}>
                                <div
                                    onClick={() => {
                                        setOpenArrivalDate(open => !open);
                                    }}
                                    className={classes.formField}
                                    style={{ cursor: 'pointer' }} >
                                    <div>
                                        <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum dolaska</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                contactData.contactArrivalDate
                                                    ?
                                                    typeof contactData.contactArrivalDate === 'string'
                                                        ?
                                                        format(parseISO(contactData.contactArrivalDate), 'dd/MM/yyyy')
                                                        :
                                                        format(contactData.contactArrivalDate, 'dd/MM/yyyy')
                                                    :
                                                    'Izaberi datum'
                                            }
                                        </Typography>
                                    </div>
                                    <DateRangeTwoToneIcon />
                                </div>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DatePicker
                                        style={{ display: 'none' }}
                                        open={openArrivalDate}
                                        required
                                        color='primary'
                                        disablePast
                                        format="dd/MM/yyyy"
                                        disableToolbar
                                        autoOk
                                        variant='dialog'
                                        emptyLabel='Izaberi datum'
                                        value={contactData.contactArrivalDate}
                                        onChange={(e) => {
                                            setContactData({
                                                ...contactData,
                                                contactArrivalDate: e
                                            });
                                            setOpenArrivalDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenArrivalDate(false);
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <div
                                    onClick={() => {
                                        if (contactData.contactArrivalDate) {
                                            setOpenDepartureDate(open => !open);
                                        } else {
                                            alert('Prvo odaberite datum dolaska.');
                                        }
                                    }}
                                    className={classes.formField}
                                    style={{ cursor: 'pointer' }} >
                                    <div>
                                        <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum odlaska</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                contactData.contactDepartureDate
                                                    ?
                                                    typeof contactData.contactDepartureDate === 'string'
                                                        ?
                                                        format(parseISO(contactData.contactDepartureDate), 'dd/MM/yyyy')
                                                        :
                                                        format(contactData.contactDepartureDate, 'dd/MM/yyyy')
                                                    :
                                                    'Izaberi datum'
                                            }
                                        </Typography>

                                    </div>
                                    <DateRangeTwoToneIcon />
                                </div>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DatePicker
                                        style={{ display: 'none' }}
                                        open={openDepartureDate}
                                        disabled={contactData.contactArrivalDate ? false : true}
                                        required={contactData.contactArrivalDate ? true : false}
                                        minDate={contactData.contactArrivalDate}
                                        color='primary'
                                        disablePast
                                        format="dd/MM/yyyy"
                                        disableToolbar
                                        autoOk
                                        variant='dialog'
                                        emptyLabel='Izaberi datum'
                                        value={contactData.contactDepartureDate}
                                        onChange={(e) => {
                                            setContactData({
                                                ...contactData,
                                                contactDepartureDate: e
                                            });

                                            setOpenDepartureDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenDepartureDate(false);
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                        </MuiPickersUtilsProvider>
                        {
                            !fav
                                ?
                                accUnits.length > 0
                                    ?
                                    <Grid item xs={12} md={12}>
                                        <div
                                            onClick={handleOpenAnchor}
                                            style={{ cursor: 'pointer' }}
                                            className={classes.formField}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Smještajna jedinica</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        contactData.contactAccUnit
                                                            ?
                                                            contactData.contactAccUnit
                                                            :
                                                            'Izaberi apartman ili sobu'
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>
                                        <Menu
                                            className={classes.menu}
                                            anchorEl={selectAccUnitAnchor}
                                            open={Boolean(selectAccUnitAnchor)}
                                            onClose={handleCloseAnchor}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        >
                                            {
                                                accUnits.map((unit, index) => {
                                                    return (
                                                        <MenuItem
                                                            className={classes.menuItem}
                                                            onClick={() => {
                                                                setContactData({
                                                                    ...contactData,
                                                                    contactAccUnit: unit.name
                                                                });
                                                                handleCloseAnchor();
                                                            }}
                                                            key={index}>
                                                            <Typography variant='subtitle1'>
                                                                {unit.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Menu>
                                    </Grid>
                                    :
                                    null
                                :
                                null
                        }

                        <Grid item xs={6} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactNumOfGrownups"
                                label={t("message_form_num_of_grownups")}
                                name="contactNumOfGrownups"
                                type="number"
                                value={contactData.contactNumOfGrownups}
                                onChange={handleChange}
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 30
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactNumOfKids"
                                label={t("message_form_num_of_kids")}
                                name="contactNumOfKids"
                                type="number"
                                value={contactData.contactNumOfKids}
                                onChange={handleChange}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 30
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactName"
                                label={t("message_form_name")}
                                name="contactName"
                                type="text"
                                value={contactData.contactName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactSurname"
                                label={t("message_form_surname")}
                                name="contactSurname"
                                type="text"
                                value={contactData.contactSurname}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactEmail"
                                label="Email"
                                name="contactEmail"
                                type="email"
                                value={contactData.contactEmail}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.field}
                                id="contactPhoneNumber"
                                label={t("message_form_phone")}
                                name="contactPhoneNumber"
                                type="text"
                                value={contactData.contactPhoneNumber}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                label='Poruka...'
                                fullWidth
                                className={classes.field}
                                variant="outlined"
                                multiline
                                minRows={3}
                                maxRows={5}
                                name='contactQuestionMessage'
                                type='text'
                                value={contactData.contactQuestionMessage}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Button
                                disableElevation
                                fullWidth
                                variant='contained'
                                type='submit'
                                style={{ backgroundColor: '#f79800', color: 'white', fontSize: '18px', fontWeight: 'bold' }} >
                                {t("message_form_send_button")}
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </div>
        </>
    );
};

export default ContactForm;