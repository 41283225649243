import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { checkSuperAdminAction } from './actions/users_actions';
import { useDispatch, useSelector } from 'react-redux';

const SuperAdminProtected = () => {

    const dispatch = useDispatch();

    const checkSuperAdminSelector = useSelector(state => state.superAdminCheck);
    const { superAdmin } = checkSuperAdminSelector;

    React.useEffect(() => {
        dispatch(checkSuperAdminAction());
    }, []);

    return (
        typeof superAdmin !== 'undefined'
            ?
            superAdmin.success
                ?
                <Outlet />
                :
                <Navigate to='/login' />
            :
            null
    );
};

export default SuperAdminProtected;
