import { Grid, Paper, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, emptyLoginDataAction } from '../actions/users_actions';

const initialLoginData = {
    emailOrPhone: '',
    password: ''
};

const Login = () => {

    const classes = useStyles();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [loginData, setLoginData] = React.useState(initialLoginData);

    const loginSelectorData = useSelector(state => state.login);
    const { loading, userLoginData } = loginSelectorData;

    const handleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        });
    };

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        dispatch(loginAction(loginData, 'login'));
    };

    React.useEffect(() => {
        if (typeof userLoginData !== 'undefined') {
            if (userLoginData.success) {
                if (userLoginData.is_super_admin) {
                    navigate('/superAdminPanel');
                    dispatch(emptyLoginDataAction());
                } else {
                    navigate('/ownerAdminPanel');
                    dispatch(emptyLoginDataAction());
                }
            }
        }
    }, [userLoginData]);

    return (
        <div className={classes.loginDiv}>
            <div className={classes.backButton}>
                <Button
                    onClick={() => { navigate("/"); }}
                    size='large'
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#0F79A1', color: 'white' }}>
                    Nazad
                </Button>
            </div>
            <Paper elevation={7} className={classes.form}>
                <div className={classes.loginTitle}>
                    <LockOutlinedIcon style={{ color: '#0F79A1' }} />
                    <Typography component="h1" variant="h5">
                        Prijava Petrovac
                    </Typography>
                </div>
                {
                    typeof userLoginData !== 'undefined'
                        ?
                        !userLoginData.success
                            ?
                            <Alert severity="error" style={{ marginBottom: '20px' }}>
                                <AlertTitle>Greška</AlertTitle>
                                Neuspješna prijava : {userLoginData?.message}
                            </Alert>
                            :
                            null
                        :
                        null
                }
                <form onSubmit={handleLoginSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="email"
                                label="Email ili telefon"
                                name="emailOrPhone"
                                type="text"
                                value={loginData.emailOrPhone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="password"
                                label="Šifra"
                                required
                                name="password"
                                type="password"
                                inputProps={{
                                    minLength: 6
                                }}
                                helperText="Minimum 6 karatkera"
                                value={loginData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                loading
                                    ?
                                    <div style={{ textAlign: 'center' }}>
                                        <CircularProgress size={130} />
                                    </div>
                                    :
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        type='submit'
                                        style={{ backgroundColor: '#0F79A1', color: 'white' }}>
                                        Prijavi se
                                    </Button>
                            }
                        </Grid>
                        <div className={classes.loginAdditional}>
                            <Typography
                                onClick={() => { navigate('/forgot-password'); }}
                                color='primary'
                                style={{ marginBottom: '10px', cursor: 'pointer' }}>
                                Zaboravili ste šifru?
                            </Typography>
                            <Typography
                                onClick={() => { navigate('/register'); }}
                                color='primary'
                                style={{ cursor: 'pointer' }}>
                                Nemate nalog? Registrujte se.
                            </Typography>
                        </div>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
};

export default Login;