import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    objectBigDiv: {
        //border : '3px solid black',
        position: 'relative',
        width: '88%',
        margin: 'auto',
        marginTop: '10px',
        padding: '15px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            //border : '1px solid red',
            marginTop: '0px',
            padding: '0px',
        }
    },
    basicInfoSection: {
        //border : '2px solid green',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    title: {
        //border : '2px solid black',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    address: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '7px'
    },
    addToWishList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '14%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'row',
            marginTop: '15px',
            justifyContent: 'center',
        }
    },
    galleryAndMap: {
        //border : '3px solid black',
        marginTop: '25px',
        marginBottom: '25px',
        display: 'flex',
        justifyContent: 'center',
        // alignItems : 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: '100%',
            marginTop: '0px'
        }
    },
    imageGalleryDiv: {
        //border : '3px solid green',
        position: 'relative',
        width: '60%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    mobileTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '5px'
    },
    divCarousel: {
        height: '270px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    carousel: {
        //border : '2px solid red',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    priceType: {
        borderTopLeftRadius: '12px',
        backgroundColor: '#0F79A1',
        padding: '6px',
        textAlign: 'center',
        color: 'white',
        borderBottomRightRadius: '8px',
        position: 'absolute',
        top: '0',
        left: '0',
    },
    mapDiv: {
        //border : '3px solid red',
        width: '45%',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            margin: 'auto'
        }
    },
    map: {
        borderRadius: '20px',
        height: '450px',
        zIndex: '2',
        [theme.breakpoints.down('xs')]: {
            height: '250px'
        }

    },
    mapDivInfo: {
        //border : '1px solid black',
        backgroundColor: 'white',
        padding: '5px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '5px',
        width: '90%',
        margin: 'auto',
        marginTop: '15px',
        padding: '10px',
        borderRadius: '12px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr 1fr',
            width: '98%',
        }
    },
    mapDivInfoDistance: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    descriptionAndEquipment: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '1rem',
        alignItems: 'stretch',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        }
    },
    description: {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '7px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 'auto'
        }
    },
    equipmentDiv: {
        //backgroundColor: 'white',
        borderRadius: '10px',
        padding: '7px',
    },
    equipment: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
        margin: 'auto',
        marginTop : '20px',
        marginLeft : '10px',
    },
    equipmentItemDivMobile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#f7f9fb',
        //border: '1px solid #cccccc',
        // width: '121px',
        // height: '90px',
        gap: '10px'
    },
    equipment_item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#F2F1EF',
        padding: '5px',
        borderRadius: '10px'
    },
    contactWithOwner: {
        marginTop: '5px',
        //border : '3px solid black',
        padding: '5px',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        }
    },
    contactOwnerTitle: {
        backgroundColor: 'white',
        padding: '5px',
        textAlign: 'center',
        borderRadius: '7px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    unitsTitle: {
        backgroundColor: 'white',
        padding: '5px',
        textAlign: 'left',
        borderRadius: '7px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-left',
        marginTop: '25px'
    },
    contactWithOwnerForm: {
        // border : '3px solid green',
        padding: '5px',
    },
    contactWithOwnerInfo: {
        // border : '3px solid red',
        padding: '5px'
    },
    ownerPhotoAndName: {

    },
    ownerPhotoAndNameMain: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '15px'
    },
    ownerPhoneData: {


    },
    phoneNumber: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px'
    },
    units: {
        //border: '3px solid black',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '1rem',
        width: '70%',
        marginTop: '25px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    singleUnit: {
        //border: '2px solid red',
        cursor: 'pointer',
        '&:hover': {
            [theme.breakpoints.up('md')]: {
                transform: 'scale(1.05)',
            }
        },
        transition: "transform .5s ease-in-out",
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '3px solid #F2F1EF',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            //border : '2px solid green'
        },
        [theme.breakpoints.down('322')]: {
            //border : '1px solid red',
            width : '90%'
        }
    },
    singleUnitPhotoAndInfo: {
        //border : '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            justifyContent: 'flex-start'
        }
    },
    singleUnitPhoto: {
        //border : '2px solid green',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: '180px',
        height: '140px',
        borderRadius: '12px'
    },
    singleUnitButton: {
        //border : '2px solid black',
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    singleUnitInfo: {
        marginLeft: '15px'
    },
    /////////////////////////
    unitDiv: {
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '100%',
            gap: '0',
        }
    },
    unitTitle: {
        padding: '11px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    unitPhotoGallery: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    imageDiv: {
        height: '550px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        [theme.breakpoints.down('xs')]: {
            height: '250px'
        },
    },
    carouselUnit: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    unitEquipment: {
        marginTop: '15px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem'
    },
    singleItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    unitInfo: {
        padding: '3px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    isPopularMark: {
        backgroundColor: "#0F79A1",
        position: 'absolute',
        borderTopLeftRadius: '12px',
        top: '0',
        left: '0',
        padding: '5px',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    contactFormDiv: {
        border: '6px solid purple',
        padding: '10px',
        backgroundColor: '#f7f9fb',
        border: '2px solid #cccccc'
    },
    contactFormTitle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
    },
    field: {
        "& .MuiOutlinedInput-root": {
            backgroundColor: 'white',
            "&.Mui-focused fieldset": {
                borderColor: '#0F79A1',
            }
        }
    },
    menu: {
        "& .MuiPaper-root": {
            padding: '6px',
            width: '300px',
            marginTop: '13px',
        }
    },
    formField: {
        border: '1px solid #D2D7D3',
        backgroundColor: 'white',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        width: '100%'
    },
    galleryDiv: {
        //border: '4px solid green',
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '0.5rem'
    },
    galleryDivBigPhoto: {
        //border: '2px solid red',
        position: 'relative',
        height: '480px',
        //width: '620px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        cursor: 'pointer',
        '&:hover': {
            //transform: 'scale(1.05)',
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
    },
    galleryDivOtherPhotos: {
        //border: '2px solid blue',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '0.5rem'
    },
    galleryDivSmallPhoto: {
        //border: '1px solid purple',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        '&:hover': {
            //transform: 'scale(1.05)',
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
    },
    galleryButton: {
        position: 'absolute',
        right: '15px',
        bottom: '15px',
        backgroundColor: 'white',
        borderRadius: '6px',
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '190px',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
            //boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
    },
    /////////////////////////////////////////////////////////
    middleSection: {
        //border: '3px solid green',
        padding: '10px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '1rem'
    },
    contactFormAndInfo: {
        //border: '3px solid red',
        padding: '5px'
    },
    contactForm: {
        //border : '1px solid purple'
    },
    contactInfo: {
        //border : '1px solid pink',
        marginTop: '15px'
    },
    accData: {
        //border: '3px solid yellow',
        padding: '5px',

    },
    accDataEquipment: {
        //border  :'1px solid red',
        marginTop: '5px'
    },
    oneItem: {
        //border : '1px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '5px'
    },
    mainInfoSection: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    mainInfoPart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '6px'
    },
    nameAndReview: {
        //border : '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mainInfoPricing: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    mainInfoAddress: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    share: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mainInfoShareFav: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '10%'
    },
    imageCounter: {
        position: 'absolute',
        bottom: '25px',
        right: '25px',
        width: '50px',
        padding: '5px',
        borderRadius: '6px',
        color: 'white',
        background: 'rgba(0,0,0,0.7)',
    },
    backButton: {
        cursor: 'pointer',
        //boxShadow: "0 2px 2px #888888",
        position: 'absolute',
        top: '15px',
        left: '10px',
        padding: '8px',
        backgroundColor: 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    shareAndFavButtons: {
        //border : '3px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'absolute',
        top: '15px',
        right: '8px',
        width: '110px',
        padding: '5px'
    },
    shareFavOneBut: {
        //boxShadow: "0 2px 2px #888888",
        padding: '8px',
        backgroundColor: 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bottomBar: {
        width: '100%',
        height: '60px',
        padding: '10px',
        backgroundColor: 'white',
        position: 'sticky',
        bottom: '0',
        borderTop: '2px solid #ececec',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: '20'

    },
    bottomBarPrice: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    tablesDiv: {
        display: 'grid',
        gridTemplateColumns: "1fr",
        gap: '10px',
        width: '90%',
        margin : 'auto',
        marginTop : '15px'
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        '& $td,th': {
            border: '1px solid #cccccc',
            textAlign: 'center',
            padding: '8px'
        },
        '& $th': {
            backgroundColor: '#f7f9fb'
        },
        '& $td': {
            fontWeight: 'bold'
        }
    },
}));

export default useStyles;