import React from 'react';
import useStyles from './style';
import { Typography, Button, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { useContext } from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { GlobalContext } from '../App';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { accObjectEquipmentData } from '../Accommodation Object/acc_object_equipment_data';

const ObjectCard = ({ objectData, size, objectHoverOpen, objectHoverClose, index, cardWidth, cardHeight, imageHeight }) => {

    const classes = useStyles();

    const { favoriteAccObjects, setFavoriteAccObjects, currentLanguage } = useContext(GlobalContext);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const checkFavorite = (id) => {
        return favoriteAccObjects.find(favId => favId === id) ? true : false;
    };

    const FavoritesIconSection = ({ id }) => {
        return <div className={classes.addToFavoritesIcon}>
            {
                checkFavorite(id)
                    ?
                    <Tooltip title="Ukloni iz želja">
                        <FavoriteIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                const updatedFav = favoriteAccObjects.filter(favId => favId !== id);
                                setFavoriteAccObjects([...updatedFav]);
                                localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                            }}
                            fontSize='medium'
                            style={{ cursor: 'pointer', color: '#ce0b0b' }} />
                    </Tooltip>
                    :
                    <Tooltip title="Dodaj u želje">
                        <FavoriteBorderIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                const updatedFav = [...favoriteAccObjects, id];
                                setFavoriteAccObjects([...updatedFav]);
                                localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                            }}
                            fontSize='medium'
                            style={{ cursor: 'pointer', color: '#ce0b0b' }} />
                    </Tooltip>
            }
        </div>
    };

    const getPriceType = (priceType) => {
        switch (priceType) {
            case 'per_person':
                return (
                    <Typography style={{ color: '#a1a1a1', fontSize: '12px' }}> {t("acc_object_page_per_night")} </Typography>
                );
            case 'per_night':
                return (
                    <Typography style={{ color: '#a1a1a1', fontSize: '12px' }}> {t("acc_object_page_per_person")} </Typography>
                );
            case 'on_request':
                return (
                    <Typography style={{ color: "#0ca6d8", fontSize: '28px', fontWeight: '900' }}>
                        {t("acc_object_page_on_request")}
                    </Typography>
                )
            default: return 0;
        }
    };

    const getPriceTypeMobile = (priceType) => {
        switch (priceType) {
            case 'per_person':
                return (
                    <Typography variant='subtitle2'>&nbsp;/ po {t("acc_object_page_per_night")} </Typography>
                );
            case 'per_night':
                return (
                    <Typography variant='subtitle2'>&nbsp;/ po {t("acc_object_page_per_person")} </Typography>
                );
            case 'on_request':
                return (
                    <Typography style={{ color: "#0ca6d8", fontSize: '28px', fontWeight: '900' }}>
                        {t("acc_object_page_on_request")}
                    </Typography>
                )
            default: return 0;
        }
    };

    return (
        <div
            onClick={() => {
                navigate(`/object/${objectData.id}?name=${objectData.name}`);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            onMouseEnter={() => { return typeof index !== 'undefined' ? objectHoverOpen(index) : null }}
            onMouseLeave={() => { return typeof index !== 'undefined' ? objectHoverClose(index) : null }}
            style={{
                width : cardWidth,
                height : cardHeight
            }}
            className={classes.objectCard}>

            <div
                className={classes.objectCardImage}
                style={{
                    backgroundImage: objectData.photo ? `url('${process.env.REACT_APP_UPLOADS_PATH + objectData.photo}')` : noPhotoAvailable,
                    // height: size !== 'map' ? '180px' : '135px'
                    height: isMobile ? '140px' : imageHeight
                }}></div>
            <div className={classes.objectCardBodyMobile}>
                <Typography style={{ fontSize: '15px', fontWeight: '900', marginLeft : '5px' }}>
                    {objectData.name}
                </Typography>
                <div style={{marginLeft : '5px' }}>
                    {
                        objectData.type_of_price === 'on_request'
                            ?
                            <Typography variant='subtitle2'>
                                Cijena na upit
                            </Typography>
                            :
                            <div style={{ display : 'flex', flexDirection : 'row' }}>
                                <Typography variant='subtitle2' style={{ fontWeight : 'bolder', color : '#ce0b0b' }}>
                                    &euro; {objectData.minimal_price_from}
                                </Typography>
                                    {
                                        getPriceTypeMobile(objectData.type_of_price)
                                    }
                               
                            </div>
                    }
                </div>
            </div>
            <div className={classes.objectCardBody}>

                <div className={classes.objectCardTitle}>
                    <Typography style={{ fontSize: size !== 'map' ? '19px' : '15px', fontWeight: '900' }}>
                        {objectData.name}
                    </Typography>
                </div>

                <div className={classes.objectCardData}>

                    {
                        accObjectEquipmentData[currentLanguage].map((item, index) => {
                            const check = objectData.equipment[item.equipmentName];
                            if (check) {
                                return (
                                    <Tooltip title={item.nameToDisplay} placement='bottom' arrow key={index}>
                                        <img
                                            src={item.icon}
                                            width={size !== 'map' ? '20' : '15'}
                                            alt='Item'
                                            style={{
                                                marginRight: '10px',
                                                filter: 'invert(26%) sepia(96%) saturate(421%) hue-rotate(164deg) brightness(98%) contrast(88%)'
                                            }} />
                                    </Tooltip>
                                )
                            }
                        })
                    }

                </div>

                <div className={classes.objectCardPriceAndButton}>

                    <div className={classes.objectCardPrice} >

                        <div className={classes.objectCardPriceFrom}>
                            <Typography style={{ color: '#a1a1a1', fontWeight: 'bold', fontSize: size !== 'map' ? '13px' : '10px' }}>
                                {
                                    objectData.type_of_price === 'on_request'
                                        ?
                                        'CIJENA'
                                        :
                                        'CIJENA OD'
                                }
                            </Typography>
                        </div>

                        <div className={classes.objectCardPriceInfo}>
                            {
                                objectData.type_of_price === 'on_request'
                                    ?
                                    <Typography style={{ color: "#0ca6d8", fontSize: size !== 'map' ? '20px' : '17px', fontWeight: '900' }}>
                                        NA UPIT
                                    </Typography>
                                    :
                                    <>
                                        <div>
                                            <Typography style={{ color: "#0ca6d8", fontSize: '28px', fontWeight: '900' }}>
                                                {objectData.minimal_price_from}
                                            </Typography>
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            <div>
                                                <Typography style={{ color: '#f8ac33', fontSize: '12px', fontWeight: 'bold', marginBottom: '-4px' }}>EUR</Typography>
                                            </div>
                                            <div>
                                                <Typography style={{ color: '#a1a1a1', fontSize: '12px' }}>
                                                    {getPriceType(objectData.type_of_price)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>

                    </div>

                    <div className={classes.objectCardReserveButton}>
                        <Button
                            size={size !== 'map' ? 'large' : 'medium'}
                            style={{
                                color: '#f8ac33',
                                fontWeight: 'bold',
                                borderRadius: '50px',
                                boxShadow: "rgba(0, 0, 0, 0) 0px 3px 6px, rgba(0, 0, 0, 0.13) 0px 3px 6px"
                            }}>
                            Rezerviši
                        </Button>
                    </div>

                </div>

            </div>

            <FavoritesIconSection id={objectData.id} />

            {
                objectData.is_popular
                    ?
                    <div className={classes.isPopularMark}>
                        <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: '12px', textTransform: 'uppercase' }}>
                            {t("card_popular_flag")}
                        </Typography>
                    </div>
                    :
                    null
            }

        </div>
    );
};

export default ObjectCard;