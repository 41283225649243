import { Grid, Typography, CircularProgress, LinearProgress } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import ApartmentCard from '../ApartmentCard/ApartmentCard';
import { useDispatch, useSelector } from 'react-redux';
import { getPopularAccObjectsAction } from '../actions/acc_objects_actions';
import { Alert, AlertTitle } from '@material-ui/lab';
import { t } from 'i18next';
import ObjectCard from '../AllObjects/ObjectCard';


const RecommendedApartments = ({ isMobile, firstPage, cardWidth, cardHeight, imageHeight }) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const popularAccObjectsSelector = useSelector(state => state.popularAccObjectsState);
    const { loading, popularAccObjectsData: data } = popularAccObjectsSelector;

    React.useEffect(() => {
        dispatch(getPopularAccObjectsAction());
    }, []);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>Greška</AlertTitle>
                Neuspješno prikazivanje objekata
            </Alert>
        );
    };


    return (
        <div
            style={{
                width: isMobile ? '99%' : !firstPage ? '100%' : '90%',
                margin: 'auto',
                marginTop: '20px',
            }}
            className={classes.recommendedApartmentsBigDiv} >
            <div className={classes.sectionTitleDiv}>
                <Typography className={classes.sectionTitle}>
                    Popularni objekti
                </Typography>
            </div>
            {
                loading
                    ?
                    <div style={{ marginTop: '20px' }}>
                        <LinearProgress />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data.success
                            ?
                            data.popularObjects.length > 0
                                ?
                                <div
                                    style={{
                                        //border : '3px solid green',
                                        //display: isMobile ? 'flex' : firstPage ? 'grid' : 'flex',
                                        display: isMobile ? 'flex' : 'grid',
                                        gridTemplateColumns: isMobile ? '1fr 1fr' : firstPage ? '1fr 1fr 1fr 1fr' : '1fr 1fr',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        gap: isMobile ? '5px' : firstPage ? '20px' : '10px'
                                    }}
                                    className={classes.recommendedApartments}>
                                    {data.popularObjects.map((object, index) => {
                                        return (
                                            <ObjectCard
                                                cardHeight={cardHeight}
                                                cardWidth={cardWidth}
                                                imageHeight={imageHeight}
                                                key={index}
                                                objectData={object}
                                                size={
                                                    isMobile
                                                        ?
                                                        'map'
                                                        :
                                                        firstPage
                                                            ?
                                                            null
                                                            :
                                                            'map'
                                                } />
                                        )
                                    })}
                                </div>
                                :
                                <Typography variant='subtitle1'>
                                    Trenutno nema popularnih smještaja
                                </Typography>
                            :
                            <ShowError />
                        :
                        null
            }
        </div>
    );

};

export default RecommendedApartments;
