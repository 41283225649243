import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    recommendedApartmentsBigDiv: {
    //   width  : '100%',
    //   margin : 'auto',
    //   marginTop : '20px',
    //   [theme.breakpoints.down('xs')]: {
    //     width: '100%'
    // }
    },
    recommendedApartments : {
      //border : '2px solid red'
    },
    sectionTitleDiv: {
      borderLeft: '5px solid #f79800',
      marginBottom: '20px',
      marginTop: '20px'
  },
  sectionTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#0ca6d8',
      textTransform: 'uppercase',
      marginLeft: '10px',
  },
}));

export default useStyles;