import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    navigation: {
        //border : '2px solid black',
        position: 'relative',
        height: '120px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        [theme.breakpoints.down('xs')]: {
            //border : '3px solid red',
            height: '270px'
        }
    },
    innerContainer: {
        //border : '4px solid red',
        width: '100%',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    datePickerDiv: {
        //border : '1px solid red',
        width: '60%',
        margin: 'auto',
        padding: '5px',
        //marginTop: '30px',
        backgroundColor: 'white',
        borderRadius: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    pickerItem: {
        //border : '1px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            //border : '1px solid red',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '5px',
            marginBottom: '10px'
        }
    },
    dropdownImg: {
        marginRight: '10px',
        cursor: 'pointer',
        color: '#000000'
    },
    pickerText: {
        //border : '1px solid green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    searchButton: {
        backgroundColor: '#f79800',
        color: 'white',
        borderRadius: '20px',
    },
    test: {
        display: 'flex',
        flexDirection: 'column'
    },
    personNumPicker: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            marginTop: '10%'
        }
    },
    buttonSearch: {
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginTop: '8%'
        }
    },
    infoSection: {
        //border : '2px solid yellow',
        width: '87%',
        margin: '0 auto',
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            width: '98%',
        }
    },
    infoPart: {
        //border : '2px solid green',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },
    infoImg: {
        width: '85px',
        marginRight: '15px'
    },
    menu: {
        "& .MuiPaper-root": {
            padding: '6px',
            height: '250px',
            marginTop: '13px',
        }
    },
    menuItem: {
        //border : '2px solid green',
    },
    mobileGridSection: {
        margin: 'auto',
        padding: '5px',
        // backgroundColor: '#ECF0F1',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRadius: '0px'
        }
    },
    mobileFormField: {
        //border: '1px solid #D2D7D3',
        backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        width: '100%',
        cursor: 'pointer'
    },
    carsDiv: {
        //border: '3px solid green',
        width: '90%',
        margin: 'auto',
        marginTop: '15px',
        [theme.breakpoints.down('xs')]: {
            width : '98%'
        }
    },
    carsList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gap: '15px',
        [theme.breakpoints.down('xs')]: {
            display : 'flex',
            flexDirection : 'column',
            justifyContent : 'center',
            alignItems : 'center'
        }
    },
    carCard: {
        //border: '2px solid red',
        position: 'relative',
        width: '335px',
        height: '360px',
        boxShadow: '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 0px 1px 1.583px 0.125px rgba(0,0,0,0.14), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        transition: '0.5s',
        '&:hover': {
            //border : '2px solid #0F79A1'
            transform: 'scale(1.02)',
            //boxShadow : '2px 0.458px 0.645px -0.292px #245d8d, 0px 1px 1.583px 0.125px #245d8d, 0px 0.375px 1.916px 0.333px #245d8d'
            boxShadow: '#245d8d 0px 0px 10px, #245d8d 0px 1px 2px'
        }
    },
    carCardImage: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '165px',
        cursor: 'pointer',
        width: '100%'
    },
    carCardBody: {

    },
    carCardTitle: {
        marginTop: '5px',
        textAlign: 'center'
    },
    carCardData: {
        width: '95%',
        margin: 'auto',
        backgroundColor: '#ecf5fe',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '17px',
        marginTop: '10px',
        padding: '8px'
    },
    carCardDataSingle: {
        //border : '1px solid green',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        //alignItems : 'center'
    },
    carCardPriceAndButton: {
        width: '90%',
        margin: 'auto',
        marginTop: '5px',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    carCardPrice: {
        display: 'flex',
        flexDirection: "column",
    },
    carCardPriceFrom: {
        marginBottom: '-3px'
    },
    carCardPriceInfo: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    carCardReserveButton: {

    },
    addToFavoritesIcon: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        backgroundColor: 'hsla(0,0%,100%,.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px',
        borderRadius: '50%'
    },
    isPopularMark: {
        backgroundColor: "#dd0000",
        position: 'absolute',
        top: '10px',
        left: '0',
        padding: '3px',
        borderTopRightRadius: '7px',
        borderBottomRightRadius: '7px'
    },
    locationDialogPicker: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px'
    },
    singleLocation: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '10px'
    },
    location: {
        cursor: 'pointer'
    }
}));

export default useStyles;