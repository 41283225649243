import { Grid, Paper, Typography, TextField, Button, CircularProgress, LinearProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPasswordResetLinkAction, resetPasswordAction } from '../actions/users_actions';
import { useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const ResetPassword = () => {

    const classes = useStyles();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get('token');

    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

    const resetPasswordSelector = useSelector(state => state.resetPasswordState);
    const { loading, resetPassword } = resetPasswordSelector;

    const handleResetPassword = (e) => {
        e.preventDefault();
        if(newPassword !== confirmNewPassword){
            alert('Šifre se moraju poklapati');
        }else {
            dispatch(resetPasswordAction({
                newPassword, 
                confirmNewPassword, 
                token
            }));
        }
    };

    React.useEffect(()=>{
        if(!token){
            navigate('/login');
        }else {
            try {
                const decoded = jwtDecode(token);
            } catch (error) {
                navigate('/login');
            }
        }
    },[token]);

    return (
        <div className={classes.loginDiv}>
            <div className={classes.backButton}>
                <Button
                    onClick={() => { navigate("/login"); }}
                    size='large'
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#0F79A1', color: 'white' }}>
                    Nazad
                </Button>
            </div>
            <Paper elevation={7} className={classes.form}>
                <div className={classes.loginTitle}>
                <Typography component="h1" variant="h5">
                        Nova šifra
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginTop: '10px' }}>
                        Unesite novu šifru.
                    </Typography>
                </div>
                {
                    loading
                        ?
                        <LinearProgress style={{ marginBottom : '20px' }}/>
                        :
                        typeof resetPassword !== 'undefined'
                            ?
                            !resetPassword.success
                                ?
                                <Alert severity="error" style={{ marginBottom: '20px' }}>
                                    Neuspješno resetovanje šifre
                                </Alert>
                                :
                                <Alert severity="success" style={{ marginBottom: '20px' }}>
                                    Uspješno resetovanje šifre
                                </Alert>
                            :
                            null
                }
                <form onSubmit={handleResetPassword}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                label="Nova šifra"
                                name="newPassword"
                                type="password"
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                label="Potvrdite novu šifru"
                                name="confirmNewPassword"
                                type="password"
                                value={confirmNewPassword}
                                onChange={(e) => {
                                    setConfirmNewPassword(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button
                                variant='contained'
                                fullWidth
                                type='submit'
                                style={{ backgroundColor: '#0F79A1', color: 'white' }}>
                                Potvrdi
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
};

export default ResetPassword;