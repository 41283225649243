import {
    Button,
    Typography,
    TextField,
    IconButton,
    Grid,
    Paper,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    LinearProgress,
    Snackbar
} from '@material-ui/core'
import React from 'react'
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { addNewRestaurantAction } from '../actions/restaurants_actions';
import { AlertTitle, Alert } from '@material-ui/lab';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';


const initialRestaurantData = {
    restaurantName: '',
    restaurant_is_restaurant: '',
    restaurant_is_club: '',
    restaurant_is_cafe: '',
    restaurantDescription: '',
    restaurantWorkingHours: '',
    restaurantCapacity: '',
    restaurantMusic: '',
    restaurantPhotoGallery: [],
    restaurantAddress: '',
    restaurantLatitude: '',
    restaurantLongitude: '',
    restaurantEquipment: {
        internet: false,
        parking: false,
        disability_friendly: false,
        card_payment: false,
        smokers_zone: false
    },
    restaurantPhones: [
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        },
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        },
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        }
    ],
    restaurantEmail: '',
    restaurantWebSite: ''
};

const phones = [1, 2, 3];

const equipment = [
    'Internet',
    'Parking',
    'Prilagođenost invalidima',
    'Plaćanje karticom',
    'Zona za pušače'
];

const snackBarInitialState = {
    show: false,
    severity: 'info',
    snackTitle: '',
    snackMessage: ''
};

const AddNewRestaurant = ({ switchActivePanel }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [restaurantData, setRestaurantData] = React.useState(initialRestaurantData);

    const [imageGalleryDisplay, setImageGalleryDisplay] = React.useState([]);

    const newRestaurantSelector = useSelector(state => state.newRestaurantState);
    const { loading, newRestaurantData } = newRestaurantSelector;

    const [showSnackBar, setShowSnackBar] = React.useState(snackBarInitialState);

    const [mainPhotoIndex, setMainPhotoIndex] = React.useState(null);

    const handleCloseSnackBar = () => {
        setShowSnackBar(snackBarInitialState)
    };

    React.useEffect(() => {
        if (typeof newRestaurantData !== 'undefined') {
            if (newRestaurantData?.success) {
                setShowSnackBar({
                    show: true,
                    severity: 'success',
                    snackTitle: 'Uspješno',
                    snackMessage: 'Dodat novi restoran !'
                });
            } else {
                setShowSnackBar({
                    show: true,
                    severity: 'error',
                    snackTitle: 'Greška',
                    snackMessage: 'Greška u kreiranju novog restorana.'
                })
            }
        }
    }, [newRestaurantData]);

    const handleImageChange = (e) => {
        if (e.target.files) {
            setRestaurantData((prevData) => {
                return {
                    ...prevData,
                    restaurantPhotoGallery: prevData.restaurantPhotoGallery.concat([...e.target.files])
                }
            });
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setImageGalleryDisplay((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const handleRemoveImageFromGallery = (index) => {
        setRestaurantData((prevData) => {
            return {
                ...prevData,
                restaurantPhotoGallery: prevData.restaurantPhotoGallery.filter((photo, photoIndex) => photoIndex !== index)
            }
        });
        setImageGalleryDisplay((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    const handleChange = (e) => {
        setRestaurantData({
            ...restaurantData,
            [e.target.name]: e.target.value
        });
    };

    const handleAddRestaurantForm = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', restaurantData.restaurantName);
        formData.append('description', restaurantData.restaurantDescription);
        formData.append('working_hours', restaurantData.restaurantWorkingHours);
        formData.append('capacity', restaurantData.restaurantCapacity);
        formData.append('music', restaurantData.restaurantMusic);
        formData.append('address', restaurantData.restaurantAddress);
        formData.append('latitude', restaurantData.restaurantLatitude);
        formData.append('longitude', restaurantData.restaurantLongitude);
        formData.append('email_for_reservations', restaurantData.restaurantEmail);
        formData.append('web_site', restaurantData.restaurantWebSite);
        formData.append('is_restaurant', restaurantData.restaurant_is_restaurant);
        formData.append('is_club', restaurantData.restaurant_is_club);
        formData.append('is_cafe', restaurantData.restaurant_is_cafe);
        formData.append('mainPhotoIndex',mainPhotoIndex);

        formData.append('restaurantPhoneNumbers', JSON.stringify(restaurantData.restaurantPhones));
        formData.append('restaurantEquipment', JSON.stringify(restaurantData.restaurantEquipment));

        restaurantData.restaurantPhotoGallery.forEach(photo => {
            formData.append('restaurantPhotos', photo);
        });

        dispatch(addNewRestaurantAction(formData));
    };

    function AddMarkerToClick() {

        useMapEvents({
            click(e) {
                setRestaurantData({
                    ...restaurantData,
                    restaurantLatitude: e.latlng.lat,
                    restaurantLongitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[restaurantData.restaurantLatitude, restaurantData.restaurantLongitude]} />
    };

    const getKeyName = (index) => {
        return Object.keys(restaurantData.restaurantEquipment)[index];
    };

    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };

    return (
        <div className={classes.addNewObjectDiv}>
            <div style={{ marginBottom: '25px' }}>
                <Typography
                    style={{ cursor: 'pointer' }}
                    variant='h5'
                    color='primary'
                    onClick={() => { switchActivePanel('myObjects'); }}>
                    Odustani
                </Typography>
            </div>
            <form onSubmit={handleAddRestaurantForm} className={classes.addNewObjectDiv}>
                <Paper elevation={7} className={classes.addNewObject_section1}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Osnovni podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section1Form}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantName"
                                label="Naziv restorana"
                                name="restaurantName"
                                type="text"
                                value={restaurantData.restaurantName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Opis restorana"
                                fullWidth
                                variant="outlined"
                                multiline
                                name='restaurantDescription'
                                value={restaurantData.restaurantDescription}
                                onChange={handleChange}
                                minRows={6}
                                maxRows={8}
                                required
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                            <div className={classes.section1GalleryTitle}>
                                <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color='primary'
                                >
                                    <AddIcon fontSize='small' />
                                    <input
                                        id='dugme'
                                        type="file"
                                        onChange={handleImageChange}
                                        multiple
                                        accept='image/*'
                                        hidden
                                    />
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                    Izabrana glavna fotografija
                                </Typography>
                                {
                                    checkSelectedImage(restaurantData.restaurantPhotoGallery) !== -1
                                        ?
                                        <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                                        :
                                        <CloseIcon color='error' fontSize='large' />
                                }
                            </div>
                            <div className={classes.section1ImagePreviewDiv}>
                                {
                                    imageGalleryDisplay.map((photo, index) => {
                                        return (
                                            <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                <img
                                                    onClick={() => {
                                                        setMainPhotoIndex(index);
                                                        const gallery = restaurantData.restaurantPhotoGallery.map((photo, indexG) => {
                                                            if (index === indexG) {
                                                                photo.is_main = true;
                                                                return photo
                                                            } else {
                                                                photo.is_main = false;
                                                                return photo;
                                                            }
                                                        });
                                                        setRestaurantData((prevData) => {
                                                            return {
                                                                ...prevData,
                                                                restaurantPhotoGallery: [...gallery]
                                                            }
                                                        });
                                                    }}
                                                    src={photo}
                                                    width={210}
                                                    alt={`Object ${index}`}
                                                    style={{
                                                        borderRadius: '10px',
                                                        cursor: 'pointer',
                                                        border: checkSelectedImage(restaurantData.restaurantPhotoGallery) === index ? '4px solid green' : '1px solid transparent'
                                                    }} />
                                                <div key={2} className={classes.editImage} >
                                                    <IconButton onClick={() => { handleRemoveImageFromGallery(index); }}>
                                                        <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section2}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>

                            <MapContainer
                                center={[42.207232, 18.943100]}
                                zoom={16}
                                tap={false}
                                className={classes.map}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />

                                <AddMarkerToClick />
                            </MapContainer>

                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantLatitude"
                                label="Latituda"
                                name="restaurantLatitude"
                                value={restaurantData.restaurantLatitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantLongitude"
                                label="Longituda"
                                name="restaurantLongitude"
                                value={restaurantData.restaurantLongitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantAddress"
                                label="Adresa"
                                name="restaurantAddress"
                                value={restaurantData.restaurantAddress}
                                onChange={handleChange}
                                type="text"
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section3}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Ostali podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>
                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Restoran?</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(restaurantData.restaurant_is_restaurant)}
                                                    onChange={(e) => {
                                                        setRestaurantData({
                                                            ...restaurantData,
                                                            restaurant_is_restaurant: true
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Da"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={restaurantData.restaurant_is_restaurant === '' ? false : !Boolean(restaurantData.restaurant_is_restaurant)}
                                                    onChange={(e) => {
                                                        setRestaurantData({
                                                            ...restaurantData,
                                                            restaurant_is_restaurant: false
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Ne"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Klub?</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(restaurantData.restaurant_is_club)}
                                                    onChange={(e) => {
                                                        setRestaurantData({
                                                            ...restaurantData,
                                                            restaurant_is_club: true
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Da"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={restaurantData.restaurant_is_club === '' ? false : !Boolean(restaurantData.restaurant_is_club)}
                                                    onChange={(e) => {
                                                        setRestaurantData({
                                                            ...restaurantData,
                                                            restaurant_is_club: false
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Ne"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Kafić?</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(restaurantData.restaurant_is_cafe)}
                                                    onChange={(e) => {
                                                        setRestaurantData({
                                                            ...restaurantData,
                                                            restaurant_is_cafe: true
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Da"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={restaurantData.restaurant_is_cafe === '' ? false : !Boolean(restaurantData.restaurant_is_cafe)}
                                                    onChange={(e) => {
                                                        setRestaurantData({
                                                            ...restaurantData,
                                                            restaurant_is_cafe: false
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Ne"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                multiline
                                minRows={6}
                                maxRows={8}
                                id="restaurantWorkingHours"
                                label="Opis radnog vremena"
                                name="restaurantWorkingHours"
                                type="text"
                                value={restaurantData.restaurantWorkingHours}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantCapacity"
                                label="Broj mjesta"
                                name="restaurantCapacity"
                                value={restaurantData.restaurantCapacity}
                                onChange={handleChange}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 2
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Muzika?</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(restaurantData.restaurantMusic)}
                                                onChange={(e) => {
                                                    setRestaurantData({
                                                        ...restaurantData,
                                                        restaurantMusic: true
                                                    });
                                                }}
                                            />
                                        }
                                        label="Da"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={restaurantData.restaurantMusic === '' ? false : !Boolean(restaurantData.restaurantMusic)}
                                                onChange={(e) => {
                                                    setRestaurantData({
                                                        ...restaurantData,
                                                        restaurantMusic: false
                                                    });
                                                }}
                                            />
                                        }
                                        label="Ne"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section4}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Oprema objekta</Typography></div>
                    <div className={classes.equipmentDiv}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <div className={classes.equipment}>
                                    {
                                        equipment.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                restaurantData.restaurantEquipment[getKeyName(index)]
                                                            }
                                                            onChange={(e) => {
                                                                const keyName = getKeyName(index);
                                                                const updatedEquipment = {
                                                                    ...restaurantData.restaurantEquipment,
                                                                    [keyName]: e.target.checked
                                                                };
                                                                setRestaurantData((prevData) => {
                                                                    return {
                                                                        ...prevData,
                                                                        restaurantEquipment: updatedEquipment
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    label={item}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>
                        </FormControl>
                    </div>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section3}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        {
                            phones.map((phone, index) => {
                                return (
                                    <Grid item xs={12} md={12} key={index}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            required={index === 0 ? true : false}
                                            id="numOfGrownups"
                                            label={`Broj telefona ${phone}`}
                                            name="phoneNum"
                                            type="text"
                                            value={restaurantData.restaurantPhones[index].phoneNumber}
                                            onChange={(e) => {
                                                setRestaurantData((prevData) => {
                                                    prevData.restaurantPhones[index].phoneNumber = e.target.value;
                                                    return { ...prevData }
                                                });
                                            }}
                                        />
                                        <FormControl component="fieldset">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={restaurantData.restaurantPhones[index].viber}
                                                            onChange={(e) => {
                                                                setRestaurantData((prevData) => {
                                                                    prevData.restaurantPhones[index].viber = e.target.checked;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Viber"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={restaurantData.restaurantPhones[index].whatsapp}
                                                            onChange={(e) => {
                                                                setRestaurantData((prevData) => {
                                                                    prevData.restaurantPhones[index].whatsapp = e.target.checked;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Whatsapp"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="restaurantEmail"
                                label="Email adresa"
                                name="restaurantEmail"
                                type="text"
                                value={restaurantData.restaurantEmail}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantWebSite"
                                label="Link vaseg web sajta"
                                name="restaurantWebSite"
                                type="text"
                                value={restaurantData.restaurantWebSite}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                </Paper>
                {

                    loading
                        ?
                        <div>
                            <Typography variant='subtitle2'>Kreiranje restorana...</Typography>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        <div className={classes.addNewObjectButtons}>
                            <Button
                                type='submit'
                                style={{ backgroundColor: '#26A65B', color: 'white' }} >
                                Dodaj
                            </Button>
                            <Button
                                onClick={() => { switchActivePanel('myRestaurants'); }}
                                variant='text'
                                style={{ color: '#DC3023' }}>
                                <CancelIcon />
                                Odustani
                            </Button>
                        </div>
                }

            </form>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: "center" }}
                open={showSnackBar.show}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}>
                <Alert
                    variant='filled'
                    // onClose={handleCloseSnackBar}
                    severity={showSnackBar.severity}
                    sx={{ width: '100%' }}>
                    <AlertTitle>{showSnackBar.snackTitle}</AlertTitle>
                    {showSnackBar.snackMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AddNewRestaurant;