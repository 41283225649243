import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    loginDiv : {
        //border : '3px solid black',
        position : 'relative',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        width : '90%',
        height : '99vh',
        margin : 'auto',
        [theme.breakpoints.down('xs')]: {
            width : '100%',
        }
    },
    backButton : {
        position : 'absolute',
        top : '20px',
        left : '0px'
    },
    form : {
        padding : '30px',
        width : '40%',
        margin : 'auto',
        [theme.breakpoints.down('xs')]: {
            width : '80%',
        }
    },
    loginTitle : {
        width : '70%',
        margin : 'auto',
        marginBottom : '20px',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center',
        textAlign : 'center'
    },
    loginAdditional : {
        marginTop : '20px',
        display : 'grid',
        gridTemplateColumns : '1fr',
        gap : '5px'
    },
    infoCard : {
        //border : '1px solid black',
        textAlign : 'center',
        [theme.breakpoints.down('xs')]: {
            
        }
    }
}));

export default useStyles;