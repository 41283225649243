import { Typography, Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import Person from '@material-ui/icons/AccountCircle';
import People from '@material-ui/icons/PeopleAlt';
import Children from '@material-ui/icons/ChildCare';
import Arrival from '@material-ui/icons/FlightLand';
import Departure from '@material-ui/icons/FlightTakeoff';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/LocalPhone';
import Question from '@material-ui/icons/Help';
import Date from '@material-ui/icons/Event';
import { format, parseISO } from 'date-fns';


const ViewQuestionDetailsDialog = ({ show, setShow, data }) => {

    const classes = useStyles();

    const handleClose = () => {
        setShow({
            show: false,
            data: {}
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={show}
            onClose={handleClose}
        >
            <DialogTitle >Pregled upita za objekat {data.accObjectName}</DialogTitle>
            <DialogContent className={classes.viewQuestionContent}>
                <div className={classes.viewQuestionSection}>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Person fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Ime i prezime</strong></Typography>
                        </div>
                        <Typography>{data.contact_name} {data.contact_surname}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Email fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Email kontakt</strong></Typography>
                        </div>
                        <Typography>{data.contact_email}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Phone fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Telefon</strong></Typography>
                        </div>
                        <Typography>{data.contact_phone}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Date fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Pitano</strong></Typography>
                        </div>
                        <Typography>
                            {
                                typeof data.date_asked !== 'undefined'
                                    ?
                                    format(parseISO(data.date_asked), 'dd/MM/yyyy/HH:mm:ss')
                                    :
                                    null
                            }
                        </Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <People fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Broj odraslih</strong></Typography>
                        </div>
                        <Typography>{data.num_of_grownups}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Children fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Broj djece</strong></Typography>
                        </div>
                        <Typography>{data.num_of_kids}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Arrival fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Dolazak</strong></Typography>
                        </div>
                        <Typography>{data.arrival_date}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Departure fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Odlazak</strong></Typography>
                        </div>
                        <Typography>{data.departure_date}</Typography>
                    </div>
                </div>
                <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Question fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Poruka</strong></Typography>
                        </div>
                        <Typography>{data.contact_message}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zatvori</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewQuestionDetailsDialog;


