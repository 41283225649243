import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { checkRegularAdminAction } from './actions/users_actions';
import { useDispatch, useSelector } from 'react-redux';

const ProtectedRoute = () => {

    const dispatch = useDispatch();

    const checkRegularAdminSelector = useSelector(state => state.regularAdminCheck);
    const { regularAdmin } = checkRegularAdminSelector;

    React.useEffect(() => {
        dispatch(checkRegularAdminAction());
    }, []);

    return (
        typeof regularAdmin !== 'undefined'
        ?
        regularAdmin.success
            ?
            <Outlet />
            :
            <Navigate to='/login' />
        :
        null
    );

};

export default ProtectedRoute;
