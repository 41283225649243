import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    TextField,
    Menu,
    MenuItem,
    Box,
    CssBaseline,
    Drawer,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import React, { useContext } from 'react';
import logo from '../assets/photos/logo.png';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import montenegro from '../assets/countries_flags_photos/montenegroLanguage.png';
import useStyles from './style';
import MenuIcon from '@material-ui/icons/Menu';
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ListItem, List, Divider } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { languagesMenuObject } from './languages';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import i18next from '../i18next';
import { t } from 'i18next';
import logoHome from '../assets/photos/logoHome.svg';
import logoSticky from '../assets/photos/logoSticky.svg';
import sunce from '../assets/photos/sunce.svg';
import { navButtons } from './languages';
import CloseIcon from '@material-ui/icons/Close';
import LanguagePickerDialog from '../Footer/LanguagePickerDialog';

const NavBar = () => {

    const { currentLanguage, setCurrentLanguage, currentPage, setCurrentPage } = useContext(GlobalContext);

    const isHomePage = window.location.pathname === '/' ? true : false;

    const navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [languagePickerDialog, setLanguagePickerDialog] = React.useState(false);

    const [anchorLanguage, setAnchorLanguage] = React.useState(null);
    const [drawerState, setDrawerState] = React.useState({ right: false });
    const [appBarBackground, setAppBarBackground] = React.useState('navBar');

    const appBarRef = React.useRef();

    appBarRef.current = appBarBackground;

    const [searchWord, setSearchWord] = React.useState('');

    React.useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 110;
            if (show) {
                setAppBarBackground('navBarSolid');
            } else {
                setAppBarBackground('navBar')
            }
        };

        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const handleOpenLanguageMenu = (event) => {
        setAnchorLanguage(event.currentTarget);
    };

    const handleCloseLanguageMenu = () => {
        setAnchorLanguage(null);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        navigate(`/search?searchWord=${searchWord}`);
        console.log('Searching...');
    };

    const handleSwitchLanguage = (lang, index) => {
        localStorage.setItem('currentLanguage', JSON.stringify(index));
        setCurrentLanguage(index);
        i18next.changeLanguage(`${lang.toLowerCase()}`)
    };

    const handleNavButtonClick = (index) => {
        localStorage.setItem('currentPage', JSON.stringify(index));
        setCurrentPage(index);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        switch (index) {
            case 0:
                navigate('/');
                break;
            case 1:
                navigate('/objects');
                break;
            case 2:
                navigate('/restaurants');
                break;
            case 3:
                navigate('/rent-a-car');
                break;
            case 4:
                navigate('/tours-and-excursions');
                break;
            case 5:
                navigate('/events');
                break;
            case 6:
                navigate('/favorites');
                break;
            default: navigate('/');
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const mobileMenu = () => (
        <Box sx={{ width: 300 }}>
            <div style={{
                backgroundColor: '#0F79A1',
                height: '9vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }}>
                <IconButton size='medium' onClick={() => { setDrawerState({ right: false }); }}>
                    <CloseIcon fontSize='large' style={{ color: '#f79800 ' }} />
                </IconButton>
            </div>
            <List className={classes.list}>
                {
                    navButtons[currentLanguage].map((name, index) => {
                        return (
                            [
                                <ListItem
                                    style={{
                                        backgroundColor: currentPage === index ? '#f79800' : 'white',
                                        color: currentPage === index ? 'white' : 'black'
                                    }}
                                    onClick={() => {
                                        handleNavButtonClick(index);
                                        setDrawerState({ right: false });
                                    }}
                                    key={index}>
                                    <Typography style={{ fontSize: '18px' }}>
                                        {name}
                                    </Typography>
                                </ListItem>,
                                <Divider key={index + 1}></Divider>
                            ]
                        )
                    })
                }
                <ListItem
                    style={{
                        backgroundColor: currentPage === 6 ? '#f79800' : 'white',
                        color: currentPage === 6 ? 'white' : 'black'
                    }}
                    onClick={() => {
                        handleNavButtonClick(6);
                        setDrawerState({ right: false });
                    }}
                >
                    <Typography style={{ fontSize: '18px' }}>
                        Moj izbor
                    </Typography>
                    <FavoriteIcon
                        fontSize='small'
                        style={{
                            marginLeft: '5px',
                            color: currentPage === 6 ? 'white' : '#ce0b0b'
                        }} />
                </ListItem>
                <Divider></Divider>
                <ListItem
                    onClick={()=>{ setLanguagePickerDialog(true); }} 
                    style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={languagesMenuObject[currentLanguage].flag} width={55} alt='Flag' />
                    <Typography
                        variant='subtitle1'
                        style={{
                            color: 'black',
                            marginLeft: '5px',
                            marginRight: '5px',
                            fontWeight : 'bold'
                        }}>
                        {languagesMenuObject[currentLanguage].code}
                    </Typography>
                </ListItem>
            </List>

        </Box>
    );

    return (
        <>
            <CssBaseline />
            <AppBar
                position={
                    !isHomePage
                        ?
                        'sticky'
                        :
                        appBarRef.current === 'navBar'
                            ?
                            'relative'
                            :
                            'sticky'
                }
                className={isHomePage ? classes[appBarRef.current] : classes.navBarSolid}
                elevation={0}
                style={{
                    borderBottom: !isHomePage
                        ?
                        '1px solid #ececec'
                        :
                        appBarRef.current === 'navBar'
                            ?
                            'none'
                            :
                            '1px solid #ececec'
                }}
            >
                <Toolbar className={classes.toolbar}>

                    <img
                        src={
                            isMobile
                                ?
                                sunce
                                :
                                isHomePage && appBarRef.current === 'navBar'
                                    ?
                                    logoHome
                                    :
                                    logoSticky
                        }
                        alt='Logo'
                        className={classes.logo}
                        onClick={() => { 
                            handleNavButtonClick(0);
                         }}
                    />

                    <Box
                        sx={{
                            display: {
                                md: 'block',
                                lg: 'none'
                            },
                            width: {
                                //md : '50%'
                                xs: '60%'
                            }
                        }} >
                        <form onSubmit={handleSearchSubmit}>
                            <TextField
                                fullWidth
                                className={classes.searchField}
                                autoComplete='off'
                                required
                                variant='outlined'
                                value={searchWord}
                                onChange={(e) => {
                                    setSearchWord(e.target.value);
                                }}
                                size='small'
                                type='text'
                                placeholder={t("nav_search_bar")}
                                inputProps={{
                                    style: {
                                        //border: !isHomePage ? '2px solid black' : appBarRef.current === 'navBar' ? 'none' : '2px solid black',
                                        fontSize: '15px',
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type='submit' size='small' style={{ backgroundColor: '#f79800', padding: '8px' }}>
                                                <Search
                                                    type='submit'
                                                    className={classes.searchIcon}
                                                    fontSize='medium'
                                                    style={{
                                                        color: 'white'
                                                    }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </form>
                    </Box>

                    <Box sx={{
                        display: {
                            xs: 'none',
                            md: 'none',
                            lg: 'block'
                        }
                    }}>
                        <div className={classes.navBarRight}>
                            {
                                navButtons[currentLanguage].map((buttonName, index) => {
                                    return (
                                        <Button
                                            className={classes.navButton}
                                            size='small'
                                            style={{
                                                color: !isHomePage ? '#0074b9' : appBarRef.current === 'navBar' ? 'white' : '#0074b9',
                                                borderBottom: currentPage === index ? '3px solid #f79800' : 'none'
                                            }}
                                            key={index}
                                            onClick={() => { handleNavButtonClick(index) }}
                                            variant='text'
                                            disableElevation
                                        >
                                            {buttonName}
                                        </Button>
                                    )
                                })
                            }

                            <Button
                                size='small'
                                style={{
                                    color: !isHomePage ? '#0F79A1' : appBarRef.current === 'navBar' ? 'white' : '#0F79A1', marginRight: '15px',
                                    borderBottom: currentPage === 6 ? '3px solid #f79800' : 'none',
                                    borderRadius: '0',
                                }}
                                onClick={() => {
                                    localStorage.setItem('currentPage', JSON.stringify(6));
                                    setCurrentPage(6);
                                    navigate('/favorites');
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                                variant='text'
                            >
                                <FavoriteIcon fontSize='small' style={{ marginRight: '5px', color: '#ce0b0b' }} />
                                moj izbor
                            </Button>

                            <div className={classes.navBarLanguagePicker}>
                                <Button disableElevation disableRipple onClick={handleOpenLanguageMenu}>
                                    <img src={languagesMenuObject[currentLanguage].flag} width={40} alt='Flag' />
                                    <Typography
                                        variant='subtitle2'
                                        style={{
                                            color: !isHomePage ? '#0F79A1' : appBarRef.current === 'navBar' ? 'white' : '#0F79A1',
                                            marginLeft: '5px',
                                            marginRight: '5px'
                                        }}>
                                        {languagesMenuObject[currentLanguage].code}
                                    </Typography>
                                    <ExpandMoreIcon style={{ color: !isHomePage ? '#0F79A1' : appBarRef.current === 'navBar' ? 'white' : '#0F79A1' }} />
                                </Button>
                                <Menu
                                    anchorEl={anchorLanguage}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={Boolean(anchorLanguage)}
                                    onClose={handleCloseLanguageMenu}
                                >
                                    {languagesMenuObject.map((language, index) => (
                                        <MenuItem
                                            style={{
                                                backgroundColor: index === currentLanguage ? '#0F79A1' : 'white',
                                                color: index === currentLanguage ? 'white' : 'black'
                                            }}
                                            key={index}
                                            onClick={() => { handleSwitchLanguage(language.code, index); }}
                                            className={classes.menuItem}>
                                            <img src={language.flag} width={22} style={{ marginRight: '5px' }} alt='Flag' />
                                            <div>
                                                <Typography variant='subtitle2' >{language.code}</Typography>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>

                        </div>
                    </Box>
                    <Box sx={{ display: { md: 'block', lg: 'none' } }}>
                        <div className={classes.mobileMenu}>
                            <MenuIcon
                                fontSize='large'
                                style={{ color: "#0F79A1" }}
                                onClick={toggleDrawer('right', true)} />
                        </div>
                    </Box>
                </Toolbar>
                <Drawer
                    elevation={20}
                    anchor='right'
                    open={drawerState.right}
                    onClose={toggleDrawer('right', false)}
                >
                    {mobileMenu('right')}
                </Drawer>
            </AppBar >
            <LanguagePickerDialog
                currentLanguage={currentLanguage}
                languagesMenuObject={languagesMenuObject}
                classes={classes}
                languagePickerDialog={languagePickerDialog}
                setLanguagePickerDialog={setLanguagePickerDialog}
                handleSwitchLanguage={handleSwitchLanguage} />
        </>
    )
}

export default NavBar;
