import {
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Avatar,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  LinearProgress,
  IconButton,
  Box
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './mobileStyle';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import StarIcon from '@material-ui/icons/Star';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { accObjectEquipmentData } from '../Accommodation Object/acc_object_equipment_data';
import { contactOwnerPhotos } from '../Accommodation Object/owner_info_data';
import ContactForm from './CarContactForm';
import FavoriteIcon from '@material-ui/icons/Favorite';
import backArrow from '../assets/photos/left-arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../App';
import PeopleIcon from '@material-ui/icons/PeopleAlt';
import { useTranslation } from 'react-i18next';
import share from '../assets/photos/share.png';
import telefon from '../assets/photos/telefon.svg';
import website from '../assets/photos/website.svg';
import Lightbox from 'react-image-lightbox';
import SquareMeter from '@material-ui/icons/AspectRatio';
import { useNavigate } from 'react-router-dom';
import { carEquipment } from './carEquipment';

const CarMobileView = ({ data }) => {

  const { favoriteCars, setFavoriteCars, currentLanguage } = useContext(GlobalContext);

  const formRef = React.useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const [currentLightBoxPhotoIndex, setCurrentLightBoxPhotoIndex] = React.useState(0);
  const [lightBoxOpen, setLightBoxOpen] = React.useState(false);

  const getPriceType = (priceType, price) => {
    switch (priceType) {
      case 3:
        return (
          <>
            <Typography variant={!isMobile ? 'h4' : 'h5'} style={{ color: "#ce0b0b" }}>
              <strong>&euro;{price}</strong>&nbsp;
            </Typography>
            <Typography variant={!isMobile ? 'h4' : 'h5'} >
              / {t("acc_object_page_per_night")}
            </Typography>
          </>
        )
      case 2:
        return (
          <>
            <Typography variant={!isMobile ? 'h4' : 'h5'} style={{ color: "#ce0b0b" }}>
              <strong>&euro;{price}</strong>&nbsp;
            </Typography>
            <Typography variant={!isMobile ? 'h4' : 'h5'} >
              / {t("acc_object_page_per_person")}
            </Typography>
          </>
        )
      case 1:
        return (
          <Typography variant={!isMobile ? 'h4' : 'h5'}>
            {t("acc_object_page_on_request")}
          </Typography>
        )
      default: return (
        <Typography variant={!isMobile ? 'h4' : 'h5'}>
          {t("acc_object_page_on_request")}
        </Typography>
      )
    }
  };

  const checkFavorite = (id) => {
    return favoriteCars.find(favId => favId === id) ? true : false;
  };

  const ShowLightBox = () => {
    return (
      <>
        {
          lightBoxOpen
            ?
            <Lightbox
              //animationDisabled={true}
              animationDuration={0}
              mainSrc={process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[currentLightBoxPhotoIndex]}
              nextSrc={process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[(currentLightBoxPhotoIndex + 1) % data.car.carPhotos.length]}
              prevSrc={process.env.REACT_APP_UPLOADS_PATH + data.car.carPhotos[(currentLightBoxPhotoIndex + data.car.carPhotos.length - 1) % data.car.carPhotos.length]}
              onCloseRequest={() => { setLightBoxOpen(false); }}
              onMovePrevRequest={() =>
                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + data.car.carPhotos.length - 1) % data.car.carPhotos.length)
              }
              onMoveNextRequest={() =>
                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + 1) % data.car.carPhotos.length)
              }
            />
            :
            null
        }
      </>

    )
  };

  const DescriptionAndEquipmentSectionMobile = () => {
    return (
      <div className={classes.descriptionAndEquipment}>

        <div className={classes.equipmentDiv}>
          <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
              Oprema
            </Typography>
          </div>
          <div className={classes.equipment}>
            {
              carEquipment.map((equipmentItem, index) => {
                return (
                  <div
                    key={index}
                    className={classes.equipmentItemDivMobile}>
                    <img
                      style={{
                        filter: 'invert(26%) sepia(96%) saturate(421%) hue-rotate(164deg) brightness(98%) contrast(88%)'
                      }}
                      width='27'
                      src={equipmentItem.icon}
                      alt='Eq' />
                    <Typography variant='caption'>
                      <strong>
                        {data.car[equipmentItem.propName]} &nbsp;
                        {equipmentItem.additionalText ?? equipmentItem.additionalText}
                      </strong>
                    </Typography>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    )
  };

  const ContactOwnerSectionMobile = () => {
    return (
      <div className={classes.contactWithOwner}>

        <div className={classes.contactInfo}>
          <div className={classes.contactWithOwnerInfo}>

            <div style={{ borderLeft: '3px solid #f79800', marginBottom: '15px' }}>
              <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                {t("acc_object_page_main_data")}
              </Typography>
            </div>

            {/* Podaci o vlasniku */}
            <div className={classes.ownerPhotoAndName}>
              <div className={classes.ownerPhotoAndNameMain}>
                <Avatar src={process.env.REACT_APP_UPLOADS_PATH + data.car.ownerData.avatar} style={{ width: '60px', height: '60px', marginRight: '11px' }} />
                <div>
                  <Typography style={{ color: 'gray' }} variant='subtitle2' > {t("acc_object_page_owner_name")} </Typography>
                  <Typography variant='subtitle1' > {data.car.ownerData.name} {data.car.ownerData.surname}  </Typography>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
            {/* Brojevi telefona */}
            <div className={classes.ownerPhoneData}>
              {
                data.car.carPhones.map((phone, index) => {
                  return (
                    <div className={classes.phoneNumber} key={index}>
                      <img src={telefon} alt="Phone" height={20} style={{ marginRight: '5px' }} />
                      <Typography variant='subtitle1'> {phone.phone_number} </Typography>
                      {phone.viber ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                      {phone.whatsapp ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                    </div>
                  )
                })
              }
            </div>
            <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
              <img src={website} alt="Website" height={20} style={{ marginRight: '5px' }} />
              <Typography variant='subtitle1'>{data.car.website} </Typography>
            </div>
          </div>
        </div>

        <div className={classes.contactWithOwnerForm}>
          <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
              {t("acc_object_page_owner_contact")}
            </Typography>
          </div>

          <span ref={formRef} ></span>
          <ContactForm carId={data.car.id} />
        </div>
      </div>
    );
  };

  const ReservationConditions = () => {
    return (
      <div style={{ padding: '7px', }}>

        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
          <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
            Uslovi rezervacije
          </Typography>
        </div>
        <div style={{ marginLeft: '8px', marginTop: '20px' }}>
          <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
            <strong>Uslovi i pravila</strong> <br></br>
            Minimum 21 godina starosti <br></br>
            Minimum 2 godine posjedovanja vozačke dozvole <br></br>
            Obavezna identifikacija - lična karta ili pasoš <br></br>
            Minimalna dužina najma je 1 dan (24h) <br></br>
            Vrijeme preuzimanja i vraćanja vozila moraju biti isti. Tolerišemo 60 minuta kašnjenja <br></br>
            Po isteku prekoračenja od 60 minuta, zadržavamo pravo da naplatimo još jedan dan najma <br></br>
          </Typography>

          <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
            <strong>Preuzimanje i vraćanje vozila</strong> <br></br>
            Vozilo se može preuzeti i vratiti u jednoj od naših poslovnica a moguća je i dostava odnosno vraćanje izvan poslovnice (hotel, kućna adresa i sl.).
            Takođe,vozilo možete preuzeti i vratiti i izvan radnog vremena.
          </Typography>

          <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
            <strong>Depozit</strong> <br></br>
            Klijent je u obavezi da ostavi depozit u visini 200 do 500€ u zavisnosti od kategorije vozila. Ukoliko se utvrdi da vozilo ima novo oštećenje u trenutku vraćanja,
            depozit će biti zadržan u onolikom iznosu koliko se klijent prema ugovoru o najmu zadužuje za oštećenje.
          </Typography>

          <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
            <strong>Načini plaćanja</strong> <br></br>
            Najam vozila se plaća prlikom preuzimanja vozila gotovinom, kreditnom karticom (Visa, Mastercard, Diners, Amex), iii uplatom na račun (pravna lica).
          </Typography>

          <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
            <strong>Saobraćajne kazne</strong> <br></br>
            Klijent je dužan da plati sve saobraćajne/parking kazne koje su nastale za vrijeme trajanja najma.
          </Typography>


          <Typography variant='subtitle2' style={{ marginBottom: '25px' }}>
            <strong>Podrška na putu</strong> <br></br>
            Ukoliko odlučite da iznajmite jedno od vozila iz našeg voznog parka, imaćete podršku 24/7 na teritoriji cijele Evrope.
          </Typography>
        </div>
      </div>
    )
  };

  const BottomBar = () => {
    return (
      <div className={classes.bottomBar}>
        <div className={classes.bottomBarPrice}>
          {getPriceType(data.car.price_type, data.car.minimal_price_from)}
        </div>
        <div className={classes.bottomBarButton}>
          <Button
            onClick={() => {
              window.scrollTo({ behavior: 'smooth', top: formRef.current.offsetTop - 87 })
            }}
            size='medium'
            disableElevation
            variant='contained'
            style={{ backgroundColor: "#f79800", color: 'white', borderRadius: '12px' }} >
            Rezerviši
          </Button>
        </div>
      </div>
    );
  };

  const Pricing = () => {
    return (
      <div style={{ padding: '7px', marginTop: '20px', marginBottom: '20px' }}>

        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
          <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
            Cijenovnik
          </Typography>
        </div>

        <div className={classes.tablesDiv}>
          <div className={classes.singleTableDiv}>
            <table className={classes.table}>
              <tr>
                <th>
                  <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                    BROJ DANA
                  </Typography>
                </th>
                <th>
                  <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                    CIJENA
                  </Typography>
                </th>
              </tr>
              <tr>
                <td>1 do 3 dana</td>
                <td>{data.car.one_to_three_days}&euro; / dan</td>
              </tr>

              <tr>
                <td>4 do 7 dana</td>
                <td>{data.car.four_to_seven_days}&euro; / dan</td>
              </tr>

              <tr>
                <td>8 do 15 dana</td>
                <td>{data.car.eight_to_fifteen_days}&euro; / dan</td>
              </tr>

              <tr>
                <td>16 do 30 dana</td>
                <td>{data.car.sixteen_to_thirty_days}&euro; / dan</td>
              </tr>

              <tr>
                <td>31 do 45 dana</td>
                <td>{data.car.thirtyone_to_fortyfive_days}&euro; / dan</td>
              </tr>

              <tr>
                <td>45+ dana</td>
                <td>{data.car.fortyfive_plus_days}&euro; / dan</td>
              </tr>

            </table>
          </div>
          <div className={classes.singleTableDiv}>
            <table className={classes.table}>
              <tr>
                <th>
                  <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                    DODATNO
                  </Typography>
                </th>
                <th>
                  <Typography style={{ color: '#245d8d', fontWeight: 'bold' }} variant='subtitle1'>
                    CIJENA
                  </Typography>
                </th>
              </tr>
              <tr>
                <td>Sjedište za djecu</td>
                <td>{data.car.child_seat}&euro; / dan</td>
              </tr>

              <tr>
                <td>GPS navigacija</td>
                <td>{data.car.gps_navigation}&euro; / dan</td>
              </tr>

              <tr>
                <td>Wifi 4G mobile router</td>
                <td>{data.car.wifi_4g_mobile_router}&euro; / dan</td>
              </tr>

              <tr>
                <td>Osiguranje</td>
                <td>{data.car.insurance}&euro; / dan</td>
              </tr>

              <tr>
                <td>Dostava vozila</td>
                <td>{data.car.deposit}&euro; / dan</td>
              </tr>

              <tr>
                <td>Depozit</td>
                <td>{data.car.vehicle_delivery}&euro; / dan</td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    )
  };

const MobileGallery = () => {
  return (
    <div className={classes.galleryAndMap}>
      <div className={classes.imageGalleryDiv}>
        <Carousel
          showThumbs={false}
          emulateTouch
          showIndicators={false}
          showArrows={false}
          className={classes.carousel}
          swipeable
          showStatus={false}>
          {
            data.car.carPhotos.length > 0
              ?
              data.car.carPhotos.map((image, index) => {
                return (
                  <div
                    className={classes.divCarousel}
                    style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + image}')` }}
                    key={index}
                    onClick={() => {
                      setLightBoxOpen(true);
                      setCurrentLightBoxPhotoIndex(index);
                    }}>
                    <div className={classes.imageCounter}>
                      <Typography variant='caption' >{index + 1} / {data.car.carPhotos.length}</Typography>
                    </div>
                  </div>
                )
              })
              :
              <div>
                <img src={noPhotoAvailable} alt='No Photo' />
              </div>
          }
        </Carousel>
        <div className={classes.backButton} onClick={() => { navigate(-1) }}>
          <img src={backArrow} height={17} alt='Back' />
        </div>
        <div className={classes.shareAndFavButtons}>
          <div className={classes.shareFavOneBut}>
            <img src={share} height={20} alt='Back' />
          </div>
          <div className={classes.shareFavOneBut}>
            {
              checkFavorite(data.car.id)
                ?
                <FavoriteIcon
                  onClick={() => {
                    const updatedFav = favoriteCars.filter(favId => favId !== data.car.id);
                    setFavoriteCars([...updatedFav]);
                    localStorage.setItem('favoriteCars', JSON.stringify(updatedFav));
                  }}
                  fontSize='small'
                  style={{ cursor: 'pointer', color: '#ce0b0b' }} />
                :
                <FavoriteBorderIcon
                  onClick={() => {
                    const updatedFav = [...favoriteCars, data.car.id];
                    setFavoriteCars([...updatedFav]);
                    localStorage.setItem('favoriteCars', JSON.stringify(updatedFav));
                  }}
                  fontSize='small'
                  style={{ cursor: 'pointer', color: '#ce0b0b' }} />

            }
          </div>
        </div>
      </div>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          marginTop: '15px',
          width: '95%',
          margin: 'auto'
        }} >
        <div className={classes.mobileTitle}>
          <Typography variant='h5' style={{ marginRight: '5px' }}>
            <strong>{data.car.manufacturer} {data.car.model} - {data.car.engine}</strong>
          </Typography>
        </div>
        <div className={classes.mainInfoAddress}>
          <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>
            Cijena od
          </Typography>
          &nbsp;&nbsp;
          <Typography style={{ color: "#dd0000", fontSize: '20px', fontWeight: 'bolder' }}>
            {data.car.one_to_three_days},00 &euro;
          </Typography>
          &nbsp;&nbsp;
          <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>
            po danu
          </Typography>
        </div>
      </Box>
    </div>
  )
}

return (
  <>
    <MobileGallery />
    <DescriptionAndEquipmentSectionMobile />
    <Pricing />
    <ContactOwnerSectionMobile />
    <ReservationConditions />
    {/* <BottomBar /> */}
    <ShowLightBox />
  </>
)
}

export default CarMobileView;
