
export const registerValidator = (registerData) => {

    const { name,surname,phone,email,password,confirmPassword,avatar } = registerData;

    const errors = [];

    if(!name || !surname || !password || !confirmPassword) {
        errors.push('Morate popuniti sva obavezna polja.');
    };

    if(name.length < 2) {
        errors.push('Ime mora imati min 2 karaktera');
    };

    if(surname.length < 2) {
        errors.push('Prezime mora imati min 2 karaktera');
    };

    if(phone){
        if(phone.length < 8) {
            errors.push('Telefon mora sadržati min 8 cifara');
        };
    }

    if(!phone && !email){
        errors.push('Morate unijeti barem jedno od email ili telefon');
    }

    if(password.length < 6) {
        errors.push('Šifra mora sadržati min 6 karaktera');
    };

    if(password !== confirmPassword) {
        errors.push('Šifre se moraju poklapati');
    };

    if(avatar) {
        if(avatar.type !== 'image/jpeg' && avatar.type !== 'image/jpg' && avatar.type !== 'image/png') errors.push('Avatar mora biti slika');
    }

    return errors;
};