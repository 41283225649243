import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, TextField, ThemeProvider } from '@material-ui/core';
import { Grid, Typography, MenuItem, Select, InputAdornment, useTheme, useMediaQuery, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getAllCarsAction } from '../actions/rent_a_car_actions';
import { AlertTitle, Alert } from '@material-ui/lab';
import backPhoto from '../assets/homePhotos/001.jpg';
import { Menu } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { format, parseISO } from 'date-fns';
import dropdown from '../assets/photos/dropdown.svg';
import { useTranslation } from 'react-i18next';
import { getAllToursAction } from '../actions/tours_actions';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const eventTypes = ['Sve', 'Muzika', 'Sport', 'Kultura'];

const searchDataInitial = {
    eventDate: null,
    eventType: '',
};

const Events = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [searchData, setSearchData] = React.useState(searchDataInitial);

    const [openEventTypeDialog, setOpenEventTypeDialog] = React.useState(false);

    const [openDate, setOpenDate] = React.useState(false);
    const [eventTypeAnchor, setEventTypeAnchor] = React.useState(null);

    const handleOpenEventTypeAnchor = (event) => {
        setEventTypeAnchor(event.currentTarget);
    };

    const handleCloseEventTypeAnchor = () => {
        setEventTypeAnchor(null);
    };

    const handleReservationFormSubmit = (e) => {
        e.preventDefault();
    };

    React.useEffect(() => {
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if (fsData) {
            setSearchData({
                ...searchData,
                eventDate: fsData.arrivalDate
            });
        }
    }, []);

    const handleChange = (e) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value
        })
    };

    return (
        <>
            <div className={classes.navigation} style={{ backgroundImage: `url(${backPhoto}` }}>
                <div className={classes.innerContainer}>
                    {
                        !isMobile
                            ?
                            <form onSubmit={handleReservationFormSubmit}>

                                <div className={classes.datePickerDiv}>

                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <div
                                            onClick={() => {
                                                setOpenDate(open => !open);
                                            }}
                                            className={classes.pickerItem}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.eventDate
                                                            ?
                                                            typeof searchData.eventDate === 'string'
                                                                ?
                                                                format(parseISO(searchData.eventDate), 'dd/MM/yyyy')
                                                                :
                                                                format(searchData.eventDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>

                                            </div>
                                        </div>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                style={{ display: 'none' }}
                                                open={openDate}
                                                required
                                                color='primary'
                                                disablePast
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                autoOk
                                                // variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                emptyLabel='Izaberi datum'
                                                value={searchData.eventDate}
                                                onChange={(e) => {
                                                    setSearchData({
                                                        ...searchData,
                                                        eventDate: e
                                                    });
                                                    setOpenDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDate(false);
                                                }}
                                            />
                                        </ThemeProvider>

                                    </MuiPickersUtilsProvider>

                                    <div className={classes.pickerItem} onClick={handleOpenEventTypeAnchor}>
                                        <img

                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Tip događaja</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    searchData.eventType
                                                        ?
                                                        searchData.eventType
                                                        :
                                                        'Izaberi tip događaja'
                                                }
                                            </Typography>
                                        </div>

                                    </div>

                                    <Menu
                                        style={{
                                            padding: '6px',
                                            height: '250px',
                                            marginTop: '13px',
                                        }}
                                        anchorEl={eventTypeAnchor}
                                        open={Boolean(eventTypeAnchor)}
                                        onClose={handleCloseEventTypeAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            eventTypes.map((type, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setSearchData({
                                                                ...searchData,
                                                                eventType: type
                                                            });
                                                            handleCloseEventTypeAnchor();
                                                        }}
                                                        key={index}>
                                                        <Typography variant='subtitle2'>
                                                            {type}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>

                                    <div className={classes.buttonSearch}>
                                        <Button
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation
                                            size='medium' >
                                            <SearchIcon fontSize='small' />
                                            {t("search_section_title")}
                                        </Button>
                                    </div>

                                </div>
                            </form>
                            :
                            <form onSubmit={handleReservationFormSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.mobileGridSection}>
                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <Grid item xs={12}>
                                            <div className={classes.mobileFormField}
                                                onClick={() => {
                                                    setOpenDate(open => !open);
                                                }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            searchData.eventDate
                                                                ?
                                                                typeof searchData.eventDate === 'string'
                                                                    ?
                                                                    format(parseISO(searchData.eventDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(searchData.eventDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <DatePicker
                                                    style={{ display: 'none' }}
                                                    open={openDate}
                                                    required
                                                    color='primary'
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    disableToolbar
                                                    autoOk
                                                    variant='dialog'
                                                    emptyLabel='Izaberi datum'
                                                    value={searchData.tourDate}
                                                    onChange={(e) => {
                                                        setSearchData({
                                                            ...searchData,
                                                            eventDate: e
                                                        });
                                                        setOpenDate(false);
                                                    }}
                                                    onClose={() => {
                                                        setOpenDate(false);
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <Grid item xs={12}>
                                        <div className={classes.mobileFormField} onClick={() => { setOpenEventTypeDialog(true); }}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Tip događaja</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.eventType
                                                            ?
                                                            searchData.eventType
                                                            :
                                                            'Izaberi tip događaja'
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            size='medium' >
                                            <SearchIcon fontSize='large' />
                                            {t("search_section_title")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                    }
                </div>
                <Dialog
                    fullWidth
                    open={openEventTypeDialog}
                    onClose={() => { setOpenEventTypeDialog(false); }}>
                    <DialogTitle>Tip događaja</DialogTitle>
                    <DialogContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px'
                    }}>
                        {
                            eventTypes.map((type, index) => {
                                return (
                                    <div onClick={() => {
                                        setSearchData({
                                            ...searchData,
                                            eventType: type
                                        });
                                        setOpenEventTypeDialog(false);
                                    }}>
                                        <Typography

                                            style={{
                                                color: searchData.eventType === type ? '#f79800' : 'black',
                                                fontWeight: searchData.eventType === type ? 'bolder' : 'normal',
                                                cursor: 'pointer'
                                            }}
                                            variant='subtitle1'
                                            key={index}>
                                            {type}
                                        </Typography>
                                    </div>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenEventTypeDialog(false); }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default Events;