import photo1 from '../assets/homePhotos/001.jpg';
import photo2 from '../assets/homePhotos/002.jpg';
import photo3 from '../assets/homePhotos/003.jpg';
import photo4 from '../assets/homePhotos/004.jpg';
import photo5 from '../assets/homePhotos/005.jpg';

export const homePhotos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
];