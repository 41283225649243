export const registerReducer = (userRegister = {}, action) => {
    switch (action.type) {
        case 'REGISTER_REQUEST':
            return {
                loading: true
            }
        case 'REGISTER_SUCCESS':
        case 'REGISTER_FAIL':
            return {
                loading: false,
                userRegister: action.payload
            }
        default:
            return userRegister;
    };
};

export const verifyEmailReducer = (verifyEmailData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_VERIFY_EMAIL':
            return {
                loading: true
            }
        case 'SUCCESS_VERIFY_EMAIL':
        case 'ERROR_VERIFY_EMAIL':
            return {
                loading: false,
                verifyEmailData: action.payload
            }
        default:
            return verifyEmailData;
    };
};

export const loginUserReducer = (userLoginData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN_USER':
            return {
                loading: true
            }
        case 'SUCCESS_LOGIN_USER':
        case 'ERROR_LOGIN_USER':
            if (action.payload.emailOrPhone) {
                //delete action.payload.success;
                localStorage.setItem('loggedUserData', JSON.stringify(action.payload));
            }
            return {
                loading: false,
                userLoginData: action.payload
            }
        case 'EMPTY_LOGIN_USER_DATA':
            userLoginData = {};
            return userLoginData;
        default:
            return userLoginData;
    };
};

export const checkJwtTokenReducer = (jwtToken = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_CHECK_JWT':
        case 'ERROR_CHECK_JWT':
            return {
                jwtToken: action.payload
            }
        case 'EMPTY_CHECK_JWT':
            jwtToken = {};
            return jwtToken;
        default:
            return jwtToken;
    };
};

export const checkSuperAdminReducer = (superAdmin = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_CHECK_SUPER_ADMIN':
        case 'ERROR_CHECK_SUPER_ADMIN':
            return {
                superAdmin: action.payload
            }
        case 'EMPTY_REGULAR_AND_SUPER_ADMIN_CHECK':
            superAdmin = {};
            return superAdmin;
        default:
            return superAdmin;
    };
};

export const checkRegularAdminReducer = (regularAdmin = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_CHECK_REGULAR_ADMIN':
        case 'ERROR_CHECK_REGULAR_ADMIN':
            return {
                regularAdmin: action.payload
            }
        case 'EMPTY_REGULAR_AND_SUPER_ADMIN_CHECK':
            regularAdmin = {};
            return regularAdmin;
        default:
            return regularAdmin;
    };
};

export const logoutUserReducer = (userLogout = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_LOGOUT_USER':
        case 'ERROR_LOGOUT_USER':
            localStorage.removeItem('loggedUserData');
            localStorage.removeItem('saCurrentPage');
            return {
                userLogout: action.payload
            }
        default:
            return userLogout;
    };
};

export const updateUserDataReducer = (updateUser = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_USER_DATA':
            return {
                loading: true
            }
        case 'SUCCESS_UPDATE_USER_DATA':
            const data = JSON.parse(localStorage.getItem('loggedUserData'));
            const newData = {
                ...data,
                name: action.payload.data.name,
                surname: action.payload.data.surname,
                avatar: action.payload.data.avatar === null ? data.avatar : action.payload.data.avatar
            };
            localStorage.setItem('loggedUserData', JSON.stringify(newData));
            return {
                loading: false,
                updateUser: action.payload
            }
        case 'ERROR_UPDATE_USER_DATA':
            return {
                loading: false,
                updateUser: action.payload
            }
        default: return updateUser;
    }
};

export const superAdminGetAllUsersReducer = (allUsers = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SUPER_ADMIN_GET_ALL_USERS':
            return {
                loading: true
            }
        case 'SUCCESS_SUPER_ADMIN_GET_ALL_USERS':
        case 'ERROR_SUPER_ADMIN_GET_ALL_USERS':
            return {
                loading: false,
                allUsers: action.payload
            }
        default:
            return allUsers;
    };
};

export const superAdminUpdateUserReducer = (superAdminUpdateUser = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SUPER_ADMIN_UPDATE_USER':
            return {
                loading_update: true
            }
        case 'SUCCESS_SUPER_ADMIN_UPDATE_USER':
        case 'ERROR_SUPER_ADMIN_UPDATE_USER':
            return {
                loading_update: false,
                superAdminUpdateUser: action.payload
            }
        default:
            return superAdminUpdateUser;
    };
};

export const getPasswordResetLinkReducer = (getPasswordResetLink = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_PASSWORD_RESET_LINK':
            return {
                loading: true
            }
        case 'SUCCESS_GET_PASSWORD_RESET_LINK':
        case 'ERROR_GET_PASSWORD_RESET_LINK':
            return {
                loading: false,
                getPasswordResetLink: action.payload
            }
        default:
            return getPasswordResetLink;
    };
};

export const resetPasswordReducer = (resetPassword = {}, action) => {
    switch (action.type) {
        case 'REQUEST_RESET_PASSWORD':
            return {
                loading: true
            }
        case 'SUCCESS_RESET_PASSWORD':
        case 'ERROR_RESET_PASSWORD':
            return {
                loading: false,
                resetPassword: action.payload
            }
        default:
            return resetPassword;
    };
};