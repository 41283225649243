

export const addNewEventReducer = (newEvent = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_EVENT':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_EVENT':
        case 'ERROR_ADD_NEW_EVENT':
            return {
                loading: false,
                newEvent: action.payload
            }
        default: return newEvent
    }
};

export const getEventsTableReducer = (eventsTable = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_EVENTS_TABLE':
            return {
                loading: true
            }
        case 'SUCCESS_GET_EVENTS_TABLE':
        case 'ERROR_GET_EVENTS_TABLE':
            return {
                loading: false,
                eventsTable: action.payload
            }
        default: return eventsTable
    }
};


export const getEventDataForEditReducer = (eventDataForEdit = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_EVENT_DATA_FOR_EDIT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_EVENT_DATA_FOR_EDIT':
        case 'ERROR_GET_EVENT_DATA_FOR_EDIT':
            return {
                loading: false,
                eventDataForEdit: action.payload
            }
        default: return eventDataForEdit
    }
};

export const updateEventReducer = (updateEvent = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_EVENT':
            return {
                loading_update: true
            }
        case 'SUCCESS_UPDATE_EVENT':
        case 'ERROR_UPDATE_EVENT':
            return {
                loading_update: false,
                updateEvent: action.payload
            }
        default: return updateEvent
    }
};

export const deleteEventReducer = (deleteEvent = {}, action) => {
    switch (action.type) {
        case 'REQUEST_DELETE_EVENT':
            return {
                loading: true
            }
        case 'SUCCESS_DELETE_EVENT':
        case 'ERROR_DELETE_EVENT':
            return {
                loading: false,
                deleteEvent: action.payload
            }
        default: return deleteEvent
    }
};

export const previewEventReducer = (previewEvent = {}, action) => {
    switch (action.type) {
        case 'REQUEST_PREVIEW_EVENT':
            return {
                loading: true
            }
        case 'SUCCESS_PREVIEW_EVENT':
        case 'ERROR_PREVIEW_EVENT':
            return {
                loading: false,
                previewEvent: action.payload
            }
        default: return previewEvent
    }
};

export const getAllEventsReducer = (allEvents = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ALL_EVENTS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ALL_EVENTS':
        case 'ERROR_GET_ALL_EVENTS':
            return {
                loading: false,
                allEvents: action.payload
            }
        default: return allEvents
    }
};