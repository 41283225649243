import * as api from '../api/api_manager';

export const addNewEventAction = (eventData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_EVENT' });

        const { data } = await api.addNewEvent(eventData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_EVENT',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new event: ', error);
        dispatch({
            type: 'ERROR_ADD_NEW_EVENT',
            payload: error.response.data
        })
    }
};

export const getEventsTableAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_EVENTS_TABLE' });

        const { data } = await api.getEventsTable();

        dispatch({
            type: 'SUCCESS_GET_EVENTS_TABLE',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting events table: ', error);
        dispatch({
            type: 'SUCCESS_GET_EVENTS_TABLE',
            payload: error.response.data
        })
    }
};

export const getEventDataForEditAction = (eventId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_EVENT_DATA_FOR_EDIT' });

        const { data } = await api.getEventDataForEdit(eventId);

        dispatch({
            type: 'SUCCESS_GET_EVENT_DATA_FOR_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting EVENT data for edit: ', error);
        dispatch({
            type: 'SUCCESS_GET_EVENT_DATA_FOR_EDIT',
            payload: error.response.data
        })
    }
};

export const updateEventAction = (eventData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_EVENT' });

        const { data } = await api.updateEvent(eventData);

        dispatch({
            type: 'SUCCESS_UPDATE_EVENT',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating EVENT: ', error);
        dispatch({
            type: 'ERROR_UPDATE_EVENT',
            payload: error.response.data
        })
    }
};

export const deleteEventAction = (eventData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_DELETE_EVENT' });

        const { data } = await api.deleteEvent(eventData);

        dispatch({
            type: 'SUCCESS_DELETE_EVENT',
            payload: data
        });

    } catch (error) {
        console.log('Error in deleting EVENT: ', error.message);
        dispatch({
            type: 'ERROR_DELETE_EVENT',
            payload: error.response.data
        })
    }
};

export const previewEventAction = (eventId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_PREVIEW_EVENT' });

        const { data } = await api.previewEvent(eventId);

        dispatch({
            type: 'SUCCESS_PREVIEW_EVENT',
            payload: data
        });

    } catch (error) {
        console.log('Error in previewing EVENT: ', error.message);
        dispatch({
            type: 'ERROR_PREVIEW_EVENT',
            payload: error.response.data
        })
    }
};

export const getAllEventsAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_ALL_EVENTS' });

        const { data } = await api.getAllEvents();

        dispatch({
            type: 'SUCCESS_GET_ALL_EVENTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting all EVENTS: ', error.message);
        dispatch({
            type: 'ERROR_GET_ALL_EVENTS',
            payload: error.response.data
        })
    }
};