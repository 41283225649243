import { Typography, Dialog, DialogTitle, Button, DialogActions, DialogContent, Divider } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import StarFull from '@material-ui/icons/Star';
import StarEmpty from '@material-ui/icons/StarBorder';


const SuperAdminObjectStatusDialog = ({
    showDialog,
    setShowDialog,
    handleSwitchStatus,
    handleSwitchPopularity,
    objectData,
    setDeleteState
}) => {

    const classes = useStyles();

    const handleClose = () => {
        setShowDialog({
            show: false,
            objectData: {}
        })
    };

    return (
        <Dialog
            fullWidth
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>
                Status objekta {objectData.name}
            </DialogTitle >
            <DialogContent className={classes.objectDialog}>
                <Typography variant='body1'>
                    Vlasnik : {objectData.owner}
                </Typography>
                <Divider></Divider>
                <Typography variant='body1'>
                    Datum : {objectData.created_at}
                </Typography>
                <Divider></Divider>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
                    <Typography variant='body1' style={{ marginRight: '15px' }}> Status : </Typography>
                    {!objectData.is_approved
                        ?
                        <Button
                            onClick={() => {
                                handleSwitchStatus(objectData.id);
                                objectData.is_approved = !objectData.is_approved
                            }}
                            variant='contained'
                            color='default' >
                            Odobri
                        </Button>
                        :
                        <Button
                            onClick={() => {
                                handleSwitchStatus(objectData.id);
                                objectData.is_approved = !objectData.is_approved
                            }}
                            variant='contained'
                            style={{ backgroundColor: '#CF3A24', color: 'white' }}>
                            Ukloni
                        </Button>}
                </div>
                <Divider></Divider>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }} >
                    <Typography style={{ marginRight: '15px' }}>Akcija : </Typography>
                    <div>
                        <VisibilityIcon
                            fontSize='large'
                            style={{ color: '#26C281', cursor: 'pointer', marginRight: '20px' }} />
                        {
                            objectData.is_popular
                                ?
                                <StarFull
                                    onClick={() => {
                                        handleSwitchPopularity(objectData.id);
                                        objectData.is_popular = !objectData.is_popular;
                                    }}
                                    style={{ color: 'gold', cursor: 'pointer', marginLeft: '20px', marginRight: '20px' }}
                                    fontSize='large' />
                                :
                                <StarEmpty
                                    onClick={() => {
                                        handleSwitchPopularity(objectData.id);
                                        objectData.is_popular = !objectData.is_popular;
                                    }}
                                    style={{ color: 'gold', cursor: 'pointer', marginLeft: '20px', marginRight: '20px' }}
                                    fontSize='large' />
                        }
                        <DeleteIcon
                            onClick={() => {
                                setDeleteState({
                                    show: true,
                                    accObjectId: objectData.id,
                                    accObjectName: objectData.name
                                });
                            }}
                            fontSize='large'
                            style={{ color: '#DC3023', cursor: 'pointer', marginLeft: '20px' }} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    onClick={handleClose}
                    style={{ marginRight: '20px' }}>
                    Izađi
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SuperAdminObjectStatusDialog