import { Grid, Paper, Typography, TextField, Button, CircularProgress, LinearProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPasswordResetLinkAction } from '../actions/users_actions';

const ForgotPassword = () => {

    const classes = useStyles();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [email, setEmail] = React.useState('');

    const getPasswordResetLinkSelector = useSelector(state => state.getPasswordResetLinkState);
    const { loading, getPasswordResetLink } = getPasswordResetLinkSelector;

    const handleGetPasswordResetLink = (e) => {
        e.preventDefault();
        dispatch(getPasswordResetLinkAction({userEmail : email}));
    };

    return (
        <div className={classes.loginDiv}>
            <div className={classes.backButton}>
                <Button
                    onClick={() => { navigate("/login"); }}
                    size='large'
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#0F79A1', color: 'white' }}>
                    Nazad
                </Button>
            </div>
            <Paper elevation={7} className={classes.form}>
                <div className={classes.loginTitle}>
                    <Typography component="h1" variant="h5">
                        Zaboravili ste šifru ?
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginTop: '10px' }}>
                        Unesite vaš email kako bi dobili link za resetovanje šifre.
                    </Typography>
                </div>
                {
                    loading
                        ?
                        <LinearProgress style={{ marginBottom : '20px' }} />
                        :
                        typeof getPasswordResetLink !== 'undefined'
                            ?
                            !getPasswordResetLink.success
                                ?
                                <Alert severity="error" style={{ marginBottom: '20px' }}>
                                    Neuspješno slanje linka
                                </Alert>
                                :
                                <Alert severity="success" style={{ marginBottom: '20px' }}>
                                    Link uspješno poslat
                                </Alert>
                            :
                            null
                }
                <form onSubmit={handleGetPasswordResetLink}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                id="email"
                                label="Email"
                                name="email"
                                type="text"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button
                                variant='contained'
                                fullWidth
                                type='submit'
                                style={{ backgroundColor: '#0F79A1', color: 'white' }}>
                                Pošalji
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
};

export default ForgotPassword;