import { Typography, Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import Person from '@material-ui/icons/AccountCircle';
import People from '@material-ui/icons/PeopleAlt';
import Children from '@material-ui/icons/ChildCare';
import Arrival from '@material-ui/icons/FlightLand';
import Departure from '@material-ui/icons/FlightTakeoff';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/LocalPhone';
import Question from '@material-ui/icons/Help';
import Date from '@material-ui/icons/Event';
import CarIcon from '@material-ui/icons/DirectionsCar';
import { format, parseISO } from 'date-fns';


const CarQuestionDetails = ({ show, setShow, data }) => {

    const classes = useStyles();

    const handleClose = () => {
        setShow({
            show: false,
            data: {}
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={show}
            onClose={handleClose}
        >
            <DialogTitle >Pregled upita za auto {data.manufacturer} {data.model} {data.engine} {data.year}</DialogTitle>
            <DialogContent className={classes.viewQuestionContent}>
                <div className={classes.viewQuestionSection}>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Person fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Ime i prezime</strong></Typography>
                        </div>
                        <Typography>{data.contact_name} {data.contact_surname}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Email fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Email kontakt</strong></Typography>
                        </div>
                        <Typography>{data.contact_email}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Phone fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Telefon</strong></Typography>
                        </div>
                        <Typography>{data.contact_phone}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Date fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Pitano</strong></Typography>
                        </div>
                        <Typography>
                            {
                                typeof data.date_asked !== 'undefined'
                                    ?
                                    format(parseISO(data.date_asked), 'dd/MM/yyyy/HH:mm:ss')
                                    :
                                    null
                            }
                        </Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Arrival fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Lokacija preuzimanja</strong></Typography>
                        </div>
                        <Typography>{data.pick_up_location}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Departure fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Lokacija vraćanja</strong></Typography>
                        </div>
                        <Typography>{data.drop_off_location}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Arrival fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Datum preuzimanja</strong></Typography>
                        </div>
                        <Typography>{data.pick_up_date}</Typography>
                    </div>
                    <div className={classes.viewQuestionBigRow}>
                        <div className={classes.viewQuestionRow}>
                            <Departure fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Datum vraćanja</strong></Typography>
                        </div>
                        <Typography>{data.drop_off_date}</Typography>
                    </div>
                </div>
                <div className={classes.viewQuestionRightSide}>
                    <div>
                        <div className={classes.viewQuestionRow}>
                            <Question fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Poruka</strong></Typography>
                        </div>
                        <Typography>{data.contact_message}</Typography>
                    </div>
                    <div>
                        <div className={classes.viewQuestionRow}>
                            <CarIcon fontSize='large' color='primary' style={{ marginRight: '3px' }} />
                            <Typography variant='subtitle1'><strong>Dodatna oprema</strong></Typography>
                        </div>
                        {
                            data?.additional_equipment?.length > 0
                            ?
                            data.additional_equipment.split(',').map(item=>(<Typography>- {item}</Typography>))
                            :
                            <Typography>Bez dodatne opreme</Typography>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zatvori</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CarQuestionDetails;


