import React from 'react';
import useStyle from './showAccObjectStyle';
import { useDispatch, useSelector } from 'react-redux';
import { showAccObjectAction, emptySendQuestionData } from '../actions/acc_objects_actions';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useTheme, useMediaQuery, Typography, LinearProgress, Button, Tooltip, Avatar, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import share from '../assets/photos/share.svg';
import StarIcon from '@material-ui/icons/Star';
import LocationIcon from '@material-ui/icons/LocationOn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import ContactForm from './ContactForm';
import Lightbox from 'react-image-lightbox';
import { contactOwnerPhotos } from './owner_info_data';
import telefon from '../assets/photos/telefon.svg';
import website from '../assets/photos/website.svg';
import RecommendedApartments from '../RecommendedApartments/RecommendedApartments';
import { accObjectEquipmentData } from '../Accommodation Object/acc_object_equipment_data';
import { SignalCellularNull } from '@material-ui/icons';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/PeopleAlt';
import SquareMeter from '@material-ui/icons/AspectRatio';
import UnitDialogue from './UnitDialogue';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MobileView from './MobileView';

const ShowAccObject = () => {

    const { favoriteAccObjects, setFavoriteAccObjects, currentLanguage } = useContext(GlobalContext);

    const formRef = React.useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const { accObjectId } = useParams();

    const [currentLightBoxPhotoIndex, setCurrentLightBoxPhotoIndex] = React.useState(0);
    const [lightBoxOpen, setLightBoxOpen] = React.useState(false);

    const [showUnitDialogue, setShowUnitDialogue] = React.useState({
        show: false,
        unitId: null
    });

    const [accUnitChoice, setAccUnitChoice] = React.useState('');

    const showAccObjectSelector = useSelector(state => state.showAccObjectState);
    const { loading, showAccObjectData: data } = showAccObjectSelector;

    React.useEffect(() => {
        dispatch(showAccObjectAction(accObjectId));
        dispatch(emptySendQuestionData());
    }, [accObjectId]);

    const getPriceType = (priceType) => {
        switch (priceType) {
            case 2:
                return (
                    <Typography style={{ color: '#a1a1a1', fontSize: '12px' }}> {t("acc_object_page_per_night")} </Typography>
                );
            case 3:
                return (
                    <Typography style={{ color: '#a1a1a1', fontSize: '12px' }}> {t("acc_object_page_per_person")} </Typography>
                );
            case 1:
                return (
                    <Typography style={{ color: "#0ca6d8", fontSize: '28px', fontWeight: '900' }}>
                        {t("acc_object_page_on_request")}
                    </Typography>
                )
            default: return 0;
        }
    };

    const checkFavorite = (id) => {
        return favoriteAccObjects.find(favId => favId === id) ? true : false;
    };

    const ShowLightBox = () => {
        return (
            <>
                {
                    lightBoxOpen
                        ?
                        <Lightbox
                            //animationDisabled={true}
                            animationDuration={0}
                            mainSrc={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[currentLightBoxPhotoIndex]}
                            nextSrc={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[(currentLightBoxPhotoIndex + 1) % data.accObjectData.accObjectPhotos.length]}
                            prevSrc={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[(currentLightBoxPhotoIndex + data.accObjectData.accObjectPhotos.length - 1) % data.accObjectData.accObjectPhotos.length]}
                            onCloseRequest={() => { setLightBoxOpen(false); }}
                            onMovePrevRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + data.accObjectData.accObjectPhotos.length - 1) % data.accObjectData.accObjectPhotos.length)
                            }
                            onMoveNextRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + 1) % data.accObjectData.accObjectPhotos.length)
                            }
                        />
                        :
                        null
                }
            </>

        )
    };

    const SectionTitle = ({ title }) => {
        return (
            <div className={classes.sectionTitleDiv}>
                <Typography className={classes.sectionTitle}>
                    {title}
                </Typography>
            </div>
        )
    };

    return (
        <>
            <div className={classes.mainDiv}>
                {
                    loading
                        ?
                        <div style={{ marginTop: '50px' }}>
                            <LinearProgress />
                        </div>
                        :
                        typeof data !== 'undefined'
                            ?
                            data.success
                                ?
                                (
                                    isMobile
                                        ?
                                        <MobileView data={data} />
                                        :
                                        <div className={classes.mainSection}>
                                            <div className={classes.firstSection}>
                                                <div className={classes.objectInfo}>
                                                    <div className={classes.titleRatingAddress}>
                                                        <div className={classes.titleRating}>
                                                            <div className={classes.title}>
                                                                <Typography variant='h5' style={{ fontWeight: 'bolder', color: '#333333', marginRight: '5px' }}>
                                                                    {data.accObjectData.name}
                                                                </Typography>
                                                            </div>
                                                            <div className={classes.rating}>
                                                                {
                                                                    data.accObjectData.category > 0
                                                                        ?
                                                                        [...Array(data.accObjectData.category - 1)].map((star, index) => {
                                                                            return <StarIcon key={index} fontSize='medium' style={{ color: '#ffc338' }} />
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={classes.address}>
                                                            <LocationIcon fontSize='medium' style={{ color: '#ffc338' }} />
                                                            <Typography style={{ textTransform: 'uppercase', fontSize: '16px' }}>
                                                                {data.accObjectData.address}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className={classes.objectCardPrice} >

                                                        <div className={classes.objectCardPriceFrom}>
                                                            <Typography style={{ color: '#a1a1a1', fontWeight: 'bold', fontSize: '11px' }}>
                                                                {
                                                                    data.accObjectData.price_type === 1
                                                                        ?
                                                                        'CIJENA'
                                                                        :
                                                                        'CIJENA OD'
                                                                }
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.objectCardPriceInfo}>
                                                            {
                                                                data.accObjectData.price_type === 1
                                                                    ?
                                                                    <Typography style={{ color: "#245d8d", fontSize: '17px', fontWeight: '900' }}>
                                                                        NA UPIT
                                                                    </Typography>
                                                                    :
                                                                    <>
                                                                        <div>
                                                                            <Typography style={{ color: "#dd0000", fontSize: '30px', fontWeight: 'bolder' }}>
                                                                                {data.accObjectData.minimal_price_from},00
                                                                            </Typography>
                                                                        </div>
                                                                        <div style={{ marginLeft: '5px' }}>
                                                                            <div>
                                                                                <Typography style={{ color: '#f8ac33', fontSize: '13px', fontWeight: 'bolder', marginBottom: '-4px' }}>EUR</Typography>
                                                                            </div>
                                                                            <div>
                                                                                <Typography style={{ color: '#a1a1a1', fontSize: '13px' }}>
                                                                                    {getPriceType(data.accObjectData.price_type)}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className={classes.gallerySection}>
                                                    <div className={classes.gallerySectionFirstPart}>
                                                        <div
                                                            onClick={() => {
                                                                setLightBoxOpen(true);
                                                                setCurrentLightBoxPhotoIndex(0);
                                                            }}
                                                            style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[0]}')` }}
                                                            className={classes.gallerySectionBigPhoto}>

                                                        </div>
                                                        <div className={classes.gallerySectionTwoPhotos}>
                                                            <div
                                                                onClick={() => {
                                                                    setLightBoxOpen(true);
                                                                    setCurrentLightBoxPhotoIndex(1);
                                                                }}
                                                                className={classes.gallerySectionTwoPhotosPhoto}
                                                                style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[1]}')` }} >
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setLightBoxOpen(true);
                                                                    setCurrentLightBoxPhotoIndex(2);
                                                                }}
                                                                className={classes.gallerySectionTwoPhotosPhoto}
                                                                style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.accObjectPhotos[2]}')` }} >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.gallerySectionSecondPart}>
                                                        {
                                                            data.accObjectData.accObjectPhotos.map((photo, index) => {
                                                                if (index > 2 && index < 8) {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                setLightBoxOpen(true);
                                                                                setCurrentLightBoxPhotoIndex(index);
                                                                            }}
                                                                            style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + photo}')` }}
                                                                            className={classes.gallerySectionSecondPartPhoto}
                                                                            key={index}>
                                                                            {
                                                                                index === 7
                                                                                    ?
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            setLightBoxOpen(true);
                                                                                            setCurrentLightBoxPhotoIndex(0);
                                                                                        }}
                                                                                        className={classes.viewAllPhotos}>
                                                                                        <Typography style={{ color: 'white', fontWeight: 'bolder' }}>
                                                                                            POGLEDAJ SVE FOTOGRAFIJE
                                                                                        </Typography>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className={classes.equipmentDiv}>
                                                    {
                                                        accObjectEquipmentData[currentLanguage].map((item, index) => {
                                                            const check = data.accObjectData.accObjectEquipment[item.equipmentName];
                                                            if (check) {
                                                                return (
                                                                    <div className={classes.equipmentItemDiv}>
                                                                        <img
                                                                            style={{
                                                                                filter: 'invert(26%) sepia(96%) saturate(421%) hue-rotate(164deg) brightness(98%) contrast(88%)'
                                                                            }}
                                                                            width='30'
                                                                            src={item.icon}
                                                                            alt='Eq' />
                                                                        <Typography variant='caption'>
                                                                            {item.nameToDisplay}
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className={classes.accObjectDescription}>
                                                    <SectionTitle title='opis objekta' />
                                                    <Typography>
                                                        {data.accObjectData.description}
                                                    </Typography>
                                                </div>
                                                {
                                                    data.accObjectData.accUnits.length > 0
                                                        ?
                                                        <div className={classes.accUnitsDiv}>
                                                            <SectionTitle title='smještajne jedinice' />
                                                            <div className={classes.accUnits}>
                                                                {
                                                                    data.accObjectData.accUnits.map((unit, index) => {
                                                                        return (
                                                                            <div
                                                                                onClick={() => {
                                                                                    setShowUnitDialogue({
                                                                                        show: true,
                                                                                        unitId: unit.id
                                                                                    })
                                                                                }}
                                                                                className={classes.unit}>
                                                                                <div
                                                                                    style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + unit.photoPreview}')` }}
                                                                                    className={classes.accUnitPhoto}>
                                                                                </div>
                                                                                <div className={classes.accUnitInfo}>
                                                                                    <div>
                                                                                        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                                                                                            {unit.name}
                                                                                        </Typography>
                                                                                    </div>
                                                                                    <div className={classes.unitInfo}>
                                                                                        <PeopleIcon style={{ marginLeft: '5px', color: "#245d8d", marginRight: '6px' }} fontSize='small' />
                                                                                        <Typography style={{ fontWeight: 'bold' }}>{unit.max_occupancy} osobe</Typography>
                                                                                    </div>
                                                                                    <div className={classes.unitInfo}>
                                                                                        <RoomIcon style={{ marginLeft: '5px', color: "#245d8d", marginRight: '6px' }} fontSize='small' />
                                                                                        <Typography style={{ fontWeight: 'bold' }}>{unit.num_of_rooms} sobe</Typography>
                                                                                    </div>
                                                                                    <div className={classes.unitInfo}>
                                                                                        <SquareMeter style={{ marginLeft: '5px', color: "#245d8d", marginRight: '6px' }} fontSize='small' />
                                                                                        <Typography style={{ fontWeight: 'bold' }}>{unit.area} &#13217;</Typography>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={classes.accUnitButton}>
                                                                                    <Button
                                                                                        fullWidth
                                                                                        disableElevation
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setAccUnitChoice(unit.name);
                                                                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                                        }}
                                                                                        variant='contained'
                                                                                        style={{
                                                                                            backgroundColor: "#f79800",
                                                                                            color: 'white',
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '20px'
                                                                                        }}>
                                                                                        REZERVIŠI
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className={classes.mapMainDiv}>
                                                    <SectionTitle title='lokacija objekta' />
                                                    <div className={classes.infoAndMap}>
                                                        <div className={classes.mapInfo}>
                                                            <div className={classes.mapSingleInfo}>
                                                                <BeachAccessIcon style={{ color: '#245d8d', fontSize: '50px', marginRight: '7px' }} />
                                                                <div className={classes.mapSingleInfoText}>
                                                                    <Typography variant='subtitle2'>Plaža</Typography>
                                                                    <Typography variant='subtitle2'>{data.accObjectData.to_beach}m</Typography>
                                                                </div>
                                                            </div>
                                                            <div className={classes.mapSingleInfo}>
                                                                <RestaurantIcon style={{ color: '#245d8d', fontSize: '50px', marginRight: '7px' }} />
                                                                <div className={classes.mapSingleInfoText}>
                                                                    <Typography variant='subtitle2'>Restoran</Typography>
                                                                    <Typography variant='subtitle2'>{data.accObjectData.to_restaurant}m</Typography>
                                                                </div>
                                                            </div>
                                                            <div className={classes.mapSingleInfo}>
                                                                <StorefrontIcon style={{ color: '#245d8d', fontSize: '50px', marginRight: '7px' }} />
                                                                <div className={classes.mapSingleInfoText}>
                                                                    <Typography variant='subtitle2'>Prodavnica</Typography>
                                                                    <Typography variant='subtitle2'>{data.accObjectData.to_store}m</Typography>
                                                                </div>
                                                            </div>
                                                            <div className={classes.mapSingleInfo}>
                                                                <LocationCityIcon style={{ color: '#245d8d', fontSize: '50px', marginRight: '7px' }} />
                                                                <div className={classes.mapSingleInfoText}>
                                                                    <Typography variant='subtitle2'>Centar grada</Typography>
                                                                    <Typography variant='subtitle2'>{data.accObjectData.to_city_center}m</Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <MapContainer
                                                            center={[data.accObjectData.latitude, data.accObjectData.longitude]}
                                                            zoom={16}
                                                            tap={false}
                                                            className={classes.map}>
                                                            <TileLayer
                                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                            />
                                                            <Marker position={[data.accObjectData.latitude, data.accObjectData.longitude]}>
                                                                <Popup>
                                                                    <Typography variant='h5'>
                                                                        {data.accObjectData.name}
                                                                    </Typography>
                                                                    <Typography variant='h6'>
                                                                        {data.accObjectData.address}
                                                                    </Typography>
                                                                </Popup>
                                                            </Marker>
                                                        </MapContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.secondSection}>
                                                <div className={classes.shareSaveDiv}>
                                                    <div className={classes.shareSave}>
                                                        <div className={classes.shareSaveParts}>
                                                            <img width='22' src={share} alt='Share' style={{ marginRight: '7px', cursor: 'pointer' }} />
                                                            <Typography style={{ color: '#245d8d' }}>PODIJELI</Typography>
                                                        </div>
                                                        <div className={classes.shareSaveParts}>
                                                            {
                                                                checkFavorite(data.accObjectData.id)
                                                                    ?
                                                                    <Tooltip title={`${t("acc_object_page_remove_from_wishes")}`}>
                                                                        <FavoriteIcon
                                                                            onClick={() => {
                                                                                const updatedFav = favoriteAccObjects.filter(favId => favId !== data.accObjectData.id);
                                                                                setFavoriteAccObjects([...updatedFav]);
                                                                                localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                                                                            }}
                                                                            fontSize='medium'
                                                                            style={{ cursor: 'pointer', color: '#245d8d', marginRight: '7px' }} />
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title={`${t("acc_object_page_add_to_wishes")}`}>
                                                                        <FavoriteBorderIcon
                                                                            onClick={() => {
                                                                                const updatedFav = [...favoriteAccObjects, data.accObjectData.id];
                                                                                setFavoriteAccObjects([...updatedFav]);
                                                                                localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                                                                            }}
                                                                            fontSize='medium'
                                                                            style={{ cursor: 'pointer', color: '#245d8d', marginRight: '7px' }} />
                                                                    </Tooltip>
                                                            }
                                                            <Typography style={{ color: '#245d8d' }}>
                                                                SAČUVAJ
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ContactForm
                                                    accObject={data.accObjectData.id}
                                                    accUnits={data.accObjectData.accUnits}
                                                    accUnitChoice={accUnitChoice}
                                                />
                                                <div className={classes.contactDataDiv}>
                                                    <SectionTitle title='kontakt podaci' />
                                                    <div className={classes.ownerPhotoAndName}>
                                                        <div className={classes.ownerPhotoAndNameMain}>
                                                            <Avatar src={process.env.REACT_APP_UPLOADS_PATH + data.accObjectData.ownerData.avatar} style={{ width: '50px', height: '50px', marginRight: '11px' }} />
                                                            <div>
                                                                <Typography style={{ color: 'gray' }} variant='subtitle2' > {t("acc_object_page_owner_name")} </Typography>
                                                                <Typography variant='subtitle1' > {data.accObjectData.ownerData.name} {data.accObjectData.ownerData.surname}  </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                                    <div className={classes.ownerPhoneData}>
                                                        {
                                                            data.accObjectData.accObjectPhones.map((phone, index) => {
                                                                return (
                                                                    <div className={classes.phoneNumber} key={index}>
                                                                        <img src={telefon} alt="Phone" height={20} style={{ marginRight: '15px' }} />
                                                                        <Typography variant='subtitle1'> {phone.phone_number} </Typography>
                                                                        {phone.viber ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                                                        {phone.whatsapp ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img src={website} alt="Website" height={20} style={{ marginRight: '15px' }} />
                                                        <Typography variant='subtitle1'>{data.accObjectData.web_site} </Typography>
                                                    </div>
                                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                                    <div>
                                                        <SectionTitle title='uslovi rezervacije' />
                                                        {
                                                            data.accObjectData.advance_payment
                                                                ?
                                                                <div>
                                                                    <Typography variant='subtitle1'>
                                                                        {t("acc_object_page_advance_payment")}: {data.accObjectData.advance_amount} %
                                                                    </Typography>
                                                                    {
                                                                        data.accObjectData.non_refundable_advance
                                                                            ?
                                                                            <Typography variant='subtitle1'>
                                                                                {t("acc_object_page_advance_non_refundable")}
                                                                            </Typography>
                                                                            :
                                                                            <Typography variant='subtitle1'>
                                                                                {t("acc_object_page_advance_refundable")}
                                                                            </Typography>
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <Accordion elevation={0}>
                                                            <AccordionSummary>
                                                                <Typography color="primary">{t("acc_object_page_payment_data")}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography variant='subtitle2'>
                                                                    {
                                                                        data.accObjectData.payment_data
                                                                    }
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div>
                                                        {/* <SectionTitle title='popularni objekti' /> */}
                                                        <RecommendedApartments
                                                            cardHeight='285px'
                                                            cardWidth='230px'
                                                            imageHeight='135px'
                                                            isMobile={isMobile} />
                                                    </div>
                                                </div>
                                            </div>
                                            <UnitDialogue
                                                unitDialogue={showUnitDialogue}
                                                setShow={setShowUnitDialogue}
                                                isMobile={isMobile}
                                                currentLanguage={currentLanguage} />
                                            <ShowLightBox />
                                        </div>
                                )
                                :
                                <Alert severity="error">
                                    <AlertTitle>{t("error")}</AlertTitle>
                                    {t("acc_object_page_error_message")}
                                </Alert>
                            :
                            null
                }
            </div>

        </>
    )
}

export default ShowAccObject