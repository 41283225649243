import {
    Typography,
    Toolbar,
    AppBar,
    Button,
    Avatar,
    Box,
    useTheme,
    useMediaQuery,
    TableCell,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    TableBody,
    Tooltip,
    Grid,
    TextField,
    InputAdornment,
    Divider,
    CircularProgress,
    MenuItem
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useContext } from 'react';
import useStyles from './style';
import { IconButton } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import Restaurant from '@material-ui/icons/Restaurant';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { checkSuperAdminAction, emptyJwtTokenAction, logoutUserAction } from '../actions/users_actions';
import { GlobalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SuperAdminObjectStatusDialog from './SuperAdminObjectStatusDialog';
import DeleteAccObjectDialog from './DeleteAccObjectDialog';
import DeleteRestaurantDialog from './DeleteRestaurantDialog';
import Search from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import {
    superAdminGetAccObjectsAction,
    superAdminChangeAccObjectStatusAction,
    superAdminSearchAccObjectsAction,
    superAdminSearchAccObjectsByStatus,
    superAdminSwitchAccObjectPopularityAction
} from '../actions/acc_objects_actions';
import { parseISO, format } from 'date-fns'
import { Alert, AlertTitle } from '@material-ui/lab';
import Repeat from '@material-ui/icons/Repeat';
import StarFull from '@material-ui/icons/Star';
import StarEmpty from '@material-ui/icons/StarBorder';
import CarIcon from '@material-ui/icons/DirectionsCar';
import SuperAdminPreviewAccObject from './SuperAdminPreviewAccObject';
import SuperAdminPreviewRestaurant from './SuperAdminPreviewRestaurant';
import SuperAdminPreviewCar from './SuperAdminPreviewCar';
import PeopleIcon from '@material-ui/icons/People';
import SuperAdminEditUsersDialog from './SuperAdminEditUsersDialog';
import MapIcon from '@material-ui/icons/Map';
import DeleteTourDialog from './DeleteTourDialog';
import SuperAdminPreviewTour from './SuperAdminPreviewTour';
import DeleteCarDialog from './DeleteCarDialog';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DeleteEventDialog from './DeleteEventDialog';
import SuperAdminPreviewEvent from './SuperAdminPreviewEvent';
import { emptyRegularAndSuperAdminCheck } from '../actions/users_actions';

const statuses = [
    {
        name: 'Svi',
        value: 3
    },
    {
        name: 'Odobreni',
        value: 1
    },
    {
        name: 'Neodobreni',
        value: 0
    }
];

const types = [
    {
        name: 'Sve',
        value: 0
    },
    {
        name: 'Smještaji',
        value: 1
    },
    {
        name: 'Restorani',
        value: 2
    },
    {
        name: 'Rent A Car',
        value: 3
    },
    {
        name: 'Ture',
        value: 4
    },
    {
        name: 'Događaji',
        value: 5
    },
]

const SuperAdminPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const { loggedUserData, setLoggedUserData } = useContext(GlobalContext);

    const [searchQuery, setSearchQuery] = React.useState('');

    const [disableReset, setDisableReset] = React.useState(true);

    const [byStatus, setByStatus] = React.useState(3);

    const [byType, setByType] = React.useState(0);

    const [showEditUsersDialog, setShowEditUsersDialog] = React.useState(false);

    const [showPreviewRestaurant, setShowPreviewRestaurant] = React.useState({
        show: false,
        previewRestaurantId: null
    });

    const [showPreviewAccObject, setShowPreviewAccObject] = React.useState({
        show: false,
        previewAccObjectId: null
    });

    const [showPreviewCar, setShowPreviewCar] = React.useState({
        show: false,
        previewCarId: null
    });

    const [showPreviewTour, setShowPreviewTour] = React.useState({
        show: false,
        previewTourId: null
    });

    const [showPreviewEvent, setShowPreviewEvent] = React.useState({
        show: false,
        previewEventId: null
    });

    const [showObjectDialogStatus, setShowObjectDialogStatus] = React.useState({
        show: false,
        objectData: {}
    });

    const [deleteState, setDeleteState] = React.useState({
        show: false,
        accObjectId: null,
        accObjectName: null
    });

    const [deleteRestaurantState, setDeleteRestaurantState] = React.useState({
        show: false,
        restaurantId: null,
        restaurantName: null
    });

    const [deleteTourState, setDeleteTourState] = React.useState({
        show: false,
        tourId: null,
        tourName: null
    });

    const [deleteCarState, setDeleteCarState] = React.useState({
        show: false,
        carId: null,
        carName: null
    });

    const [deleteEventState, setDeleteEventState] = React.useState({
        show: false,
        eventId: null,
        eventName: null
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [drawerState, setDrawerState] = React.useState({ top: false });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const accObjectsSelector = useSelector(state => state.superAdminAccObjectsState);
    const { loading, superAdminAccObjectsData: data } = accObjectsSelector;

    React.useEffect(() => {
        if (byStatus === 3 && byType === 0) {
            dispatch(superAdminGetAccObjectsAction(JSON.parse(localStorage.getItem('saCurrentPage')) || 1));
        } else if (byStatus === 3 && byType !== 0) {
            dispatch(superAdminSearchAccObjectsByStatus(byStatus, byType));
        } else {
            dispatch(superAdminSearchAccObjectsByStatus(byStatus, byType));
        }
    }, [byStatus, byType]);

    const handleSwitchStatus = (id, type) => {
        const page = JSON.parse(localStorage.getItem('saCurrentPage')) || 1;
        dispatch(superAdminChangeAccObjectStatusAction({
            id,
            page,
            type
        }));
    };

    const handleSearchAccObjects = (e) => {
        e.preventDefault();
        if (searchQuery.length > 2) {
            dispatch(superAdminSearchAccObjectsAction({ searchQuery }));
            setDisableReset(false);
        } else {
            dispatch(superAdminGetAccObjectsAction(JSON.parse(localStorage.getItem('saCurrentPage')) || 1));
        }
    };

    const handleResetTable = () => {
        dispatch(superAdminGetAccObjectsAction(JSON.parse(localStorage.getItem('saCurrentPage')) || 1));
        setDisableReset(true);
        setSearchQuery('');
    };

    const handleShowObjectsByStatus = () => {
        console.log({
            byStatus,
            byType
        });
        // if (status === 3) {
        //     dispatch(superAdminGetAccObjectsAction(JSON.parse(localStorage.getItem('saCurrentPage')) || 1));
        // } else {
        //     dispatch(superAdminSearchAccObjectsByStatus(status));
        // }
        // setDisableReset(false);
    };

    const handleSwitchPopularity = (id, type) => {
        const page = JSON.parse(localStorage.getItem('saCurrentPage')) || 1;
        dispatch(superAdminSwitchAccObjectPopularityAction({
            id,
            page,
            type
        }));
    };

    const handleLogout = () => {
        dispatch(emptyJwtTokenAction());
        dispatch(logoutUserAction());
        setLoggedUserData({});
        navigate('/login');
    };

    const getTypeIcon = (type) => {
        switch (type) {
            case 'accObject':
                return <Tooltip title='Objekat'>
                    <HomeIcon color='secondary' fontSize='small' style={{ marginRight: '4px' }} />
                </Tooltip>
            case 'restaurant':
                return <Tooltip title='Restoran'>
                    <Restaurant color='secondary' fontSize='small' style={{ marginRight: '4px' }} />
                </Tooltip>
            case 'rent_a_car':
                return <Tooltip title='Rent A Car'>
                    <CarIcon color='secondary' fontSize='small' style={{ marginRight: '4px' }} />
                </Tooltip>
            case 'tour':
                return <Tooltip title='Tura'>
                    <MapIcon color='secondary' fontSize='small' style={{ marginRight: '4px' }} />
                </Tooltip>
            case 'event':
                return <Tooltip title='Događaj'>
                    <CalendarTodayIcon color='secondary' fontSize='small' style={{ marginRight: '4px' }} />
                </Tooltip>
            default: return null;
        }
    };

    const mobileMenu = () => (
        <Box>
            <ul style={{ listStyleType: 'none' }}>
                <li
                    onClick={() => { setShowEditUsersDialog(true); }}
                    className={classes.listItem}>
                    <PeopleIcon color='secondary' fontSize='large' style={{ marginRight: '7px' }} />
                    <Typography variant='h6'> Korisnici </Typography>
                </li>
                <li onClick={handleLogout} className={classes.listItem}>
                    <ExitToAppIcon color='secondary' fontSize='large' style={{ marginRight: '7px' }} />
                    <Typography variant='h6'> Odjava </Typography>
                </li>
            </ul>
        </Box>
    );

    const AllObjectsTableDesktop = () => {
        return (
            <div className={classes.superAdminObjectsTableDiv}>
                <TableContainer component={Paper} >
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Ime</TableCell>
                                <TableCell align="left">Vlasnik</TableCell>
                                <TableCell align="left">Datum Kreiranja</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Akcija</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.objectsPayload.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell variant='head' align='left'>
                                        {getTypeIcon(row.type)}
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">{row.owner}</TableCell>
                                    <TableCell align="left">{format(parseISO(row.created_at), 'dd/MM/yyyy/HH:mm:ss')}</TableCell>
                                    <TableCell align="center">
                                        {
                                            !row.is_approved
                                                ?
                                                <Tooltip title='Odobrite' placement='right' arrow>
                                                    <Button
                                                        onClick={() => {
                                                            handleSwitchStatus(row.id, row.type);
                                                        }}
                                                        variant='contained'
                                                        disableElevation
                                                        color='default' >
                                                        Odobri
                                                    </Button>
                                                </Tooltip>
                                                :
                                                <Tooltip title='Uklonite odobrenje' placement='right' arrow>
                                                    <Button
                                                        onClick={() => {
                                                            handleSwitchStatus(row.id, row.type);
                                                        }}
                                                        variant='contained'
                                                        disableElevation
                                                        style={{ backgroundColor: '#CF3A24', color: 'white' }}>
                                                        Ukloni
                                                    </Button>
                                                </Tooltip>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <VisibilityIcon
                                            onClick={() => {
                                                if (row.type === 'accObject') {
                                                    setShowPreviewAccObject({
                                                        show: true,
                                                        previewAccObjectId: row.id
                                                    })
                                                }
                                                if (row.type === 'restaurant') {
                                                    setShowPreviewRestaurant({
                                                        show: true,
                                                        previewRestaurantId: row.id
                                                    })
                                                }
                                                if (row.type === 'rent_a_car') {
                                                    setShowPreviewCar({
                                                        show: true,
                                                        previewCarId: row.id
                                                    })
                                                }
                                                if (row.type === 'tour') {
                                                    setShowPreviewTour({
                                                        show: true,
                                                        previewTourId: row.id
                                                    })
                                                }
                                                if (row.type === 'event') {
                                                    setShowPreviewEvent({
                                                        show: true,
                                                        previewEventId: row.id
                                                    })
                                                }
                                            }}
                                            fontSize='medium'
                                            style={{ color: '#26C281', cursor: 'pointer', marginRight: '20px' }} />
                                        {
                                            row.is_popular
                                                ?
                                                <Tooltip title='Ukloni iz popularnih' placement='bottom' arrow>
                                                    <StarFull
                                                        onClick={() => {
                                                            handleSwitchPopularity(row.id, row.type);
                                                        }}
                                                        style={{ color: 'gold', cursor: 'pointer', marginLeft: '20px', marginRight: '20px' }}
                                                        fontSize='medium' />
                                                </Tooltip>
                                                :
                                                <Tooltip title='Obelježi kao popularan' placement='bottom' arrow>
                                                    <StarEmpty
                                                        onClick={() => {
                                                            handleSwitchPopularity(row.id, row.type);
                                                        }}
                                                        style={{ color: 'gold', cursor: 'pointer', marginLeft: '20px', marginRight: '20px' }}
                                                        fontSize='medium' />
                                                </Tooltip>
                                        }
                                        <DeleteIcon
                                            onClick={() => {
                                                if (row.type === 'accObject') {
                                                    setDeleteState({
                                                        show: true,
                                                        accObjectId: row.id,
                                                        accObjectName: row.name
                                                    });
                                                }
                                                if (row.type === 'restaurant') {
                                                    setDeleteRestaurantState({
                                                        show: true,
                                                        restaurantId: row.id,
                                                        restaurantName: row.name
                                                    });
                                                }
                                                if (row.type === 'tour') {
                                                    setDeleteTourState({
                                                        show: true,
                                                        tourId: row.id,
                                                        tourName: row.name
                                                    });
                                                }
                                                if (row.type === 'event') {
                                                    setDeleteEventState({
                                                        show: true,
                                                        eventId: row.id,
                                                        eventName: row.name
                                                    });
                                                }
                                                if (row.type === 'rent_a_car') {
                                                    setDeleteCarState({
                                                        show: true,
                                                        carId: row.id,
                                                        carName: row.name
                                                    });
                                                }
                                            }}
                                            fontSize='medium'
                                            style={{ color: '#DC3023', cursor: 'pointer', marginLeft: '20px' }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    };

    const AllObjectsTableMobile = () => {
        return (
            <div className={classes.superAdminObjectsTableDiv}>
                <TableContainer component={Paper} >
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Ime Objekta</TableCell>
                                <TableCell align="left">Vlasnik</TableCell>
                                <TableCell align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.objectsPayload.map((row, index) => (
                                <TableRow
                                    onClick={() => {
                                        setShowObjectDialogStatus({
                                            show: true,
                                            objectData: { ...row, created_at: format(parseISO(row.created_at), 'dd/MM/yyyy/HH:mm:ss') }
                                        })
                                    }}
                                    hover
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell variant='head' align='left'> {row.name}</TableCell>
                                    <TableCell align="left">{row.owner}</TableCell>
                                    <TableCell align="left">
                                        {
                                            !row.is_approved
                                                ?
                                                <Tooltip title='Još nije odobreno' placement='right' arrow>
                                                    <CloseIcon fontSize='medium' style={{ color: '#C3272B' }} />
                                                </Tooltip>
                                                :
                                                <Tooltip title='Odobreno' placement='right' arrow>
                                                    <DoneIcon fontSize='medium' style={{ color: '#26A65B' }} />
                                                </Tooltip>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    };

    const ShowTable = () => {
        if (data?.success) {
            return (
                <>
                    <div className={classes.superAdminPagination}>
                        <Pagination
                            shape='rounded'
                            count={data?.numOfPages}
                            disabled={data?.numOfPages ? false : true}
                            page={JSON.parse(localStorage.getItem('saCurrentPage')) || 1}
                            color="secondary"
                            onChange={(e, value) => {
                                dispatch(superAdminGetAccObjectsAction(value));
                                localStorage.setItem('saCurrentPage', JSON.stringify(value));
                            }} />
                    </div>
                    {
                        isMobile
                            ?
                            <AllObjectsTableMobile />
                            :
                            <AllObjectsTableDesktop />
                    }
                </>
            )
        } else {
            return (
                <Alert severity="error" style={{ marginBottom: '20px' }}>
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje objekata : {data?.message}
                </Alert>
            )
        }
    };

    return (
        <>
            <AppBar position={isMobile ? 'sticky' : 'static'} style={{ backgroundColor: '#044F67' }} elevation={8}>
                <Toolbar className={classes.adminToolbar}>
                    <div>
                        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                            Petrovac Super Admin Panel
                        </Typography>
                    </div>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }} className={classes.avatarBox}>
                        <div className={classes.users} onClick={() => { setShowEditUsersDialog(true); }}>
                            <PeopleIcon fontSize='large' />
                            <Typography variant='subtitle1' style={{ marginLeft: '10px' }}> Korisnici </Typography>
                        </div>
                        <IconButton size='medium' onClick={handleLogout}>
                            <ExitToAppIcon color='secondary' fontSize='large' />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <div>
                            <MenuIcon
                                fontSize='large'
                                style={{ color: 'white', cursor: 'pointer' }}
                                onClick={toggleDrawer('top', true)} />
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>

            <div className={classes.superAdminSearchSection}>
                <Grid container spacing={4} justifyContent='center' alignItems='center'>
                    <Grid item md={6} xs={12}>
                        <form onSubmit={handleSearchAccObjects}>
                            <TextField
                                disabled={!data?.success}
                                fullWidth
                                variant="outlined"
                                id="search"
                                label="Pretraga..."
                                name="search"
                                type="text"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type='submit' size='small' >
                                                <Search type='submit' fontSize='large' />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                value={searchQuery}
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                }}
                            />
                        </form>

                    </Grid>

                    <Grid item xs={6} md={3}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            required
                            value={byStatus}
                            label='Status'
                            onChange={(e) => {
                                setByStatus(e.target.value);
                            }}
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }
                            }}
                            variant='outlined'
                            select>
                            {
                                statuses.map((status, index) => {
                                    return <MenuItem value={status.value} key={index}>{status.name}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            required
                            value={byType}
                            label='Vrsta'
                            onChange={(e) => {
                                setByType(e.target.value);
                            }}
                            name='tourType'
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }
                            }}
                            variant='outlined'
                            select>
                            {
                                types.map((type, index) => {
                                    return <MenuItem value={type.value} key={index}>{type.name}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                   
                </Grid>
            </div>

            <Divider
                light
                variant='middle'
                style={{ marginTop: '20px', marginBottom: '20px' }} ></Divider>

            {
                loading
                    ?
                    <div className={classes.superAdminLoadingTable}>
                        <CircularProgress
                            size={200}
                            color='secondary'
                            thickness={8} />
                    </div>
                    :
                    (
                        typeof data !== 'undefined'
                            ?
                            <ShowTable />
                            :
                            null
                    )
            }

            <Drawer
                anchor='top'
                open={drawerState.top}
                onClose={toggleDrawer('top', false)}
            >
                {mobileMenu('top')}
            </Drawer>

            <SuperAdminObjectStatusDialog
                handleSwitchPopularity={handleSwitchPopularity}
                setDeleteState={setDeleteState}
                handleSwitchStatus={handleSwitchStatus}
                showDialog={showObjectDialogStatus.show}
                setShowDialog={setShowObjectDialogStatus}
                objectData={showObjectDialogStatus.objectData} />

            <SuperAdminPreviewAccObject
                previewAccObjectId={showPreviewAccObject.previewAccObjectId}
                show={showPreviewAccObject.show}
                setShow={setShowPreviewAccObject} />

            <SuperAdminPreviewCar
                previewCarId={showPreviewCar.previewCarId}
                show={showPreviewCar.show}
                setShow={setShowPreviewCar} />

            <SuperAdminPreviewTour
                previewTourId={showPreviewTour.previewTourId}
                show={showPreviewTour.show}
                setShow={setShowPreviewTour} />

            <SuperAdminPreviewRestaurant
                previewRestaurantId={showPreviewRestaurant.previewRestaurantId}
                show={showPreviewRestaurant.show}
                setShow={setShowPreviewRestaurant} />
            
            <SuperAdminPreviewEvent
               previewEventId={showPreviewEvent.previewEventId}
               show={showPreviewEvent.show}
               setShow={setShowPreviewEvent} 
             />

            <DeleteAccObjectDialog deleteState={deleteState} setDeleteState={setDeleteState} />

            <DeleteTourDialog deleteState={deleteTourState} setDeleteState={setDeleteTourState} />

            <DeleteCarDialog deleteState={deleteCarState} setDeleteState={setDeleteCarState} />

            <DeleteRestaurantDialog deleteState={deleteRestaurantState} setDeleteState={setDeleteRestaurantState} />
            
            <DeleteEventDialog deleteState={deleteEventState} setDeleteState={setDeleteEventState}  />

            <SuperAdminEditUsersDialog open={showEditUsersDialog} setOpen={setShowEditUsersDialog} />

        </>
    );
};

export default SuperAdminPanel;

