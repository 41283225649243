import React from 'react';
import {
    Dialog,
    Toolbar,
    AppBar,
    Typography,
    Button,
    IconButton,
    Paper,
    Grid,
    FormLabel,
    TextField,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    Snackbar,
    LinearProgress,
    CircularProgress
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import { Slide } from '@material-ui/core';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurantDataEditAction, updateRestaurantAction } from '../actions/restaurants_actions';
import { parseISO } from 'date-fns';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialRestaurantData = {
    restaurantName: '',
    restaurant_is_restaurant: '',
    restaurant_is_club: '',
    restaurant_is_cafe: '',
    restaurantDescription: '',
    restaurantWorkingHours: '',
    restaurantCapacity: '',
    restaurantMusic: '',
    restaurantPhotoGallery: [],
    restaurantPhones: [
        {
            phone_number: '',
            viber: false,
            whatsapp: false
        },
        {
            phone_number: '',
            viber: false,
            whatsapp: false
        },
        {
            phone_number: '',
            viber: false,
            whatsapp: false
        }
    ],
    restaurantAddress: '',
    restaurantLatitude: '',
    restaurantLongitude: '',
    restaurantEquipment: {
        internet: false,
        parking: false,
        disability_friendly: false,
        card_payment: false,
        smokers_zone: false
    },
    restaurantEmail: '',
    restaurantWebSite: '',
    photosToRemove: [],
    restaurantId: ''
};

const phones = [1, 2, 3];

const restaurantPhones = [
    {
        phone_number: '',
        viber: false,
        whatsapp: false
    },
    {
        phone_number: '',
        viber: false,
        whatsapp: false
    },
    {
        phone_number: '',
        viber: false,
        whatsapp: false
    }
]

const equipment = [
    'Internet',
    'Parking',
    'Prilagođenost invalidima',
    'Plaćanje karticom',
    'Zona za pušače'
];

const snackBarInitialState = {
    show: false,
    severity: 'info',
    snackTitle: '',
    snackMessage: ''
};

const EditRestaurantDialog = ({ editRestaurantState, setEditRestaurantState }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [restaurantData, setRestaurantData] = React.useState(initialRestaurantData);

    const [imageGalleryDisplay, setImageGalleryDisplay] = React.useState([]);

    const [showSnackBar, setSnackBar] = React.useState({
        show: false,
        variant: 'info',
        message: ''
    });

    const handleCloseSnackBar = () => {
        setSnackBar({
            show: false,
            variant: 'info',
            message: ''
        });
    };

    const restaurantEditDataSelector = useSelector(state => state.getRestaurantDataEditState);
    const { loading, restaurantEditData: data } = restaurantEditDataSelector;

    const updateRestaurantDataSelector = useSelector(state => state.updateRestaurantState);
    const { loading_update, updateRestaurantData } = updateRestaurantDataSelector;

    const handleClose = () => {
        setEditRestaurantState({
            show: false,
            restaurantId: null
        });
    };

    const handleImageChange = async (e) => {
        if (e.target.files) {
            const filesArray = await Promise.all(Array.from(e.target.files).map(async (file) => {
                return {
                    photo_id: null,
                    path: await readFileAsBase64(file),
                    photo_originalname: file.name,
                    is_main: false
                }
            }));
            setRestaurantData((prevData) => {
                return {
                    ...prevData,
                    restaurantPhotoGallery: prevData.restaurantPhotoGallery.concat(filesArray)
                }
            });
            setImageGalleryDisplay((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleRemoveImageFromGallery = (index, photo_id) => {
        setRestaurantData((prevData) => {
            return {
                ...prevData,
                restaurantPhotoGallery: prevData.restaurantPhotoGallery.filter((photo, photoIndex) => photoIndex !== index),
                photosToRemove: [...prevData.photosToRemove, photo_id !== null ? photo_id : null]
            }
        });
        setImageGalleryDisplay((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    React.useEffect(() => {
        if (editRestaurantState.restaurantId !== null) {
            dispatch(getRestaurantDataEditAction(editRestaurantState.restaurantId));
        }
    }, [editRestaurantState]);

    React.useEffect(() => {
        if (data?.success) {

            const loadedData = { ...data.restaurantData };

            if (loadedData.restaurantPhones.length > 0) {
                for (let i = 0; i < loadedData.restaurantPhones.length; i++) {
                    restaurantPhones[i] = { ...loadedData.restaurantPhones[i] };
                }
            }

            setRestaurantData({
                ...restaurantData,
                restaurantId: loadedData.id,
                restaurantName: loadedData.name,
                restaurantAddress: loadedData.address,
                restaurantCapacity: loadedData.capacity,
                restaurantDescription: loadedData.description,
                restaurantEmail: loadedData.email_for_reservations || '',
                restaurantLatitude: loadedData.latitude,
                restaurantLongitude: loadedData.longitude,
                restaurantMusic: loadedData.music,
                restaurantWebSite: loadedData.web_site,
                restaurantWorkingHours: loadedData.working_hours,
                restaurant_is_cafe: loadedData.is_cafe,
                restaurant_is_club: loadedData.is_club,
                restaurant_is_restaurant: loadedData.is_restaurant,
                restaurantEquipment: { ...loadedData.restaurantEquipment },
                restaurantPhones: [...restaurantPhones],
                restaurantPhotoGallery: [...loadedData.photos]
            });
            setImageGalleryDisplay([...loadedData.photos])

        }
    }, [data]);

    React.useEffect(() => {
        if (typeof updateRestaurantData !== 'undefined') {
            if (updateRestaurantData?.success) {
                setSnackBar({
                    show: true,
                    variant: 'success',
                    message: 'Podaci ažurirani'
                })
            } else {
                setSnackBar({
                    show: false,
                    variant: 'error',
                    message: 'Greška u ažuriranju'
                });
            }
        }
    }, [updateRestaurantData]);

    const handleChange = (e) => {
        setRestaurantData({
            ...restaurantData,
            [e.target.name]: e.target.value
        });
    };

    function AddMarkerToClick() {
        useMapEvents({
            click(e) {
                setRestaurantData({
                    ...restaurantData,
                    restaurantLatitude: e.latlng.lat,
                    restaurantLongitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[restaurantData.restaurantLatitude, restaurantData.restaurantLongitude]} />
    };

    const getKeyName = (index) => {
        return Object.keys(restaurantData.restaurantEquipment)[index];
    };

    const handleUpdateRestaurantForm = (e) => {
        e.preventDefault();
        dispatch(updateRestaurantAction(restaurantData));
        //console.log(restaurantData);
    };


    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };


    return (
        <Dialog
            fullScreen
            open={editRestaurantState.show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#006442' }}>
                <Toolbar className={classes.accUnitToolbar}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CancelIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Ažuriranje restorana
                        </Typography>
                    </div>
                    <div>
                        <Button
                            form="myform"
                            type='submit'
                            style={{ backgroundColor: '#26A65B', color: 'white' }}
                            variant='contained'>
                            Sačuvaj
                        </Button>
                    </div>
                </Toolbar>
                {
                    loading_update
                        ?
                        <div>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        null
                }
            </AppBar>
            {
                loading
                    ?
                    <div style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>
                        <CircularProgress size={150} />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data.success
                            ?
                            <form onSubmit={handleUpdateRestaurantForm} className={classes.editRestaurant} id="myform">
                                <Paper elevation={7} className={classes.addNewObject_section1}>
                                    <div className={classes.sectionTitle}><Typography variant='h5'>Osnovni podaci</Typography></div>
                                    <Grid container spacing={4} className={classes.section1Form}>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                id="restaurantName"
                                                label="Naziv restorana"
                                                name="restaurantName"
                                                type="text"
                                                value={restaurantData.restaurantName}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                label="Opis restorana"
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                name='restaurantDescription'
                                                value={restaurantData.restaurantDescription}
                                                onChange={handleChange}
                                                minRows={6}
                                                maxRows={8}
                                                required
                                                type='text'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                                            <div className={classes.section1GalleryTitle}>
                                                <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    color='primary'
                                                >
                                                    <AddIcon fontSize='small' />
                                                    <input
                                                        id='dugme'
                                                        type="file"
                                                        onChange={handleImageChange}
                                                        multiple
                                                        accept='image/*'
                                                        hidden
                                                    />
                                                </Button>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                                    Izabrana glavna fotografija
                                                </Typography>
                                                {
                                                    checkSelectedImage(restaurantData.restaurantPhotoGallery) !== -1
                                                        ?
                                                        <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                                                        :
                                                        <CloseIcon color='error' fontSize='large' />
                                                }
                                            </div>
                                            <div className={classes.section1ImagePreviewDiv}>
                                                {
                                                    imageGalleryDisplay.map((photo, index) => {
                                                        return (
                                                            <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                                <img
                                                                    onClick={() => {
                                                                        const gallery = restaurantData.restaurantPhotoGallery.map((photo, indexG) => {
                                                                            if (index === indexG) {
                                                                                photo.is_main = true;
                                                                                return photo
                                                                            } else {
                                                                                photo.is_main = false;
                                                                                return photo;
                                                                            }
                                                                        });
                                                                        setRestaurantData((prevData) => {
                                                                            return {
                                                                                ...prevData,
                                                                                restaurantPhotoGallery: [...gallery]
                                                                            }
                                                                        });
                                                                    }}
                                                                    src={photo.photo_id === null ? photo.path : process.env.REACT_APP_UPLOADS_PATH + photo.path}
                                                                    width={210}
                                                                    alt={`Object ${index}`}
                                                                    style={{
                                                                        borderRadius: '10px',
                                                                        cursor: 'pointer',
                                                                        border: checkSelectedImage(restaurantData.restaurantPhotoGallery) === index ? '4px solid green' : '1px solid transparent'
                                                                    }} />
                                                                <div key={2} className={classes.editImage} >
                                                                    <IconButton onClick={() => { handleRemoveImageFromGallery(index, photo.photo_id); }}>
                                                                        <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper elevation={7} className={classes.addNewObject_section2}>
                                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                                    <Grid container spacing={4} className={classes.section2MapDiv}>
                                        <Grid item xs={12} md={12}>

                                            <MapContainer
                                                center={[42.207232, 18.943100]}
                                                zoom={16}
                                                tap={false}
                                                className={classes.map}
                                            >
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />

                                                <AddMarkerToClick />
                                            </MapContainer>

                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                id="restaurantLatitude"
                                                label="Latituda"
                                                name="restaurantLatitude"
                                                value={restaurantData.restaurantLatitude}
                                                onChange={handleChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                id="restaurantLongitude"
                                                label="Longituda"
                                                name="restaurantLongitude"
                                                value={restaurantData.restaurantLongitude}
                                                onChange={handleChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                id="restaurantAddress"
                                                label="Adresa"
                                                name="restaurantAddress"
                                                value={restaurantData.restaurantAddress}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper elevation={7} className={classes.addNewObject_section3}>
                                    <div className={classes.sectionTitle}><Typography variant='h5'>Ostali podaci</Typography></div>
                                    <Grid container spacing={4} className={classes.section2MapDiv}>
                                        <Grid item xs={12} md={12}>
                                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                                <FormControl component="fieldset" >
                                                    <FormLabel component="legend">Restoran?</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(restaurantData.restaurant_is_restaurant)}
                                                                    onChange={(e) => {
                                                                        setRestaurantData({
                                                                            ...restaurantData,
                                                                            restaurant_is_restaurant: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={restaurantData.restaurant_is_restaurant === '' ? false : !Boolean(restaurantData.restaurant_is_restaurant)}
                                                                    onChange={(e) => {
                                                                        setRestaurantData({
                                                                            ...restaurantData,
                                                                            restaurant_is_restaurant: false
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                                <FormControl component="fieldset" >
                                                    <FormLabel component="legend">Klub?</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(restaurantData.restaurant_is_club)}
                                                                    onChange={(e) => {
                                                                        setRestaurantData({
                                                                            ...restaurantData,
                                                                            restaurant_is_club: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={restaurantData.restaurant_is_club === '' ? false : !Boolean(restaurantData.restaurant_is_club)}
                                                                    onChange={(e) => {
                                                                        setRestaurantData({
                                                                            ...restaurantData,
                                                                            restaurant_is_club: false
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                                <FormControl component="fieldset" >
                                                    <FormLabel component="legend">Kafić?</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(restaurantData.restaurant_is_cafe)}
                                                                    onChange={(e) => {
                                                                        setRestaurantData({
                                                                            ...restaurantData,
                                                                            restaurant_is_cafe: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={restaurantData.restaurant_is_cafe === '' ? false : !Boolean(restaurantData.restaurant_is_cafe)}
                                                                    onChange={(e) => {
                                                                        setRestaurantData({
                                                                            ...restaurantData,
                                                                            restaurant_is_cafe: false
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                multiline
                                                minRows={6}
                                                maxRows={8}
                                                id="restaurantWorkingHours"
                                                label="Opis radnog vremena"
                                                name="restaurantWorkingHours"
                                                type="text"
                                                value={restaurantData.restaurantWorkingHours}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                id="restaurantCapacity"
                                                label="Broj mjesta"
                                                name="restaurantCapacity"
                                                value={restaurantData.restaurantCapacity}
                                                onChange={handleChange}
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 2
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Muzika?</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={Boolean(restaurantData.restaurantMusic)}
                                                                onChange={(e) => {
                                                                    setRestaurantData({
                                                                        ...restaurantData,
                                                                        restaurantMusic: true
                                                                    });
                                                                }}
                                                            />
                                                        }
                                                        label="Da"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={restaurantData.restaurantMusic === '' ? false : !Boolean(restaurantData.restaurantMusic)}
                                                                onChange={(e) => {
                                                                    setRestaurantData({
                                                                        ...restaurantData,
                                                                        restaurantMusic: false
                                                                    });
                                                                }}
                                                            />
                                                        }
                                                        label="Ne"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper elevation={7} className={classes.addNewObject_section4}>
                                    <div className={classes.sectionTitle}><Typography variant='h5'>Oprema objekta</Typography></div>
                                    <div className={classes.equipmentDiv}>
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <div className={classes.equipment}>
                                                    {
                                                        equipment.map((item, index) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                restaurantData.restaurantEquipment[getKeyName(index)]
                                                                            }
                                                                            onChange={(e) => {
                                                                                const keyName = getKeyName(index);
                                                                                const updatedEquipment = {
                                                                                    ...restaurantData.restaurantEquipment,
                                                                                    [keyName]: e.target.checked
                                                                                };
                                                                                setRestaurantData((prevData) => {
                                                                                    return {
                                                                                        ...prevData,
                                                                                        restaurantEquipment: updatedEquipment
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={item}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </Paper>
                                <Paper elevation={7} className={classes.addNewObject_section3}>
                                    <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt podaci</Typography></div>
                                    <Grid container spacing={4} className={classes.section2MapDiv}>
                                        {
                                            phones.map((phone, index) => {
                                                return (
                                                    <Grid item xs={12} md={12} key={index}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            required={index === 0 ? true : false}
                                                            id="numOfGrownups"
                                                            label={`Broj telefona ${phone}`}
                                                            name="phoneNum"
                                                            type="text"
                                                            value={restaurantData.restaurantPhones[index].phone_number}
                                                            onChange={(e) => {
                                                                setRestaurantData((prevData) => {
                                                                    prevData.restaurantPhones[index].phone_number = e.target.value;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                        <FormControl component="fieldset">
                                                            <FormGroup row>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={restaurantData.restaurantPhones[index].viber}
                                                                            onChange={(e) => {
                                                                                setRestaurantData((prevData) => {
                                                                                    prevData.restaurantPhones[index].viber = e.target.checked;
                                                                                    return { ...prevData }
                                                                                });
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Viber"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={restaurantData.restaurantPhones[index].whatsapp}
                                                                            onChange={(e) => {
                                                                                setRestaurantData((prevData) => {
                                                                                    prevData.restaurantPhones[index].whatsapp = e.target.checked;
                                                                                    return { ...prevData }
                                                                                });
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Whatsapp"
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="restaurantEmail"
                                                label="Email adresa"
                                                name="restaurantEmail"
                                                type="text"
                                                value={restaurantData.restaurantEmail}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                required
                                                id="restaurantWebSite"
                                                label="Link vaseg web sajta"
                                                name="restaurantWebSite"
                                                type="text"
                                                value={restaurantData.restaurantWebSite}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>

                                </Paper>
                            </form>
                            :
                            <div>
                                <Typography>Error</Typography>
                            </div>
                        :
                        null
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                open={showSnackBar.show}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}>
                <Alert
                    variant='filled'
                    onClose={handleCloseSnackBar}
                    severity={showSnackBar.variant}
                    sx={{ width: '100%' }}>
                    {showSnackBar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default EditRestaurantDialog;