import mne from '../assets/countries_flags_photos/montenegroLanguage.png';
import eng from '../assets/countries_flags_photos/greatBritainLanguage.png';
import rus from '../assets/countries_flags_photos/russiaLanguage.png';
import deu from '../assets/countries_flags_photos/germanyLanguage.png';
import ita from '../assets/countries_flags_photos/italyLanguage.png';
import fra from '../assets/countries_flags_photos/franceLanguage.png';
import alb from '../assets/countries_flags_photos/albaniaLanguage.png';
import hun from '../assets/countries_flags_photos/hungaryLanguage.png';
import svn from '../assets/countries_flags_photos/sloveniaLanguage.png';
import rou from '../assets/countries_flags_photos/romaniaLanguage.png';
import cze from '../assets/countries_flags_photos/czechLanguage.png';
import swe from '../assets/countries_flags_photos/swedenLanguage.png';
import pol from '../assets/countries_flags_photos/polandLanguage.png';
 
export const languagesMenuObject = [
    {
        code : 'ME',
        flag : mne
    },
    {
        code : 'EN',
        flag : eng
    },
    {
        code : 'RU',
        flag : rus
    },
    {
        code : 'DE',
        flag : deu
    },
    {
        code : 'IT',
        flag : ita
    },
    {
        code : 'FR',
        flag : fra
    },
    {
        code : 'AL',
        flag : alb
    },
    {
        code : 'HU',
        flag : hun
    },
    {
        code : 'SI',
        flag : svn
    },
    {
        code : 'RO',
        flag : rou
    },
    {
        code : 'CZ',
        flag : cze
    },
    {
        code : 'SE',
        flag : swe
    },
    {
        code : 'PL',
        flag : pol
    }
];

export const navButtons = [
    ['Početna', 'Smještaj', 'Hrana i Piće', 'Rent a car', 'Ture i izleti', 'Događaji'],
    ['Home page','Accommodation','Food and drink','Rent a car','Tours and excursions','Events'],
    ['Дома', 'Жилье', 'Еда и напитки', 'Аренда автомобилей', 'Туры и экскурсии', 'События'],
    ['Zuhause', 'Unterkunft', 'Essen und Trinken', 'Autovermietungen', 'Führungen', 'Veranstaltungen'],
    ['Casa', 'Struttura ricettiva', 'Cibo e bevande', 'Affittare una macchina', 'Tour ed escursioni', 'Eventi'],
    ['Maison', 'Hébergement', 'Nourriture et boisson', 'Louer une voiture', 'Visites et excursions', 'Événements'],
    ['Shtëpi', 'Akomodimi', 'Ushqim dhe pije', 'Merr nje makine me qera', 'Turne dhe ekskursione', 'Ngjarjet'],
    ['Itthon', 'Szállás', 'Étel és ital', 'Autót bérelni', 'Túrák és kirándulások', 'Események'],
    ['Domov', 'Namestitev', 'Hrana in pijača', 'Izposoditi si avto', 'Ogledi in izleti', 'Dogodki'],
    ['Acasă', 'Cazare', 'Mancare si bautura', 'Închiriază o mașină', 'Tururi și excursii', 'Evenimente'],
    ['Domov', 'Ubytování', 'Jídlo a pití', 'Půjčit auto', 'Zájezdy a exkurze', 'Události'],
    ['Hem', 'Boende', 'Mat och dryck', 'Hyra en bil', 'Turer och utflykter', 'Evenemang'],
    ['Dom', 'Zakwaterowanie', 'Jedzenie i picie', 'Wypożyczyć auto', 'Wycieczki i wycieczki', 'Wydarzenia'],
];
