import * as api from '../api/api_manager';

export const addNewRestaurantAction = (restaurantData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_RESTAURANT' });

        const { data } = await api.addNewRestaurant(restaurantData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_RESTAURANT',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new restaurant data: ', error.message);
        dispatch({
            type: 'ERROR_ADD_NEW_RESTAURANT',
            payload: error.response.data
        });
    };
};

export const getRestaurantsTableAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_RESTAURANTS_TABLE_DATA' });

        const { data } = await api.getRestaurantsTableData();

        dispatch({
            type: 'SUCCESS_GET_RESTAURANTS_TABLE_DATA',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting restaurants data for table : ', error.message);
        dispatch({
            type: "ERROR_GET_RESTAURANTS_TABLE_DATA",
            payload: error.response.data
        });
    }
};

export const deleteRestaurantAction = (deleteData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_DELETE_RESTAURANT' });

        const { data } = await api.deleteRestaurant(deleteData);

        dispatch({
            type: 'SUCCESS_DELETE_RESTAURANT',
            payload: data
        });

    } catch (error) {
        console.log('Error in deleting restaurant: ', error.message);
        dispatch({
            type: 'ERROR_DELETE_RESTAURANT',
            payload: error.response.data
        });
    }
};

export const getRestaurantDataEditAction = (restaurantId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_RESTAURANT_EDIT' });

        const { data } = await api.getRestaurantDataEdit(restaurantId);

        dispatch({
            type: 'SUCCESS_GET_RESTAURANT_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting restaurant data to edit: ', error.message);
        dispatch({
            type: 'ERROR_GET_RESTAURANT_EDIT',
            payload: error.response.data
        });
    }
};

export const updateRestaurantAction = (updateData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_RESTAURANT' });

        const { data } = await api.updateRestaurant(updateData);

        dispatch({
            type: 'SUCCESS_UPDATE_RESTAURANT',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating restaurant: ', error.message);
        dispatch({
            type: 'ERROR_UPDATE_RESTAURANT',
            payload: error.response.data
        });
    }
};

export const previewRestaurantAction = (restaurantId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_PREVIEW_RESTAURANT' });

        const { data } = await api.superAdminPreviewRestaurant(restaurantId);

        dispatch({
            type: 'SUCCESS_PREVIEW_RESTAURANT',
            payload: data
        });

    } catch (error) {
        console.log('Error in previewing restaurant: ', error.message);
        dispatch({
            type: 'ERROR_PREVIEW_RESTAURANT',
            payload: error.response.data
        });
    }
};

export const getAllRestaurantsAction = (type) => async (dispatch) => {
    try {

        if (type === 'all') {
            dispatch({ type: 'REQUEST_GET_ALL_RESTAURANTS' });
        }

        const { data } = await api.getAllRestaurants(type);

        dispatch({
            type: 'SUCCESS_GET_ALL_RESTAURANTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting all restaurants: ', error.message);
        dispatch({
            type: 'ERROR_GET_ALL_RESTAURANTS',
            payload: error.response.data
        });
    }
};

export const makeReservationAction = (reservationData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_MAKE_RESERVATION' });

        const { data } = await api.makeReservation(reservationData);

        dispatch({
            type: 'SUCCESS_MAKE_RESERVATION',
            payload: data
        });

    } catch (error) {
        console.log('Error in making reservation: ', error.message);
        dispatch({
            type: 'ERROR_MAKE_RESERVATION',
            payload: error.response.data
        });
    }
};

export const getRestaurantAction = (restaurantId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_RESTAURANT' });

        const { data } = await api.getRestaurant(restaurantId);

        dispatch({
            type: 'SUCCESS_GET_RESTAURANT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting restaurant: ', error.message);
        dispatch({
            type: 'ERROR_GET_RESTAURANT',
            payload: error.response.data
        });
    }
};