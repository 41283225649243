
export const typesOfSearch = [
    ['Smještaj', 'Restorani', 'Rent a car', 'Izleti / Ture', 'Događaji'],
    ['Accommodation', 'Restaurants', 'Rent a car', 'Excursions / Tours', 'Events'],
    ['Проживание', 'Рестораны', 'Аренда автомобилей', 'Экскурсии / Туры', 'События'],
    ['Unterkunft', 'Restaurants', 'Autovermietungen', 'Ausflüge / Touren', 'Veranstaltungen'],
    ['Struttura ricettiva', 'Ristoranti', 'Affittare una macchina', 'Escursioni / Tour', 'Eventi'],
    ['Logement', 'Restaurants', 'Louer une voiture', 'Excursions / Tours', 'Événements'],
    ['Akomodimi', 'Restorante', 'Merr nje makine me qera', 'Ekskursione / Turne', 'Ngjarjet'],
    ['Szállás', 'Éttermek', 'Autót bérelni', 'Kirándulások / túrák', 'Események'],
    ['Namestitev', 'Restavracije', 'Izposoditi si avto', 'Izleti / Ogledi', 'Dogodki'],
    ['Cazare', 'Restaurante', 'Închiriază o mașină', 'Excursii / Tururi', 'Evenimente'],
    ['Ubytování', 'Restaurace', 'Půjčit auto', 'Exkurze / výlety', 'Události'],
    ['Boende', 'Restauranger', 'Hyra en bil', 'Utflykter/turer', 'Evenemang'],
    ['Zakwaterowanie', 'Restauracje', 'Wypożyczyć auto', 'Wycieczki / Wycieczki', 'Wydarzenia'],
];