import React from 'react';
import { LinearProgress, Paper, Typography, Avatar, Divider, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { AlertTitle, Alert } from '@material-ui/lab';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Carousel } from 'react-responsive-carousel';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import RestaurantForm from './RestaurantForm';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import CafeIcon from '@material-ui/icons/LocalCafe';
import ClubIcon from '@material-ui/icons/LocalBar';
import PeopleIcon from '@material-ui/icons/People';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { useTranslation } from 'react-i18next';
import { restaurantEquipmentData } from './restaurantEquipmentData';
import { contactOwnerPhotos } from '../Accommodation Object/owner_info_data';
import { getRestaurantAction } from '../actions/restaurants_actions';
import share from '../assets/photos/share.png';
import email from '../assets/photos/email.png';
import telefon from '../assets/photos/telefon.svg';
import backArrow from '../assets/photos/left-arrow.png';
import website from '../assets/photos/website.svg';
import Lightbox from 'react-image-lightbox';

const ShowRestaurant = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [currentLightBoxPhotoIndex, setCurrentLightBoxPhotoIndex] = React.useState(0);
    const [lightBoxOpen, setLightBoxOpen] = React.useState(false);

    const getRestaurantSelector = useSelector(state => state.getRestaurantState);
    const { loading, getRestaurantData: data } = getRestaurantSelector;

    React.useEffect(() => {
        dispatch(getRestaurantAction(restaurantId));
    }, [restaurantId]);

    const NameAndBasicInfo = () => {
        return (
            <div className={classes.nameAndBasicInfo}>
                <div className={classes.infoSection}>
                    <div className={classes.name}>
                        <Typography variant='h4' ><strong>{data.restaurant.name}</strong></Typography>
                    </div>
                    <div className={classes.types}>
                        {
                            data.restaurant.is_restaurant
                                ?
                                <div className={classes.type}>
                                    <RestaurantIcon fontSize='small' style={{ color: '#0F79A1' }} />
                                    <Typography>Restoran</Typography>
                                </div>
                                :
                                null
                        }
                        {
                            data.restaurant.is_cafe
                                ?
                                <div className={classes.type}>
                                    <CafeIcon fontSize='small' style={{ color: '#0F79A1' }} />
                                    <Typography>Kafić</Typography>
                                </div>
                                :
                                null
                        }
                        {
                            data.restaurant.is_club
                                ?
                                <div className={classes.type}>
                                    <ClubIcon fontSize='small' style={{ color: '#0F79A1' }} />
                                    <Typography>Klub</Typography>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <div className={classes.infoSection}>
                    <div className={classes.address}>
                        <LocationOnTwoToneIcon fontSize='small' />
                        <Typography variant='subtitle1'>
                            {data.restaurant.address}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div className={classes.share}>
                            <img src={share} alt='Share' height={16} style={{ marginRight: '5px' }} />
                            <Typography variant='subtitle1' style={{ textDecoration: 'underline' }}>
                                Podijeli
                            </Typography>
                        </div>
                        <div className={classes.capacity}>
                            <Typography>Kapacitet {data.restaurant.capacity}</Typography>
                            <PeopleIcon fontSize='small' style={{ marginLeft: '5px', color: '#0F79A1' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DesktopGallery = () => {
        return (
            <div className={classes.desktopGallery}>
                <div
                    onClick={() => {
                        setCurrentLightBoxPhotoIndex(0);
                        setLightBoxOpen(true);
                    }}
                    style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + data.restaurant.photos[0]})` }}
                    className={classes.desktopGalleryBigPhoto}>
                </div>
                <div className={classes.desktopGallerySmallPhotos}>
                    {
                        [...Array(4)].map((el, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        setCurrentLightBoxPhotoIndex(index + 1);
                                        setLightBoxOpen(true);
                                    }}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + data.restaurant.photos[index + 1]})`,
                                        borderTopRightRadius: index === 1 ? '12px' : '0px',
                                        borderBottomRightRadius: index === 3 ? '12px' : '0px'
                                    }}
                                    key={index}
                                    className={classes.desktopGalleryOneSmallPhoto}>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    };

    const DesktopData = () => {
        return (
            <div className={classes.desktopData}>
                <div className={classes.desktopDataInfo}>
                    <div>
                        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                {t("acc_object_page_description")}
                            </Typography>
                        </div>
                        <Typography variant='subtitle1' style={{ padding: '5px' }}>
                            {data.restaurant.description}
                        </Typography>
                    </div>
                    <div>
                        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                Radno vrijeme
                            </Typography>
                        </div>
                        <Typography variant='subtitle1' style={{ padding: '5px' }}>
                            {data.restaurant.working_hours}
                        </Typography>
                    </div>
                    <div>
                        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                Muzika
                            </Typography>
                        </div>
                        <Typography variant='subtitle1' style={{ padding: '5px' }}>
                            {
                                data.restaurant.music
                                    ?
                                    'Da'
                                    :
                                    'Ne'
                            }
                        </Typography>
                    </div>
                    <div>
                        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                {t("acc_object_page_equipment")}
                            </Typography>
                        </div>
                        <div className={classes.items}>
                            {
                                restaurantEquipmentData.map((eqItem, index) => {
                                    const flag = data.restaurant.restaurantEquipment[eqItem.equipmentName];
                                    if (flag) {
                                        return (
                                            <div className={classes.equipment_item} key={index}>
                                                <img width={25} style={{ marginRight: '7px' }} src={eqItem.icon} alt="Icon" />
                                                <Typography variant='subtitle1'>
                                                    {eqItem.nameToDisplay}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>
                    </div>
                    <div>
                        <div className={classes.mapDiv}>
                            <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                                <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                    Lokacija
                                </Typography>
                            </div>
                            <MapContainer
                                center={[data.restaurant.latitude, data.restaurant.longitude]}
                                zoom={16}
                                tap={false}
                                className={classes.map}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[data.restaurant.latitude, data.restaurant.longitude]}>
                                    <Popup>
                                        <Typography variant='h5'>
                                            {data.restaurant.name}
                                        </Typography>
                                        <Typography variant='h6'>
                                            {data.restaurant.address}
                                        </Typography>
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                    </div>
                </div>
                <div className={classes.desktopDataFormInfo}>
                    <div className={classes.desktopDataForm}>
                        <div style={{ borderLeft: '3px solid #f79800', marginBottom: '20px' }}>
                            <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                Rezervišite
                            </Typography>
                        </div>
                        {
                            data.restaurant.email_for_reservations
                                ?
                                <RestaurantForm data={data.restaurant} />
                                :
                                <div className={classes.ownerPhoneData}>
                                    {
                                        data.restaurant.restaurantPhones.map((phone, index) => {
                                            return (
                                                <div className={classes.phoneNumber} key={index}>
                                                    <img src={telefon} alt="Phone" height={25} style={{ marginRight: '10px' }} />
                                                    <Typography variant='h6'> {phone.phone_number} </Typography>
                                                    {phone.viber ? <img style={{ marginLeft: '10px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                                    {phone.whatsapp ? <img style={{ marginLeft: '10px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                    <div className={classes.desktopDataOwnerInfo}>
                        <div className={classes.ownerInfo}>
                            <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                                <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                                    Vlasnik
                                </Typography>
                            </div>
                            <div className={classes.ownerPhotoAndName}>
                                <div className={classes.ownerPhotoAndNameMain}>
                                    <Avatar src={process.env.REACT_APP_UPLOADS_PATH + data.restaurant.ownerPhoto} style={{ width: '60px', height: '60px', marginRight: '11px' }} />
                                    <div>
                                        <Typography style={{ color: 'gray' }} variant='subtitle2' > {t("acc_object_page_owner_name")} </Typography>
                                        <Typography variant='subtitle1' > {data.restaurant.ownerName}</Typography>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <img src={website} alt="Website" height={20} style={{ marginRight: '5px' }} />
                                    <Typography variant='subtitle1'>{data.restaurant.web_site} </Typography>
                                </div>
                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <img src={email} alt="Website" height={22} style={{ marginRight: '5px' }} />
                                    <Typography variant='subtitle1'>
                                        {
                                            data.restaurant.email_for_reservations
                                                ?
                                                data.restaurant.email_for_reservations
                                                :
                                                'Rezervacija putem telefona'
                                        }
                                    </Typography>
                                </div>

                            </div>
                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>

                            <div className={classes.ownerPhoneData}>

                                {
                                    data.restaurant.restaurantPhones.map((phone, index) => {
                                        return (
                                            <div className={classes.phoneNumber} key={index}>
                                                <img src={telefon} alt="Phone" height={20} style={{ marginRight: '5px' }} />
                                                <Typography variant='subtitle1'> {phone.phone_number} </Typography>
                                                {phone.viber ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                                {phone.whatsapp ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const PhotosAndName = () => {
        return (
            <div className={classes.galleryAndContact}>
                <div className={classes.gallery}>
                    <Carousel
                        showThumbs={false}
                        showArrows={false}
                        emulateTouch
                        className={classes.carousel}
                        swipeable
                        showStatus={false}>
                        {
                            data.restaurant.photos.length > 0
                                ?
                                data.restaurant.photos.map((image, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setCurrentLightBoxPhotoIndex(index);
                                                setLightBoxOpen(true);
                                            }}
                                            key={index}>
                                            <img src={process.env.REACT_APP_UPLOADS_PATH + image} alt='Accommodation' />
                                            <div className={classes.imageCounter}>
                                                <Typography variant='caption' >
                                                    {index + 1} / {data.restaurant.photos.length}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div>
                                    <img src={noPhotoAvailable} alt='No Photo' />
                                </div>
                        }
                    </Carousel>
                    {/* {
                        data.restaurant.is_popular
                            ?
                            <div className={classes.isPopularMark}>
                                <Typography variant='h6'>{t("card_popular_flag")}</Typography>
                            </div>
                            :
                            null
                    } */}
                    <div className={classes.backButton} >
                        <img src={backArrow} height={17} alt='Back' />
                    </div>
                    <div className={classes.shareButton}>
                        <img src={share} height={20} alt='Back' />
                    </div>
                    <div className={classes.capacityMobile}>
                        <PeopleIcon style={{ height: '20px', color: "#0F79A1" }} />
                        <Typography variant='subtitle2' >
                            {data.restaurant.capacity}
                        </Typography>
                    </div>
                </div>
                <Box sx={{
                    display: { xs: 'block', md: 'none' },
                    marginTop: '15px',
                    marginBottom: '30px',
                    width: '95%',
                    margin: 'auto'
                }} >
                    <div className={classes.mobileTitle}>
                        <Typography variant='h5' style={{ marginRight: '5px' }}>
                            <strong>{data.restaurant.name}</strong>
                        </Typography>
                    </div>
                    <div className={classes.mainInfoAddress} style={{ marginTop: '5px' }}>
                        <LocationOnTwoToneIcon fontSize='small' />
                        <Typography variant='body1'>
                            {data.restaurant.address}
                        </Typography>
                    </div>
                    <div className={classes.types} style={{ marginTop: '10px' }}>
                        {
                            data.restaurant.is_restaurant
                                ?
                                <div className={classes.type}>
                                    <RestaurantIcon fontSize='small' style={{ color: '#0F79A1' }} />
                                    <Typography>Restoran</Typography>
                                </div>
                                :
                                null
                        }
                        {
                            data.restaurant.is_cafe
                                ?
                                <div className={classes.type}>
                                    <CafeIcon fontSize='small' style={{ color: '#0F79A1' }} />
                                    <Typography>Kafić</Typography>
                                </div>
                                :
                                null
                        }
                        {
                            data.restaurant.is_club
                                ?
                                <div className={classes.type}>
                                    <ClubIcon fontSize='small' style={{ color: '#0F79A1' }} />
                                    <Typography>Klub</Typography>
                                </div>
                                :
                                null
                        }
                    </div>
                </Box>
            </div>
        );
    };

    const OtherData = () => {
        return (
            <div className={classes.otherDataDiv}>

                <div className={classes.desktopDataForm} style={{ width: '98%' }}>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '20px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            Rezervišite
                        </Typography>
                    </div>
                    {
                        data.restaurant.email_for_reservations
                            ?
                            <RestaurantForm data={data.restaurant} />
                            :
                            <div className={classes.ownerPhoneData}>
                                {
                                    data.restaurant.restaurantPhones.map((phone, index) => {
                                        return (
                                            <div className={classes.phoneNumber} key={index}>
                                                <img src={telefon} alt="Phone" height={25} style={{ marginRight: '10px' }} />
                                                <Typography variant='h6'> {phone.phone_number} </Typography>
                                                {phone.viber ? <img style={{ marginLeft: '10px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                                {phone.whatsapp ? <img style={{ marginLeft: '10px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>

                <div className={classes.ownerInfo} style={{ padding: '0px' }}>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            Vlasnik
                        </Typography>
                    </div>
                    <div className={classes.ownerPhotoAndName}>
                        <div className={classes.ownerPhotoAndNameMain}>
                            <Avatar src={process.env.REACT_APP_UPLOADS_PATH + data.restaurant.ownerPhoto} style={{ width: '60px', height: '60px', marginRight: '11px' }} />
                            <div>
                                <Typography style={{ color: 'gray' }} variant='subtitle2' > {t("acc_object_page_owner_name")} </Typography>
                                <Typography variant='subtitle1' > {data.restaurant.ownerName}</Typography>
                            </div>
                        </div>
                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <img src={website} alt="Website" height={20} style={{ marginRight: '5px' }} />
                            <Typography variant='subtitle1'>{data.restaurant.web_site} </Typography>
                        </div>
                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <img src={email} alt="Website" height={22} style={{ marginRight: '5px' }} />
                            <Typography variant='subtitle1'>
                                {
                                    data.restaurant.email_for_reservations
                                        ?
                                        data.restaurant.email_for_reservations
                                        :
                                        'Rezervacija putem telefona'
                                }
                            </Typography>
                        </div>

                    </div>
                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>

                    <div className={classes.ownerPhoneData}>

                        {
                            data.restaurant.restaurantPhones.map((phone, index) => {
                                return (
                                    <div className={classes.phoneNumber} key={index}>
                                        <img src={telefon} alt="Phone" height={20} style={{ marginRight: '5px' }} />
                                        <Typography variant='subtitle1'> {phone.phone_number} </Typography>
                                        {phone.viber ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.viber} alt='Viber' /> : null}
                                        {phone.whatsapp ? <img style={{ marginLeft: '7px' }} width={25} src={contactOwnerPhotos.whatsapp} alt='Viber' /> : null}
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            {t("acc_object_page_description")}
                        </Typography>
                    </div>
                    <Typography variant='subtitle1' style={{ padding: '5px' }}>
                        {data.restaurant.description}
                    </Typography>
                </div>
                <div>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            Radno vrijeme
                        </Typography>
                    </div>
                    <Typography variant='subtitle1' style={{ padding: '5px' }}>
                        {data.restaurant.working_hours}
                    </Typography>
                </div>
                <div>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            Muzika
                        </Typography>
                    </div>
                    <Typography variant='subtitle1' style={{ padding: '5px' }}>
                        {
                            data.restaurant.music
                                ?
                                'Da'
                                :
                                'Ne'
                        }
                    </Typography>
                </div>
                <div>
                    <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                        <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                            {t("acc_object_page_equipment")}
                        </Typography>
                    </div>
                    <div className={classes.items}>
                        {
                            restaurantEquipmentData.map((eqItem, index) => {
                                const flag = data.restaurant.restaurantEquipment[eqItem.equipmentName];
                                if (flag) {
                                    return (
                                        <div className={classes.equipment_item} key={index}>
                                            <img width={25} style={{ marginRight: '7px' }} src={eqItem.icon} alt="Icon" />
                                            <Typography variant='subtitle1'>
                                                {eqItem.nameToDisplay}
                                            </Typography>
                                        </div>
                                    )
                                }
                            })
                        }

                    </div>
                </div>

            </div>
        )
    };

    const ShowMap = () => {
        return (
            <div className={classes.mapDiv} style={{ padding: '5px' }}>
                <div style={{ borderLeft: '3px solid #f79800', marginBottom: '5px' }}>
                    <Typography variant='h5' style={{ marginBottom: '6px', fontWeight: 'bold', color: '#0ca6d8', marginLeft: '10px' }}>
                        Lokacija
                    </Typography>
                </div>
                <MapContainer
                    center={[data.restaurant.latitude, data.restaurant.longitude]}
                    zoom={16}
                    tap={false}
                    className={classes.map}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[data.restaurant.latitude, data.restaurant.longitude]}>
                        <Popup>
                            <Typography variant='h5'>
                                {data.restaurant.name}
                            </Typography>
                            <Typography variant='h6'>
                                {data.restaurant.address}
                            </Typography>
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        )
    };

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ margin: '20px' }}>
                <AlertTitle>{t("error")}</AlertTitle>
                Greška u prikazivanju restorana
            </Alert>
        )
    };

    const ShowLightBox = () => {
        return (
            <>
                {
                    lightBoxOpen
                        ?
                        <Lightbox
                            animationDisabled={true}
                            animationDuration={0}
                            mainSrc={process.env.REACT_APP_UPLOADS_PATH + data.restaurant.photos[currentLightBoxPhotoIndex]}
                            nextSrc={process.env.REACT_APP_UPLOADS_PATH + data.restaurant.photos[(currentLightBoxPhotoIndex + 1) % data.restaurant.photos.length]}
                            prevSrc={process.env.REACT_APP_UPLOADS_PATH + data.restaurant.photos[(currentLightBoxPhotoIndex + data.restaurant.photos.length - 1) % data.restaurant.photos.length]}
                            onCloseRequest={() => { setLightBoxOpen(false); }}
                            onMovePrevRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + data.restaurant.photos.length - 1) % data.restaurant.photos.length)
                            }
                            onMoveNextRequest={() =>
                                setCurrentLightBoxPhotoIndex(prevIndex => (prevIndex + 1) % data.restaurant.photos.length)
                            }
                        />
                        :
                        null
                }
            </>

        )
    };

    return (
        <div className={classes.restaurantBigDiv}>
            {
                loading
                    ?
                    <div>
                        <Typography variant='h4' style={{ marginRight: '25px', marginBottom: '20px' }}>{t("acc_object_page_loading")}</Typography>
                        <LinearProgress style={{ backgroundColor: '#0F79A1' }} variant='indeterminate' />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data?.success
                            ?
                            <>
                                {
                                    !isMobile
                                        ?
                                        <>
                                            <NameAndBasicInfo />
                                            <DesktopGallery />
                                            <DesktopData />
                                        </>
                                        :
                                        <>
                                            <PhotosAndName />
                                            <OtherData />
                                            <ShowMap />
                                        </>
                                }
                                <ShowLightBox />
                            </>
                            :
                            <ShowError />
                        :
                        null
            }
        </div>
    );
};

export default ShowRestaurant;