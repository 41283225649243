import {
    Grid,
    Typography,
    TextField,
    Accordion,
    AccordionActions,
    AccordionSummary,
    AccordionDetails,
    Button,
    IconButton,
    LinearProgress
} from '@material-ui/core'
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { ExpandMoreSharp } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDataAction } from '../actions/users_actions';

const Settings = ({ switchActivePanel, loggedUserData, setLoggedUserData }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [previewImage, setPreviewImage] = React.useState(loggedUserData.avatar);

    const [showImageButtons, setShowImageButtons] = React.useState(false);

    const updateUserSelector = useSelector(state => state.updateUserState);
    const { loading, updateUser } = updateUserSelector;

    const [settingsData, setSettingsData] = React.useState({
        name: loggedUserData.name,
        surname: loggedUserData.surname,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        avatar: null
    });

    const handleAvatarChange = (e) => {
        const imgFile = e.target.files[0];
        if (imgFile) {
            console.log(imgFile);
            setPreviewImage(URL.createObjectURL(imgFile));
            setSettingsData({
                ...settingsData,
                avatar: imgFile
            });
        };
    };

    const handleChange = (e) => {
        setSettingsData({
            ...settingsData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', settingsData.name);
        formData.append('surname', settingsData.surname);
        if (settingsData.newPassword.length > 0) {
            formData.append('oldPassword', settingsData.oldPassword);
            formData.append('newPassword', settingsData.newPassword);
            formData.append('confirmNewPassword', settingsData.confirmNewPassword);
        };
        formData.append('avatar', settingsData.avatar);
        dispatch(updateUserDataAction(formData));
    };

    // React.useEffect(()=>{
    //     if( typeof updateUser !== 'undefined' ){
    //         if( updateUser?.success ) {
    //             setLoggedUserData({...JSON.parse(localStorage.getItem('loggedUserData'))})
    //         }
    //     };
    // },[updateUser]);

    return (
        <form onSubmit={handleSubmit}>
            {
                loading
                    ?
                    <div style={{ width: '50%', margin: 'auto', marginBottom: '30px', }}>
                        <Typography>Ažuriranje...</Typography>
                        <LinearProgress />
                    </div>
                    :
                    null
            }
            {
                typeof updateUser !== 'undefined'
                    ?
                    updateUser?.success
                        ?
                        <Alert severity="success" style={{ marginBottom: '20px' }}>
                            <AlertTitle>Uspješno</AlertTitle>
                            Podaci ažurirani
                        </Alert>
                        :
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            <AlertTitle>Greška</AlertTitle>
                            Neuspješno ažuriranje podataka {updateUser?.message}
                        </Alert>
                    :
                    null
            }
            <div className={classes.settingsDiv}>
                <Grid className={classes.container} container spacing={4}>
                    <Grid item xs={12} md={6} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            required
                            id="name"
                            label="Ime"
                            name="name"
                            type="text"
                            value={settingsData.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            required
                            id="surname"
                            label="Prezime"
                            name="surname"
                            type="text"
                            value={settingsData.surname}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Accordion elevation={0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreSharp />}>
                                <Typography>Promijeni šifru</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="oldPassword"
                                            label="Stara šifra"
                                            name="oldPassword"
                                            type="password"
                                            value={settingsData.oldPassword}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="newPassword"
                                            label="Nova šifra"
                                            name="newPassword"
                                            type="password"
                                            value={settingsData.newPassword}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="confirmPassword"
                                            label="Potvrdi novu šifru"
                                            name="confirmNewPassword"
                                            type="password"
                                            value={settingsData.confirmNewPassword}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant="contained"
                            component="label"
                            color='primary'
                        >
                            <AddIcon />
                            Promijeni sliku
                            <input
                                accept='image/*'
                                onChange={handleAvatarChange}
                                type="file"
                                hidden
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div
                            onMouseEnter={() => { setShowImageButtons(true); }}
                            onMouseLeave={() => { setShowImageButtons(false); }}
                            className={classes.previewImageDiv}>
                            {
                                previewImage
                                    ?
                                    [
                                        <img
                                            className={classes.image}
                                            src={settingsData.avatar === null ? process.env.REACT_APP_UPLOADS_PATH + previewImage : previewImage }
                                            key={1} />,
                                        <div key={2} className={classes.editImage} style={{ display: showImageButtons ? 'block' : 'none' }} >
                                            <IconButton onClick={() => {
                                                setPreviewImage(null);
                                            }}>
                                                <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                            </IconButton>
                                        </div>
                                    ]
                                    :
                                    null
                            }
                        </div>
                    </Grid>
                    <div className={classes.finishButtons}>
                        <Button
                            type='submit'
                            variant='contained'
                            style={{ backgroundColor: '#26A65B', color: 'white' }} >
                            Sačuvaj izmjene
                        </Button>
                        <Button variant='text' style={{ color: '#DC3023' }} onClick={() => { switchActivePanel('myObjects') }}>
                            <CancelIcon />
                            Odustani
                        </Button>
                    </div>
                </Grid>
            </div>
        </form>
    );
};

export default Settings;
