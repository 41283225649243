import internet from '../assets/objects_equipment_photos/internet.png';
import parking from '../assets/objects_equipment_photos/parking.png';
import disability_friendly from '../assets/objects_equipment_photos/wheelchair.png';
import card_payment from '../assets/objects_equipment_photos/card_payment.png';
import smokers_zone from '../assets/objects_equipment_photos/smokers_zone.png';

export const restaurantEquipmentData = [
   {
        equipmentName: 'internet',
        icon: internet,
        nameToDisplay: 'Internet'
    },
    {
        equipmentName: 'parking',
        icon: parking,
        nameToDisplay: 'Parking'
    },
    {
        equipmentName: 'disability_friendly',
        icon: disability_friendly,
        nameToDisplay: 'Prilagođenost invalidima'
    },
    {
        equipmentName: 'card_payment',
        icon: card_payment,
        nameToDisplay: 'Plaćanje karticom'
    },
    {
        equipmentName: 'smokers_zone',
        icon: smokers_zone,
        nameToDisplay: 'Zona za pušače'
    },]

