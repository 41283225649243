import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    bigSection: {
        display: 'grid',
        border : '4px solid red',
        gridTemplateColumns: '1fr 2fr',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr'
        }
    },
    cardsSection: {
        //border : '2px solid red',
        padding: '20px'
    },
    mapSection: {
        height: '55vh',
        position: 'sticky',
        top: '80px',
        [theme.breakpoints.down('xs')]: {
            height: '450px',
            position: 'relative',
            top: '0',
        }
    },
    map: {
        height: '100vh',
        zIndex: "1",
        [theme.breakpoints.down('xs')]: {
            height: '100%'
        }
    },
    mapImage: {
        height: '150px',
        width: '200px',
        borderRadius: '8px'
    }
}));

export default useStyles;