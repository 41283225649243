

export const addNewTourReducer = (newTour = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_TOUR':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_TOUR':
        case 'ERROR_ADD_NEW_TOUR':
            return {
                loading: false,
                newTour: action.payload
            }
        default: return newTour
    }
};

export const getToursTableReducer = (toursTable = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_TOURS_TABLE':
            return {
                loading: true
            }
        case 'SUCCESS_GET_TOURS_TABLE':
        case 'ERROR_GET_TOURS_TABLE':
            return {
                loading: false,
                toursTable: action.payload
            }
        default: return toursTable
    }
};

export const getTourDataForEditReducer = (tourDataForEdit = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_TOUR_DATA_FOR_EDIT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_TOUR_DATA_FOR_EDIT':
        case 'ERROR_GET_TOUR_DATA_FOR_EDIT':
            return {
                loading: false,
                tourDataForEdit: action.payload
            }
        default: return tourDataForEdit
    }
};

export const updateTourReducer = (updateTour = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_TOUR':
            return {
                loading_update: true
            }
        case 'SUCCESS_UPDATE_TOUR':
        case 'ERROR_UPDATE_TOUR':
            return {
                loading_update: false,
                updateTour: action.payload
            }
        default: return updateTour
    }
};

export const deleteTourReducer = (deleteTour = {}, action) => {
    switch (action.type) {
        case 'REQUEST_DELETE_TOUR':
            return {
                loading: true
            }
        case 'SUCCESS_DELETE_TOUR':
        case 'ERROR_DELETE_TOUR':
            return {
                loading: false,
                deleteTour: action.payload
            }
        default: return deleteTour
    }
};

export const previewTourReducer = (previewTour = {}, action) => {
    switch (action.type) {
        case 'REQUEST_PREVIEW_TOUR':
            return {
                loading: true
            }
        case 'SUCCESS_PREVIEW_TOUR':
        case 'ERROR_PREVIEW_TOUR':
            return {
                loading: false,
                previewTour: action.payload
            }
        default: return previewTour
    }
};

export const getAllToursReducer = (allTours = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ALL_TOURS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ALL_TOURS':
        case 'ERROR_GET_ALL_TOURS':
            return {
                loading: false,
                allTours: action.payload
            }
        default: return allTours
    }
};