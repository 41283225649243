import * as api from '../api/api_manager';

export const registerAction = (registerData) => async (dispatch) => {
    try {
        dispatch({
            type: 'REGISTER_REQUEST'
        });

        const { data } = await api.registerUser(registerData);

        dispatch({
            type: 'REGISTER_SUCCESS',
            payload: data
        });
    } catch (error) {
        console.log("Error in registering user: ", error.message);
        dispatch({
            type: 'REGISTER_FAIL',
            payload: error.response.data
        });
    }
};

export const verifyEmailAction = (token) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_VERIFY_EMAIL'
        });

        const { data } = await api.verifyEmail(token);

        dispatch({
            type: 'SUCCESS_VERIFY_EMAIL',
            payload: data
        });

    } catch (error) {
        console.log('Error in verifying email: ', error.message);
        dispatch({
            type: 'ERROR_VERIFY_EMAIL',
            payload: error.response.data
        });
    }
};

export const loginAction = (credentialsData,type) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_LOGIN_USER' });

        const { data } = type === 'login' ? await api.loginUser(credentialsData) : await api.registerUser(credentialsData);

        dispatch({
            type: 'SUCCESS_LOGIN_USER',
            payload: data
        });

    } catch (error) {
        console.log('Error in logging in user: ', error.message);
        dispatch({
            type: 'ERROR_LOGIN_USER',
            payload: error.response.data
        });
    }
};

export const emptyLoginDataAction = () => async (dispatch) => {
    dispatch({
        type : 'EMPTY_LOGIN_USER_DATA'
    });
};

export const checkJwtTokenAction = () => async (dispatch) => {
    try {

        const { data } = await api.checkJwtToken();

        dispatch({
            type: 'SUCCESS_CHECK_JWT',
            payload: data
        });

    } catch (error) {
        console.log('Error in checking jwt token: ', error.message);
        dispatch({
            type: 'ERROR_CHECK_JWT',
            payload: error.response.data
        });
    };
};

export const emptyJwtTokenAction = () => async (dispatch) => {
    dispatch({
        type : 'EMPTY_CHECK_JWT'
    });
};

export const emptyRegularAndSuperAdminCheck = () => async (dispatch) => {
    dispatch({
        type : 'EMPTY_REGULAR_AND_SUPER_ADMIN_CHECK'
    });
};

export const logoutUserAction = () => async (dispatch) => {
    try {

        const { data } = await api.logoutUser();

        dispatch({
            type: 'SUCCESS_LOGOUT_USER',
            payload: data
        });

    } catch (error) {
        console.log('Error in logging out user: ', error.message);
        dispatch({
            type: 'ERROR_LOGOUT_USER',
            payload: error.response.data
        });
    };
};

export const updateUserDataAction = (userData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_USER_DATA' });

        const { data } = await api.updateUserData(userData);

        dispatch({
            type: 'SUCCESS_UPDATE_USER_DATA',
            payload: data
        });
        
    } catch (error) {
        console.log('Error in updating user data: ',error.message);
        dispatch({
            type : 'ERROR_UPDATE_USER_DATA',
            payload: error.response.data
        });
    }
};

export const checkSuperAdminAction = () => async (dispatch) => {
    try {

        const { data } = await api.checkSuperAdmin();

        dispatch({
            type : 'SUCCESS_CHECK_SUPER_ADMIN',
            payload: data
        })
        
    } catch (error) {
        console.log('Error in checking super admin: ',error.message);
        dispatch({
            type : 'ERROR_CHECK_SUPER_ADMIN',
            payload: error.response.data
        })
    }
};

export const checkRegularAdminAction = () => async (dispatch) => {
    try {

        const { data } = await api.checkRegularAdmin();

        dispatch({
            type : 'SUCCESS_CHECK_REGULAR_ADMIN',
            payload: data
        })
        
    } catch (error) {
        console.log('Error in checking regular admin: ',error.message);
        dispatch({
            type : 'ERROR_CHECK_REGULAR_ADMIN',
            payload: error.response.data
        })
    }
};

export const superAdminGetAllUsersAction = () => async (dispatch) => {
    try {

        dispatch({ type : 'REQUEST_SUPER_ADMIN_GET_ALL_USERS' });

        const { data } = await api.superAdminGetAllUsers();

        dispatch({
            type : 'SUCCESS_SUPER_ADMIN_GET_ALL_USERS',
            payload: data
        })
        
    } catch (error) {
        console.log('Error in getting all users for super admin: ',error.message);
        dispatch({
            type : 'ERROR_SUPER_ADMIN_GET_ALL_USERS',
            payload: error.response.data
        })
    }
};

export const superAdminUpdateUserAction = (userData) => async (dispatch) => {
    try {

        dispatch({ type : 'REQUEST_SUPER_ADMIN_UPDATE_USER' });

        const { data } = await api.superAdminUpdateUser(userData);

        dispatch({
            type : 'SUCCESS_SUPER_ADMIN_UPDATE_USER',
            payload: data
        })
        
    } catch (error) {
        console.log('Error in updating user super admin: ',error.message);
        dispatch({
            type : 'ERROR_SUPER_ADMIN_UPDATE_USER',
            payload: error.response.data
        })
    }
};

export const getPasswordResetLinkAction = (userEmail) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_GET_PASSWORD_RESET_LINK'
        });

        const { data } = await api.getPasswordResetLink(userEmail);

        dispatch({
            type: 'SUCCESS_GET_PASSWORD_RESET_LINK',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting password reset link: ', error.message);
        dispatch({
            type: 'ERROR_GET_PASSWORD_RESET_LINK',
            payload: error.response.data
        });
    }
};

export const resetPasswordAction = (passData) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_RESET_PASSWORD'
        });

        const { data } = await api.resetPassword(passData);

        dispatch({
            type: 'SUCCESS_RESET_PASSWORD',
            payload: data
        });

    } catch (error) {
        console.log('Error in resetting password: ', error.message);
        dispatch({
            type: 'ERROR_RESET_PASSWORD',
            payload: error.response.data
        });
    }
};