import React from 'react';
import { Grid, TextField,Typography, Button, MenuItem, createTheme, ThemeProvider, useTheme, useMediaQuery, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import useStyle from './style';
import { Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getAllCarsAction } from '../actions/rent_a_car_actions';
import { AlertTitle, Alert } from '@material-ui/lab';
import backPhoto from '../assets/homePhotos/001.jpg';
import { Menu } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { format, parseISO } from 'date-fns';
import dropdown from '../assets/photos/dropdown.svg';
import { getAllRestaurantsAction } from '../actions/restaurants_actions';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const restaurantTypes = ['Sve', 'Restorani', 'Kafići', 'Klubovi'];

const searchDataInitial = {
    arrivalDate: null,
    restaurantType: '',
    numberOfPeople: ''
};

const Restaurants = () => {

    const dispatch = useDispatch();
    const classes = useStyle();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [searchData, setSearchData] = React.useState(searchDataInitial);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const allRestaurantsSelector = useSelector(state => state.getAllRestaurantsState);
    const { loading, allRestaurantsData: data } = allRestaurantsSelector;

    const [openDate, setOpenDate] = React.useState(false);

    const [openRestaurantTypeDialog, setOpenRestaurantTypeDialog] = React.useState(false);
    const [openNumOfGuestsDialog, setOpenNumOfGuestsDialog] = React.useState(false);

    const [restaurantTypeAnchor, setRestaurantTypeAnchor] = React.useState(null);
    const [numOfPeopleAnchor, setNumOfPeopleAnchor] = React.useState(null);

    const handleOpenAnchor = (event) => {
        setNumOfPeopleAnchor(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setNumOfPeopleAnchor(null);
    };

    const handleOpenRestaurantTypeAnchor = (event) => {
        setRestaurantTypeAnchor(event.currentTarget);
    }

    const handleCloseRestaurantTypeAnchor = () => {
        setRestaurantTypeAnchor(null);
    };

    React.useEffect(() => {
        dispatch(getAllRestaurantsAction('all'));
    }, []);


    const ShowError = () => {
        return (
            <Alert severity="error">
                <AlertTitle>Greška</AlertTitle>
                Nije moguće prikazati restorane
            </Alert>
        );
    };

    const handleReservationFormSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div className={classes.navigation} style={{ backgroundImage: `url(${backPhoto}` }}>
                <div className={classes.innerContainer}>
                    {
                        !isMobile
                            ?
                            <form onSubmit={handleReservationFormSubmit}>

                                <div className={classes.datePickerDiv}>

                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <div
                                            onClick={() => {
                                                setOpenDate(open => !open);
                                            }}
                                            className={classes.pickerItem}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.arrivalDate
                                                            ?
                                                            typeof searchData.arrivalDate === 'string'
                                                                ?
                                                                format(parseISO(searchData.arrivalDate), 'dd/MM/yyyy')
                                                                :
                                                                format(searchData.arrivalDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>

                                            </div>
                                        </div>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                style={{ display: 'none' }}
                                                open={openDate}
                                                required
                                                color='primary'
                                                disablePast
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                autoOk
                                                // variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                emptyLabel='Izaberi datum'
                                                value={searchData.arrivalDate}
                                                onChange={(e) => {
                                                    setSearchData({
                                                        ...searchData,
                                                        arrivalDate: e
                                                    });
                                                    setOpenDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDate(false);
                                                }}
                                            />
                                        </ThemeProvider>

                                    </MuiPickersUtilsProvider>

                                    <div className={classes.pickerItem} onClick={handleOpenRestaurantTypeAnchor}>
                                        <img
                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Tip restorana</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    searchData.restaurantType
                                                        ?
                                                        searchData.restaurantType
                                                        :
                                                        'Izaberi tip restorana'
                                                }
                                            </Typography>
                                        </div>

                                    </div>

                                    <Menu
                                        style={{
                                            padding: '6px',
                                            height: '250px',
                                            marginTop: '13px',
                                        }}
                                        anchorEl={restaurantTypeAnchor}
                                        open={Boolean(restaurantTypeAnchor)}
                                        onClose={handleCloseRestaurantTypeAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            restaurantTypes.map((type, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setSearchData({
                                                                ...searchData,
                                                                restaurantType: type
                                                            });
                                                            handleCloseRestaurantTypeAnchor();
                                                        }}
                                                        key={index}>
                                                        <Typography variant='subtitle2'>
                                                            {type}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>


                                    <div className={classes.pickerItem} onClick={handleOpenAnchor}>
                                        <img
                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Broj gostiju</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    searchData.numberOfPeople
                                                        ?
                                                        searchData.numberOfPeople
                                                        :
                                                        'Izaberi'
                                                }
                                            </Typography>

                                        </div>
                                    </div>

                                    <Menu
                                        className={classes.menu}
                                        anchorEl={numOfPeopleAnchor}
                                        open={Boolean(numOfPeopleAnchor)}
                                        onClose={handleCloseAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            [...Array(30)].map((el, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setSearchData({
                                                                ...searchData,
                                                                numberOfPeople: index + 1
                                                            });
                                                            handleCloseAnchor();
                                                        }}

                                                        key={index}>
                                                        <Typography variant='subtitle1'>
                                                            {index + 1}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>


                                    <div className={classes.buttonSearch}>
                                        <Button
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation
                                            size='medium' >
                                            <SearchIcon fontSize='small' />
                                            {t("search_section_title")}
                                        </Button>
                                    </div>

                                </div>
                            </form>
                            :
                            <form onSubmit={handleReservationFormSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.mobileGridSection}>
                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <Grid item xs={12}>
                                            <div className={classes.mobileFormField}
                                                onClick={() => {
                                                    setOpenDate(open => !open);
                                                }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            searchData.arrivalDate
                                                                ?
                                                                typeof searchData.arrivalDate === 'string'
                                                                    ?
                                                                    format(parseISO(searchData.arrivalDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(searchData.arrivalDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <DatePicker
                                                    style={{ display: 'none' }}
                                                    open={openDate}
                                                    required
                                                    color='primary'
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    disableToolbar
                                                    autoOk
                                                    variant='dialog'
                                                    emptyLabel='Izaberi datum'
                                                    value={searchData.arrivalDate}
                                                    onChange={(e) => {
                                                        setSearchData({
                                                            ...searchData,
                                                            arrivalDate: e
                                                        });
                                                        setOpenDate(false);
                                                    }}
                                                    onClose={() => {
                                                        setOpenDate(false);
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <Grid item xs={6}>
                                        <div className={classes.mobileFormField} onClick={() => { setOpenRestaurantTypeDialog(true); }}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Tip restorana</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.restaurantType
                                                            ?
                                                            searchData.restaurantType
                                                            :
                                                            'Izaberi tip restorana'
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.mobileFormField} onClick={() => { setOpenNumOfGuestsDialog(true); }}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Broj gostiju</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.numberOfPeople
                                                            ?
                                                            searchData.numberOfPeople
                                                            :
                                                            'Izaberi broj gostiju'
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            size='medium' >
                                            <SearchIcon fontSize='large' />
                                            {t("search_section_title")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                    }
                </div>
                <Dialog
                    fullWidth
                    open={openRestaurantTypeDialog}
                    onClose={() => { setOpenRestaurantTypeDialog(false); }}>
                    <DialogTitle>Tip restorana</DialogTitle>
                    <DialogContent
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '10px'
                        }}>
                        {
                            restaurantTypes.map((type, index) => {
                                return (
                                    <Button
                                        variant={searchData.restaurantType === type ? 'contained' : 'outlined'}
                                        onClick={() => {
                                            setSearchData({
                                                ...searchData,
                                                restaurantType: type
                                            });
                                            setOpenRestaurantTypeDialog(false);
                                        }}
                                        disableElevation>
                                        {type}
                                    </Button>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenRestaurantTypeDialog(false); }}>Odustani</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    open={openNumOfGuestsDialog}
                    onClose={() => { setOpenNumOfGuestsDialog(false); }}>
                    <DialogTitle>{t("home_form_num_of_guests")}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Unesite broj gostiju"
                            type="number"
                            value={searchData.numberOfPeople}
                            onChange={(e) => {
                                setSearchData({
                                    ...searchData,
                                    numberOfPeople: e.target.value
                                })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenNumOfGuestsDialog(false); }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default Restaurants