import React from 'react';
import NavBar from './NavBarMaterialUi/NavBar';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Footer';

const WithNavBar = () => {
    return (
        <>
            <NavBar />
            <div style={{ minHeight : '100vh' }}>
            <Outlet />
            </div>
            <Footer/>
        </>
    );
};

export default WithNavBar;