import React from 'react';
import {
    Dialog,
    Toolbar,
    AppBar,
    Typography,
    Button,
    IconButton,
    Paper,
    Grid,
    TextField,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    Snackbar,
    LinearProgress,
    CircularProgress
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import { Slide } from '@material-ui/core';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { getAccUnitDataToEditAction, updateAccUnitAction } from '../actions/acc_objects_actions';
import { parseISO } from 'date-fns';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const accUnitEquipment = [
    'Internet',
    'TV',
    'Kablovska TV',
    'Radni sto',
    'Klima uređaj',
    'Pogled na more',
    'Krevetac za bebe',
    'Balkon',
    'Pegla',
    'Fen za kosu',
    'Mašina za pranje veša',
    'Mašina za pranje posuđa',
    'Kuhinja',
    'Šporet',
    'Frižider'
];

const initialAccUnitData = {
    unitName: '',
    maxOccupancy: '',
    numOfRooms: '',
    area: '',
    unitPrice: '',
    unitEquipment: {
        internet: false,
        tv: false,
        cable_tv: false,
        workdesk: false,
        air_conditioner: false,
        seaview: false,
        baby_crib: false,
        balcony: false,
        iron: false,
        hair_dryer: false,
        washing_machine: false,
        dishwasher: false,
        kitchen: false,
        stove: false,
        fridge: false
    },
    photoGallery: [],
    otherPeriods: [],
    photosToRemove: [],
    periodsToRemove: [],
    accUnitId: ''
};

const certainPeriodInitialData = {
    period_from: null,
    period_to: null,
    period_price: ''
};

const EditAccUnitDialog = ({ editUnitState, setEditUnitState }) => {


    const classes = useStyles();

    const dispatch = useDispatch();

    const [showSnackBar, setShowSnackBar] = React.useState({
        show: false,
        message: '',
        variant: 'info'
    });

    const accUnitToEditSelector = useSelector(state => state.accUnitDataEditState);
    const { loading, accUnitEditData } = accUnitToEditSelector;

    const [accUnitData, setAccUnitData] = React.useState(initialAccUnitData);

    const [imageGalleryDisplay, setImageGalleryDisplay] = React.useState([]);

    const [certainPeriod, setCertainPeriod] = React.useState(certainPeriodInitialData);

    const updateAccUnitSelector = useSelector(state => state.updateAccUnitState);
    const { loading_update, updateAccUnitData } = updateAccUnitSelector;

    const handleImageChange = async (e) => {
        if (e.target.files) {
            const filesArray = await Promise.all(Array.from(e.target.files).map(async (file) => {
                return {
                    photo_id: null,
                    path: await readFileAsBase64(file),
                    photo_originalname: file.name
                }
            }));
            setAccUnitData((prevData) => {
                return {
                    ...prevData,
                    photoGallery: prevData.photoGallery.concat(filesArray)
                }
            });
            setImageGalleryDisplay((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleRemoveImageFromGallery = (index, photo_id) => {
        setAccUnitData((prevData) => {
            return {
                ...prevData,
                photoGallery: prevData.photoGallery.filter((photo, photoIndex) => photoIndex !== index),
                photosToRemove: [...prevData.photosToRemove, photo_id !== null ? photo_id : null]
            }
        });
        setImageGalleryDisplay((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    const handleClose = () => {
        setEditUnitState({
            show: false,
            accUnitId: null
        });
    };

    const handleCloseSnackBar = () => {
        setShowSnackBar({
            show: false,
            message: '',
            variant: 'info'
        });
    };

    const handleAddNewPeriod = () => {
        const otherPeriodsUpdated = [...accUnitData.otherPeriods, certainPeriod];
        setAccUnitData({
            ...accUnitData,
            otherPeriods: otherPeriodsUpdated
        });
        setCertainPeriod(certainPeriodInitialData);
    };

    const handleRemoveCertainPeriod = (index, periodId) => {
        setAccUnitData((prevData) => ({
            ...prevData,
            otherPeriods: [...prevData.otherPeriods].filter((period, indexPeriod) => indexPeriod !== index),
            periodsToRemove: [...prevData.periodsToRemove, periodId ?? periodId]
        }));
    };

    const getKeyName = (index) => {
        return Object.keys(accUnitData.unitEquipment)[index];
    };

    const handleChange = (e) => {
        setAccUnitData({
            ...accUnitData,
            [e.target.name]: e.target.value
        });
    };

    React.useEffect(() => {
        if (editUnitState.accUnitId !== null) {
            dispatch(getAccUnitDataToEditAction(editUnitState.accUnitId));
        }
    }, [editUnitState]);

    React.useEffect(() => {
        if (accUnitEditData?.success) {
            delete accUnitEditData.accUnitEquipment.id;
            delete accUnitEditData.accUnitEquipment.acc_unit;
            const existingPeriods = accUnitEditData.accUnitOtherPeriods.map((period) => {
                return {
                    ...period,
                    period_from: format(parseISO(period.period_from), 'dd/MM/yyyy'),
                    period_to: format(parseISO(period.period_to), 'dd/MM/yyyy'),
                };
            });
            console.log(accUnitEditData.accUnitOtherPeriods);
            setAccUnitData({
                ...accUnitData,
                unitName: accUnitEditData.accUnit.name,
                accUnitId: accUnitEditData.accUnit.id,
                maxOccupancy: accUnitEditData.accUnit.max_occupancy,
                numOfRooms: accUnitEditData.accUnit.num_of_rooms,
                area: accUnitEditData.accUnit.area,
                unitPrice: accUnitEditData.accUnit.price,
                unitEquipment: { ...accUnitEditData.accUnitEquipment },
                otherPeriods: [...accUnitEditData.accUnitOtherPeriods],
                photoGallery: [...accUnitEditData.accUnitPhotos]
            });
            setImageGalleryDisplay([...accUnitEditData.accUnitPhotos]);
        };
    }, [accUnitEditData]);

    React.useEffect(() => {
        if (typeof updateAccUnitData !== 'undefined') {
            if (!updateAccUnitData.success) {
                setShowSnackBar({
                    show: true,
                    message: updateAccUnitData.message,
                    variant: 'error'
                });
            } else {
                setShowSnackBar({
                    show: true,
                    message: 'Podaci uspješno ažurirani',
                    variant: 'success'
                });
            }
        }
    }, [updateAccUnitData]);

    const handleUpdateAccUnit = (e) => {
        e.preventDefault();
        dispatch(updateAccUnitAction(accUnitData));
        console.log(accUnitData);
    };

    return (
        <Dialog
            fullScreen
            open={editUnitState.show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#006442' }}>
                <Toolbar className={classes.accUnitToolbar}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CancelIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Ažuriranje smještajne jedinice
                        </Typography>
                    </div>
                    <div>
                        <Button
                            form="myform"
                            type='submit'
                            style={{ backgroundColor: '#26A65B', color: 'white' }}
                            variant='contained'>
                            Sačuvaj
                        </Button>
                    </div>
                </Toolbar>
                {
                    loading_update
                        ?
                        <div>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        null
                }
            </AppBar>
            {
                loading
                    ?
                    <div style={{ width: '90%', margin: 'auto', marginTop: '20%', textAlign: 'center' }}>
                        <CircularProgress size={200} />
                    </div>
                    :
                    typeof accUnitEditData !== 'undefined'
                        ?
                        accUnitEditData.success
                            ?
                            <form onSubmit={handleUpdateAccUnit} id='myform'>
                                <div className={classes.addAccUnitSection}>
                                    <Paper elevation={7} className={classes.addNewObject_section1}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Osnovni podaci</Typography></div>
                                        <Grid container spacing={4} className={classes.section1Form}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    id="unitName"
                                                    label="Naziv smjestajne jedinice"
                                                    name="unitName"
                                                    value={accUnitData.unitName}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                                                <div className={classes.section1GalleryTitle}>
                                                    <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        color='primary'
                                                    >
                                                        <AddIcon fontSize='small' />
                                                        <input
                                                            onChange={handleImageChange}
                                                            multiple
                                                            accept='image/*'
                                                            type="file"
                                                            hidden
                                                        />
                                                    </Button>
                                                </div>
                                                <div className={classes.section1ImagePreviewDiv}>
                                                    {
                                                        imageGalleryDisplay.map((photo, index) => {
                                                            return (
                                                                <div key={index} style={{
                                                                    textAlign: 'center',
                                                                    position: 'relative',
                                                                    width: '100%',
                                                                    margin: 'auto'
                                                                }}>
                                                                    <img
                                                                        src={photo.photo_id === null ? photo.path : process.env.REACT_APP_UPLOADS_PATH + photo.path}
                                                                        alt={`Object Image ${index}`}
                                                                        style={{ borderRadius: '10px', width: '100%' }} />
                                                                    <div key={2} className={classes.editImage} >
                                                                        <IconButton onClick={() => { handleRemoveImageFromGallery(index, photo.photo_id); }}>
                                                                            <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Paper>
                                    <Paper elevation={7} className={classes.addNewObject_section1}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Ostali podaci</Typography></div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="maxOccupancy"
                                                    label="Maksimum osoba"
                                                    name="maxOccupancy"
                                                    type="number"
                                                    value={accUnitData.maxOccupancy}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="numOfRooms"
                                                    label="Broj soba"
                                                    name="numOfRooms"
                                                    type="number"
                                                    value={accUnitData.numOfRooms}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="area"
                                                    label="Površina"
                                                    name="area"
                                                    type="number"
                                                    value={accUnitData.area}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Paper elevation={7} className={classes.addNewObject_section4}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Oprema smještajne jedinice</Typography></div>
                                        <div className={classes.equipmentDiv}>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    <div className={classes.equipment}>
                                                        {
                                                            accUnitEquipment.map((item, index) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={
                                                                                    accUnitData.unitEquipment[getKeyName(index)]
                                                                                }
                                                                                onChange={(e) => {
                                                                                    const keyName = getKeyName(index);
                                                                                    const updatedEquipment = {
                                                                                        ...accUnitData.unitEquipment,
                                                                                        [keyName]: e.target.checked
                                                                                    };
                                                                                    setAccUnitData((prevData) => {
                                                                                        return {
                                                                                            ...prevData,
                                                                                            unitEquipment: updatedEquipment
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={item}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </Paper>
                                    <Paper elevation={7} className={classes.addNewObject_section4}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Cijena za određene periode</Typography></div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="unitPrice"
                                                    label="Cijena"
                                                    name="unitPrice"
                                                    type="number"
                                                    value={accUnitData.unitPrice}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={12} style={{ margin: 'auto' }}>
                                                <Accordion elevation={1}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreSharp />}>
                                                        <Typography>Dodaj novi period</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                                            <Grid container spacing={4}>
                                                                <Grid item xs={12} md={3}>
                                                                    <DatePicker
                                                                        required
                                                                        color='secondary'
                                                                        disablePast
                                                                        format="dd/MM/yyyy"
                                                                        disableToolbar
                                                                        autoOk
                                                                        variant='dialog'
                                                                        label="Od"
                                                                        helperText="Period od"
                                                                        value={certainPeriod.period_from}
                                                                        onChange={(e) => {
                                                                            setCertainPeriod((certainPeriod) => {
                                                                                return {
                                                                                    ...certainPeriod,
                                                                                    // period_from: format(e, 'dd/MM/yyyy')
                                                                                    period_from: e
                                                                                }
                                                                            })
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <DateRangeTwoToneIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                    <DatePicker
                                                                        disabled={certainPeriod.period_from ? false : true}
                                                                        required={certainPeriod.period_from ? true : false}
                                                                        color='secondary'
                                                                        minDate={certainPeriod.period_from}
                                                                        disableToolbar
                                                                        disablePast
                                                                        format="dd/MM/yyyy"
                                                                        autoOk
                                                                        variant='dialog'
                                                                        label="Do"
                                                                        helperText="Period do"
                                                                        value={certainPeriod.period_to}
                                                                        onChange={(e) => {
                                                                            setCertainPeriod((certainPeriod) => {
                                                                                return {
                                                                                    ...certainPeriod,
                                                                                    // period_to: format(e, 'dd/MM/yyyy')
                                                                                    period_to: e
                                                                                }
                                                                            })
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <DateRangeTwoToneIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        id="numOfGrownups"
                                                                        label="Cijena"
                                                                        name="numOfGrownups"
                                                                        type="number"
                                                                        value={certainPeriod.period_price}
                                                                        onChange={(e) => {
                                                                            setCertainPeriod({
                                                                                ...certainPeriod,
                                                                                period_price: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                    <Button onClick={handleAddNewPeriod} variant='contained'>
                                                                        <AddIcon />
                                                                        Dodaj
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </MuiPickersUtilsProvider>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <div className={classes.periodsDiv}>
                                                    {
                                                        accUnitData.otherPeriods.map((period, index) => {
                                                            return (
                                                                <div className={classes.singlePeriod} key={index} >
                                                                    <Typography variant='subtitle1'>
                                                                        Od: <strong>
                                                                            {typeof period.period_from === 'object'
                                                                                ?
                                                                                format(period.period_from, 'dd/MM/yyyy')
                                                                                :
                                                                                format(parseISO(period.period_from), 'dd/MM/yyyy')}
                                                                        </strong>
                                                                        {/* Od: <strong>{period.period_from}</strong> */}
                                                                    </Typography>
                                                                    <Typography variant='subtitle1'>
                                                                        Do: <strong>
                                                                            {typeof period.period_to === 'object'
                                                                                ?
                                                                                format(period.period_to, 'dd/MM/yyyy')
                                                                                :
                                                                                format(parseISO(period.period_to), 'dd/MM/yyyy')}
                                                                        </strong>
                                                                        {/* Do: <strong>{period.period_to}</strong> */}
                                                                    </Typography>
                                                                    <Typography variant='subtitle1'>
                                                                        Cijena: <strong>{period.period_price} &euro;</strong>
                                                                    </Typography>
                                                                    <IconButton onClick={() => {
                                                                        handleRemoveCertainPeriod(index, period.id);
                                                                    }} style={{ color: '#DC3023' }}>
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Grid>


                                        </Grid>
                                    </Paper>
                                </div>
                            </form>
                            :
                            <Alert severity="error" style={{ marginTop: '20%' }}>
                                <AlertTitle>Greška</AlertTitle>
                                Doslo je do greske
                            </Alert>
                        :
                        null
            }
           <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                open={showSnackBar.show}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}>
                <Alert
                    variant='filled'
                    onClose={handleCloseSnackBar}
                    severity={showSnackBar.variant}
                    sx={{ width: '100%' }}>
                    {showSnackBar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};


export default EditAccUnitDialog;