import React from 'react';
import useStyles from './style';
import photo from '../assets/homePhotos/001.jpg';
import { Button, Typography, Menu, MenuItem, Dialog, DialogContent, DialogActions, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { languagesMenuObject } from '../NavBarMaterialUi/languages';
import { GlobalContext } from '../App';
import { useContext } from 'react';
import i18next from '../i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useNavigate } from 'react-router-dom';
import LanguagePickerDialog from './LanguagePickerDialog';

const projects = ['AutoDiler.me', 'Oglasi.me', 'AutoEkspert.me', 'TehnickiPregled.me', 'PostExpress.me', 'KupujemProdajemPoklanjam.com', 'AutoNovosti.me', 'WebLabMedia.me', 'MojSajt.me', 'Genije.NET']

const Footer = () => {

    const { currentLanguage, setCurrentLanguage } = useContext(GlobalContext);

    const classes = useStyles();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [anchorLanguage, setAnchorLanguage] = React.useState(null);

    const [languagePickerDialog, setLanguagePickerDialog] = React.useState(false);

    const handleOpenLanguageMenu = (event) => {
        setAnchorLanguage(event.currentTarget);
    };

    const handleCloseLanguageMenu = () => {
        setAnchorLanguage(null);
    };

    const handleSwitchLanguage = (lang, index) => {
        localStorage.setItem('currentLanguage', JSON.stringify(index));
        setCurrentLanguage(index);
        i18next.changeLanguage(`${lang.toLowerCase()}`)
    };

    return (
        <div
            style={{ backgroundImage: `url(${photo}` }}
            className={classes.footerDiv}>
            <div className={classes.infoDiv}>
                <div className={classes.infoNavigation}>
                    <Typography style={{ fontWeight: 'bold', borderBottom: '4px solid #f8ac33', cursor: 'text' }}>NAVIGACIJA</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>POČETNA</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/objects'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>SMJEŠTAJ</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/restaurants'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>HRANA I PIĆE</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/rent-a-car'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>RENT A CAR</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/tours-and-excursions'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>TURE I IZLETI</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/events'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>DOGAĐAJI</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'start' }}>
                        <Typography variant='subtitle2' onClick={() => { navigate('/favorites'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>MOJ IZBOR</Typography>
                        <FavoriteIcon style={{ color: '#ce0b0b', marginLeft: '5px' }} />

                    </div>
                </div>

                <div className={classes.infoNavigation}>
                    <Typography style={{ fontWeight: 'bold', borderBottom: '4px solid #f8ac33', cursor: 'text' }}>VAŠ PETROVAC.ME</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/login'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>PRIJAVI SE</Typography>
                    <Typography variant='subtitle2' onClick={() => { navigate('/register'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>REGISTRUJ SE</Typography>
                    <Typography variant='subtitle2'>KONTAKTIRAJTE NAS</Typography>
                    <Typography variant='subtitle2'>MAPA SAJTA</Typography>
                    <Typography variant='subtitle2'>USLOVI KORIŠĆENJA</Typography>
                    <Typography variant='subtitle2'>POLITIKA PRIVATNOSTI</Typography>
                    <Typography variant='subtitle2'>POMOĆ</Typography>
                </div>

                <div className={classes.infoNavigation}>
                    <Typography style={{ fontWeight: 'bold', borderBottom: '4px solid #f8ac33', cursor: 'text' }}>PRATITE NAS</Typography>
                    <Typography variant='subtitle2'>INSTAGRAM</Typography>
                    <Typography variant='subtitle2'>FACEBOOK</Typography>
                    <Typography variant='subtitle2'>TIKTOK</Typography>
                    <Typography style={{ fontWeight: 'bold', borderBottom: '4px solid #f8ac33', cursor: 'text' }}>WEBLAB D.O.O</Typography>
                    <Typography variant='subtitle2' style={{ cursor: 'auto' }}>Jovana Tomaševića 1, Bar, Crna Gora</Typography>
                    <Typography variant='subtitle2' style={{ cursor: 'auto' }}>Telefon: +382 (0) 30 550 099</Typography>
                    <Typography variant='subtitle2' style={{ cursor: 'auto' }}>Email: info@petrovac.me</Typography>
                </div>

                <div className={classes.infoNavigation}>
                    <Typography style={{ fontWeight: 'bold', borderBottom: '4px solid #f8ac33', cursor: 'text' }}>IZDAJETE SMJEŠTAJ ?</Typography>
                    <Typography variant='subtitle2' style={{ cursor: 'text' }}>Postavi svoj objekat brzo i lako na petrovac.me</Typography>
                    <Button
                        onClick={() => { navigate('/register'); }}
                        disableElevation
                        size='small'
                        style={{ backgroundColor: '#ff0000', color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>
                        <PostAddIcon style={{ marginRight: '5px' }} /> DODAJ SMJEŠTAJ
                    </Button>
                    <Typography style={{ fontWeight: 'bold', borderBottom: '4px solid #f8ac33', cursor: 'text' }}>NAŠI PROJEKTI</Typography>
                    <div className={classes.allProjects} style={{ cursor: 'default' }}>
                        {
                            projects.map((project, index) => {
                                return (
                                    <Typography key={index}>
                                        {project} |
                                    </Typography>

                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={classes.blackDiv}>
                <div className={classes.navBarLanguagePicker}>
                    <Button
                        disableElevation
                        disableRipple
                        onClick={(e) => {
                            if (isMobile) { setLanguagePickerDialog(true) }
                            else { handleOpenLanguageMenu(e); }
                        }}>
                        <img src={languagesMenuObject[currentLanguage].flag} width={40} alt='Flag' />
                        <Typography
                            variant='subtitle2'
                            style={{
                                color: 'white',
                                marginLeft: '5px',
                                marginRight: '5px'
                            }}>
                            {languagesMenuObject[currentLanguage].code}
                        </Typography>
                        <ExpandMoreIcon style={{ color: 'white' }} />
                    </Button>
                    <Menu
                        style={{ display: isMobile ? 'none' : 'inherit' }}
                        anchorEl={anchorLanguage}
                        //getContentAnchorEl={null}
                        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        // transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={Boolean(anchorLanguage)}
                        onClose={handleCloseLanguageMenu}
                    >
                        {languagesMenuObject.map((language, index) => (
                            <MenuItem
                                style={{
                                    backgroundColor: index === currentLanguage ? '#0F79A1' : 'white',
                                    color: index === currentLanguage ? 'white' : 'black'
                                }}
                                key={index}
                                onClick={() => { handleSwitchLanguage(language.code, index); }}
                                className={classes.menuItem}>
                                <img src={language.flag} width={22} style={{ marginRight: '5px' }} alt='Flag' />
                                <div>
                                    <Typography variant='subtitle2' >{language.code}</Typography>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div className={classes.copy}>
                    <Typography
                        variant='subtitle2'
                        className={classes.copyText}>
                        Petrovac.me je dio WebLab grupe. Copyright &copy; 2022. Sva prava zadržana.
                    </Typography>
                </div>
            </div>
            <LanguagePickerDialog
                currentLanguage={currentLanguage}
                languagesMenuObject={languagesMenuObject}
                classes={classes} 
                languagePickerDialog={languagePickerDialog} 
                setLanguagePickerDialog={setLanguagePickerDialog}
                handleSwitchLanguage={handleSwitchLanguage}/>
        </div>
    );
};

export default Footer