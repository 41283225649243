import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, Slide, Divider, LinearProgress, Grid } from '@material-ui/core';
import useStyles from './style';
import { AlertTitle, Alert } from '@material-ui/lab';
import { Carousel } from 'react-responsive-carousel';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { previewCarAction } from '../actions/rent_a_car_actions';
import { previewTourAction } from '../actions/tours_actions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const SuperAdminPreviewTour = ({ previewTourId, show, setShow }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow({
            show: false,
            previewTourId: null
        });
    };

    const previewTourSelector = useSelector(state => state.previewTourState);
    const { loading, previewTour: data } = previewTourSelector;

    React.useEffect(() => {
        if (previewTourId !== null) {
            dispatch(previewTourAction(previewTourId));
        }
    }, [previewTourId]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ margin:'auto' }}>
                <AlertTitle>Greška</AlertTitle>
                Greška u prikazivanju ture
            </Alert>
        );
    };

    return (
        <Dialog
            fullScreen
            open={show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#003171' }} >
                <Toolbar className={classes.previewToolbar}>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                        Pregled podataka ture
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CancelIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {
                loading
                    ?
                    <div style={{
                        width: "50%",
                        margin: 'auto'
                    }}>
                        <Typography variant='h5'>Učitavanje podataka...</Typography>
                        <LinearProgress color='primary' />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data?.success
                            ?
                            <div className={classes.previewDiv}>
                                <div className={classes.previewGallery}>
                                    {
                                        data.tour.photos.length > 0
                                            ?
                                            <Carousel
                                                emulateTouch
                                                className={classes.carousel}
                                                swipeable
                                                showStatus={false}>
                                                {
                                                    data.tour.photos.map((photo, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <img src={process.env.REACT_APP_UPLOADS_PATH + photo} alt='Tour' />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                            :
                                            <Typography variant='h3'>
                                                Nema slika
                                            </Typography>
                                    }

                                </div>
                                <div className={classes.previewInfo}>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Naziv ture</strong> </Typography>
                                        <Typography variant='h6'>{data.tour.name}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Adresa</strong> </Typography>
                                        <Typography variant='h6'>{data.tour.address}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Website</strong> </Typography>
                                        <Typography variant='h6'>{data.tour.website}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Email</strong> </Typography>
                                        <Typography variant='subtitle1'>{data.tour.email_for_reservation}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Trajanje ture</strong> </Typography>
                                        <Typography variant='subtitle1'>{data.tour.duration}</Typography>
                                    </div>
                                </div>
                            </div>
                            :
                            <ShowError />
                        :
                        null

            }
        </Dialog>
    );
};

export default SuperAdminPreviewTour;