import {
    Button,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    TableCell,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    TableBody,
    Tooltip,
    LinearProgress
} from '@material-ui/core'
import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { Search } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { getAccObjectsTableDataAction } from '../actions/acc_objects_actions';
import { parseISO } from 'date-fns';
import EditAccObjectDialog from './EditAccObjectDialog';
import DeleteAccObjectDialog from './DeleteAccObjectDialog';

const MyObjects = ({ switchActivePanel }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const accObjectsTableSelector = useSelector(state => state.accObjectsTableState);
    const { loading, accObjectsTable } = accObjectsTableSelector;

    const [editState, setEditState] = React.useState({
        show: false,
        objectId: null
    });

    const [deleteState, setDeleteState] = React.useState({
        show: false,
        accObjectId: null,
        accObjectName: null
    });

    React.useEffect(() => {
        dispatch(getAccObjectsTableDataAction());
    }, []);


    const handleEdit = (objectId) => {
        setEditState({
            show: true,
            objectId
        });
    };

    const ShowTable = () => {
        if (typeof accObjectsTable !== 'undefined') {
            return (
                <>
                    {
                        accObjectsTable.success
                            ?
                            <div className={classes.myObjectsTable}>
                                <TableContainer component={Paper} className={classes.table} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Ime Objekta</TableCell>
                                                <TableCell align="left">Datum Kreiranja</TableCell>
                                                <TableCell align="left">Odobren</TableCell>
                                                <TableCell align="center">Akcija</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {accObjectsTable.data.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align='left'> {row.name}</TableCell>
                                                    <TableCell align="left">{format(parseISO(row.created_at), 'dd/MM/yyyy/HH:mm:ss')}</TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            !row.is_approved
                                                                ?
                                                                <Tooltip title='Još nije odobren' placement='right' arrow>
                                                                    <CloseIcon fontSize='medium' style={{ color: '#C3272B' }} />
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title='Odobren' placement='right' arrow>
                                                                    <DoneIcon fontSize='medium' style={{ color: '#26A65B' }} />
                                                                </Tooltip>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" >
                                                        <Tooltip title='Uredi' placement='left' arrow>
                                                            <EditIcon
                                                                onClick={() => {
                                                                    handleEdit(row.id);
                                                                }}
                                                                fontSize='medium'
                                                                style={{ color: '#89C4F4', cursor: 'pointer', marginRight: '30px' }} />
                                                        </Tooltip>

                                                        <Tooltip title='Obriši' placement='bottom' arrow>
                                                            <DeleteIcon
                                                                onClick={() => {
                                                                    setDeleteState({
                                                                        show: true,
                                                                        accObjectId: row.id,
                                                                        accObjectName: row.name
                                                                    });
                                                                }}
                                                                fontSize='medium'
                                                                style={{ color: '#DC3023', cursor: 'pointer' }} />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            :
                            <div style={{ marginTop: '10%' }}>
                                <Alert severity="error">
                                    <AlertTitle>Greška</AlertTitle>
                                    Neuspješno prikazivanje tabele : {accObjectsTable.message}
                                </Alert>
                            </div>
                    }
                </>
            )
        } else return null;
    };

    return (
        <>
            <div className={classes.myObjectsDiv}>
                <div className={classes.myObjectsFirstDiv}>

                    <Button
                        onClick={() => { switchActivePanel('addNewObject'); }}
                        fullWidth
                        variant='contained'
                        style={{ backgroundColor: '#26A65B', color: 'white' }}>
                        <AddIcon fontSize='medium' />
                        Dodaj novi objekat
                    </Button>

                    <TextField
                        disabled={!accObjectsTable?.success}
                        color='primary'
                        fullWidth
                        className={classes.searchField}
                        autoComplete='off'
                        required
                        variant='outlined'
                        size='small'
                        type='text'
                        placeholder='Pretraga'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type='submit' size='small'>
                                        <Search
                                            type='submit'
                                            className={classes.searchIcon}
                                            fontSize='small'
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }} />
                </div>
                {
                    loading
                        ?
                        <div className={classes.linearProgress}>
                            <Typography variant='h6'>Učitavanje objekata</Typography>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        <ShowTable />
                }
                <EditAccObjectDialog editState={editState} setEditState={setEditState} />
                <DeleteAccObjectDialog deleteState={deleteState} setDeleteState={setDeleteState} />
            </div>

        </>
    );

};

export default MyObjects