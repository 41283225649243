import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, TextField, ThemeProvider } from '@material-ui/core';
import { Grid, Typography, MenuItem, Select, InputAdornment, useMediaQuery, useTheme, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import CarCard from './CarCard';
import { getAllCarsAction } from '../actions/rent_a_car_actions';
import { AlertTitle, Alert } from '@material-ui/lab';
import backPhoto from '../assets/homePhotos/001.jpg';
import { Menu } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { format, parseISO } from 'date-fns';
import dropdown from '../assets/photos/dropdown.svg';
import { useTranslation } from 'react-i18next';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const locations = [
    'Aerodrom Podgorica',
    'Aerodrom Tivat',
    'Ulcinj',
    'Dobre Vode',
    'Bar',
    'Sutomore',
    'Čanj',
    'Buljarica',
    'Petrovac',
    'Reževići',
    'Sveti Stefan',
    'Rafailovići',
    'Bečići',
    'Budva',
    'Krimovica',
    'Radanovići',
    'Kotor',
    'Tivat',
    'Dobrota',
    'Perast',
    'Risan',
    'Bijela',
    'Kumbor',
    'Herceg Novi',
    'Igalo',
    'Cetinje',
    'Danilovgrad',
    'Nikšić',
    'Podgorica',
    'Andrijevica',
    'Berane',
    'Bijelo Polje',
    'Gusinje',
    'Kolašin',
    'Mojkovac',
    'Petnjica',
    'Plav',
    'Pljevlja',
    'Plužine',
    'Rožaje',
    'Šavnik',
    'Žabljak'];

const searchRentACarInitialData = {
    pickUpLocation: '',
    pickUpDate: null,
    dropOffLocation: '',
    dropOffDate: null
};

const RentACar = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [locationDialog, setLocationDialog] = React.useState(false);

    const [rentACarSearchData, setRentACarSearchData] = React.useState(searchRentACarInitialData);

    const [pickUpLocationAnchor, setPickUpLocationAnchor] = React.useState(null);
    const [dropOffLocationAnchor, setDropOffLocationAnchor] = React.useState(null);

    const [openPickUpDate, setOpenPickUpDate] = React.useState(false);
    const [openDropOffDate, setOpenDropOffDate] = React.useState(false);

    const getAllCarsSelector = useSelector(state => state.getAllCarsState);
    const { loading, allCarsData } = getAllCarsSelector;

    React.useEffect(() => {
        dispatch(getAllCarsAction());
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if (fsData) {
            setRentACarSearchData({
                ...rentACarSearchData,
                pickUpDate: fsData.arrivalDate,
                dropOffDate: fsData.departureDate
            });
        }
    }, []);

    const ShowError = () => {
        return <Alert severity="error" style={{ marginTop: '20px' }}>
            <AlertTitle>Greška</AlertTitle>
            Greška u prikazivanju automobila
        </Alert>
    };

    const handleReservationFormSubmit = (e) => {
        e.preventDefault();
    };

    const handleOpenPickUpLocationAnchor = (event) => {
        setPickUpLocationAnchor(event.currentTarget);
    }

    const handleClosePickUpLocationAnchor = () => {
        setPickUpLocationAnchor(null);
    };

    const handleOpenDropOffLocationAnchor = (event) => {
        setDropOffLocationAnchor(event.currentTarget);
    }

    const handleCloseDropOffLocationAnchor = () => {
        setDropOffLocationAnchor(null);
    };

    return (
        <>
            <div className={classes.navigation} style={{ backgroundImage: `url(${backPhoto}` }}>
                <div className={classes.innerContainer}>
                    {
                        !isMobile
                            ?
                            <form onSubmit={handleReservationFormSubmit}>

                                <div className={classes.datePickerDiv}>

                                    <div className={classes.pickerItem} onClick={handleOpenPickUpLocationAnchor}>
                                        <img
                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Preuzimanje</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    rentACarSearchData.pickUpLocation
                                                        ?
                                                        rentACarSearchData.pickUpLocation
                                                        :
                                                        'Izaberi lokaciju'
                                                }
                                            </Typography>
                                        </div>

                                    </div>

                                    <Menu
                                        className={classes.menu}
                                        anchorEl={pickUpLocationAnchor}
                                        open={Boolean(pickUpLocationAnchor)}
                                        onClose={handleClosePickUpLocationAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            locations.map((location, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setRentACarSearchData({
                                                                ...rentACarSearchData,
                                                                pickUpLocation: location
                                                            });
                                                            handleClosePickUpLocationAnchor();
                                                        }}
                                                        key={index}>
                                                        <Typography variant='subtitle2'>
                                                            {location}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>

                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <div
                                            onClick={() => {
                                                setOpenPickUpDate(open => !open);
                                            }}
                                            className={classes.pickerItem}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum preuzimanja</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        rentACarSearchData.pickUpDate
                                                            ?
                                                            typeof rentACarSearchData.pickUpDate === 'string'
                                                                ?
                                                                format(parseISO(rentACarSearchData.pickUpDate), 'dd/MM/yyyy')
                                                                :
                                                                format(rentACarSearchData.pickUpDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                style={{ display: 'none' }}
                                                open={openPickUpDate}
                                                required
                                                color='primary'
                                                disablePast
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                autoOk
                                                // variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                emptyLabel='Izaberi datum'
                                                value={rentACarSearchData.pickUpDate}
                                                onChange={(e) => {
                                                    setRentACarSearchData({
                                                        ...rentACarSearchData,
                                                        pickUpDate: e
                                                    });
                                                    setOpenPickUpDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenPickUpDate(false);
                                                }}
                                            // InputProps={{
                                            //   disableUnderline: true
                                            // }}
                                            />
                                        </ThemeProvider>

                                        <div className={classes.pickerItem} onClick={handleOpenDropOffLocationAnchor}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Vraćanje</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        rentACarSearchData.dropOffLocation
                                                            ?
                                                            rentACarSearchData.dropOffLocation
                                                            :
                                                            'Izaberi lokaciju'
                                                    }
                                                </Typography>
                                            </div>

                                        </div>
                                        <Menu
                                            className={classes.menu}
                                            anchorEl={dropOffLocationAnchor}
                                            open={Boolean(dropOffLocationAnchor)}
                                            onClose={handleCloseDropOffLocationAnchor}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        >
                                            {
                                                locations.map((location, index) => {
                                                    return (
                                                        <MenuItem
                                                            className={classes.menuItem}
                                                            onClick={() => {
                                                                setRentACarSearchData({
                                                                    ...rentACarSearchData,
                                                                    dropOffLocation: location
                                                                });
                                                                handleCloseDropOffLocationAnchor();
                                                            }}
                                                            key={index}>
                                                            <Typography variant='subtitle2'>
                                                                {location}
                                                            </Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Menu>

                                        <div
                                            onClick={() => {
                                                if (rentACarSearchData.pickUpDate) {
                                                    setOpenDropOffDate(open => !open);
                                                } else {
                                                    alert('Prvo izaberite datum preuzimanja');
                                                }
                                            }}
                                            className={classes.pickerItem}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum vraćanja</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        rentACarSearchData.dropOffDate
                                                            ?
                                                            typeof rentACarSearchData.dropOffDate === 'string'
                                                                ?
                                                                format(parseISO(rentACarSearchData.dropOffDate), 'dd/MM/yyyy')
                                                                :
                                                                format(rentACarSearchData.dropOffDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>

                                            </div>
                                        </div>

                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                open={openDropOffDate}
                                                style={{ display: 'none' }}
                                                disabled={rentACarSearchData.pickUpDate ? false : true}
                                                required={rentACarSearchData.pickUpDate ? true : false}
                                                minDate={rentACarSearchData.pickUpDate}
                                                disableToolbar
                                                disablePast
                                                format="dd/MM/yyyy"
                                                autoOk
                                                emptyLabel='Izaberi datum'
                                                //variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                value={rentACarSearchData.dropOffDate}
                                                onChange={(e) => {
                                                    setRentACarSearchData({
                                                        ...rentACarSearchData,
                                                        dropOffDate: e
                                                    });
                                                    setOpenDropOffDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDropOffDate(false);
                                                }}
                                            />
                                        </ThemeProvider>

                                    </MuiPickersUtilsProvider>

                                    <div className={classes.buttonSearch}>
                                        <Button
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation
                                            size='medium' >
                                            <SearchIcon fontSize='small' />
                                            {t("search_section_title")}
                                        </Button>
                                    </div>

                                </div>
                            </form>
                            :
                            <form onSubmit={handleReservationFormSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.mobileGridSection}>
                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <Grid item xs={6}>
                                            <div className={classes.mobileFormField} onClick={() => { setLocationDialog(true); }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Preuzimanje</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            rentACarSearchData.pickUpLocation
                                                                ?
                                                                rentACarSearchData.pickUpLocation
                                                                :
                                                                'Izaberi lokaciju'
                                                        }
                                                    </Typography>
                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={classes.mobileFormField}
                                                onClick={() => {
                                                    setOpenPickUpDate(open => !open);
                                                }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum preuzimanja</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            rentACarSearchData.pickUpDate
                                                                ?
                                                                typeof rentACarSearchData.pickUpDate === 'string'
                                                                    ?
                                                                    format(parseISO(rentACarSearchData.pickUpDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(rentACarSearchData.pickUpDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <DatePicker
                                                    style={{ display: 'none' }}
                                                    open={openPickUpDate}
                                                    required
                                                    color='primary'
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    disableToolbar
                                                    autoOk
                                                    variant='dialog'
                                                    emptyLabel='Izaberi datum'
                                                    value={rentACarSearchData.pickUpDate}
                                                    onChange={(e) => {
                                                        setRentACarSearchData({
                                                            ...rentACarSearchData,
                                                            pickUpDate: e
                                                        });
                                                        setOpenPickUpDate(false);
                                                    }}
                                                    onClose={() => {
                                                        setOpenPickUpDate(false);
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Grid>



                                        <Grid item xs={6}>
                                            <div className={classes.mobileFormField} onClick={() => { setLocationDialog(true); }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Vraćanje</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            rentACarSearchData.dropOffLocation
                                                                ?
                                                                rentACarSearchData.dropOffLocation
                                                                :
                                                                'Izaberi lokaciju'
                                                        }
                                                    </Typography>
                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                onClick={() => {
                                                    if (rentACarSearchData.pickUpDate) {
                                                        setOpenDropOffDate(open => !open);
                                                    } else {
                                                        alert('Prvo izaberite datum preuzimanja');
                                                    }
                                                }}
                                                className={classes.mobileFormField}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum vraćanja</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            rentACarSearchData.dropOffDate
                                                                ?
                                                                typeof rentACarSearchData.dropOffDate === 'string'
                                                                    ?
                                                                    format(parseISO(rentACarSearchData.dropOffDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(rentACarSearchData.dropOffDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <DatePicker
                                                open={openDropOffDate}
                                                style={{ display: 'none' }}
                                                disabled={rentACarSearchData.pickUpDate ? false : true}
                                                required={rentACarSearchData.pickUpDate ? true : false}
                                                minDate={rentACarSearchData.pickUpDate}
                                                disableToolbar
                                                disablePast
                                                format="dd/MM/yyyy"
                                                autoOk
                                                emptyLabel='Izaberi datum'
                                                //variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                value={rentACarSearchData.dropOffDate}
                                                onChange={(e) => {
                                                    setRentACarSearchData({
                                                        ...rentACarSearchData,
                                                        dropOffDate: e
                                                    });
                                                    setOpenDropOffDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDropOffDate(false);
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            size='medium' >
                                            <SearchIcon fontSize='large' />
                                            {t("search_section_title")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                    }
                </div>

            </div>

            <div className={classes.carsDiv}>
                {
                    loading
                        ?
                        <div>
                            <LinearProgress variant='indeterminate' />
                        </div>
                        :
                        typeof allCarsData !== 'undefined'
                            ?
                            allCarsData.success
                                ?
                                <div className={classes.carsList}>
                                    {
                                        allCarsData.cars.map((car, index) => {
                                            return <CarCard carData={car} key={index} />
                                        })
                                    }
                                </div>
                                :
                                <ShowError />
                            :
                            null
                }
                <Dialog
                    fullWidth
                    maxWidth='lg'
                    open={locationDialog}
                    onClose={() => { setLocationDialog(false); }}>
                    <DialogTitle>Lokacija</DialogTitle>
                    <DialogContent className={classes.locationDialogPicker}>
                        <div className={classes.singleLocation}>
                            <Typography variant='subtitle1' style={{ fontWeight: 'bolder' }}>
                                Preuzimanje
                            </Typography>
                            {
                                locations.map((location, index) => {
                                    return (
                                        <Typography
                                            onClick={() => {
                                                setRentACarSearchData({
                                                    ...rentACarSearchData,
                                                    pickUpLocation: location
                                                })
                                            }}
                                            style={{
                                                color: rentACarSearchData.pickUpLocation === location ? '#f79800' : 'black',
                                                fontWeight: rentACarSearchData.pickUpLocation === location ? 'bolder' : 'normal',
                                            }}
                                            className={classes.location}
                                            key={index}
                                            variant='subtitle2' >
                                            {location}
                                        </Typography>
                                    )
                                })
                            }
                        </div>
                        <div className={classes.singleLocation}>
                            <Typography variant='subtitle1' style={{ fontWeight: 'bolder' }}>
                                Vraćanje
                            </Typography>
                            {
                                locations.map((location, index) => {
                                    return (
                                        <Typography
                                            onClick={() => {
                                                setRentACarSearchData({
                                                    ...rentACarSearchData,
                                                    dropOffLocation: location
                                                })
                                            }}
                                            style={{
                                                color: rentACarSearchData.dropOffLocation === location ? '#f79800' : 'black',
                                                fontWeight: rentACarSearchData.dropOffLocation === location ? 'bolder' : 'normal',
                                            }}
                                            className={classes.location}
                                            key={index}
                                            variant='subtitle2'>
                                            {location}
                                        </Typography>
                                    )
                                })
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setLocationDialog(false); }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default RentACar;