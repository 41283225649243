import React from 'react';
import {
    Dialog,
    Toolbar,
    AppBar,
    Typography,
    Button,
    IconButton,
    DialogContent,
    Paper,
    Grid,
    TextField,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    Snackbar,
    LinearProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import { Slide } from '@material-ui/core';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { addNewAccUnitForExistingAccObjectAction, getAccObjectDataToEditAction } from '../actions/acc_objects_actions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const accUnitEquipment = [
    'Internet',
    'TV',
    'Kablovska TV',
    'Radni sto',
    'Klima uređaj',
    'Pogled na more',
    'Krevetac za bebe',
    'Balkon',
    'Pegla',
    'Fen za kosu',
    'Mašina za pranje veša',
    'Mašina za pranje posuđa',
    'Kuhinja',
    'Šporet',
    'Frižider'
];

const initialAccUnitData = {
    unitName: '',
    maxOccupancy: '',
    numOfRooms: '',
    area: '',
    unitPrice: '',
    unitEquipment: {
        internet: false,
        tv: false,
        cable_tv: false,
        workdesk: false,
        air_conditioner: false,
        seaview: false,
        baby_crib: false,
        balcony: false,
        iron: false,
        hair_dryer: false,
        washing_machine: false,
        dishwasher: false,
        kitchen: false,
        stove: false,
        fridge: false
    },
    photoGallery: [],
    otherPeriods: []
};

const certainPeriodInitialData = {
    periodFrom: null,
    periodTo: null,
    periodPrice: ''
};

const AddAccUnitDialog = ({
    show,
    setShow,
    accObjectData,
    setAccObjectData,
    editUnit,
    setEditUnit,
    addNewUnitState,
    setAddNewUnitState
}) => {


    const classes = useStyles();

    const dispatch = useDispatch();

    const [showSnackBar, setShowSnackBar] = React.useState(false);

    const [showSnackBarAccUnitInfo, setShowSnackBarAccUnitInfo] = React.useState({
        show: false,
        message: '',
        variant: 'info'
    });

    const [accUnitData, setAccUnitData] = React.useState(initialAccUnitData);

    const [imageGalleryDisplay, setImageGalleryDisplay] = React.useState([]);

    const [certainPeriod, setCertainPeriod] = React.useState(certainPeriodInitialData);

    const newAccUnitSelector = useSelector(state => state.newAccUnitState);
    const { loading, newAccUnitData } = newAccUnitSelector;

    const handleImageChange = (e) => {
        if (e.target.files) {
            setAccUnitData((prevData) => {
                return {
                    ...prevData,
                    photoGallery: prevData.photoGallery.concat([...e.target.files])
                }
            });
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setImageGalleryDisplay((prevImageGallery) => prevImageGallery.concat(filesArray));
        };
    };

    const handleRemoveImageFromGallery = (index) => {
        setAccUnitData((prevData) => {
            return {
                ...prevData,
                photoGallery: prevData.photoGallery.filter((photo, photoIndex) => photoIndex !== index)
            }
        });
        setImageGalleryDisplay((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    const handleClose = () => {
        if (addNewUnitState?.accObjectId) {
            setAddNewUnitState({
                show: false,
                accObjectId: null
            });
        } else {
            setShow(false);
            setEditUnit({
                editState: false,
                editUnitData: {},
                editUnitIndex: null
            });
        }
        setImageGalleryDisplay([]);
        setAccUnitData(initialAccUnitData);
    };

    const handleCloseSnackBarAccUnitInfo = () => {
        setShowSnackBarAccUnitInfo({
            show: false,
            message: '',
            variant: 'info'
        });
    };

    const handleCloseSnackBar = () => {
        setShowSnackBar(false);
        handleClose();
    };

    const handleAddNewPeriod = () => {
        const otherPeriodsUpdated = [...accUnitData.otherPeriods, certainPeriod];
        setAccUnitData({
            ...accUnitData,
            otherPeriods: otherPeriodsUpdated
        });
        setCertainPeriod(certainPeriodInitialData);
    };

    const handleRemoveCertainPeriod = (index) => {
        const otherPeriodsUpdated = [...accUnitData.otherPeriods].filter((period, indexPeriod) => indexPeriod !== index);
        setAccUnitData({
            ...accUnitData,
            otherPeriods: otherPeriodsUpdated
        });
    };

    const handleFormSubmitEdit = () => {
        const updatedAccObjectUnitsEdit = [...accObjectData.objectAccUnits].map((singleUnit, index) => {
            return index === editUnit.editUnitIndex ? accUnitData : singleUnit;
        });
        setAccObjectData((prevData) => {
            return {
                ...prevData,
                objectAccUnits: updatedAccObjectUnitsEdit
            };
        });
    };

    const handleAddNewAccUnitForExistingObject = () => {
        console.log('ADD NEW ACC UNIT FOR EXISTING OBJECT!');
        const formData = new FormData();
        formData.append('name', accUnitData.unitName);
        formData.append('max_occupancy', +accUnitData.maxOccupancy);
        formData.append('num_of_rooms', +accUnitData.numOfRooms);
        formData.append('area', +accUnitData.area);
        formData.append('price', +accUnitData.unitPrice);
        formData.append('acc_object', addNewUnitState.accObjectId)
        formData.append('accUnitOtherPeriods', JSON.stringify(accUnitData.otherPeriods));
        formData.append('accUnitEquipment', JSON.stringify(accUnitData.unitEquipment));
        accUnitData.photoGallery.forEach(photoFile => {
            formData.append('accUnitPhotos', photoFile);
        });
        dispatch(addNewAccUnitForExistingAccObjectAction(formData));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (addNewUnitState?.accObjectId) {
            handleAddNewAccUnitForExistingObject();
        } else {
            if (editUnit.editState) {
                handleFormSubmitEdit();
            } else {
                const updatedAccObjectUnits = [...accObjectData.objectAccUnits, accUnitData];
                setAccObjectData((prevData) => {
                    return {
                        ...prevData,
                        objectAccUnits: updatedAccObjectUnits
                    };
                });
            };
            setShowSnackBar(true);
            setImageGalleryDisplay([]);
            setAccUnitData(initialAccUnitData);
        }
    };

    const getKeyName = (index) => {
        return Object.keys(accUnitData.unitEquipment)[index];
    };

    const handleChange = (e) => {
        setAccUnitData({
            ...accUnitData,
            [e.target.name]: e.target.value
        });
    };

    React.useEffect(() => {
        if (editUnit.editState) {
            console.log('READY TO EDIT!');
            console.log(editUnit);
            setAccUnitData({ ...editUnit.editUnitData });
            const displayImages = editUnit.editUnitData.photoGallery.map((photo) => URL.createObjectURL(photo))
            setImageGalleryDisplay(displayImages);
        }
    }, [editUnit]);

    React.useEffect(() => {
        if (typeof newAccUnitData !== 'undefined') {
            if (!newAccUnitData.success) {
                setShowSnackBarAccUnitInfo({
                    show: true,
                    message: newAccUnitData.message,
                    variant: 'error'
                });
            } else {
                setShowSnackBarAccUnitInfo({
                    show: true,
                    message: 'Dodata nova smještajna jedinica',
                    variant: 'success'
                });
            }
        }
    }, [newAccUnitData]);

    return (
        <Dialog
            fullScreen
            open={show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#317589' }}>
                <Toolbar className={classes.accUnitToolbar}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CancelIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {addNewUnitState?.accObjectId ? 'Nova smještajna jedinica' : 'Smještajna jedinica'}
                        </Typography>
                    </div>
                    <div>
                        <Button
                            form="myform"
                            type='submit'
                            style={{ backgroundColor: '#26A65B', color: 'white' }}
                            variant='contained'>
                            {addNewUnitState?.accObjectId ? 'Dodaj' : 'Sačuvaj'}
                        </Button>
                    </div>
                </Toolbar>
                {
                    loading
                        ?
                        <div>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        null
                }
            </AppBar>
            <form id="myform" onSubmit={handleFormSubmit}>
                <div className={classes.addAccUnitSection}>
                    <Paper elevation={7} className={classes.addNewObject_section1}>
                        <div className={classes.sectionTitle}><Typography variant='h5'>Osnovni podaci</Typography></div>
                        <Grid container spacing={4} className={classes.section1Form}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="unitName"
                                    label="Naziv smjestajne jedinice"
                                    name="unitName"
                                    value={accUnitData.unitName}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                                <div className={classes.section1GalleryTitle}>
                                    <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color='primary'
                                    >
                                        <AddIcon fontSize='small' />
                                        <input
                                            onChange={handleImageChange}
                                            multiple
                                            accept='image/*'
                                            type="file"
                                            hidden
                                        />
                                    </Button>
                                </div>
                                <div className={classes.section1ImagePreviewDiv}>
                                    {
                                        imageGalleryDisplay.map((photo, index) => {
                                            return (
                                                <div key={index} style={{
                                                    textAlign: 'center',
                                                    position: 'relative',
                                                    width: '70%',
                                                    margin: 'auto'
                                                }}>
                                                    <img
                                                        src={photo}
                                                        alt={`Object Image ${index}`}
                                                        style={{ borderRadius: '10px', width: '100%' }} />
                                                    <div key={2} className={classes.editImage} >
                                                        <IconButton onClick={() => { handleRemoveImageFromGallery(index); }}>
                                                            <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>

                        </Grid>
                    </Paper>
                    <Paper elevation={7} className={classes.addNewObject_section1}>
                        <div className={classes.sectionTitle}><Typography variant='h5'>Ostali podaci</Typography></div>
                        <Grid container spacing={4} className={classes.section2MapDiv}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    required
                                    id="maxOccupancy"
                                    label="Maksimum osoba"
                                    name="maxOccupancy"
                                    type="number"
                                    value={accUnitData.maxOccupancy}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                // value={loginFormData.email}
                                // onChange={handleFormChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    required
                                    id="numOfRooms"
                                    label="Broj soba"
                                    name="numOfRooms"
                                    type="number"
                                    value={accUnitData.numOfRooms}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                // value={loginFormData.email}
                                // onChange={handleFormChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    required
                                    id="area"
                                    label="Površina"
                                    name="area"
                                    type="number"
                                    value={accUnitData.area}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                // value={loginFormData.email}
                                // onChange={handleFormChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={7} className={classes.addNewObject_section4}>
                        <div className={classes.sectionTitle}><Typography variant='h5'>Oprema smještajne jedinice</Typography></div>
                        <div className={classes.equipmentDiv}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <div className={classes.equipment}>
                                        {
                                            accUnitEquipment.map((item, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    accUnitData.unitEquipment[getKeyName(index)]
                                                                }
                                                                onChange={(e) => {
                                                                    const keyName = getKeyName(index);
                                                                    const updatedEquipment = {
                                                                        ...accUnitData.unitEquipment,
                                                                        [keyName]: e.target.checked
                                                                    };
                                                                    setAccUnitData((prevData) => {
                                                                        return {
                                                                            ...prevData,
                                                                            unitEquipment: updatedEquipment
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                        label={item}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </Paper>
                    <Paper elevation={7} className={classes.addNewObject_section4}>
                        <div className={classes.sectionTitle}><Typography variant='h5'>Cijena za određene periode</Typography></div>
                        <Grid container spacing={4} className={classes.section2MapDiv}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    required
                                    id="unitPrice"
                                    label="Cijena"
                                    name="unitPrice"
                                    type="number"
                                    value={accUnitData.unitPrice}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                // value={loginFormData.email}
                                // onChange={handleFormChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={12} style={{ margin: 'auto' }}>
                                <Accordion elevation={1}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreSharp />}>
                                        <Typography>Dodaj novi period</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} md={3}>
                                                    <DatePicker
                                                        required
                                                        color='secondary'
                                                        disablePast
                                                        format="dd/MM/yyyy"
                                                        disableToolbar
                                                        autoOk
                                                        variant='dialog'
                                                        label="Od"
                                                        helperText="Period od"
                                                        value={certainPeriod.periodFrom}
                                                        onChange={(e) => {
                                                            setCertainPeriod((certainPeriod) => {
                                                                return {
                                                                    ...certainPeriod,
                                                                    periodFrom: e
                                                                }
                                                            })
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <DateRangeTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <DatePicker
                                                        disabled={certainPeriod.periodFrom ? false : true}
                                                        required={certainPeriod.periodFrom ? true : false}
                                                        color='secondary'
                                                        minDate={certainPeriod.periodFrom}
                                                        disableToolbar
                                                        disablePast
                                                        format="dd/MM/yyyy"
                                                        autoOk
                                                        variant='dialog'
                                                        label="Do"
                                                        helperText="Period do"
                                                        value={certainPeriod.periodTo}
                                                        onChange={(e) => {
                                                            setCertainPeriod((certainPeriod) => {
                                                                return {
                                                                    ...certainPeriod,
                                                                    periodTo: e
                                                                }
                                                            })
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <DateRangeTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="numOfGrownups"
                                                        label="Cijena"
                                                        name="numOfGrownups"
                                                        type="number"
                                                        value={certainPeriod.periodPrice}
                                                        onChange={(e) => {
                                                            setCertainPeriod({
                                                                ...certainPeriod,
                                                                periodPrice: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Button onClick={handleAddNewPeriod} variant='contained'>
                                                        <AddIcon />
                                                        Dodaj
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <div className={classes.periodsDiv}>
                                    {
                                        accUnitData.otherPeriods.map((period, index) => {
                                            return (
                                                <div className={classes.singlePeriod} key={index} >
                                                    <Typography variant='subtitle1'>
                                                        Od: <strong>{format(period.periodFrom, 'dd/MM/yyyy')}</strong>
                                                    </Typography>
                                                    <Typography variant='subtitle1'>
                                                        Do: <strong>{format(period.periodTo, 'dd/MM/yyyy')}</strong>
                                                    </Typography>
                                                    <Typography variant='subtitle1'>
                                                        Cijena: <strong>{period.periodPrice} &euro;</strong>
                                                    </Typography>
                                                    <IconButton onClick={() => {
                                                        handleRemoveCertainPeriod(index);
                                                    }} style={{ color: '#DC3023' }}>
                                                        <CancelIcon />
                                                    </IconButton>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>


                        </Grid>
                    </Paper>
                </div>
            </form>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "left" }}
                open={showSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}>
                <Alert
                    variant='filled'
                    onClose={handleCloseSnackBar}
                    severity="success"
                    sx={{ width: '100%' }}>
                    Dodata nova smještajna jedinica! <br></br>
                    Bićete redirektovani za 2 sekunde
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                open={showSnackBarAccUnitInfo.show}
                autoHideDuration={3000}
                onClose={handleCloseSnackBarAccUnitInfo}>
                <Alert
                    variant='filled'
                    onClose={handleCloseSnackBarAccUnitInfo}
                    severity={showSnackBarAccUnitInfo.variant}
                    sx={{ width: '100%' }}>
                    {showSnackBarAccUnitInfo.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};


export default AddAccUnitDialog;