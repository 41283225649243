import {
    Button,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    Paper,
    MenuItem,
    Select,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    LinearProgress,
    AccordionSummary,
    AccordionDetails,
    Accordion
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { addNewTourAction } from '../actions/tours_actions';
import { useDispatch, useSelector } from 'react-redux';
import useStyle from './style';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import format from 'date-fns/format';

const newTourInitialData = {
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    type: '',
    max_capacity: '',
    duration: '',
    language: '',
    price: '',
    contact_only_by_phone: true,
    email_for_reservation: '',
    website: '',
    imageGallery: [],
    otherPeriods: [],
    phoneNumbers: [
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        },
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        },
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        }
    ]
};

const certainPeriodInitialData = {
    periodFrom: null,
    periodTo: null,
    periodPrice: ''
};

const tourTypes = ['Grupna tura', 'Privatna tura'];

const tourLanguages = ['Crnogorski',
    'Engleski',
    'Ruski',
    'Njemački',
    'Italijanski',
    'Francuski',
    'Albanski',
    'Mađarski',
    'Slovenski',
    'Rumunski',
    'Češki',
    'Švedski',
    'Poljski'
];

const phones = [1, 2, 3];

const AddNewTourAndExcursion = ({ switchActivePanel }) => {

    const classes = useStyle();
    const dispatch = useDispatch();

    const [tourData, setTourData] = React.useState(newTourInitialData);

    const [imageGalleryToBeDisplayed, setImageGalleryToBeDisplayed] = React.useState([]);

    const [certainPeriod, setCertainPeriod] = React.useState(certainPeriodInitialData);

    const addNewTourSelector = useSelector(state => state.addNewTourState);
    const { loading, newTour } = addNewTourSelector;

    const [mainPhotoIndex, setMainPhotoIndex] = React.useState(null);

    const handleImageChange = (e) => {
        if (e.target.files) {
            setTourData((prevData) => {
                return {
                    ...prevData,
                    imageGallery: prevData.imageGallery.concat([...e.target.files])
                }
            });
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const handleRemoveImageFromGallery = (index) => {
        setTourData((prevData) => {
            return {
                ...prevData,
                imageGallery: prevData.imageGallery.filter((photo, photoIndex) => photoIndex !== index)
            }
        });
        setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    function AddMarkerToClick() {

        useMapEvents({
            click(e) {
                setTourData({
                    ...tourData,
                    latitude: e.latlng.lat,
                    longitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[tourData.latitude, tourData.longitude]} />

    };

    const handleChange = (e) => {
        setTourData({
            ...tourData,
            [e.target.name]: e.target.value
        });
    };

    const handleAddNewTour = (e) => {
        e.preventDefault();
        const formData = new FormData();
        tourData.imageGallery.forEach(photo => {
            formData.append('tourPhoto', photo);
        });
        Object.keys(tourData).forEach(key => {
            if (key === 'phoneNumbers' || key === 'imageGallery' || key === 'otherPeriods') return;
            formData.append(key, tourData[key]);
        });
        formData.append('otherPeriods',JSON.stringify(tourData.otherPeriods));
        formData.append('phoneNumbers', JSON.stringify(tourData.phoneNumbers));
        formData.append('mainPhotoIndex', mainPhotoIndex);
        dispatch(addNewTourAction(formData));
    };

    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };

    const handleAddNewPeriod = () => {
        const otherPeriodsUpdated = [...tourData.otherPeriods, certainPeriod];
        setTourData({
            ...tourData,
            otherPeriods: otherPeriodsUpdated
        });
        setCertainPeriod(certainPeriodInitialData);
    };

    const handleRemoveCertainPeriod = (index) => {
        const otherPeriodsUpdated = [...tourData.otherPeriods].filter((period, indexPeriod) => indexPeriod !== index);
        setTourData({
            ...tourData,
            otherPeriods: otherPeriodsUpdated
        });
    };

    return (
        <div className={classes.addNewObjectDiv}>

            <div style={{ marginBottom: '25px' }}>
                <Typography
                    style={{ cursor: 'pointer' }}
                    variant='h5'
                    color='primary'
                    onClick={() => { switchActivePanel('myToursAndExcursions'); }}>
                    Odustani
                </Typography>
            </div>

            <form onSubmit={handleAddNewTour} className={classes.addNewObjectDiv}>

                <Paper elevation={7} className={classes.addNewObject_section1}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o turi</Typography></div>
                    <Grid container spacing={4} className={classes.section1Form}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                required
                                id="objectName"
                                label="Naziv ture"
                                name="name"
                                fullWidth
                                type="text"
                                value={tourData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel id="demo-simple-select-label">Tip ture</InputLabel>
                            <Select
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                name='type'
                                value={tourData.type}
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {
                                    tourTypes.map((tourType, index) => {
                                        return (
                                            <MenuItem value={index} key={index}>
                                                {tourType}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputLabel id="demo-simple-select-label">Jezik ture</InputLabel>
                            <Select
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                name='language'
                                value={tourData.language}
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {
                                    tourLanguages.map((language, index) => {
                                        return (
                                            <MenuItem value={index} key={index}>
                                                {language}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                required
                                id="objectName"
                                label="Cijena po osobi"
                                name="price"
                                fullWidth
                                type="text"
                                value={tourData.price}
                                onChange={handleChange}
                            />
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                required
                                id="objectName"
                                label="Max broj osoba"
                                name="max_capacity"
                                fullWidth
                                type="text"
                                value={tourData.max_capacity}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                required
                                id="objectName"
                                label="Trajanje ture"
                                name="duration"
                                fullWidth
                                type="text"
                                value={tourData.duration}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                            <div className={classes.section1GalleryTitle}  >
                                <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color='primary'
                                >
                                    <AddIcon fontSize='small' />
                                    <input
                                        id='dugme'
                                        type="file"
                                        onChange={handleImageChange}
                                        multiple
                                        accept='image/*'
                                        hidden
                                    />
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                    Izabrana glavna fotografija
                                </Typography>
                                {
                                    checkSelectedImage(tourData.imageGallery) !== -1
                                        ?
                                        <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                                        :
                                        <CloseIcon color='error' fontSize='large' />
                                }
                            </div>
                            <div className={classes.section1ImagePreviewDiv}>
                                {
                                    imageGalleryToBeDisplayed.map((photo, index) => {
                                        return (
                                            <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                <img
                                                    onClick={() => {
                                                        setMainPhotoIndex(index);
                                                        const gallery = tourData.imageGallery.map((photo, indexG) => {
                                                            if (index === indexG) {
                                                                photo.is_main = true;
                                                                return photo
                                                            } else {
                                                                photo.is_main = false;
                                                                return photo;
                                                            }
                                                        });
                                                        setTourData((prevData) => {
                                                            return {
                                                                ...prevData,
                                                                imageGallery: [...gallery]
                                                            }
                                                        });
                                                    }}
                                                    src={photo}
                                                    width={210}
                                                    alt={`Object ${index}`}
                                                    style={{
                                                        borderRadius: '10px',
                                                        cursor: 'pointer',
                                                        border: checkSelectedImage(tourData.imageGallery) === index ? '4px solid green' : '1px solid transparent'
                                                    }} />
                                                <div key={2} className={classes.editImage} >
                                                    <IconButton onClick={() => { handleRemoveImageFromGallery(index); }}>
                                                        <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>

                    </Grid>
                </Paper>

                <Paper elevation={7} className={classes.addNewObject_section2}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>

                            <MapContainer
                                center={[42.207232, 18.943100]}
                                zoom={16}
                                tap={false}
                                className={classes.map}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />

                                <AddMarkerToClick />
                            </MapContainer>

                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectLatitude"
                                label="Latituda"
                                name="latitude"
                                value={tourData.latitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectLongitude"
                                label="Longituda"
                                name="longitude"
                                value={tourData.longitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectAddress"
                                label="Adresa"
                                name="address"
                                value={tourData.address}
                                onChange={handleChange}
                                type="text"
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={7} className={classes.addNewObject_section4}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Cijena po periodima</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>

                        <Grid item xs={6} md={12} style={{ margin: 'auto' }}>
                            <Accordion elevation={1}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreSharp />}>
                                    <Typography>Dodaj novi period</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={3}>
                                                <DatePicker
                                                    required
                                                    color='secondary'
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    disableToolbar
                                                    autoOk
                                                    variant='dialog'
                                                    label="Od"
                                                    helperText="Period od"
                                                    value={certainPeriod.periodFrom}
                                                    onChange={(e) => {
                                                        setCertainPeriod((certainPeriod) => {
                                                            return {
                                                                ...certainPeriod,
                                                                periodFrom: e
                                                            }
                                                        })
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <DateRangeTwoToneIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <DatePicker
                                                    disabled={certainPeriod.periodFrom ? false : true}
                                                    required={certainPeriod.periodFrom ? true : false}
                                                    color='secondary'
                                                    minDate={certainPeriod.periodFrom}
                                                    disableToolbar
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    autoOk
                                                    variant='dialog'
                                                    label="Do"
                                                    helperText="Period do"
                                                    value={certainPeriod.periodTo}
                                                    onChange={(e) => {
                                                        setCertainPeriod((certainPeriod) => {
                                                            return {
                                                                ...certainPeriod,
                                                                periodTo: e
                                                            }
                                                        })
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <DateRangeTwoToneIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    id="numOfGrownups"
                                                    label="Cijena"
                                                    name="numOfGrownups"
                                                    type="number"
                                                    value={certainPeriod.periodPrice}
                                                    onChange={(e) => {
                                                        setCertainPeriod({
                                                            ...certainPeriod,
                                                            periodPrice: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Button onClick={handleAddNewPeriod} variant='contained'>
                                                    <AddIcon />
                                                    Dodaj
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className={classes.periodsDiv}>
                                {
                                    tourData.otherPeriods.map((period, index) => {
                                        return (
                                            <div className={classes.singlePeriod} key={index} >
                                                <Typography variant='subtitle1'>
                                                    Od: <strong>{format(period.periodFrom, 'dd/MM/yyyy')}</strong>
                                                </Typography>
                                                <Typography variant='subtitle1'>
                                                    Do: <strong>{format(period.periodTo, 'dd/MM/yyyy')}</strong>
                                                </Typography>
                                                <Typography variant='subtitle1'>
                                                    Cijena: <strong>{period.periodPrice} &euro;</strong>
                                                </Typography>
                                                <IconButton onClick={() => {
                                                    handleRemoveCertainPeriod(index);
                                                }} style={{ color: '#DC3023' }}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>


                    </Grid>
                </Paper>

                <Paper elevation={7} className={classes.addNewObject_section3}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tourData.contact_only_by_phone}
                                                onChange={(e) => {
                                                    setTourData({
                                                        ...tourData,
                                                        contact_only_by_phone: true
                                                    });
                                                }}
                                            />
                                        }
                                        label="Da"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!tourData.contact_only_by_phone}
                                                onChange={(e) => {
                                                    setTourData({
                                                        ...tourData,
                                                        contact_only_by_phone: false
                                                    });
                                                }}
                                            />
                                        }
                                        label="Ne"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {
                            phones.map((phone, index) => {
                                return (
                                    <Grid item xs={12} md={12} key={index}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            required={index === 0 && tourData.contact_only_by_phone ? true : false}
                                            id="numOfGrownups"
                                            label={`Broj telefona ${phone}`}
                                            name="phoneNum"
                                            type="text"
                                            value={tourData.phoneNumbers[index].phoneNumber}
                                            onChange={(e) => {
                                                setTourData((prevData) => {
                                                    prevData.phoneNumbers[index].phoneNumber = e.target.value;
                                                    return { ...prevData }
                                                });
                                            }}
                                        />
                                        <FormControl component="fieldset">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tourData.phoneNumbers[index].viber}
                                                            onChange={(e) => {
                                                                setTourData((prevData) => {
                                                                    prevData.phoneNumbers[index].viber = e.target.checked;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Viber"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tourData.phoneNumbers[index].whatsapp}
                                                            onChange={(e) => {
                                                                setTourData((prevData) => {
                                                                    prevData.phoneNumbers[index].whatsapp = e.target.checked;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Whatsapp"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="restaurantEmail"
                                label="Email adresa"
                                name="email_for_reservation"
                                type="text"
                                value={tourData.email_for_reservation}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantWebSite"
                                label="Link vaseg web sajta"
                                name="website"
                                type="text"
                                value={tourData.website}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                </Paper>

                <div>
                    {
                        loading
                            ?
                            <LinearProgress />
                            :
                            typeof newTour !== 'undefined'
                                ?
                                newTour.success
                                    ?
                                    <Alert severity="success" style={{ marginBottom: '20px' }}>
                                        <AlertTitle>Uspješno</AlertTitle>
                                        Nova tura dodata
                                    </Alert>
                                    :
                                    <Alert severity="error" style={{ marginBottom: '20px' }}>
                                        <AlertTitle>Greška</AlertTitle>
                                        Neuspješno kreiranje ture
                                    </Alert>
                                :
                                null
                    }
                </div>
                <div className={classes.addNewObjectButtons}>
                    <Button
                        type='submit'
                        style={{ backgroundColor: '#26A65B', color: 'white' }} >
                        Dodaj
                    </Button>
                    <Button
                        onClick={() => { switchActivePanel('myToursAndExcursions'); }}
                        variant='text'
                        style={{ color: '#DC3023' }}>
                        <CancelIcon />
                        Odustani
                    </Button>
                </div>

            </form>
        </div>
    );
};

export default AddNewTourAndExcursion;