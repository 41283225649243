import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    favoritesBigDiv: {
        //border: '2px solid red',
        width : '100%',
        height : '100vh',
        display : 'grid',
        gridTemplateColumns : '1fr 2fr',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns : '1fr',
        }
    },
    mapDiv: {
        //border: '2px solid blue',
        position : 'relative',
        height : '654px',
        position : 'sticky',
        top : '80px',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        }
    },
    mapLoading : {
        position : 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        transform: 'translateY(-50%)',
        zIndex : '4',
        textAlign : 'center'
    },
    map : { 
        height : '100%',
        zIndex: '2',
    },
    favorites: {
        //border: '2px solid green'
    },
    favoriteTypes : {
        //border : '2px solid purple',
        position: 'relative',
        height: '100px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        [theme.breakpoints.down('sm')]: {
            height : '150px'
        },
    },
    absoluteDiv : {
        //border : '3px solid red',
        position : 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        transform: 'translateY(-50%)',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexWrap : 'wrap',
            gap : '15px',
            justifyContent : 'center',
        },
        [theme.breakpoints.down('375')]: {
            display : 'grid',
            gridTemplateColumns : '1fr 1fr',
            width : '90%',
            margin : 'auto'
        },
    },
    button : {
        borderRadius : '0px',
        fontWeight : 'bolder',
        transition : '0.5s',
        '&:hover':{
            transform : 'scale(1.1)'
        }
    },
    favoriteList : {
        width : '90%',
        margin : 'auto',
        marginTop : '20px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        flexWrap : 'wrap',
        gap : '25px',
        [theme.breakpoints.down('1125')]: {
            //border : '6px solid green',
            //justifyContent : 'space-evenly',
        }
    },
}));

export default useStyles;