import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, Button, TextField, DialogTitle } from '@material-ui/core';
import useStyles from './style';


const SuperAdminEditUserDialogMobile = ({
    open,
    setOpen,
    handleChange,
    user,
    setUserMobileData,
    handleUpdateUser,
    setImageUserId,
    handleImageChange
}) => {

    const classes = useStyles();
    const inputRef = React.useRef();

    const handleClose = () => {
        setUserMobileData({});
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Edit korisnika</DialogTitle>

            <DialogContent>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            onChange={(e) => { handleChange(e, user.id) }}
                            label='Ime'
                            name='name'
                            value={user.name}
                            size='small'
                            variant='outlined' />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={(e) => { handleChange(e, user.id) }}
                            label='Prezime'
                            name='surname'
                            value={user.surname}
                            size='small'
                            variant='outlined' />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={(e) => { handleChange(e, user.id) }}
                            label='Email'
                            name='email'
                            value={user.email}
                            size='small'
                            variant='outlined' />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={(e) => { handleChange(e, user.id) }}
                            label='Telefon'
                            name='phone'
                            value={user.phone}
                            size='small'
                            variant='outlined' />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img
                            onClick={() => {
                                inputRef.current.click();
                                setImageUserId(user.id);
                            }}
                            className={classes.userAvatar}
                            src={user.avatar_originalname ? user.avatar : process.env.REACT_APP_UPLOADS_PATH + user.avatar}
                            width='170'
                            height='110'
                            alt='Avatar' />
                        <input
                            onChange={handleImageChange}
                            accept='image/*'
                            type="file"
                            hidden
                            ref={inputRef}
                        />
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button variant='contained' color='primary' onClick={() => { handleUpdateUser(user.id); }}>
                    OK
                </Button>
                <Button variant='contained' color='secondary' onClick={() => { handleClose(); }}>
                    Odustani
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default SuperAdminEditUserDialogMobile