export const initialState = {
    loading: false,
    currentDisplay: 0,
};

export const questionsReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_CURRENT_DISPLAY':
            return {
                ...state,
                currentDisplay : action.payload
            }
        default: return state;
    }
};