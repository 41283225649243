import React, {useContext} from 'react'
import useStyle from './style';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Typography, Card, CardMedia, CardContent, Tooltip, Button, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../App';
import { restaurantEquipmentData } from '../ShowRestaurant/restaurantEquipmentData';

const getTooltipStyling = makeStyles(theme => ({
    tooltip: {
      backgroundColor : '#0F79A1',
      color : 'white',
    },
    arrow : {
      color : '#0F79A1'
    }
  }));
  

const RestaurantCard = ({ data, isMobile }) => {

    const { currentLanguage } = useContext(GlobalContext);

    const classes = useStyle();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const toolTipStyle = getTooltipStyling();

    return (
        <Card
            className={classes.card}
            elevation={1}
            onClick={() => {
                navigate(`/showRestaurant/${data.id}`);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
        >
            <CardMedia
                style={{ cursor: 'pointer', filter: "brightness(63%)" }}
                component="img"
                height={isMobile ? '150' : '250'}
                src={ process.env.REACT_APP_UPLOADS_PATH + data.photo}
                alt="green iguana"
            />
            {
                <div className={classes.restaurantTypes}>
                    {
                        data.is_restaurant
                            ?
                            <div className={classes.type}>
                                <Typography variant='subtitle1'>Restoran</Typography>
                            </div>
                            :
                            null
                    }
                    {
                        data.is_cafe
                            ?
                            <div className={classes.type}>
                                <Typography variant='subtitle1'>Kafić</Typography>
                            </div>
                            :
                            null
                    }
                    {
                        data.is_club
                            ?
                            <div className={classes.type}>
                                <Typography variant='subtitle1'>Klub</Typography>
                            </div>
                            :
                            null
                    }
                </div>
            }
            {
                data.is_popular
                    ?
                    <div className={classes.isPopularMark}>
                        <Typography variant='subtitle1' style={{ color: '#0F79A1' }}>{t("card_popular_flag")}</Typography>
                    </div>
                    :
                    null
            }
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant={!isMobile ? 'h5' : 'body1'} component="div">
                    {data.name}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '8px' }}>
                    <LocationOnIcon fontSize='small' style={{ color: '#0F79A1' }} />
                    <Typography variant="body2">
                        {data.address}
                    </Typography>
                </div>

                {
                    data.equipment
                        ?
                        <div className={classes.eqDiv}>
                            {
                                restaurantEquipmentData[currentLanguage].map((item, index) => {
                                    const flag = data.equipment[item.equipmentName];
                                    if (flag) {
                                        return (
                                            <Tooltip
                                                arrow
                                                title={
                                                    <Typography variant='subtitle1'>
                                                        {item.nameToDisplay}
                                                    </Typography>
                                                }
                                                placement='bottom'
                                                key={index}
                                                classes={toolTipStyle}
                                            >
                                                <img width={18} src={item.icon} alt="Icon" style={{ marginRight: '14px', cursor: 'pointer' }} />
                                            </Tooltip>
                                        )
                                    }
                                })
                            }
                        </div>
                        :
                        null
                } 

            </CardContent>
        </Card>
    )
}

export default RestaurantCard