import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, TextField, ThemeProvider } from '@material-ui/core';
import { Grid, Typography, MenuItem, Select, InputAdornment, useTheme, useMediaQuery, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getAllCarsAction } from '../actions/rent_a_car_actions';
import { AlertTitle, Alert } from '@material-ui/lab';
import backPhoto from '../assets/homePhotos/001.jpg';
import { Menu } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { format, parseISO } from 'date-fns';
import dropdown from '../assets/photos/dropdown.svg';
import { useTranslation } from 'react-i18next';
import { getAllToursAction } from '../actions/tours_actions';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const tourLanguages = [
    'Svi jezici',
    'Crnogorski',
    'Engleski',
    'Ruski',
    'Njemački',
    'Italijanski',
    'Francuski',
    'Albanski',
    'Mađarski',
    'Slovenski',
    'Rumunski',
    'Češki',
    'Švedski',
    'Poljski'
];

const tourTypes = ['Svi tipovi', 'Grupna tura', 'Privatna tura'];

const searchDataInitial = {
    tourDate: null,
    tourType: '',
    tourLanguage: ''
};

const ToursAndExcursions = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [searchData, setSearchData] = React.useState(searchDataInitial);

    const [openLanguageDialog, setOpenLanguageDialog] = React.useState(false);

    const [openTourTypeDialog, setOpenTourTypeDialog] = React.useState(false);

    const getAllToursSelector = useSelector(state => state.getAllToursState);
    const { loading, allTours } = getAllToursSelector;

    const [openDate, setOpenDate] = React.useState(false);
    const [tourTypeAnchor, setTourTypeAnchor] = React.useState(null);
    const [languageAnchor, setLanguageAnchor] = React.useState(null);

    const handleOpenTourTypeAnchor = (event) => {
        setTourTypeAnchor(event.currentTarget);
    }

    const handleCloseTourTypeAnchor = () => {
        setTourTypeAnchor(null);
    };

    const handleOpenLanguageAnchor = (event) => {
        setLanguageAnchor(event.currentTarget);
    }

    const handleCloseLanguageAnchor = () => {
        setLanguageAnchor(null);
    };

    React.useEffect(() => {
        dispatch(getAllToursAction());
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if (fsData) {
            setSearchData({
                ...searchData,
                tourDate: fsData.tourDate
            });
        }
    }, []);

    const handleChange = (e) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value
        })
    };

    const ShowError = () => {
        return <Alert severity="error" style={{ marginTop: '20px' }}>
            <AlertTitle>Greška</AlertTitle>
            Greška u prikazivanju tura
        </Alert>
    };

    const handleReservationFormSubmit = (e) => {
        e.preventDefault();
    };


    return (
        <>
            <div className={classes.navigation} style={{ backgroundImage: `url(${backPhoto}` }}>
                <div className={classes.innerContainer}>
                    {
                        !isMobile
                            ?
                            <form onSubmit={handleReservationFormSubmit}>

                                <div className={classes.datePickerDiv}>

                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <div
                                            onClick={() => {
                                                setOpenDate(open => !open);
                                            }}
                                            className={classes.pickerItem}>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                            <div className={classes.pickerText}>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.tourDate
                                                            ?
                                                            typeof searchData.tourDate === 'string'
                                                                ?
                                                                format(parseISO(searchData.tourDate), 'dd/MM/yyyy')
                                                                :
                                                                format(searchData.tourDate, 'dd/MM/yyyy')
                                                            :
                                                            'Izaberi datum'
                                                    }
                                                </Typography>

                                            </div>
                                        </div>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DatePicker
                                                style={{ display: 'none' }}
                                                open={openDate}
                                                required
                                                color='primary'
                                                disablePast
                                                format="dd/MM/yyyy"
                                                disableToolbar
                                                autoOk
                                                // variant={isMobile ? 'dialog' : 'inline'}
                                                variant='dialog'
                                                emptyLabel='Izaberi datum'
                                                value={searchData.tourDate}
                                                onChange={(e) => {
                                                    setSearchData({
                                                        ...searchData,
                                                        tourDate: e
                                                    });
                                                    setOpenDate(false);
                                                }}
                                                onClose={() => {
                                                    setOpenDate(false);
                                                }}
                                            />
                                        </ThemeProvider>

                                    </MuiPickersUtilsProvider>

                                    <div className={classes.pickerItem} onClick={handleOpenTourTypeAnchor}>
                                        <img

                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Tip ture</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    searchData.tourType
                                                        ?
                                                        searchData.tourType
                                                        :
                                                        'Izaberi tip ture'
                                                }
                                            </Typography>
                                        </div>

                                    </div>

                                    <Menu
                                        style={{
                                            padding: '6px',
                                            height: '250px',
                                            marginTop: '13px',
                                        }}
                                        anchorEl={tourTypeAnchor}
                                        open={Boolean(tourTypeAnchor)}
                                        onClose={handleCloseTourTypeAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            tourTypes.map((type, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setSearchData({
                                                                ...searchData,
                                                                tourType: type
                                                            });
                                                            handleCloseTourTypeAnchor();
                                                        }}
                                                        key={index}>
                                                        <Typography variant='subtitle2'>
                                                            {type}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>

                                    <div className={classes.pickerItem} onClick={handleOpenLanguageAnchor}>
                                        <img

                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                        <div className={classes.pickerText}>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Jezik</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    searchData.tourLanguage
                                                        ?
                                                        searchData.tourLanguage
                                                        :
                                                        'Izaberi jezik'
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <Menu
                                        className={classes.menu}
                                        anchorEl={languageAnchor}
                                        open={Boolean(languageAnchor)}
                                        onClose={handleCloseLanguageAnchor}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        {
                                            tourLanguages.map((language, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            setSearchData({
                                                                ...searchData,
                                                                tourLanguage: language
                                                            });
                                                            handleCloseLanguageAnchor();
                                                        }}
                                                        key={index}>
                                                        <Typography variant='subtitle2'>
                                                            {language}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Menu>



                                    <div className={classes.buttonSearch}>
                                        <Button
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation
                                            size='medium' >
                                            <SearchIcon fontSize='small' />
                                            {t("search_section_title")}
                                        </Button>
                                    </div>

                                </div>
                            </form>
                            :
                            <form onSubmit={handleReservationFormSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.mobileGridSection}>
                                    <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                        <Grid item xs={12}>
                                            <div className={classes.mobileFormField}
                                                onClick={() => {
                                                    setOpenDate(open => !open);
                                                }}>
                                                <div>
                                                    <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Datum</strong></Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                        {
                                                            searchData.tourDate
                                                                ?
                                                                typeof searchData.tourDate === 'string'
                                                                    ?
                                                                    format(parseISO(searchData.tourDate), 'dd/MM/yyyy')
                                                                    :
                                                                    format(searchData.tourDate, 'dd/MM/yyyy')
                                                                :
                                                                t("home_form_choose_date")
                                                        }
                                                    </Typography>

                                                </div>
                                                <img
                                                    src={dropdown}
                                                    alt='Dropdown'
                                                    width={28}
                                                    className={classes.dropdownImg} />
                                            </div>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <DatePicker
                                                    style={{ display: 'none' }}
                                                    open={openDate}
                                                    required
                                                    color='primary'
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    disableToolbar
                                                    autoOk
                                                    variant='dialog'
                                                    emptyLabel='Izaberi datum'
                                                    value={searchData.tourDate}
                                                    onChange={(e) => {
                                                        setSearchData({
                                                            ...searchData,
                                                            tourDate: e
                                                        });
                                                        setOpenDate(false);
                                                    }}
                                                    onClose={() => {
                                                        setOpenDate(false);
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <Grid item xs={6}>
                                        <div className={classes.mobileFormField} onClick={() => { setOpenTourTypeDialog(true); }}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Tip ture</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.tourType
                                                            ?
                                                            searchData.tourType
                                                            :
                                                            'Izaberi tip ture'
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.mobileFormField} onClick={() => { setOpenLanguageDialog(true); }}>
                                            <div>
                                                <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>Jezik</strong></Typography>
                                                <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                    {
                                                        searchData.tourLanguage
                                                            ?
                                                            searchData.tourLanguage
                                                            :
                                                            'Izaberi jezik'
                                                    }
                                                </Typography>
                                            </div>
                                            <img
                                                src={dropdown}
                                                alt='Dropdown'
                                                width={28}
                                                className={classes.dropdownImg} />
                                        </div>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            style={{
                                                backgroundColor: '#f79800',
                                                color: 'white',
                                                borderRadius: '20px',
                                            }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            size='medium' >
                                            <SearchIcon fontSize='large' />
                                            {t("search_section_title")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                    }
                </div>
                <Dialog
                    fullWidth
                    open={openLanguageDialog}
                    onClose={() => { setOpenLanguageDialog(false); }}>
                    <DialogTitle>Jezik</DialogTitle>
                    <DialogContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px'
                    }}>
                        {
                            tourLanguages.map((language, index) => {
                                return (
                                    <div onClick={() => {
                                        setSearchData({
                                            ...searchData,
                                            tourLanguage: language
                                        });
                                        setOpenLanguageDialog(false);
                                    }}>
                                        <Typography

                                            style={{
                                                color: searchData.tourLanguage === language ? '#f79800' : 'black',
                                                fontWeight: searchData.tourLanguage === language ? 'bolder' : 'normal',
                                                cursor: 'pointer'
                                            }}
                                            variant='subtitle1'
                                            key={index}>
                                            {language}
                                        </Typography>
                                    </div>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenLanguageDialog(false); }}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    open={openTourTypeDialog}
                    onClose={() => { setOpenTourTypeDialog(false); }}>
                    <DialogTitle>Tip ture</DialogTitle>
                    <DialogContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px'
                    }}>
                        {
                            tourTypes.map((type, index) => {
                                return (
                                    <div onClick={() => {
                                        setSearchData({
                                            ...searchData,
                                            tourType: type
                                        });
                                        setOpenTourTypeDialog(false);
                                    }}>
                                        <Typography

                                            style={{
                                                color: searchData.tourType === type ? '#f79800' : 'black',
                                                fontWeight: searchData.tourType === type ? 'bolder' : 'normal',
                                                cursor: 'pointer'
                                            }}
                                            variant='subtitle1'
                                            key={index}>
                                            {type}
                                        </Typography>
                                    </div>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenTourTypeDialog(false); }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default ToursAndExcursions;