import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footerDiv: {
        //border: '2px solid red',
        marginTop: '50px',
        width: '100%',
        height: '400px',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '30% 35%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: 'inset 0 0 00 150vw rgba(0, 164, 249, 0.41)',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            
        }
    },
    infoDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: '1fr'
        }
    },
    infoNavigation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '10px',
        color: "white",
        "& > *": {
            cursor: 'pointer',
            fontWeight: '500'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '10px',
            width: '80%',
            marginLeft: '10px',
            marginTop : '10px',
            alignItems: 'start',
        }
    },
    blackDiv: {
        //border : '3px solid green',
        backgroundColor: 'black',
        height: '60px',
        display: 'grid',
        gridTemplateColumns: '15% 85%',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            height : '100px'
        }
    },
    allProjects: {
        //border : '2px solid red',
        display: 'flex',
        flexDirection: 'row',
        //justifyContent : 'center',
        //alignItems : 'flex-start',
        flexWrap: 'wrap',
        width: '360px',
        "& > *": {
            fontSize: '14px',
            marginRight: '10px',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
            width : '100%'
        }
    },
    navBarLanguagePicker: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //cursor: 'pointer',
    },
    copy: {
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            textAlign : 'center'
         }
    },
    copyText: {
        color: 'white',
        position: 'absolute',
        top: '30%',
        left: "20%",
        [theme.breakpoints.down('xs')]: {
           position : 'relative',
           top: '0',
           left: "0",
        }
    },
    mobileLanguagePicker : {
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gap : '10px'
    },
    menuMobileItem : {
        display : 'grid',
        gridTemplateColumns : '1fr 1fr',
        alignItems : 'center',
        borderRadius : '6px',
        cursor : 'pointer'  
    },
}));

export default useStyles;