import internet from '../assets/objects_equipment_photos/internet.png';
import baby_crib from '../assets/objects_equipment_photos/baby_crib.png';
import parking from '../assets/objects_equipment_photos/parking.png';
import pool from '../assets/objects_equipment_photos/pool.png';
import seaview from '../assets/objects_equipment_photos/seaview.png';
import disability_friendly from '../assets/objects_equipment_photos/wheelchair.png';
import restaurant from '../assets/objects_equipment_photos/restaurant.png';
import reception from '../assets/objects_equipment_photos/reception.png';
import air_conditioner from '../assets/objects_equipment_photos/air_conditioner.png';

export const accObjectEquipmentData = [
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Krevetac za bebu'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parking'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Bazen'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Pogled na more'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Prilagodljivo invalidima'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restoran'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Recepcija'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Klima'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Baby crib'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parking'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Pool'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Sea view'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Disability friendly'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restaurant'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Reception'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Air conditioner'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Интернет'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Детская кроватка'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Стоянка'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Бассейн'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Вид на море'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Адаптируется для инвалидов'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Ресторан'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Прием'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Кондиционер'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Babybett'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parken'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Schwimmbad'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Seeblick'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Anpassbar für Behinderte'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restaurant'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Rezeption'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Klimaanlage'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Culla'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parcheggio'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Piscina'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Vista sul mare'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Adattabile ai disabili'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Ristorante'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Ricezione'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Aria condizionata'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Lit bébé'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parking'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Piscine'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Vue sur la mer'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Adaptable aux handicapés'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restaurant'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Accueil'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Climat'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Shtrat i fëmijës'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parkim'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Pishine'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Pamje nga deti'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'E rregullueshme'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restorant'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Pritja'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Klima'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Gyermekágy'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parkolás'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Úszómedence'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Kilátás a tengerre'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Rugalmas'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Étterem'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Recepció'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Éghajlat'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Otroška posteljica'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parkirišče'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Bazen'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Pogled na morje'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Prilagodljivo za invalide'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restavracija'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Sprejem'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Klima'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Patut'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parcare'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Piscină'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Vedere la mare'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Flexibil'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restoran'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Recepţie'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Aer conditionat'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Dětská postýlka'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parkoviště'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Plavecký bazén'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Výhled na moře'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Flexibilní'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restaurace'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Recepce'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Podnebí'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Barnsäng'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parkering'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Simbassäng'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Havsutsikt'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Anpassningsbar för funktionshindrade'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restaurang'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Reception'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Luftkonditionering'
        }
    ],
    [
        {
            equipmentName: 'internet',
            icon: internet,
            nameToDisplay: 'Internet'
        },
        {
            equipmentName: 'baby_crib',
            icon: baby_crib,
            nameToDisplay: 'Łóżeczko dla dziecka'
        },
        {
            equipmentName: 'parking',
            icon: parking,
            nameToDisplay: 'Parking'
        },
        {
            equipmentName: 'pool',
            icon: pool,
            nameToDisplay: 'Basen'
        },
        {
            equipmentName: 'seaview',
            icon: seaview,
            nameToDisplay: 'Widok morza'
        },
        {
            equipmentName: 'disability_friendly',
            icon: disability_friendly,
            nameToDisplay: 'Elastyczne'
        },
        {
            equipmentName: 'restaurant',
            icon: restaurant,
            nameToDisplay: 'Restauracja'
        },
        {
            equipmentName: 'reception',
            icon: reception,
            nameToDisplay: 'Przyjęcie'
        },
        {
            equipmentName: 'air_conditioner',
            icon: air_conditioner,
            nameToDisplay: 'Klimatyzacja'
        }
    ]

]


