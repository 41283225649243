import {
    Typography,
    Toolbar,
    AppBar,
    Button,
    Avatar,
    Menu,
    MenuItem,
    Box,
    List,
    ListItem,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import { IconButton } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BusinessIcon from '@material-ui/icons/Business';
import HelpCenterIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CarIcon from '@material-ui/icons/DirectionsCar';
import MapIcon from '@material-ui/icons/Map';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const OwnerAdminNavBar = ({ switchActivePanel, setAnchorEl, handleLogout,loggedUserData,setLoggedUserData }) => {

    const classes = useStyles();

    const [drawerState, setDrawerState] = React.useState({ top: false });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const mobileMenu = () => (
        <Box sx={{ width: 290 }} >
            <ul style={{ listStyleType: 'none' }}>
                <li onClick={() => { switchActivePanel('myObjects'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <BusinessIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Moji objekti </Typography>
                </li>
                <li onClick={() => { switchActivePanel('myRestaurants'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <StorefrontIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Moji restorani </Typography>
                </li>
                <li onClick={() => { switchActivePanel('myCars'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <CarIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Rent A Car </Typography>
                </li>
                <li onClick={() => { switchActivePanel('myRestaurants'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <MapIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Ture I Ekskurzije </Typography>
                </li>
                <li onClick={() => { switchActivePanel('myRestaurants'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <CalendarTodayIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Događaji </Typography>
                </li>
                <li onClick={() => { switchActivePanel('contactQuestions'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <HelpCenterIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Upiti </Typography>
                </li>
                <li onClick={() => { switchActivePanel('settings'); setDrawerState({ top: false }); }} className={classes.listItem}>
                    <SettingsIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Podešavanja </Typography>
                </li>
                <li onClick={handleLogout} className={classes.listItem}>
                    <ExitToAppIcon fontSize='medium' style={{ marginRight: '7px' }} />
                    <Typography variant='body1'> Odjava </Typography>
                </li>
            </ul>
        </Box>
    );

    return (
        <>
            <AppBar position={isMobile ? 'sticky' : 'static'} style={{ backgroundColor: '#317589' }} elevation={8}>
                <Toolbar className={classes.adminToolbar}>
                    <div>
                        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                            Petrovac.me Admin Panel
                        </Typography>
                    </div>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }} className={classes.avatarBox}>
                        <Typography variant='body1'>{loggedUserData.name} {loggedUserData.surname}</Typography>
                        <IconButton disabled>
                            <Avatar
                                src={process.env.REACT_APP_UPLOADS_PATH + loggedUserData.avatar}
                                style={{ width: '55px', height: '55px' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <div className={classes.mobileMenu}>
                            <MenuIcon
                                fontSize='large'
                                style={{ color: 'white', cursor: 'pointer' }}
                                onClick={toggleDrawer('top', true)} />
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor='top'
                open={drawerState.top}
                onClose={toggleDrawer('top', false)}
            >
                {mobileMenu('top')}
            </Drawer>
        </>
    );
};

export default OwnerAdminNavBar