import React, { useContext } from 'react';
import InfoSection from '../InfoSection/InfoSection';
import ReservationSection from '../ReservationSection/ReservationSection';
import { Divider } from '@material-ui/core';
import RecommendedApartments from '../RecommendedApartments/RecommendedApartments';
import NavBar from '../NavBarMaterialUi/NavBar';
import { useMediaQuery, useTheme } from "@material-ui/core";
import ReservationForm from '../ReservationSection/ReservationForm';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';

const navTypes = ['/objects','/restaurants','/rent-a-car','/tours-and-excursions','/events','/favorites']

const HomePage = () => {

    const { currentPage } = useContext(GlobalContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('970'), { noSsr: true });

    React.useEffect(()=>{
        if(currentPage !== 0){
            navigate(navTypes[currentPage-1]);
        }
    },[])

    return (
        <>

            <ReservationSection />

            {
                isMobile
                    ?
                    <ReservationForm isMobile={isMobile} />
                    :
                    null
            }

            <RecommendedApartments
                cardHeight='340px'
                cardWidth='335px'
                imageHeight='180px'
                isMobile={isMobile}
                firstPage={true} />
        </>
    );
}

export default HomePage