import { Typography, Grid, Paper, LinearProgress, Button, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { GlobalContext } from '../App';
import useStyles from './style';
import ApartmentCard from '../ApartmentCard/ApartmentCard';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import ContactForm from '../Accommodation Object/ContactForm';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoritesAction, emptySendQuestionData } from '../actions/acc_objects_actions';
import { t } from 'i18next';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { navButtons } from '../NavBarMaterialUi/languages';
import backPhoto from '../assets/homePhotos/001.jpg';
import ObjectCard from '../AllObjects/ObjectCard';
import Location from '@material-ui/icons/LocationOn';

const Favorites = () => {

  const { favoriteAccObjects } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const [currentFavorite, setCurrentFavorite] = React.useState(0);

  const { currentLanguage } = useContext(GlobalContext);

  const favoritesSelector = useSelector(state => state.favoritesState);
  const { loading, favorites } = favoritesSelector;

  React.useEffect(() => {
    switch (currentFavorite) {
      case 0: dispatch(getFavoritesAction({ favoriteIds: favoriteAccObjects, type: 0 })); break;
      case 1: dispatch(getFavoritesAction({ favoriteIds: favoriteAccObjects, type: 1 })); break;
      case 2: dispatch(getFavoritesAction({ favoriteIds: favoriteAccObjects, type: 2 })); break;
      case 3: dispatch(getFavoritesAction({ favoriteIds: favoriteAccObjects, type: 3 })); break;
      case 4: dispatch(getFavoritesAction({ favoriteIds: favoriteAccObjects, type: 4 })); break;
      default: dispatch(getFavoritesAction({ favoriteIds: favoriteAccObjects, type: 0 })); break;
    }
  }, [currentFavorite,favoriteAccObjects]);

  const ShowFavoriteAccObjects = () => {
    return (
      <>
        {
          loading
            ?
            <ShowLoading />
            :
            typeof favorites !== 'undefined'
              ?
              favorites.success
                ?
                favorites.favorites.length > 0
                  ?
                  favorites.favorites.map((object, index) => {
                    return (
                      <ObjectCard
                        size='map'
                        cardHeight='285px'
                        cardWidth='205px'
                        imageHeight='135px'
                        key={index}
                        objectData={object} />
                    )
                  })
                  :
                  <Typography variant='h5'>Nemate omiljene objekte</Typography>
                :
                <ShowError />
              :
              null
        }
      </>
    )
  };

  const ShowFavoriteRestaurants = () => {
    return (
      <>
        {
          loading
            ?
            <ShowLoading />
            :
            typeof favorites !== 'undefined'
              ?
              favorites.success
                ?
                favorites.favorites.map((fav) => {
                  return (
                    <Typography> {fav} </Typography>
                  )
                })
                :
                <ShowError />
              :
              null
        }
      </>
    )
  };

  const ShowFavoriteCars = () => {
    return (
      <>
        {
          loading
            ?
            <ShowLoading />
            :
            typeof favorites !== 'undefined'
              ?
              favorites.success
                ?
                favorites.favorites.map((fav) => {
                  return (
                    <Typography> {fav} </Typography>
                  )
                })
                :
                <ShowError />
              :
              null
        }
      </>
    )
  };

  const ShowFavoriteTours = () => {
    return (
      <>
        {
          loading
            ?
            <ShowLoading />
            :
            typeof favorites !== 'undefined'
              ?
              favorites.success
                ?
                favorites.favorites.map((fav) => {
                  return (
                    <Typography> {fav} </Typography>
                  )
                })
                :
                <ShowError />
              :
              null
        }
      </>
    )
  };

  const ShowFavoriteEvents = () => {
    return (
      <>
        {
          loading
            ?
            <ShowLoading />
            :
            typeof favorites !== 'undefined'
              ?
              favorites.success
                ?
                favorites.favorites.map((fav) => {
                  return (
                    <Typography> {fav} </Typography>
                  )
                })
                :
                <ShowError />
              :
              null
        }
      </>
    )
  };

  const ShowLoading = () => {
    return (
      <div style={{ margin: '20px', width: '100%' }}>
        <Typography variant='subtitle2' color='primary'>Učitavanje...</Typography>
        <LinearProgress color='primary' />
      </div>
    );
  };

  const ShowError = () => {
    return (
      <Alert severity="error" s>
        <AlertTitle>Greška</AlertTitle>
        Neuspješno prikazivanje
      </Alert>
    )
  };

  const getFavorite = () => {
    switch (currentFavorite) {
      case 0: return <ShowFavoriteAccObjects />
      case 1: return <ShowFavoriteRestaurants />
      case 2: return <ShowFavoriteCars />
      case 3: return <ShowFavoriteTours />
      case 4: return <ShowFavoriteEvents />
      default: return <ShowFavoriteAccObjects />
    }
  };

  return (
    <div className={classes.favoritesBigDiv}>
      <div className={classes.mapDiv}>
        {
          loading
            ?
            <div className={classes.mapLoading}>
              <CircularProgress size={200} />
            </div>
            :
            null
        }
        <MapContainer
          center={[42.206169, 18.943187]}
          zoom={15}
          tap={false}
          className={classes.map}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            favorites?.favorites.map((favItem, index) => {
              return (
                <Marker
                  ref={(element) => {
                    //markerElements.current.push(element)
                  }}
                  eventHandlers={{
                    mouseover: (e) => {
                      e.target.openPopup();
                    },
                    mouseout: (e) => {
                      e.target.closePopup();
                    }
                  }}
                  key={index}
                  position={[favItem.latitude, favItem.longitude]}>
                  <Popup>
                    <img width='200' src={process.env.REACT_APP_UPLOADS_PATH + favItem.photo} style={{ borderRadius: '8px' }} />
                    <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {favItem.name}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Location fontSize='small' style={{ color: '#0ca6d8', marginRight: '5px' }} />
                      <Typography variant='subtitle2'>
                        {favItem.address}
                      </Typography>
                    </div>
                  </Popup>
                </Marker>
              )
            })
          }
        </MapContainer>
      </div>
      <div className={classes.favorites}>
        <div className={classes.favoriteTypes} style={{ backgroundImage: `url(${backPhoto}` }}>
          <div className={classes.absoluteDiv}>
            {
              navButtons[currentLanguage].slice(1).map((type, index) => {
                return (
                  <Button
                    onClick={() => { setCurrentFavorite(index) }}
                    disableElevation
                    key={index}
                    style={{
                      backgroundColor: currentFavorite === index ? '#f79800' : 'white',
                      color: currentFavorite === index ? 'white' : 'black'
                    }}
                    variant='contained'
                    className={classes.button}
                    size='small'>
                    {type}
                    <FavoriteIcon
                      style={{
                        marginLeft: '5px',
                        color: currentFavorite === index ? 'white' : '#ce0b0b'
                      }}
                      fontSize='small' />
                  </Button>
                )
              })
            }
          </div>
        </div>
        <div className={classes.favoriteList}>
          {
            getFavorite()
          }
        </div>
      </div>
    </div>
  );
}

export default Favorites;