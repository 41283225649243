import * as api from '../api/api_manager';

export const addNewAccObjectAction = (newAccObjectData) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_ADD_NEW_ACC_OBJECT'
        });

        const { data } = await api.addNewObject(newAccObjectData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_ACC_OBJECT',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new acc object: ', error.message);
        dispatch({
            type: 'ERROR_ADD_NEW_ACC_OBJECT',
            payload: error.response.data
        });
    }
};

export const getAccObjectsTableDataAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_ACC_OBJECTS_TABLE_DATA' });

        const { data } = await api.getObjectsTableData();

        dispatch({
            type: 'SUCCESS_GET_ACC_OBJECTS_TABLE_DATA',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting acc objects data for table : ', error.message);
        dispatch({
            type: "ERROR_GET_ACC_OBJECTS_TABLE_DATA",
            payload: error.response.data
        });
    }
};

export const getAccObjectDataToEditAction = (accObjectId) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_GET_ACC_OBJECT_TO_EDIT'
        });

        const { data } = await api.getAccObjectToEdit(accObjectId);

        dispatch({
            type: 'SUCCESS_GET_ACC_OBJECT_TO_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting acc object data to edit: ', error.message);
        dispatch({
            type: 'ERROR_GET_ACC_OBJECT_TO_EDIT',
            payload: error.response.data
        });
    }
};

export const updateAccObjectAction = (updateData, typeOfUpdate) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_ACC_OBJECT' });

        let payload;
        let response;

        switch (typeOfUpdate) {
            case 0:
                response = await api.updateOsnovniPodaci(updateData);
                payload = response.data;
                break;
            case 1:
                response = await api.updatePodaciOLokaciji(updateData);
                payload = response.data;
                break;
            case 2:
                response = await api.updateOstaliPodaci(updateData);
                payload = response.data;
                break;
            case 3:
                response = await api.updateOpremaObjekta(updateData);
                payload = response.data;
                break;
            case 4:
                response = await api.updateUslovniRezevacije(updateData);
                payload = response.data;
                break;
            default: return null;
        };

        dispatch({
            type: 'SUCCESS_UPDATE_ACC_OBJECT',
            payload
        });

    } catch (error) {
        console.log('Error in updating acc object: ', error.message);
        dispatch({
            type: 'ERROR_UPDATE_ACC_OBJECT',
            payload: error.response.data
        });
    }
};

export const emptyUpdateAccObjectState = () => async (dispatch) => {
    dispatch({ type: 'EMPTY_UPDATE_ACC_OBJECT' })
};

export const deleteAccObjectAction = (deleteData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_DELETE_ACC_OBJECT' });

        const { data } = await api.deleteAccObject(deleteData);

        dispatch({
            type: 'SUCCESS_DELETE_ACC_OBJECT',
            payload: data
        });

    } catch (error) {
        console.log('Error in deleting acc object: ', error.message);
        dispatch({
            type: 'ERROR_DELETE_ACC_OBJECT',
            payload: error.response.data
        });
    }
};

export const getAccUnitDataToEditAction = (accUnitId) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_GET_ACC_UNIT_TO_EDIT'
        });

        const { data } = await api.getAccUnitToEdit(accUnitId);

        dispatch({
            type: 'SUCCESS_GET_ACC_UNIT_TO_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting acc unit data to edit: ', error.message);
        dispatch({
            type: 'ERROR_GET_ACC_UNIT_TO_EDIT',
            payload: error.response.data
        });
    }
};

export const updateAccUnitAction = (updateData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_ACC_UNIT' });

        const { data } = await api.updateAccUnit(updateData);

        dispatch({
            type: 'SUCCESS_UPDATE_ACC_UNIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating acc unit: ', error.message);
        dispatch({
            type: "ERROR_UPDATE_ACC_UNIT",
            payload: error.response.data
        });
    }
};

export const addNewAccUnitForExistingAccObjectAction = (newAccUnitData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_ACC_UNIT_FOR_EXISTING_OBJECT' });

        const { data } = await api.addNewAccUnitForExistingAccObject(newAccUnitData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_ACC_UNIT_FOR_EXISTING_OBJECT',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new acc unit for existing acc object: ', error.message);
        dispatch({
            type: 'ERROR_ADD_NEW_ACC_UNIT_FOR_EXISTING_OBJECT',
            payload: error.response.data
        });
    }
};

export const deleteAccUnitAction = (deleteData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_DELETE_ACC_UNIT' });

        const { data } = await api.deleteAccUnit(deleteData);

        dispatch({
            type: 'SUCCESS_DELETE_ACC_UNIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in deleting acc UNIT: ', error.message);
        dispatch({
            type: 'ERROR_DELETE_ACC_UNIT',
            payload: error.response.data
        });
    }
};

export const superAdminGetAccObjectsAction = (page) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SUPER_ADMIN_GET_ACC_OBJECTS' });

        const { data } = await api.superAdminGetAccObjects(page);

        dispatch({
            type: 'SUCCESS_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting acc objects for super admin: ', error.message);
        dispatch({
            type: 'ERROR_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: error.response.data
        });
    }
};

export const superAdminChangeAccObjectStatusAction = (accObjData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_CHANGE_ACC_OBJECT_STATUS' });

        const { data } = await api.superAdminChangeAccObjectStatus(accObjData);

        dispatch({
            type: 'SUCCESS_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in changing acc object status: ', error.message);
        dispatch({
            type: 'ERROR_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: error.response.data
        });
    }
};

export const superAdminSearchAccObjectsAction = (searchQuery) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SUPER_ADMIN_GET_ACC_OBJECTS' });

        const { data } = await api.superAdminSearchAccObjects(searchQuery);

        dispatch({
            type: 'SUCCESS_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in changing acc object status: ', error.message);
        dispatch({
            type: 'ERROR_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: error.response.data
        });
    }
};

export const superAdminSearchAccObjectsByStatus = (status,type) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SUPER_ADMIN_GET_ACC_OBJECTS' });

        const { data } = await api.superAdminSearchAccObjectsByStatus(status,type);

        dispatch({
            type: 'SUCCESS_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in changing acc object status: ', error.message);
        dispatch({
            type: 'ERROR_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: error.response.data
        });
    }
};

export const superAdminSwitchAccObjectPopularityAction = (accObjData) => async (dispatch) => {
    try {

        //dispatch({ type: 'REQUEST_CHANGE_ACC_OBJECT_STATUS' });

        const { data } = await api.superAdminSwitchAccObjectPopularity(accObjData);

        dispatch({
            type: 'SUCCESS_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in changing acc object status: ', error.message);
        dispatch({
            type: 'ERROR_SUPER_ADMIN_GET_ACC_OBJECTS',
            payload: error.response.data
        });
    }
};

export const getPopularAccObjectsAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_POPULAR_ACC_OBJECTS' });

        const { data } = await api.getPopularAccObjects();

        dispatch({
            type: 'SUCCESS_GET_POPULAR_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting popular acc objects: ', error.message);
        dispatch({
            type: 'ERROR_GET_POPULAR_ACC_OBJECTS',
            payload: error.response.data
        })
    }
};

export const showAccObjectAction = (id) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SHOW_ACC_OBJECT' });

        const { data } = await api.showAccObject(id);

        dispatch({
            type: 'SUCCESS_SHOW_ACC_OBJECT',
            payload: data
        });

    } catch (error) {
        console.log('Error in showing acc objects: ', error.message);
        dispatch({
            type: 'ERROR_SHOW_ACC_OBJECT',
            payload: error.response.data
        })
    }
};

export const showAccUnitAction = (id) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SHOW_ACC_UNIT' });

        const { data } = await api.showAccUnit(id);

        dispatch({
            type: 'SUCCESS_SHOW_ACC_UNIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in showing acc units: ', error.message);
        dispatch({
            type: 'ERROR_SHOW_ACC_UNIT',
            payload: error.response.data
        })
    }
};

export const getFavoritesAction = (arrayOfIds) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_FAVORITES' });

        const { data } = await api.getFavorites(arrayOfIds);

        dispatch({
            type: 'SUCCESS_GET_FAVORITES',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting favorites', error.message);
        dispatch({
            type: 'ERROR_GET_FAVORITES',
            payload: error.response.data
        })
    }
};

export const sendQuestionAction = (questionData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SEND_QUESTION' });

        const { data } = await api.sendQuestion(questionData);

        dispatch({
            type: 'SUCCESS_SEND_QUESTION',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending question: ', error.message);
        dispatch({
            type: 'ERROR_SEND_QUESTION',
            payload: error.response.data
        })
    }
};

export const sendMultipleQuestionAction = (questionData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SEND_QUESTION' });

        const { data } = await api.sendMultipleQuestion(questionData);

        dispatch({
            type: 'SUCCESS_SEND_QUESTION',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending question: ', error.message);
        dispatch({
            type: 'ERROR_SEND_QUESTION',
            payload: error.response.data
        })
    }
};

export const emptySendQuestionData = () => (dispatch) => {
    dispatch({ type: 'EMPTY_SEND_QUESTION' })
};

export const getQuestionsForOwnerAction = (type) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_QUESTIONS_FOR_OWNER' });

        const { data } = await api.getQuestionsForOwner(type);

        dispatch({
            type: 'SUCCESS_GET_QUESTIONS_FOR_OWNER',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending question: ', error.message);
        dispatch({
            type: 'ERROR_GET_QUESTIONS_FOR_OWNER',
            payload: error.response.data
        })
    }
};

export const lookupAccObjectsAction = (searchWord) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_LOOKUP_ACC_OBJECTS' });

        const { data } = await api.lookupAccObjects(searchWord);

        dispatch({
            type: 'SUCCESS_LOOKUP_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending question: ', error.message);
        dispatch({
            type: 'ERROR_LOOKUP_ACC_OBJECTS',
            payload: error.response.data
        })
    }
};

export const getAccObjectsByFiltersAction = (filterData) => async (dispatch) => {
    try {

        const { data } = await api.getAccObjectsByFilters(filterData);

        dispatch({
            type: 'SUCCESS_LOOKUP_ACC_OBJECTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending question: ', error.message);
        dispatch({
            type: 'ERROR_LOOKUP_ACC_OBJECTS',
            payload: error.response.data
        })
    }
};

export const superAdminPreviewAccObjectAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_PREVIEW_ACC_OBJECT'
        });

        const { data } = await api.superAdminPreviewAccObject(id);

        dispatch({
            type: 'SUCCESS_PREVIEW_ACC_OBJECT',
            payload: data
        });

    } catch (error) {
        console.log('Error in previewing acc object: ', error.message);
        dispatch({
            type: 'ERROR_PREVIEW_ACC_OBJECT',
            payload: error.response.data
        });
    }
};

export const superAdminPreviewAccUnitPhotosAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_PREVIEW_ACC_UNIT_PHOTOS'
        });

        const { data } = await api.superAdminPreviewAccUnitPhotos(id);

        dispatch({
            type: 'SUCCESS_PREVIEW_ACC_UNIT_PHOTOS',
            payload: data
        });

    } catch (error) {
        console.log('Error in previewing acc unit photos: ', error.message);
        dispatch({
            type: 'ERROR_PREVIEW_ACC_UNIT_PHOTOS',
            payload: error.response.data
        });
    }
};

export const reservationDataDisplayAction = (type) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_RESERVATION_DISPLAY' });

        let payload;
        let response;

        switch (type) {
            case 0:
                response = await api.reservationAccObjectsDisplay();
                payload = response.data;
                break;
            case 1:
                response = await api.reservationRestaurantsDisplay();
                payload = response.data;
                break;
            default: return null;
        };

        dispatch({
            type: "SUCCESS_RESERVATION_DISPLAY",
            payload
        });

    } catch (error) {
        console.log("Error in getting data for reservation display: ", error.message);
        dispatch({
            type: "ERROR_RESERVATION_DISPLAY",
            payload: error.response.data
        });
    };
};