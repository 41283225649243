import { Typography, useTheme, useMediaQuery, Grid, LinearProgress } from '@material-ui/core';
import { AlertTitle, Alert } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';
import LocationIcon from '@material-ui/icons/LocationOnTwoTone';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApartmentCard from '../ApartmentCard/ApartmentCard';
import RestaurantCard from '../RestaurantCard/RestaurantCard';
import { reservationDataDisplayAction } from '../actions/acc_objects_actions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ReservationSearchSection = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const { searchType } = useParams();

    const reservationDataDisplaySelector = useSelector(state => state.reservationDataDisplayState);
    const { loading, reservationDisplayData: data } = reservationDataDisplaySelector;

    React.useEffect(() => {
        switch (+searchType) {
            case 0:
                dispatch(reservationDataDisplayAction(0));
                break;
            case 1:
                dispatch(reservationDataDisplayAction(1));
                break;
            case 2:
                console.log('display rent a car');
                break;
            case 3:
                console.log('display trips');
                break;
            case 4:
                console.log('display events');
                break;
            default: dispatch(reservationDataDisplayAction(0));
        }
    }, [searchType]);

    const switchDisplay = () => {
        switch (+searchType) {
            case 0:
            case 1:
                return (
                    <>
                        <DisplayMap />
                        <DisplayCards />
                    </>
                );
            default: return (
                <>
                    <DisplayMap />
                    <DisplayCards />
                </>
            )
        }
    };

    const switchDisplayCard = (data) => {
        switch (+searchType) {
            case 0:
                return <ApartmentCard isMobile={isMobile} data={data} />
            case 1:
                return <RestaurantCard isMobile={isMobile} data={data} />
            default: return <ApartmentCard isMobile={isMobile} data={data} />
        };
    };

    const DisplayCards = () => {
        return (
            <div className={classes.cardsSection}>
                <Grid container spacing={isMobile ? 2 : 3}>
                    {
                        data.result.map((cardData, index) => {
                            return (
                                <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
                                    {
                                        switchDisplayCard(cardData)
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        );
    };

    const DisplayMap = () => {
        return (
            <div className={classes.mapSection}>
                <MapContainer
                    center={[42.205185, 18.942815]}
                    zoom={15}
                    tap={false}
                    className={classes.map}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        data.result.map(reservationType => {
                            return (
                                <Marker
                                    eventHandlers={{
                                        mouseover: (e) => {
                                            e.target.openPopup();
                                        },
                                        mouseout: (e) => {
                                            e.target.closePopup();
                                        },
                                        click: (e) => {
                                            e.target.openPopup();
                                        }
                                    }}
                                    position={[reservationType.latitude, reservationType.longitude]} >
                                    <Popup >
                                        <img
                                            onClick={() => {
                                                navigate(`/object/${reservationType.id}`);
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}
                                            src={reservationType.photo}
                                            className={classes.mapImage} />
                                        <Typography variant='h6'>
                                            {reservationType.name}
                                        </Typography>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <LocationIcon fontSize='small' />
                                            <Typography variant='subtitle2'>
                                                {reservationType.address}
                                            </Typography>
                                        </div>
                                    </Popup>
                                </Marker>
                            )
                        })
                    }
                </MapContainer>
            </div>
        )
    };

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>{t("error")}</AlertTitle>
                Greška u prikazivanju
            </Alert>
        )
    };

    const ShowLoading = () => {
        return (
            <div style={{ margin: '20px' }}>
                <Typography variant='h6' style={{ color: '#0F79A1' }}>Učitavanje</Typography>
                <LinearProgress style={{ backgroundColor: '#0F79A1' }} />
            </div>
        );
    };

    return (
        <>
            {
                loading
                    ?
                    <ShowLoading />
                    :
                    typeof data !== 'undefined'
                        ?
                        data?.success
                            ?
                            <div className={classes.bigSection}>
                                {
                                    switchDisplay()
                                }
                            </div>
                            :
                            <ShowError />
                        :
                        null
            }
        </>
    );
};

export default ReservationSearchSection;