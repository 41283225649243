import React, { useContext } from 'react'
import useStyle from './style';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Typography, Card, CardMedia, CardContent, Tooltip, makeStyles } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { GlobalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { useTranslation } from 'react-i18next';
import { accObjectEquipmentData } from '../Accommodation Object/acc_object_equipment_data';

const getTooltipStyling = makeStyles(theme => ({
  tooltip: {
    backgroundColor : '#0F79A1',
    color : 'white',
  },
  arrow : {
    color : '#0F79A1'
  }
}));

const ApartmentCard = ({ data, fav, searchSection, isMobile }) => {

  const classes = useStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toolTipStyle = getTooltipStyling();

  const { favoriteAccObjects, setFavoriteAccObjects, currentLanguage } = useContext(GlobalContext);

  const handleAddToFavorites = (e) => {
    e.stopPropagation();
  };

  const checkFavorite = (id) => {
    return favoriteAccObjects.find(favId => favId === id) ? true : false;
  };

  const getPrice = (priceType, price) => {
    switch (priceType) {
      case 'per_night':
        return (
          <>
            <Typography variant={!isMobile ? 'body1' : 'subtitle1'} style={{ color: "#ce0b0b" }}>
              <strong>&euro;{price}</strong>&nbsp;
            </Typography>
            <Typography variant={!isMobile ? 'body1' : 'subtitle1'} >
              /&nbsp;{t("acc_object_page_per_night")}
            </Typography>
          </>
        )
      case 'per_person':
        return (
          <>
            <Typography variant={!isMobile ? 'body1' : 'subtitle1'} style={{ color: "#ce0b0b" }}>
              <strong>&euro;{price}</strong>&nbsp;
            </Typography>
            <Typography variant={!isMobile ? 'body1' : 'subtitle1'} >
              /&nbsp;{t("acc_object_page_per_person")}
            </Typography>
          </>
        )
      case 'on_request':
        return (
          <Typography variant={!isMobile ? 'body1' : 'subtitle1'}>
            {t("acc_object_page_on_request")}
          </Typography>
        )
      default: return (
        <Typography variant={!isMobile ? 'body1' : 'subtitle1'}>
          {t("acc_object_page_on_request")}
        </Typography>
      )
    }
  };

  return (
    <Card
      className={classes.card}
      elevation={1}
      onClick={() => {
        navigate(`/object/${data.id}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <CardMedia
        style={{ cursor: 'pointer' }}
        component="img"
        height={isMobile ? '175' : '230'}
        src={data.photo ? process.env.REACT_APP_UPLOADS_PATH + data.photo : noPhotoAvailable}
        alt="Accommodation"
      />
      {
        data.is_popular
          ?
          <div className={classes.isPopularMark}>
            <Typography variant='subtitle1' style={{ color: '#0F79A1' }}>{t("card_popular_flag")}</Typography>
          </div>
          :
          null
      }
      <div onClick={handleAddToFavorites} className={classes.addToFavoritesIcon}>
        {
          checkFavorite(data.id)
            ?
            <Tooltip title="Ukloni iz želja">
              <FavoriteIcon
                onClick={() => {
                  const updatedFav = favoriteAccObjects.filter(favId => favId !== data.id);
                  setFavoriteAccObjects([...updatedFav]);
                  localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                }}
                fontSize='medium'
                style={{ cursor: 'pointer', color: '#ce0b0b' }} />
            </Tooltip>
            :
            <Tooltip title="Dodaj u želje">
              <FavoriteBorderIcon
                onClick={() => {
                  const updatedFav = [...favoriteAccObjects, data.id];
                  setFavoriteAccObjects([...updatedFav]);
                  localStorage.setItem('favoriteAccObjects', JSON.stringify(updatedFav));
                }}
                fontSize='medium'
                style={{ cursor: 'pointer', color: '#ce0b0b' }} />
            </Tooltip>
        }
      </div>
      <CardContent className={classes.cardContent}  >
        <Typography gutterBottom variant={!isMobile ? 'h5' : 'body1'} noWrap>
          {
            !isMobile
              ?
              data.name
              :
              <strong>{data.name}</strong>
          }
        </Typography>
        {
          !isMobile
            ?
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '8px' }}>
              <LocationOnIcon fontSize='small' style={{ color: '#0F79A1' }} />
              <Typography variant='body2'>
                {data.address}
              </Typography>
            </div>
            :
            null
        }
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          {getPrice(data.type_of_price, data.minimal_price_from)}
        </div>

        {
          data.equipment
            ?
            <div className={classes.eqDiv}>
              {
                accObjectEquipmentData[currentLanguage].map((item, index) => {
                  const flag = data.equipment[item.equipmentName];
                  if (flag) {
                    return (
                      <Tooltip 
                        arrow  
                        title={
                          <Typography variant='subtitle1'>
                            {item.nameToDisplay}
                          </Typography>
                        }
                        placement='bottom' 
                        key={index} 
                        classes={toolTipStyle}
                        >
                        <img width={18} src={item.icon} alt="Icon" style={{ marginRight: '14px', cursor : 'pointer' }} />
                      </Tooltip>
                    )
                  }
                })
              }
            </div>
            :
            null
        }

      </CardContent>
    </Card>
  )
}

export default ApartmentCard