import React from 'react';
import useStyles from './style';
import { Card, CardMedia, CardContent, Typography, CardActions, Button, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import noPhotoAvailable from '../assets/photos/no-photo-available.png';
import { useContext } from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { GlobalContext } from '../App';
import carDoor from '../assets/carDataPhotos/carDoor.png';
import carShift from '../assets/carDataPhotos/carShift.png';
import carFuel from '../assets/carDataPhotos/carFuel.png';
import carYear from '../assets/carDataPhotos/carYear.png';
import carEngine from '../assets/carDataPhotos/carEngine.png';
import carSeat from '../assets/carDataPhotos/carSeat.png';
import { useNavigate } from 'react-router-dom';

const CarCard = ({ carData }) => {

    const classes = useStyles();

    const { favoriteCars, setFavoriteCars } = useContext(GlobalContext);

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const checkFavorite = (id) => {
        return favoriteCars.find(favId => favId === id) ? true : false;
    };

    const FavoritesIconSection = ({ id }) => {
        return <div className={classes.addToFavoritesIcon}>
            {
                checkFavorite(id)
                    ?
                    <Tooltip title="Ukloni iz želja">
                        <FavoriteIcon
                            onClick={() => {
                                const updatedFav = favoriteCars.filter(favId => favId !== id);
                                setFavoriteCars([...updatedFav]);
                                localStorage.setItem('favoriteCars', JSON.stringify(updatedFav));
                            }}
                            fontSize='medium'
                            style={{ cursor: 'pointer', color: '#ce0b0b' }} />
                    </Tooltip>
                    :
                    <Tooltip title="Dodaj u želje">
                        <FavoriteBorderIcon
                            onClick={() => {
                                const updatedFav = [...favoriteCars, id];
                                setFavoriteCars([...updatedFav]);
                                localStorage.setItem('favoriteCars', JSON.stringify(updatedFav));
                            }}
                            fontSize='medium'
                            style={{ cursor: 'pointer', color: '#ce0b0b' }} />
                    </Tooltip>
            }
        </div>
    };

    return (
        <div className={classes.carCard}>

            <div 
                onClick={()=>{ navigate(`/car/${carData.id}`);window.scrollTo({top: 0, behavior: 'smooth'}); }}
                className={classes.carCardImage} 
                style={{ backgroundImage: carData.photo ? `url(${process.env.REACT_APP_UPLOADS_PATH + carData.photo})` : noPhotoAvailable }}>

            </div>

            <div className={classes.carCardBody}>

                <div className={classes.carCardTitle}>
                    <Typography style={{ fontSize: '19px', fontWeight: '900' }}>
                        {carData.manufacturer} {carData.model} - {carData.engine}
                    </Typography>
                </div>

                <div className={classes.carCardData}>

                    <div className={classes.carCardDataSingle}>
                        <img style={{ filter: 'invert(27%) sepia(96%) saturate(439%) hue-rotate(166deg) brightness(93%) contrast(89%)' }} src={carShift} width='20' />
                        <Typography variant='subtitle2' style={{  fontSize: '13px' }}> {carData.transmission_type} </Typography>
                    </div>

                    <div className={classes.carCardDataSingle}>
                        <img style={{ filter: 'invert(27%) sepia(96%) saturate(439%) hue-rotate(166deg) brightness(93%) contrast(89%)' }} src={carFuel} width='20' />
                        <Typography variant='subtitle2' style={{  fontSize: '13px' }}>{carData.fuel} </Typography>
                    </div>

                    <div className={classes.carCardDataSingle}>
                        <img style={{ filter: 'invert(27%) sepia(96%) saturate(439%) hue-rotate(166deg) brightness(93%) contrast(89%)' }} src={carSeat} width='20' />
                        <Typography variant='subtitle2' style={{  fontSize: '13px' }}>{carData.num_of_seats} sjedišta </Typography>
                    </div>

                    <div className={classes.carCardDataSingle}>
                        <img style={{ filter: 'invert(27%) sepia(96%) saturate(439%) hue-rotate(166deg) brightness(93%) contrast(89%)' }} src={carDoor} width='20' />
                        <Typography variant='subtitle2' style={{  fontSize: '13px' }}>{carData.num_of_doors} vrata</Typography>
                    </div>

                    <div className={classes.carCardDataSingle}>
                        <img style={{ filter: 'invert(27%) sepia(96%) saturate(439%) hue-rotate(166deg) brightness(93%) contrast(89%)' }} src={carYear} width='20' />
                        <Typography variant='subtitle2' style={{  fontSize: '13px' }}>{carData.year} </Typography>
                    </div>

                    <div className={classes.carCardDataSingle}>
                        <img style={{ filter: 'invert(27%) sepia(96%) saturate(439%) hue-rotate(166deg) brightness(93%) contrast(89%)' }} src={carEngine} width='20' />
                        <Typography variant='subtitle2' style={{  fontSize: '13px' }}>{carData.cubic_capacity} cm3</Typography>
                    </div>

                </div>

                <div className={classes.carCardPriceAndButton}>

                    <div className={classes.carCardPrice}>

                        <div className={classes.carCardPriceFrom}>
                            <Typography style={{ color: '#a1a1a1', fontWeight: 'bold', fontSize: '13px' }}>
                                CIJENA OD
                            </Typography>
                        </div>

                        <div className={classes.carCardPriceInfo}>
                            <div>
                                <Typography style={{ color: "#0ca6d8", fontSize: '28px', fontWeight: '900' }}>
                                    290,00
                                </Typography>
                            </div>
                            <div style={{ marginLeft: '5px' }}>
                                <div>
                                    <Typography style={{ color: '#f8ac33', fontSize: '12px', fontWeight: 'bold', marginBottom: '-4px' }}>EUR</Typography>
                                </div>
                                <div>
                                    <Typography style={{ color: '#a1a1a1', fontSize: '12px' }}>po danu</Typography>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={classes.carCardReserveButton}>
                        <Button
                            size='large'
                            style={{
                                color: '#f8ac33',
                                fontWeight: 'bold',
                                borderRadius: '50px',
                                boxShadow: "rgba(0, 0, 0, 0) 0px 3px 6px, rgba(0, 0, 0, 0.13) 0px 3px 6px"
                            }}>
                            Rezerviši
                        </Button>
                    </div>

                </div>

            </div>

            <FavoritesIconSection id={carData.id} />

            {
                carData.is_popular
                    ?
                    <div className={classes.isPopularMark}>
                        <Typography style={{ color: 'white', fontWeight : 'bold', fontSize : '12px' }}>POPULARNO</Typography>
                    </div>
                    :
                    null
            }

        </div>
    );
};

export default CarCard;