import * as api from '../api/api_manager';

export const addNewTourAction = (tourData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_TOUR' });

        const { data } = await api.addNewTour(tourData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_TOUR',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new tour: ', error);
        dispatch({
            type: 'ERROR_ADD_NEW_TOUR',
            payload: error.response.data
        })
    }
};

export const getToursTableAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_TOURS_TABLE' });

        const { data } = await api.getToursTable();

        dispatch({
            type: 'SUCCESS_GET_TOURS_TABLE',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting tours table: ', error);
        dispatch({
            type: 'SUCCESS_GET_TOURS_TABLE',
            payload: error.response.data
        })
    }
};

export const getTourDataForEditAction = (tourId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_TOUR_DATA_FOR_EDIT' });

        const { data } = await api.getTourDataForEdit(tourId);

        dispatch({
            type: 'SUCCESS_GET_TOUR_DATA_FOR_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting tour data for edit: ', error);
        dispatch({
            type: 'SUCCESS_GET_TOUR_DATA_FOR_EDIT',
            payload: error.response.data
        })
    }
};

export const updateTourAction = (tourData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_TOUR' });

        const { data } = await api.updateTour(tourData);

        dispatch({
            type: 'SUCCESS_UPDATE_TOUR',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating tour: ', error);
        dispatch({
            type: 'ERROR_UPDATE_TOUR',
            payload: error.response.data
        })
    }
};

export const deleteTourAction = (tourData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_DELETE_TOUR' });

        const { data } = await api.deleteTour(tourData);

        dispatch({
            type: 'SUCCESS_DELETE_TOUR',
            payload: data
        });

    } catch (error) {
        console.log('Error in deleting tour: ', error.message);
        dispatch({
            type: 'ERROR_DELETE_TOUR',
            payload: error.response.data
        })
    }
};

export const previewTourAction = (tourId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_PREVIEW_TOUR' });

        const { data } = await api.previewTour(tourId);

        dispatch({
            type: 'SUCCESS_PREVIEW_TOUR',
            payload: data
        });

    } catch (error) {
        console.log('Error in previewing tour: ', error.message);
        dispatch({
            type: 'ERROR_PREVIEW_TOUR',
            payload: error.response.data
        })
    }
};

export const getAllToursAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_ALL_TOURS' });

        const { data } = await api.getAllTours();

        dispatch({
            type: 'SUCCESS_GET_ALL_TOURS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting all tours: ', error.message);
        dispatch({
            type: 'ERROR_GET_ALL_TOURS',
            payload: error.response.data
        })
    }
};