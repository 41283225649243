import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    navBar: {
        //border : '1px solid red',
        backgroundColor: 'transparent',
        // top: '0',
        // zIndex: '100',
        zIndex: '1000',
        width: '100%',
        padding: '7px',
        transition: 'background-color 0.6s ease 0s',
        [theme.breakpoints.down('xs')]: {
            padding: '11px',
        }
    },
    navBarSolid: {
        backgroundColor: 'white',
        // top: '0',
        zIndex: '100',
        width: '100%',
        padding: '7px',
        transition: 'background-color 0.6s ease 0s',
        [theme.breakpoints.down('xs')]: {
            padding: '11px',
        }
    },
    logo: {
        width: '270px',
        //border : '3px solid red',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '55px',
            marginLeft: '-12px'
        }
    },
    toolbar: {
        //border : '3px solid green',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    navBarRight: {
        //border: '4px solid blue',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '70vw',
        //marginRight: '5px',
        // border : '1px solid red',
        [theme.breakpoints.down('xs')]: {
            //border : '3px solid red',
            marginRight: '0px',
            width: '0%',
        }
    },
    navButton: {
        marginRight: '15px',
        borderRadius: '0',
        //transition: "transform 1s ease-in-out",
        //borderBottom : '2px solid gold'
        
    },
    navBarLanguagePicker: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    searchIcon: {
        cursor: 'pointer'
    },
    searchField: {
        //border : '3px solid red',
        color: 'gray',
        "& .MuiOutlinedInput-root": {
            backgroundColor: 'white',
            padding: '6px',
            borderRadius: '18px',
            // "&.Mui-focused fieldset": {
            //   borderColor: '#0F79A1',
            //   color : 'gray'
            // }
        }
    },
    mobileLanguagePicker : {
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gap : '10px'
    },
    menuMobileItem : {
        display : 'grid',
        gridTemplateColumns : '1fr 1fr',
        alignItems : 'center',
        borderRadius : '6px',
        cursor : 'pointer'  
    },
    list : {
        paddingTop : '0px'
    },
    field: {

    },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderBottom: '1px solid #F2F1EF',
    },
    mobileMenu: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: '8px',
        marginRight: '-12px'
    },
    favoritesButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // width: '12%',
        cursor: 'pointer',
        marginRight: '10px',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        transition: "transform .5s ease-in-out",
    },
    favoritesButtonInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    navBarCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '25vw',
        // flex: '1',
        // maxWidth: 'fit-content',
        //border : '1px solid black',
        [theme.breakpoints.down('xs')]: {
            width: '52vw',
        }
    },
}));

export default useStyles;