import React, { createContext } from 'react';
import './i18next';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import HomePage from './HomePage/HomePage';
import OwnerAdminPanel from './Owner Admin Panel/OwnerAdminPanel';
import WithoutNavBar from './WithoutNavBar';
import WithNavBar from './WithNavBar';
import Register from './Register/Register';
import Login from './Login/Login';
import EmailVerification from './EmailVerification/EmailVerification';
import CheckLoginRoute from './CheckLoginRoute';
import { useSelector } from 'react-redux';
import SuperAdminPanel from './Owner Admin Panel/SuperAdminPanel';
import Favorites from './Favorites/Favorites';
import AllObjects from './AllObjects/AllObjects';
import Restaurants from './Restaurants/Restaurants';
import ShowRestaurant from './ShowRestaurant/ShowRestaurant';
import Events from './Events Petrovac/Events';
import RentACar from './Rent A Car/RentACar';
import ToursAndExcursions from './Tours And Excursions/ToursAndExcursions';
import ReservationSearchSection from './ReservationSearchSection/ReservationSearchSection';
import ShowAccObject from './Accommodation Object/ShowAccObject';
import ShowCar from './Show Car/ShowCar';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ForgotPassword from './Forgot Password/ForgotPassword';
import ResetPassword from './Forgot Password/ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import SuperAdminProtected from './SuperAdminProtected';


const THEME = createTheme({
    typography: {
        "fontFamily": "'Open Sans', sans-serif",
    }
});

export const GlobalContext = createContext();

const App = () => {

    const [loggedUserData, setLoggedUserData] = React.useState(JSON.parse(localStorage.getItem('loggedUserData')) || {});

    const [favoriteAccObjects, setFavoriteAccObjects] = React.useState(JSON.parse(localStorage.getItem('favoriteAccObjects')) || []);

    const [favoriteCars, setFavoriteCars] = React.useState(JSON.parse(localStorage.getItem('favoriteCars')) || []);

    const [favoriteTours, setFavoriteTours] = React.useState(JSON.parse(localStorage.getItem('favoriteTours')) || []);

    const [currentLanguage, setCurrentLanguage] = React.useState(JSON.parse(localStorage.getItem('currentLanguage')) || 0);

    const [currentPage, setCurrentPage] = React.useState(JSON.parse(localStorage.getItem('currentPage')) || 0);

    return (
        <MuiThemeProvider theme={THEME}>

            <BrowserRouter>

                <GlobalContext.Provider value={{
                    loggedUserData,
                    setLoggedUserData,
                    favoriteAccObjects,
                    setFavoriteAccObjects,
                    currentLanguage,
                    setCurrentLanguage,
                    favoriteCars,
                    setFavoriteCars,
                    favoriteTours,
                    setFavoriteTours,
                    currentPage,
                    setCurrentPage
                }}>

                    <Routes>

                        <Route element={<WithNavBar />}>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/object/:accObjectId' element={<ShowAccObject />} />
                            <Route path='/car/:carId' element={<ShowCar />} />
                            <Route path='/favorites' element={<Favorites />} />
                            <Route path='/objects' element={<AllObjects />} />
                            <Route path='/restaurants' element={<Restaurants />} />
                            <Route path='/rent-a-car' element={<RentACar />} />
                            <Route path='/tours-and-excursions' element={<ToursAndExcursions />} />
                            <Route path='/events' element={<Events />} />
                            <Route path='/showRestaurant/:restaurantId' element={<ShowRestaurant />} />
                            <Route path='/reservationSearchSection/:searchType' element={<ReservationSearchSection />} />
                        </Route>

                        <Route element={<SuperAdminProtected/>}>
                            <Route element={<SuperAdminPanel/>} path='/superAdminPanel' />
                        </Route>

                        <Route element={<ProtectedRoute/>}>
                            <Route element={<OwnerAdminPanel/>} path='/ownerAdminPanel' />
                        </Route>

                        <Route element={<CheckLoginRoute/>}>
                            <Route element={<Login/>} path='/login' />
                        </Route>

                        <Route path='/register' element={<Register />} />

                        <Route path='/forgot-password' element={<ForgotPassword />} />

                        <Route path='/password-reset' element={<ResetPassword />} />

                        <Route path='/email-verification/:token' element={<EmailVerification />} />

                    </Routes>

                </GlobalContext.Provider>

            </BrowserRouter>

        </MuiThemeProvider>
    );
};

export default App;

