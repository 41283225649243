import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        //border : '2px solid red',
        margin: 'auto',
        width: '96%',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            width : '100%',
            marginTop : '0px'
        }
    },
    mainSection: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '15px'
    },
    firstSection: {
        //border : '2px solid black'
    },
    secondSection: {
        //border : '2px solid black'
    },
    objectInfo: {
        //border : '2px solid blue',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    titleRatingAddress: {
        //border : '2px solid green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    titleRating: {
        //border : '2px solid purple',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {

    },
    rating: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    address: {
        //border : '2px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    priceInfo: {
        //border : '2px solid orange'
    },
    shareSaveDiv: {
        //border : '5px solid red',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '58px'
        //height : '100%'
    },
    shareSave: {
        //border : '2px solid orangered',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '46%'
    },
    shareSaveParts: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    objectCardPrice: {
        display: 'flex',
        flexDirection: "column",
    },
    objectCardPriceFrom: {
        marginBottom: '-3px'
    },
    objectCardPriceInfo: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        //border : '1px solid red',
        height: '45px'
    },
    gallerySection: {
        //border : '2px solid red'
    },
    gallerySectionFirstPart: {
        //border : '2px solid green',
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: "10px"
    },
    gallerySectionBigPhoto: {
        //border : '2px solid blue',
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        '&:hover': {
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
        cursor: 'pointer'
    },
    gallerySectionTwoPhotos: {
        //border : '2px solid pink',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '10px'
    },
    gallerySectionTwoPhotosPhoto: {
        height: '200px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        '&:hover': {
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
        cursor: 'pointer'
    },
    gallerySectionSecondPart: {
        //border : '2px solid green',
        display: 'grid',
        gridTemplateColumns: 'repeat(5,1fr)',
        gap: '10px',
        marginTop: '10px'
    },
    gallerySectionSecondPartPhoto: {
        position: 'relative',
        height: '110px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        '&:hover': {
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        },
        transition: "all .5s ease-in-out",
        cursor: 'pointer',
        "&:nth-child(5)": {
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.6)',
        },
    },
    viewAllPhotos: {
        position: 'absolute',
        fontWeight: 'bolder',
        textAlign: 'center',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    contactDataDiv: {
        //border : '3px solid red',
        marginTop: '20px',
    },
    sectionTitleDiv: {
        borderLeft: '5px solid #f79800',
        marginBottom: '20px',
        marginTop: '20px'
    },
    sectionTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#0ca6d8',
        textTransform: 'uppercase',
        marginLeft: '10px',
    },
    ownerPhotoAndNameMain: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '15px'
    },
    ownerPhoneData: {


    },
    phoneNumber: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px'
    },
    equipmentDiv: {
        //border : '2px solid blue',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',

    },
    equipmentItemDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f7f9fb',
        border: '1px solid #cccccc',
        width: '110px',
        height: '90px',
        gap: '10px'
    },
    accUnits: {
        //border : '2px solid red',
        display: 'grid',
        gridTemplateColumns: '1fr',
        //gap : '10px'
    },
    unit: {
        //border : '1px solid blue',
        display: 'grid',
        gridTemplateColumns: '1fr 3fr 1fr',
        gap: '10px',
        cursor : 'pointer',
        width: '95%',
        "&:nth-child(1)": {
            border: '1px solid #cccccc',
            borderBottom : 'none'
        },
        "&:nth-child(n+2)": {
            border: '1px solid #cccccc',
            //borderTop : 'none',
            borderBottom : 'none'
        },
        "&:last-child": {
            borderBottom: '1px solid #cccccc'
        },
        transition : '0.5s',
        '&:hover' : {
            //transform: 'scale(1.001)',
            border : '1px solid transparent',
            boxShadow: '#245d8d 0px 0px 10px, #245d8d 0px 1px 2px'
        }
    },
    accUnitPhoto: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: '210px',
        height: '170px',
        margin: '10px 0px 10px 10px'
    },
    accUnitInfo: {
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems  :'left',
        gap : '20px'
    },
    accUnitButton: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '10px',
        marginRight: '10px'
    },
    unitInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    infoAndMap : {
        marginTop : '25px'
    },
    map : {
        borderRadius: '20px',
        height: '430px',
        zIndex: '2',
    },
    mapInfo : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        width : '80%',
        margin : 'auto',
        marginBottom : '10px'
    },
    mapSingleInfo : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center'
    },
    mapSingleInfoText : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'left'
    }
}));

export default useStyles;