import {
    Button,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    Paper,
    MenuItem,
    Select,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    LinearProgress
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { addnewEventAction } from '../actions/tours_actions';
import { useDispatch, useSelector } from 'react-redux';
import useStyle from './style';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import format from 'date-fns/format';
import { addNewEventAction } from '../actions/events_actions';


const newEventInitialData = {
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    type: '',
    date: null,
    time : '',
    price: '',
    contact_only_by_phone: true,
    email_for_reservation: '',
    website: '',
    imageGallery: [],
    phoneNumbers: [
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        },
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        },
        {
            phoneNumber: '',
            viber: false,
            whatsapp: false
        }
    ]
};

const eventTypes = ['Muzika', 'Sport', 'Kultura'];

const phones = [1, 2, 3];

const AddNewEvent = ({ switchActivePanel }) => {

    const classes = useStyle();
    const dispatch = useDispatch();

    const [eventData, setEventData] = React.useState(newEventInitialData);

    const [imageGalleryToBeDisplayed, setImageGalleryToBeDisplayed] = React.useState([]);

    const addNewEventSelector = useSelector(state => state.addNewEventState);
    const { loading, newEvent } = addNewEventSelector;

    const [mainPhotoIndex, setMainPhotoIndex] = React.useState(null);

    const handleImageChange = (e) => {
        if (e.target.files) {
            setEventData((prevData) => {
                return {
                    ...prevData,
                    imageGallery: prevData.imageGallery.concat([...e.target.files])
                }
            });
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const handleRemoveImageFromGallery = (index) => {
        setEventData((prevData) => {
            return {
                ...prevData,
                imageGallery: prevData.imageGallery.filter((photo, photoIndex) => photoIndex !== index)
            }
        });
        setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    function AddMarkerToClick() {

        useMapEvents({
            click(e) {
                setEventData({
                    ...eventData,
                    latitude: e.latlng.lat,
                    longitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[eventData.latitude, eventData.longitude]} />

    };

    const handleChange = (e) => {
        setEventData({
            ...eventData,
            [e.target.name]: e.target.value
        });
    };

    const handleAddNewEvent = (e) => {
        e.preventDefault();
        const formData = new FormData();
        eventData.imageGallery.forEach(photo => {
            formData.append('eventPhoto', photo);
        });
        Object.keys(eventData).forEach(key => {
            if (key === 'phoneNumbers' || key === 'imageGallery') return;
            formData.append(key, eventData[key]);
        });
        formData.append('phoneNumbers', JSON.stringify(eventData.phoneNumbers));
        formData.append('mainPhotoIndex', mainPhotoIndex);
        dispatch(addNewEventAction(formData));
    };

    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };

    return (
        <div className={classes.addNewObjectDiv}>

            <div style={{ marginBottom: '25px' }}>
                <Typography
                    style={{ cursor: 'pointer' }}
                    variant='h5'
                    color='primary'
                    onClick={() => { switchActivePanel('myEvents'); }}>
                    Odustani
                </Typography>
            </div>

            <form onSubmit={handleAddNewEvent} className={classes.addNewObjectDiv}>

                <Paper elevation={7} className={classes.addNewObject_section1}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o događaju</Typography></div>
                    <Grid container spacing={4} className={classes.section1Form}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                required
                                label="Naziv događaja"
                                name="name"
                                fullWidth
                                type="text"
                                value={eventData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Cijena po osobi"
                                name="price"
                                value={eventData.price}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                    <DatePicker
                                        required
                                        inputVariant='outlined'
                                        fullWidth
                                        color='secondary'
                                        disablePast
                                        format="dd/MM/yyyy"
                                        disableToolbar
                                        autoOk
                                        variant='dialog'
                                        label="Datum"
                                        value={eventData.date}
                                        onChange={(e) => {
                                            setEventData((oldData) => {
                                                return {
                                                    ...oldData,
                                                    date: e
                                                }
                                            })
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <DateRangeTwoToneIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <InputLabel id="demo">Vrijeme</InputLabel>
                            <TextField
                            labelId="demo"
                                variant="standard"
                                required
                                //label="Vrijeme"
                                name="time"
                                fullWidth
                                type="time"
                                value={eventData.time}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel id="demo-simple-select-label">Tip</InputLabel>
                            <Select
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                name='type'
                                value={eventData.type}
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {
                                    eventTypes.map((eventType, index) => {
                                        return (
                                            <MenuItem value={index} key={index}>
                                                {eventType}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>


                        <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                            <div className={classes.section1GalleryTitle}  >
                                <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color='primary'
                                >
                                    <AddIcon fontSize='small' />
                                    <input
                                        id='dugme'
                                        type="file"
                                        onChange={handleImageChange}
                                        multiple
                                        accept='image/*'
                                        hidden
                                    />
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                    Izabrana glavna fotografija
                                </Typography>
                                {
                                    checkSelectedImage(eventData.imageGallery) !== -1
                                        ?
                                        <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                                        :
                                        <CloseIcon color='error' fontSize='large' />
                                }
                            </div>
                            <div className={classes.section1ImagePreviewDiv}>
                                {
                                    imageGalleryToBeDisplayed.map((photo, index) => {
                                        return (
                                            <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                <img
                                                    onClick={() => {
                                                        setMainPhotoIndex(index);
                                                        const gallery = eventData.imageGallery.map((photo, indexG) => {
                                                            if (index === indexG) {
                                                                photo.is_main = true;
                                                                return photo
                                                            } else {
                                                                photo.is_main = false;
                                                                return photo;
                                                            }
                                                        });
                                                        setEventData((prevData) => {
                                                            return {
                                                                ...prevData,
                                                                imageGallery: [...gallery]
                                                            }
                                                        });
                                                    }}
                                                    src={photo}
                                                    width={210}
                                                    alt={`Object ${index}`}
                                                    style={{
                                                        borderRadius: '10px',
                                                        cursor: 'pointer',
                                                        border: checkSelectedImage(eventData.imageGallery) === index ? '4px solid green' : '1px solid transparent'
                                                    }} />
                                                <div key={2} className={classes.editImage} >
                                                    <IconButton onClick={() => { handleRemoveImageFromGallery(index); }}>
                                                        <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>

                    </Grid>
                </Paper>

                <Paper elevation={7} className={classes.addNewObject_section2}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>

                            <MapContainer
                                center={[42.207232, 18.943100]}
                                zoom={16}
                                tap={false}
                                className={classes.map}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />

                                <AddMarkerToClick />
                            </MapContainer>

                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectLatitude"
                                label="Latituda"
                                name="latitude"
                                value={eventData.latitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectLongitude"
                                label="Longituda"
                                name="longitude"
                                value={eventData.longitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectAddress"
                                label="Adresa"
                                name="address"
                                value={eventData.address}
                                onChange={handleChange}
                                type="text"
                            />
                        </Grid>
                    </Grid>
                </Paper>


                <Paper elevation={7} className={classes.addNewObject_section3}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={eventData.contact_only_by_phone}
                                                onChange={(e) => {
                                                    setEventData({
                                                        ...eventData,
                                                        contact_only_by_phone: true
                                                    });
                                                }}
                                            />
                                        }
                                        label="Da"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!eventData.contact_only_by_phone}
                                                onChange={(e) => {
                                                    setEventData({
                                                        ...eventData,
                                                        contact_only_by_phone: false
                                                    });
                                                }}
                                            />
                                        }
                                        label="Ne"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {
                            phones.map((phone, index) => {
                                return (
                                    <Grid item xs={12} md={12} key={index}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            required={index === 0 && eventData.contact_only_by_phone ? true : false}
                                            id="numOfGrownups"
                                            label={`Broj telefona ${phone}`}
                                            name="phoneNum"
                                            type="text"
                                            value={eventData.phoneNumbers[index].phoneNumber}
                                            onChange={(e) => {
                                                setEventData((prevData) => {
                                                    prevData.phoneNumbers[index].phoneNumber = e.target.value;
                                                    return { ...prevData }
                                                });
                                            }}
                                        />
                                        <FormControl component="fieldset">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={eventData.phoneNumbers[index].viber}
                                                            onChange={(e) => {
                                                                setEventData((prevData) => {
                                                                    prevData.phoneNumbers[index].viber = e.target.checked;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Viber"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={eventData.phoneNumbers[index].whatsapp}
                                                            onChange={(e) => {
                                                                setEventData((prevData) => {
                                                                    prevData.phoneNumbers[index].whatsapp = e.target.checked;
                                                                    return { ...prevData }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Whatsapp"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="restaurantEmail"
                                label="Email adresa"
                                name="email_for_reservation"
                                type="text"
                                value={eventData.email_for_reservation}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="restaurantWebSite"
                                label="Link vaseg web sajta"
                                name="website"
                                type="text"
                                value={eventData.website}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                </Paper>

                <div>
                    {
                        loading
                            ?
                            <LinearProgress />
                            :
                            typeof newEvent !== 'undefined'
                                ?
                                newEvent.success
                                    ?
                                    <Alert severity="success" style={{ marginBottom: '20px' }}>
                                        <AlertTitle>Uspješno</AlertTitle>
                                        Dodat novi događaj
                                    </Alert>
                                    :
                                    <Alert severity="error" style={{ marginBottom: '20px' }}>
                                        <AlertTitle>Greška</AlertTitle>
                                        Neuspješno kreiranje događaja
                                    </Alert>
                                :
                                null
                    }
                </div>
                <div className={classes.addNewObjectButtons}>
                    <Button
                        type='submit'
                        style={{ backgroundColor: '#26A65B', color: 'white' }} >
                        Dodaj
                    </Button>
                    <Button
                        onClick={() => { switchActivePanel('myEvents'); }}
                        variant='text'
                        style={{ color: '#DC3023' }}>
                        <CancelIcon />
                        Odustani
                    </Button>
                </div>

            </form>
        </div>
    );
};

export default AddNewEvent;