import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        //width : '180px',
        // height : '100%',
        position : 'relative',
        borderRadius : '10px',
        '&:hover': {
            transform: 'scale(1.03)',
        },
        transition: "transform .5s ease-in-out",
        [theme.breakpoints.down('xs')]: {
            height : '100%',
            width : '100%',
            transition: "none",
            '&:hover': {
                transform: 'none',
            },
        }
    },
    cardContent: {
        //border : '3px solid green',
        marginTop : '-10px',
        '&.MuiCardContent-root' : {
            height : '145px',
            [theme.breakpoints.down('xs')]: {
                height : '80px'
            }
        }
    },
    addToFavoritesIcon : {
        position : 'absolute',
        top : '10px',
        right : '10px',
        backgroundColor : 'hsla(0,0%,100%,.8)',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        padding : '4px',
        borderRadius : '50%'
    },  
    isPopularMark : {
        backgroundColor : "white",
        position : 'absolute',
        top : '10px',
        left : '0',
        padding : '5px',
        borderTopRightRadius : '7px',
        borderBottomRightRadius : '7px'
    },
    eqDiv : {
        //border : '1px solid black',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        marginTop : '10px',
        width : '100%'
    },
    tooltip : {
       backgroundColor : '#0F79A1',
       color : 'white',
    },
    arrow : {
        color : '#0F79A1'
    }
}));

export default useStyles;