import * as api from '../api/api_manager';


export const addNewCarAction = (carData)  => async (dispatch) => {
    try {

        dispatch({
            type : 'REQUEST_ADD_NEW_CAR'
        });

        const { data } = await api.addNewCar(carData);

        console.log('Testing');

        dispatch({
            type : 'SUCCESS_ADD_NEW_CAR',
            payload : data
        });
        
    } catch (error) {
        console.log('Error in adding new car (action): ',error.message);
        dispatch({
            type : 'ERROR_ADD_NEW_CAR',
            payload : error.response.data
        })
    }
};

export const getOwnerCarsTableDataAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_OWNER_CARS_TABLE_DATA' });

        const { data } = await api.getOwnerCarsTable();

        dispatch({
            type: 'SUCCESS_GET_OWNER_CARS_TABLE_DATA',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting owner cars data for table : ', error.message);
        dispatch({
            type: "ERROR_GET_OWNER_CARS_TABLE_DATA",
            payload: error.response.data
        });
    }
};

export const getCarDataForEditAction = (carId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_CAR_DATA_FOR_EDIT' });

        const { data } = await api.getCarDataForEdit(carId);

        dispatch({
            type: 'SUCCESS_GET_CAR_DATA_FOR_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting car data for edit : ', error.message);
        dispatch({
            type: "ERROR_GET_CAR_DATA_FOR_EDIT",
            payload: error.response.data
        });
    }
};


export const updateCarDataAction = (carData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_UPDATE_CAR_DATA' });

        const { data } = await api.updateCarData(carData);

        dispatch({
            type: 'SUCCESS_UPDATE_CAR_DATA',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting car data for edit : ', error.message);
        dispatch({
            type: "ERROR_UPDATE_CAR_DATA",
            payload: error.response.data
        });
    }
};

export const previewCarAction = (carId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_PREVIEW_CAR' });

        const { data } = await api.previewCar(carId);

        dispatch({
            type: 'SUCCESS_PREVIEW_CAR',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting car data for preview : ', error.message);
        dispatch({
            type: "ERROR_PREVIEW_CAR",
            payload: error.response.data
        });
    }
};

export const getAllCarsAction = () => async (dispatch,getState) => {
    try {  

        dispatch({ type: 'REQUEST_GET_ALL_CARS' });

        const { data } = await api.getAllCars();

        dispatch({
            type: 'SUCCESS_GET_ALL_CARS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting all cars: ', error.message);
        dispatch({
            type: "ERROR_GET_ALL_CARS",
            payload: error.response.data
        });
    }
};

export const deleteCarAction = (carData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_DELETE_CAR' });

        const { data } = await api.deleteCar(carData);

        dispatch({
            type: 'SUCCESS_DELETE_CAR',
            payload: data
        });

    } catch (error) {
        console.log('Error in deleting car: ', error.message);
        dispatch({
            type: "ERROR_DELETE_CAR",
            payload: error.response.data
        });
    }
};

export const getCarDataAction = (carId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_CAR_DATA' });

        const { data } = await api.getCarData(carId);

        dispatch({
            type: 'SUCCESS_GET_CAR_DATA',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting car data: ', error.message);
        dispatch({
            type: "ERROR_GET_CAR_DATA",
            payload: error.response.data
        });
    }
};

export const sendCarQuestionAction = (questionData) => async (dispatch) => {
    try {  

        dispatch({ type: 'REQUEST_SEND_CAR_QUESTION' });

        const { data } = await api.sendRentACarQuestion(questionData);

        dispatch({
            type: 'SUCCESS_SEND_CAR_QUESTION',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending car question: ', error.message);
        dispatch({
            type: "ERROR_SEND_CAR_QUESTION",
            payload: error.response.data
        });
    }
};