import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    navigation: {
        //border : '2px solid green',
        position: 'relative',
        height: '120px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        [theme.breakpoints.down('xs')]: {
            //border : '3px solid red',
            height : '270px'
        }
    },
    innerContainer: {
        //border : '4px solid yellow',
        width: '100%',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    datePickerDiv: {
        //border : '1px solid red',
        width: '50%',
        margin: 'auto',
        padding: '5px',
        //marginTop: '30px',
        backgroundColor: 'white',
        borderRadius: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    menu : {
        "& .MuiPaper-root": {
            padding : '6px',
            height : '250px',
            marginTop : '13px',
          }
    },
    pickerItem: {
  
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor : 'pointer',
        [theme.breakpoints.down('xs')]: {
            //border : '1px solid red',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '5px',
            marginBottom: '10px'
        }
    },
    dropdownImg: {
        marginRight: '10px',
        cursor: 'pointer',
        color: '#000000'
    },
    pickerText: {
        //border : '1px solid green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    searchButton: {
        backgroundColor: '#f79800',
        color: 'white',
        borderRadius: '20px',
    },
    test: {
        display: 'flex',
        flexDirection: 'column'
    },
    personNumPicker: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            marginTop: '10%'
        }
    },
    buttonSearch: {
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginTop: '8%'
        }
    },
    infoSection: {
        //border : '2px solid yellow',
        width: '87%',
        margin: '0 auto',
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            width: '98%',
        }
    },
    infoPart: {
        //border : '2px solid green',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },
    infoImg: {
        width: '85px',
        marginRight: '15px'
    },
    menuItem: {
        //border : '2px solid green',
    },
    mobileGridSection: {
        margin: 'auto',
        padding: '5px',
        //backgroundColor: 'red',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRadius: '0px'
        }
    },
    mobileFormField: {
        //border: '1px solid #D2D7D3',
        backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        width: '100%',
        cursor : 'pointer'
    },
    objectsDiv: {
        //border : '3px solid green',
        width: '100%',
        margin: 'auto',
        marginTop: '15px'
    },
    objectsDivNavigation : {
        //border : '2px solid blue',
        width: '90%',
        margin: 'auto',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        marginBottom : '20px',
        [theme.breakpoints.down('xs')]: {
            justifyContent : 'center',
            width: '100%',
        }
    },  
    listMapDiv : {
        //border : '3px solid red',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        width : '300px',
        [theme.breakpoints.down('xs')]: {
            display : 'none'
        }
    },
    listMap : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        cursor : 'pointer'
    },
    categoryEqDiv : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        width : '280px',
        [theme.breakpoints.down('xs')]: {
            width : '80%'
        },
        [theme.breakpoints.down('340')]: {
            width : '95%'
        }
    },
    catEq : {
        //border : '1px solid red',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        cursor : 'pointer'
    },
    objectsListDiv: {
        width: '90%',
        margin: 'auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gap: '15px',
        [theme.breakpoints.down('xs')]: {
            width : '99%',
            gridTemplateColumns : '1fr 1fr',
            gap : '5px',
        }
    },
    objectsMapAndListDiv : {
        //border : '3px solid blue',
        display : 'grid',
        gridTemplateColumns : '4fr 3fr',
        gap : '10px'
    },
    mapDiv : {
       // border : '3px solid red',
       height : '630px',
       position : 'sticky',
       top : '85px'
    },
    map : {
        height : '100%',
        zIndex: '2',
        //border : '3px solid red'
    },  
    objectsListMap : {
        //border : '3px solid green',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        gap: '15px',
        
        //marginRight : '10px'
    },
    objectCard: {
        //border: '2px solid red',
        position : 'relative',
        // width : '335px',
        // height : '340px',
        boxShadow : '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 0px 1px 1.583px 0.125px rgba(0,0,0,0.14), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
        borderBottomLeftRadius : '15px',
        borderBottomRightRadius : '15px',
        transition : '0.5s',
        [theme.breakpoints.up('sm')]: {
            '&:hover' : {
                transform: 'scale(1.002)',
                boxShadow: '#245d8d 0px 0px 10px, #245d8d 0px 1px 2px'
            },
        },
        [theme.breakpoints.down('xs')]: {
            boxShadow : "none",
            height : '197px !important',
            width : 'calc(50vw - 5px) !important'
        },
        // [theme.breakpoints.between('545','600')]: {
        //     height : '187px !important',
        //     width : '255px !important'
        // },
        // [theme.breakpoints.between('390','545')]: {
        //     height : '197px !important',
        //     width : '190px !important' 
        // },
        // [theme.breakpoints.down('390')]: {
        //     height : '197px !important',
        //     width : '155px !important' 
        // }
    },
    objectCardImage: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // height : '180px',
        cursor : 'pointer',
        width  : '100%',
        [theme.breakpoints.down('xs')]: {
            borderRadius : '8px'
        }
    },
    objectCardBodyMobile : {
        [theme.breakpoints.up('sm')]: {
            display : "none"
        }
    },
    objectCardBody: {
        [theme.breakpoints.down('xs')]: {
            display : "none"
        }
    },
    objectCardTitle: {
        marginTop : '5px',
        textAlign : 'center'
    },
    objectCardData: {
        width : '95%',
        margin : 'auto',
        backgroundColor : '#ecf5fe',
        borderBottomLeftRadius : '8px',
        borderBottomRightRadius : '8px',
        marginTop : '10px',
        padding : '8px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        //overflow : 'scroll',
        overflowY: 'scroll',
        '&::-webkit-scrollbar' : {
            width: "0px",
            background: 'transparent' /* make scrollbar transparent */,
            display : 'none'
        }
        //overflowX: 'hidden'
    },
    objectCardDataSingle : {
        //border : '1px solid green',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-evenly',
        //alignItems : 'center'
    },
    objectCardPriceAndButton: {
        width : '90%',
        margin : 'auto',
        marginTop : '6px',
        display : 'flex',
        flexDirection : "row",
        justifyContent : 'space-between',
        alignItems : 'flex-end',
        //border : '1px solid blue'
    },
    objectCardPrice: {
        display : 'flex',
        flexDirection : "column",
    },
    objectCardPriceFrom: {
        marginBottom : '-3px'
    },
    objectCardPriceInfo: {
       display : 'flex',
       flexDirection : "row",
       justifyContent : 'space-between',
       alignItems : 'center',
       //border : '1px solid red',
       height : '45px'
    },
    objectCardReserveButton: {
        //border : '1px solid green'
    },
    addToFavoritesIcon : {
        position : 'absolute',
        top : '15px',
        right : '15px',
        backgroundColor : 'hsla(0,0%,100%,.8)',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        padding : '4px',
        borderRadius : '50%'
    },
    isPopularMark : {
        backgroundColor : "#dd0000",
        position : 'absolute',
        top : '10px',
        left : '0',
        padding : '3px',
        borderTopRightRadius : '7px',
        borderBottomRightRadius : '7px'
    },
    equipmentPickerDiv : {
        //border : '2px solid red',
        marginTop : '10px',
        marginBottom : '20px',
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gap : '25px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns : '1fr',
        }
    }
}));

export default useStyles;