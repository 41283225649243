import React from 'react';
import { Button, Typography, Menu, MenuItem, Dialog, DialogContent, DialogActions, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';


const LanguagePickerDialog = ({ 
    languagePickerDialog, 
    setLanguagePickerDialog, 
    handleSwitchLanguage,
    classes,
    currentLanguage,
    languagesMenuObject 
}) => {


    return (
        <Dialog
            fullWidth
            open={languagePickerDialog}
            onClose={() => { setLanguagePickerDialog(false); }}>
            <DialogTitle>Izaberi jezik</DialogTitle>
            <DialogContent className={classes.mobileLanguagePicker}>
                {languagesMenuObject.map((language, index) => (
                    <div
                        style={{
                            backgroundColor: index === currentLanguage ? '#0F79A1' : 'white',
                            color: index === currentLanguage ? 'white' : 'black'
                        }}
                        key={index}
                        onClick={() => {
                            handleSwitchLanguage(language.code, index);
                            setLanguagePickerDialog(false);
                        }}
                        className={classes.menuMobileItem}>
                        <img src={language.flag} width={42} style={{ marginRight: '5px' }} alt='Flag' />
                        <div>
                            <Typography variant='subtitle1' >{language.code}</Typography>
                        </div>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setLanguagePickerDialog(false); }}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LanguagePickerDialog