import carDoor from '../assets/carDataPhotos/carDoor.png';
import carShift from '../assets/carDataPhotos/carShift.png';
import carFuel from '../assets/carDataPhotos/carFuel.png';
import carYear from '../assets/carDataPhotos/carYear.png';
import carEngine from '../assets/carDataPhotos/carEngine.png';
import carSeat from '../assets/carDataPhotos/carSeat.png';
import carHorse from '../assets/carDataPhotos/carHorse.png';
import carType from '../assets/carDataPhotos/carType.png';

export const carEquipment = [
    {
        icon : carType,
        propName : 'type'
    },
    {
        icon : carYear,
        propName : 'year'
    },
    {
        icon : carShift,
        propName : 'transmission_type'
    },
    {
        icon : carFuel,
        propName : 'fuel'
    },
    {
        icon : carSeat,
        propName : 'num_of_seats',
        additionalText : 'sjedišta'
    },
    {
        icon : carDoor,
        propName : 'num_of_doors',
        additionalText : 'vrata'
    },
    {
        icon : carEngine,
        propName : 'cubic_capacity',
        additionalText : 'cm3'
    },
    {
        icon : carHorse,
        propName : 'kilowatts',
        additionalText : 'kw'
    },
]