import contactOwner from '../assets/contact_owner_photos/contact-owner.png';
import calendar from '../assets/contact_owner_photos/calendar.png';
import phone from '../assets/contact_owner_photos/phone.png';
import viber from '../assets/contact_owner_photos/viber.png';
import whatsapp from '../assets/contact_owner_photos/whatsapp.png';

export const contactOwnerPhotos = {
    contactOwner,
    calendar,
    phone,
    viber,
    whatsapp
}