import { TextField, Dialog, DialogTitle, Button, DialogContent, DialogActions, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCarAction } from '../actions/rent_a_car_actions';

const DeleteCarDialog = ({ deleteState, setDeleteState }) => {

    const [password, setPassword] = React.useState('');

    const dispatch = useDispatch();

    const deleteCarSelector = useSelector(state => state.deleteCarState);
    const { loading, deleteCar } = deleteCarSelector;

    const handleClose = () => {
        setDeleteState({
            show: false,
            carId: null,
            carName: null
        });
    };

    const handleDelete = (e) => {
        e.preventDefault();
        dispatch(deleteCarAction({
            carId : deleteState.carId,
            password
        }));
    };

    const ShowVariant = ({ type, messageTitle, reason , message }) => {
        return (
            <Alert severity={type} style={{ marginBottom: '20px' }}>
                <AlertTitle>{messageTitle}</AlertTitle>
                {message} {reason ?? reason}
            </Alert>
        )
    };

    return (
        <Dialog
            open={deleteState.show}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                Želite da uklonite auto {deleteState.carName} ?
            </DialogTitle >
            {
                typeof deleteCar !== 'undefined'
                    ?
                    !deleteCar.success
                        ?
                        <ShowVariant
                            message={'Neuspješno uklanjanje'}
                            reason={deleteCar.message}
                            type={'error'}
                            messageTitle={"Greška"} />
                        :
                        <ShowVariant
                            message={'Uspješno uklanjanje'}
                            type={'success'}
                            messageTitle={"Uspjeh"} />
                    :
                    null
            }
            {
                loading
                    ?
                    <div style={{ margin: '30px' }}>
                        <Typography variant='subtitle1' >Uklanjanje</Typography>
                        <LinearProgress />
                    </div>
                    :
                    <DialogContent>
                        <form onSubmit={handleDelete} id='myform'>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="password"
                                label="Unesite šifru"
                                required
                                name="password"
                                type="password"
                                inputProps={{
                                    minLength: 6
                                }}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </form>
                    </DialogContent>
            }
            <DialogActions>
                <Button
                    disabled={loading}
                    variant='contained'
                    onClick={handleClose}
                    style={{ marginRight: '20px' }}>
                    Odustani
                </Button>
                <Button
                    disabled={loading}
                    type='submit'
                    variant='contained'
                    form='myform'
                    style={{ backgroundColor: '#C3272B', color: 'white' }}>
                    Ukloni
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteCarDialog;