import React, { useContext } from 'react';
import {
    Typography,
    InputAdornment,
    TextField,
    Button,
    MenuItem,
    Menu,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import ExpandIcon from '@material-ui/icons/ExpandMoreOutlined';
import {format, parseISO} from 'date-fns';
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core';
import dropdown from '../assets/photos/dropdown.svg';
import useStyles from './style';
import { typesOfSearch } from './types';
import { GlobalContext } from '../App';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#0F79A1'
        }
    },
});

const initialDataReservation = {
    reservationType: null,
    reservationArrivalDate: null,
    reservationDepartureDate: null,
    reservationNumOfPeople: ''
};

const ReservationForm = ({ isMobile }) => {

    const { currentLanguage } = useContext(GlobalContext);

    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [reservationData, setReservationData] = React.useState(initialDataReservation);

    const handleReservationFormSubmit = (e) => {
        e.preventDefault();
        if (reservationData.reservationArrivalDate === null && reservationData.reservationDepartureDate === null) {
            alert('Izaberite datum');
        } else {
            if (reservationData.reservationType === 0 && (reservationData.reservationArrivalDate === null || reservationData.reservationDepartureDate === null)) {
                alert('Izaberite datum');
            } else {
                const lsData = {
                    arrivalDate: reservationData.reservationArrivalDate,
                    departureDate: reservationData.reservationDepartureDate,
                    num_of_people: reservationData.reservationNumOfPeople
                };
                localStorage.setItem('reservationData', JSON.stringify(lsData));
                switch (reservationData.reservationType) {
                    case 0: navigate('/objects'); break;
                    case 1: navigate('/restaurants'); break;
                    case 2: navigate('/rent-a-car'); break;
                    case 3: navigate('/tours-and-excursions'); break;
                    case 4: navigate('/events'); break;
                    default: navigate('/');
                }
            }
        }
    };

    const [openArrivalDate, setOpenArrivalDate] = React.useState(false);
    const [openDepartureDate, setOpenDepartureDate] = React.useState(false);

    const [numOfPeopleAnchor, setNumOfPeopleAnchor] = React.useState(null);
    const [searchTypeAnchor, setSearchTypeAnchor] = React.useState(null);

    const [openReservationTypeDialog, setOpenReservationTypeDialog] = React.useState(false);
    const [openNumOfGuestsDialog, setOpenNumOfGuestsDialog] = React.useState(false);

    React.useEffect(() => {
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if (fsData) {
            setReservationData({
                ...reservationData,
                reservationArrivalDate: fsData.arrivalDate,
                reservationDepartureDate: fsData.departureDate,
                reservationNumOfPeople: fsData.num_of_people
            });
        }
    }, []);

    const handleOpenAnchor = (event) => {
        setNumOfPeopleAnchor(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setNumOfPeopleAnchor(null);
    };

    const handleOpenTypeAnchor = (event) => {
        setSearchTypeAnchor(event.currentTarget);
    }

    const handleCloseTypeAnchor = () => {
        setSearchTypeAnchor(null);
    };

    const handleCloseReservationTypeDialog = () => {
        setOpenReservationTypeDialog(false);
    };

    const handleCloseNumOfGuestsDialog = () => {
        setOpenNumOfGuestsDialog(false);
    };

    const ReservationTypeDialog = () => {
        return (
            <Dialog
                fullWidth
                open={openReservationTypeDialog}
                onClose={handleCloseReservationTypeDialog}>
                <DialogTitle>{t("home_form_title_1")}</DialogTitle>
                <DialogContent
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '10px'
                    }}>
                    {
                        typesOfSearch[currentLanguage].map((el, index) => {
                            return (
                                <Button
                                    variant={reservationData.reservationType === index ? 'contained' : 'outlined'}
                                    onClick={() => {
                                        setReservationData({
                                            ...reservationData,
                                            reservationType: index
                                        });
                                        handleCloseReservationTypeDialog();
                                    }}
                                    disableElevation>
                                    {el}
                                </Button>
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReservationTypeDialog}>Odustani</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <div className={classes.innerContainer}>
            {
                !isMobile
                    ?
                    <form onSubmit={handleReservationFormSubmit}>
                        <div className={classes.datePickerDiv}>

                            <div className={classes.pickerItem} onClick={handleOpenTypeAnchor}>
                                <img
                                    src={dropdown}
                                    alt='Dropdown'
                                    width={28}
                                    className={classes.dropdownImg} />
                                <div className={classes.pickerText}>
                                    <Typography variant='subtitle1' style={{ color: '#000000' }}><strong>{t("home_form_title_1")}</strong></Typography>
                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                        {
                                            reservationData.reservationType !== null
                                                ?
                                                typesOfSearch[currentLanguage][reservationData.reservationType]
                                                :
                                                t("home_form_choose")
                                        }
                                    </Typography>
                                </div>
                            </div>

                            <Menu
                                className={classes.menu}
                                anchorEl={searchTypeAnchor}
                                open={Boolean(searchTypeAnchor)}
                                onClose={handleCloseTypeAnchor}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                {
                                    typesOfSearch[currentLanguage].map((el, index) => {
                                        return (
                                            <MenuItem
                                                className={classes.menuItem}
                                                onClick={() => {
                                                    setReservationData({
                                                        ...reservationData,
                                                        reservationType: index
                                                    });
                                                    handleCloseTypeAnchor();
                                                }}
                                                key={index}>
                                                <Typography variant='subtitle1'>
                                                    {el}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>

                            <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                <div
                                    onClick={() => {
                                        setOpenArrivalDate(open => !open);
                                    }}
                                    className={classes.pickerItem}>
                                    <img
                                        src={dropdown}
                                        alt='Dropdown'
                                        width={28}
                                        className={classes.dropdownImg} />
                                    <div className={classes.pickerText}>
                                        <Typography variant='subtitle1' style={{ color: '#000000' }}><strong>{t("home_form_arrival")}</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                reservationData.reservationArrivalDate
                                                    ?
                                                    typeof reservationData.reservationArrivalDate === 'string'
                                                    ?
                                                    format(parseISO(reservationData.reservationArrivalDate), 'dd/MM/yyyy')
                                                    :
                                                    format(reservationData.reservationArrivalDate, 'dd/MM/yyyy')
                                                    :
                                                    t("home_form_choose_date")
                                            }
                                        </Typography>
                                    </div>
                                </div>

                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DatePicker
                                        style={{ display: 'none' }}
                                        open={openArrivalDate}
                                        required
                                        color='primary'
                                        disablePast
                                        format="dd/MM/yyyy"
                                        disableToolbar
                                        autoOk
                                        // variant={isMobile ? 'dialog' : 'inline'}
                                        variant='dialog'
                                        emptyLabel='Izaberi datum'
                                        value={reservationData.reservationArrivalDate}
                                        onChange={(e) => {
                                            setReservationData({
                                                ...reservationData,
                                                reservationArrivalDate: e
                                            });
                                            setOpenArrivalDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenArrivalDate(false);
                                        }}
                                    // InputProps={{
                                    //   disableUnderline: true
                                    // }}
                                    />
                                </ThemeProvider>

                                <div
                                    onClick={() => {
                                        if (reservationData.reservationArrivalDate) {
                                            setOpenDepartureDate(open => !open);
                                        } else {
                                            alert('Prvo izaberite datum dolaska');
                                        }
                                    }}
                                    className={classes.pickerItem}>
                                    <img
                                        src={dropdown}
                                        alt='Dropdown'
                                        width={28}
                                        className={classes.dropdownImg} />
                                    <div className={classes.pickerText}>
                                        <Typography variant='subtitle1' style={{ color: '#000000' }}><strong>{t("home_form_departure")}</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                reservationData.reservationDepartureDate
                                                    ?
                                                    typeof reservationData.reservationDepartureDate === 'string'
                                                    ?
                                                    format(parseISO(reservationData.reservationDepartureDate), 'dd/MM/yyyy')
                                                    :
                                                    format(reservationData.reservationDepartureDate, 'dd/MM/yyyy')
                                                    :
                                                    t("home_form_choose_date")
                                            }
                                        </Typography>
                                    </div>
                                </div>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DatePicker
                                        open={openDepartureDate}
                                        style={{ display: 'none' }}
                                        disabled={reservationData.reservationArrivalDate ? false : true}
                                        required={reservationData.reservationArrivalDate ? true : false}
                                        minDate={reservationData.reservationArrivalDate}
                                        disableToolbar
                                        disablePast
                                        format="dd/MM/yyyy"
                                        autoOk
                                        emptyLabel='Izaberi datum'
                                        //variant={isMobile ? 'dialog' : 'inline'}
                                        variant='dialog'
                                        value={reservationData.reservationDepartureDate}
                                        onChange={(e) => {
                                            setReservationData({
                                                ...reservationData,
                                                reservationDepartureDate: e
                                            });
                                            setOpenDepartureDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenDepartureDate(false);
                                        }}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>

                            <div className={classes.pickerItem} onClick={handleOpenAnchor}>
                                <img
                                    src={dropdown}
                                    alt='Dropdown'
                                    width={28}
                                    className={classes.dropdownImg} />
                                <div className={classes.pickerText}>
                                    <Typography variant='subtitle1' style={{ color: '#000000' }}><strong>{t("home_form_num_of_guests")}</strong></Typography>
                                    <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                        {
                                            reservationData.reservationNumOfPeople
                                                ?
                                                reservationData.reservationNumOfPeople
                                                :
                                                t("home_form_choose")
                                        }
                                    </Typography>
                                </div>

                            </div>

                            <Menu
                                className={classes.menu}
                                anchorEl={numOfPeopleAnchor}
                                open={Boolean(numOfPeopleAnchor)}
                                onClose={handleCloseAnchor}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                {
                                    [...Array(30)].map((el, index) => {
                                        return (
                                            <MenuItem
                                                className={classes.menuItem}
                                                onClick={() => {
                                                    setReservationData({
                                                        ...reservationData,
                                                        reservationNumOfPeople: index + 1
                                                    });
                                                    handleCloseAnchor();
                                                }}
                                                key={index}>
                                                <Typography variant='subtitle1'>
                                                    {index + 1}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>

                            <div className={classes.buttonSearch}>
                                <Button
                                    disableElevation
                                    style={{
                                        backgroundColor: '#f79800',
                                        color: 'white',
                                        borderRadius: '20px',
                                    }}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    size='large' >
                                    <SearchIcon fontSize='large' />
                                    {t("search_section_title")}
                                </Button>
                            </div>

                        </div>
                    </form>
                    :
                    <form onSubmit={handleReservationFormSubmit}>
                        <Grid
                            container
                            spacing={2}
                            className={classes.mobileGridSection}>
                            <Grid item xs={12}>
                                <div className={classes.mobileFormField} onClick={() => { setOpenReservationTypeDialog(true); }}>
                                    <div>
                                        <Typography variant='subtitle1' style={{ color: '#000000' }}><strong>{t("home_form_title_1")}</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                reservationData.reservationType !== null
                                                    ?
                                                    typesOfSearch[currentLanguage][reservationData.reservationType]
                                                    :
                                                    t("home_form_choose")
                                            }
                                        </Typography>
                                    </div>
                                    <img
                                        src={dropdown}
                                        alt='Dropdown'
                                        width={28}
                                        className={classes.dropdownImg} />
                                </div>
                            </Grid>
                            <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                <Grid item xs={6}>
                                    <div className={classes.mobileFormField}
                                        onClick={() => {
                                            setOpenArrivalDate(open => !open);
                                        }}>
                                        <div>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>{t("home_form_arrival")}</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    reservationData.reservationArrivalDate
                                                        ?
                                                        typeof reservationData.reservationArrivalDate === 'string'
                                                        ?
                                                        format(parseISO(reservationData.reservationArrivalDate), 'dd/MM/yyyy')
                                                        :
                                                        format(reservationData.reservationArrivalDate, 'dd/MM/yyyy')
                                                        :
                                                        t("home_form_choose_date")
                                                }
                                            </Typography>

                                        </div>
                                        <img
                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                    </div>
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <DatePicker
                                            style={{ display: 'none' }}
                                            open={openArrivalDate}
                                            required
                                            color='primary'
                                            disablePast
                                            format="dd/MM/yyyy"
                                            disableToolbar
                                            autoOk
                                            variant='dialog'
                                            emptyLabel='Izaberi datum'
                                            value={reservationData.reservationArrivalDate}
                                            onChange={(e) => {
                                                setReservationData({
                                                    ...reservationData,
                                                    reservationArrivalDate: e
                                                });
                                                setOpenArrivalDate(false);
                                            }}
                                            onClose={() => {
                                                setOpenArrivalDate(false);
                                            }}
                                        />
                                    </ThemeProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <div
                                        onClick={() => {
                                            if (reservationData.reservationArrivalDate) {
                                                setOpenDepartureDate(open => !open);
                                            } else {
                                                alert('Prvo izaberite datum dolaska');
                                            }
                                        }}
                                        className={classes.mobileFormField}>
                                        <div>
                                            <Typography variant='subtitle2' style={{ color: '#000000' }}><strong>{t("home_form_departure")}</strong></Typography>
                                            <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                                {
                                                    reservationData.reservationDepartureDate
                                                        ?
                                                        typeof reservationData.reservationDepartureDate === 'string'
                                                        ?
                                                        format(parseISO(reservationData.reservationDepartureDate), 'dd/MM/yyyy')
                                                        :
                                                        format(reservationData.reservationDepartureDate, 'dd/MM/yyyy')
                                                        :
                                                        t("home_form_choose_date")
                                                }
                                            </Typography>

                                        </div>
                                        <img
                                            src={dropdown}
                                            alt='Dropdown'
                                            width={28}
                                            className={classes.dropdownImg} />
                                    </div>
                                    <DatePicker
                                        open={openDepartureDate}
                                        style={{ display: 'none' }}
                                        disabled={reservationData.reservationArrivalDate ? false : true}
                                        required={reservationData.reservationArrivalDate ? true : false}
                                        minDate={reservationData.reservationArrivalDate}
                                        disableToolbar
                                        disablePast
                                        format="dd/MM/yyyy"
                                        autoOk
                                        emptyLabel='Izaberi datum'
                                        //variant={isMobile ? 'dialog' : 'inline'}
                                        variant='dialog'
                                        value={reservationData.reservationDepartureDate}
                                        onChange={(e) => {
                                            setReservationData({
                                                ...reservationData,
                                                reservationDepartureDate: e
                                            });
                                            setOpenDepartureDate(false);
                                        }}
                                        onClose={() => {
                                            setOpenDepartureDate(false);
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                            <Grid item xs={12}>
                                <div className={classes.mobileFormField} onClick={() => { setOpenNumOfGuestsDialog(true); }}>
                                    <div>
                                        <Typography variant='subtitle1' style={{ color: '#000000' }}><strong>{t("home_form_num_of_guests")}</strong></Typography>
                                        <Typography variant='subtitle2' style={{ color: '#333333' }}>
                                            {
                                                reservationData.reservationNumOfPeople
                                                    ?
                                                    reservationData.reservationNumOfPeople
                                                    :
                                                    t("home_form_choose")
                                            }
                                        </Typography>
                                    </div>
                                    <img
                                        src={dropdown}
                                        alt='Dropdown'
                                        width={28}
                                        className={classes.dropdownImg} />
                                </div>

                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disableElevation
                                    style={{
                                        backgroundColor: '#f79800',
                                        color: 'white',
                                        borderRadius: '20px',
                                    }}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    size='medium' >
                                    <SearchIcon fontSize='large' />
                                    {t("search_section_title")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
            }
            <ReservationTypeDialog />
            <Dialog
                fullWidth
                open={openNumOfGuestsDialog}
                onClose={handleCloseNumOfGuestsDialog}>
                <DialogTitle>{t("home_form_num_of_guests")}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Unesite broj gostiju"
                        type="number"
                        value={reservationData.reservationNumOfPeople}
                        onChange={(e)=>{
                            setReservationData({
                                ...reservationData,
                                reservationNumOfPeople : e.target.value
                            })
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNumOfGuestsDialog}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ReservationForm