import React from 'react';
import {
    TextField,
    Grid,
    InputAdornment,
    Select,
    MenuItem,
    InputLabel,
    Button,
    LinearProgress,
    Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './style';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { makeReservationAction } from '../actions/restaurants_actions';

const initialContactData = {
    contactDate: null,
    contactName: '',
    contactSurname: '',
    contactNumOfPeople: '',
    contactEmail : '',
    contactPhone : '',
    contactMessage: ''
};

const RestaurantForm = ({ data }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [contactData, setContactData] = React.useState(initialContactData);

    const makeReservationSelector = useSelector(state => state.makeReservationState);
    const { loading, makeReservationData } = makeReservationSelector;

    const handleChange = (e) => {
        setContactData({
            ...contactData,
            [e.target.name]: e.target.value
        });
    };

    React.useEffect(()=>{
        const fsData = JSON.parse(localStorage.getItem('reservationData'));
        if(fsData){
            setContactData({
                ...contactData,
                contactDate : fsData.arrivalDate,
                contactNumOfPeople : fsData.num_of_people
            });
        }
    },[]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ margin: '20px' }}>
                <AlertTitle>{t("error")}</AlertTitle>
                {t("message_form_error_message")}
            </Alert>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success" style={{ margin: '20px' }}>
                <AlertTitle>{t("message_success")}</AlertTitle>
                {t("message_success_message")}
            </Alert>
        );
    };

    const ShowLoading = () => {
        return (
            <div style={{ margin: '20px' }}>
                <Typography variant='caption' style={{ color : '#0F79A1' }}>{t("message_sending_message")}</Typography>
                <LinearProgress style={{ backgroundColor : '#0F79A1' }} />
            </div>
        );
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (contactData.contactDate === null) {
            alert('Unesite datum');
        } else {
            const fixedData = {
                date: format(contactData.contactDate, 'dd/MM/yyyy'),
                contact_name: contactData.contactName,
                contact_surname: contactData.contactSurname,
                num_of_people: contactData.contactNumOfPeople,
                contact_message: contactData.contactMessage,
                contact_phone_number : contactData.contactPhone,
                contact_email : contactData.contactEmail,
                restaurant: data.id
            };
            dispatch(makeReservationAction(fixedData));
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>
            {
                loading
                    ?
                    <ShowLoading />
                    :
                    typeof makeReservationData !== 'undefined'
                        ?
                        makeReservationData?.success
                            ?
                            <ShowSuccess />
                            :
                            <ShowError />
                        :
                        null
            }
            <Grid container spacing={2}>
                <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                    <Grid item xs={12} md={12}>
                        <DatePicker
                            fullWidth
                            inputVariant='outlined'
                            required
                            className={classes.field}
                            disablePast
                            format="dd/MM/yyyy"
                            disableToolbar
                            autoOk
                            variant='dialog'
                            label='Datum'
                            value={contactData.contactDate}
                            onChange={(e) => {
                                setContactData({
                                    ...contactData,
                                    contactDate: e
                                });
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <DateRangeTwoToneIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={6} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        required
                        className={classes.field}
                        id="contactName"
                        label={t("message_form_name")}
                        name="contactName"
                        type="text"
                        value={contactData.contactName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        required
                        className={classes.field}
                        id="contactName"
                        label={t("message_form_surname")}
                        name="contactSurname"
                        type="text"
                        value={contactData.contactSurname}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        required
                        className={classes.field}
                        id="contactEmail"
                        label={t("message_form_email")}
                        name="contactEmail"
                        type="email"
                        value={contactData.contactEmail}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        required
                        className={classes.field}
                        id="contactName"
                        label={t("message_form_phone")}
                        name="contactPhone"
                        type="text"
                        value={contactData.contactPhone}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        required
                        className={classes.field}
                        id="contactNumOfKids"
                        label={t("form_reservation_num_of_people")}
                        name="contactNumOfPeople"
                        type="number"
                        value={contactData.contactNumOfPeople}
                        onChange={handleChange}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: data.capacity
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label={t("message_form_message_to_owner")}
                        fullWidth
                        className={classes.field}
                        variant="outlined"
                        multiline
                        minRows={6}
                        maxRows={8}
                        name='contactMessage'
                        type='text'
                        value={contactData.contactMessage}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Button
                        fullWidth
                        variant='contained'
                        type='submit'
                        style={{ backgroundColor : '#f79800', color : 'white' }} >
                        {t("message_form_send_button")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default RestaurantForm;