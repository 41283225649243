
export const addNewAccObjectReducer = (newAccObject = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_ACC_OBJECT':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_ACC_OBJECT':
        case 'ERROR_ADD_NEW_ACC_OBJECT':
            return {
                loading: false,
                newAccObject: action.payload
            }
        default:
            return newAccObject
    };
};

export const getAccObjectsTableDataReducer = (accObjectsTable = [], action) => {
    switch (action.type) {
        case 'REQUEST_GET_ACC_OBJECTS_TABLE_DATA':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ACC_OBJECTS_TABLE_DATA':
        case 'ERROR_GET_ACC_OBJECTS_TABLE_DATA':
            return {
                loading: false,
                accObjectsTable: action.payload
            }
        default: return accObjectsTable;
    }
};

export const getAccObjectDataToEditReducer = (accObjectEditData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ACC_OBJECT_TO_EDIT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ACC_OBJECT_TO_EDIT':
        case 'ERROR_GET_ACC_OBJECT_TO_EDIT':
            return {
                loading: false,
                accObjectEditData: action.payload
            }
        default: return accObjectEditData;
    };
};

export const updateAccObjectReducer = (updateAccObject = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_ACC_OBJECT':
            return {
                loading_update: true
            }
        case 'SUCCESS_UPDATE_ACC_OBJECT':
        case 'ERROR_UPDATE_ACC_OBJECT':
            return {
                loading_update: false,
                updateAccObject: action.payload
            }
        case 'EMPTY_UPDATE_ACC_OBJECT':
            updateAccObject = {};
            return updateAccObject;
        default: return updateAccObject;
    }
};

export const deleteAccObjectReducer = (deleteAccObject = {}, action) => {
    switch (action.type) {
        case 'REQUEST_DELETE_ACC_OBJECT':
            return {
                loading: true
            }
        case 'SUCCESS_DELETE_ACC_OBJECT':
        case 'ERROR_DELETE_ACC_OBJECT':
            return {
                loading: false,
                deleteAccObject: action.payload
            }
        default: return deleteAccObject;
    }
};

export const getAccUnitDataToEditReducer = (accUnitEditData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ACC_UNIT_TO_EDIT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_ACC_UNIT_TO_EDIT':
        case 'ERROR_GET_ACC_UNIT_TO_EDIT':
            return {
                loading: false,
                accUnitEditData: action.payload
            }
        default: return accUnitEditData;
    };
};

export const updateAccUnitReducer = (updateAccUnitData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_UPDATE_ACC_UNIT':
            return {
                loading_update: true
            }
        case 'SUCCESS_UPDATE_ACC_UNIT':
        case 'ERROR_UPDATE_ACC_UNIT':
            return {
                loading_update: false,
                updateAccUnitData: action.payload
            }
        default: return updateAccUnitData;
    }
};

export const addNewAccUnitForExistingAccObjectReducer = (newAccUnitData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_ACC_UNIT_FOR_EXISTING_OBJECT':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_ACC_UNIT_FOR_EXISTING_OBJECT':
        case 'ERROR_ADD_NEW_ACC_UNIT_FOR_EXISTING_OBJECT':
            return {
                loading: false,
                newAccUnitData: action.payload
            }
        default: return newAccUnitData;
    }
};

export const deleteAccUnitReducer = (deleteAccUnit = {}, action) => {
    switch (action.type) {
        case 'REQUEST_DELETE_ACC_UNIT':
            return {
                loading_delete_unit: true
            }
        case 'SUCCESS_DELETE_ACC_UNIT':
        case 'ERROR_DELETE_ACC_UNIT':
            return {
                loading_delete_unit: false,
                deleteAccUnit: action.payload
            }
        default: return deleteAccUnit;
    }
};

export const superAdminGetAccObjectsReducer = (superAdminAccObjectsData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SUPER_ADMIN_GET_ACC_OBJECTS':
            return {
                loading: true
            }
        case 'SUCCESS_SUPER_ADMIN_GET_ACC_OBJECTS':
        case 'ERROR_SUPER_ADMIN_GET_ACC_OBJECTS':
            return {
                loading: false,
                superAdminAccObjectsData: action.payload
            }
        default: return superAdminAccObjectsData;
    }
};

export const getPopularAccObjectsReducer = (popularAccObjectsData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_POPULAR_ACC_OBJECTS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_POPULAR_ACC_OBJECTS':
        case 'ERROR_GET_POPULAR_ACC_OBJECTS':
            return {
                loading: false,
                popularAccObjectsData: action.payload
            }
        default: return popularAccObjectsData;
    }
};

export const showAccObjectReducer = (showAccObjectData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SHOW_ACC_OBJECT':
            return {
                loading: true
            }
        case 'SUCCESS_SHOW_ACC_OBJECT':
        case 'ERROR_SHOW_ACC_OBJECT':
            return {
                loading: false,
                showAccObjectData: action.payload
            }
        default: return showAccObjectData;
    }
};

export const showAccUnitReducer = (showAccUnitData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SHOW_ACC_UNIT':
            return {
                loading: true
            }
        case 'SUCCESS_SHOW_ACC_UNIT':
        case 'ERROR_SHOW_ACC_UNIT':
            return {
                loading: false,
                showAccUnitData: action.payload
            }
        default: return showAccUnitData;
    }
};

export const getFavoritesReducer = (favorites = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_FAVORITES':
            return {
                loading: true
            }
        case 'SUCCESS_GET_FAVORITES':
        case 'ERROR_GET_FAVORITES':
            return {
                loading: false,
                favorites: action.payload
            }
        default: return favorites;
    }
};

export const sendQuestionReducer = (sendQuestionData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SEND_QUESTION':
            return {
                loading: true
            }
        case 'SUCCESS_SEND_QUESTION':
        case 'ERROR_SEND_QUESTION':
            return {
                loading: false,
                sendQuestionData: action.payload
            }
        case 'EMPTY_SEND_QUESTION':
            sendQuestionData = {};
            return sendQuestionData;
        default: return sendQuestionData;
    }
};

export const getQuestionsForOwnerReducer = (questionsForOwnerData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_QUESTIONS_FOR_OWNER':
            return {
                loading: true
            }
        case 'SUCCESS_GET_QUESTIONS_FOR_OWNER':
        case 'ERROR_GET_QUESTIONS_FOR_OWNER':
            return {
                loading: false,
                questionsForOwnerData: action.payload
            }
        case 'EMPTY_SEND_QUESTION':
            questionsForOwnerData = {};
            return questionsForOwnerData;
        default: return questionsForOwnerData;
    }
};

export const lookupAccObjectsReducer = (lookupAccObjectsData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_LOOKUP_ACC_OBJECTS':
            return {
                loading: true
            }
        case 'SUCCESS_LOOKUP_ACC_OBJECTS':
        case 'ERROR_LOOKUP_ACC_OBJECTS':
            return {
                loading: false,
                lookupAccObjectsData: action.payload
            }
        case 'EMPTY_LOOKUP_ACC_OBJECTS':
            lookupAccObjectsData = {};
            return lookupAccObjectsData;
        default: return lookupAccObjectsData;
    }
};

export const superAdminPreviewAccObjectReducer = (previewAccObjectData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_PREVIEW_ACC_OBJECT':
            return {
                loading: true
            }
        case 'SUCCESS_PREVIEW_ACC_OBJECT':
        case 'ERROR_PREVIEW_ACC_OBJECT':
            return {
                loading: false,
                previewAccObjectData: action.payload
            }
        case 'EMPTY_PREVIEW_ACC_OBJECT':
            previewAccObjectData = {};
            return previewAccObjectData;
        default: return previewAccObjectData;
    }
};

export const superAdminPreviewAccUnitPhotosReducer = (previewAccUnitPhotosData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_PREVIEW_ACC_UNIT_PHOTOS':
            return {
                loading: true
            }
        case 'SUCCESS_PREVIEW_ACC_UNIT_PHOTOS':
        case 'ERROR_PREVIEW_ACC_UNIT_PHOTOS':
            return {
                loading: false,
                previewAccUnitPhotosData: action.payload
            }
        case 'EMPTY_PREVIEW_ACC_UNIT_PHOTOS':
            previewAccUnitPhotosData = {};
            return previewAccUnitPhotosData;
        default: return previewAccUnitPhotosData;
    }
};

export const reservationAccObjectsDisplayReducer = (reservationDisplayData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_RESERVATION_DISPLAY':
            return {
                loading: true
            }
        case 'SUCCESS_RESERVATION_DISPLAY':
        case 'ERROR_RESERVATION_DISPLAY':
            return {
                loading: false,
                reservationDisplayData: action.payload
            }
        case 'EMPTY_RESERVATION_DISPLAY':
            reservationDisplayData = {};
            return reservationDisplayData;
        default: return reservationDisplayData;
    }
};
