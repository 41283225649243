import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
});

//USERS
export const registerUser = (data) => API.post('/users/register', data);
export const verifyEmail = (token) => API.post('/users/verifyEmail',token);
export const loginUser = (data) => API.post('/users/login',data);
export const checkJwtToken = () => API.post('/users/checkJwtToken');
export const logoutUser = () => API.post('/users/logout');
export const updateUserData = (data) => API.post('/users/updateUserData',data);
export const checkSuperAdmin = () => API.post('/users/checkSuperAdmin');
export const checkRegularAdmin = () => API.post('/users/checkRegularAdmin');
export const superAdminGetAllUsers = () => API.get('/users/get-all-users');
export const superAdminUpdateUser = (data) => API.post('/users/super-admin-update-user',data);
export const getPasswordResetLink = (data) => API.post('/users/get-password-reset-link',data);
export const resetPassword = (data) => API.post('/users/reset-password',data);

//OBJECTS
export const addNewObject = (data) => API.post('/acc-objects/add-new-object',data);
export const getObjectsTableData = () => API.get('/acc-objects/acc-objects-table');
export const getAccObjectToEdit = (accObjectId) => API.get(`/acc-objects/get-acc-object-edit/${accObjectId}`);
export const updateOsnovniPodaci = (data) => API.post('/acc-objects/update-osnovni-podaci',data);
export const updatePodaciOLokaciji = (data) => API.post('/acc-objects/update-podaci-o-lokaciji',data);
export const updateOstaliPodaci = (data) => API.post('/acc-objects/update-ostali-podaci',data);
export const updateOpremaObjekta = (data) => API.post('/acc-objects/update-oprema-objekta',data);
export const updateUslovniRezevacije = (data) => API.post('/acc-objects/update-uslovi-rezervacije',data);
export const deleteAccObject = (data) => API.post('/acc-objects/delete-acc-object',data);
export const getQuestionsForOwner = (type) => API.get(`/acc-objects/get-questions-for-owner?type=${type}`);
export const reservationAccObjectsDisplay = () => API.get('/acc-objects/get-acc-objects-for-reservation');
export const getAccObjectsByFilters = (data) => API.post('/acc-objects/get-acc-objects-by-filters',data);

//UNITS
export const getAccUnitToEdit = (accUnitId) => API.get(`/acc-objects/get-acc-unit-edit/${accUnitId}`);
export const updateAccUnit = (data) => API.post('/acc-objects/update-acc-unit',data);
export const addNewAccUnitForExistingAccObject = (data) => API.post('/acc-objects/add-new-acc-unit',data);
export const deleteAccUnit = (data) => API.post('/acc-objects/delete-acc-unit',data);

//SUPER ADMIN
export const superAdminGetAccObjects = (page) => API.get(`/acc-objects/get-acc-objects-super-admin/${page}`);
export const superAdminChangeAccObjectStatus = (data) => API.post('/acc-objects/change-acc-object-status',data);
export const superAdminSearchAccObjects = (data) => API.post('/acc-objects/search-acc-objects-super-admin',data);
export const superAdminSearchAccObjectsByStatus = (status,type) => API.get(`/acc-objects/get-objects-by-status-super-admin?status=${status}&type=${type}`);
export const superAdminSwitchAccObjectPopularity = (data) => API.post('/acc-objects/switch-acc-object-popularity',data);
export const superAdminPreviewAccObject = (id) => API.get(`/acc-objects/preview-acc-object-super-admin/${id}`);
export const superAdminPreviewAccUnitPhotos = (id) => API.get(`/acc-objects/preview-acc-unit-photos-super-admin/${id}`);
export const superAdminPreviewRestaurant = (id) => API.get(`/restaurants/preview-restaurant-super-admin/${id}`);
export const previewCar = (carId) => API.get(`/rent-a-car/preview-car/${carId}`);
export const previewTour = (tourId) => API.get(`/tours/preview-tour/${tourId}`);
export const previewEvent = (eventId) => API.get(`/events/preview-event/${eventId}`);

//VIEW OBJECTS
export const getPopularAccObjects = () => API.get('/acc-objects/popular-acc-objects');
export const showAccObject = (id) => API.get(`/acc-objects/show-acc-object/${id}`);
export const showAccUnit = (id) => API.get(`/acc-objects/show-acc-unit/${id}`);
export const getFavorites = (data) => API.post('/acc-objects/favorites',data);
export const lookupAccObjects = (searchWord) => API.get(`/acc-objects/acc-objects-lookup?searchWord=${searchWord}`);

//QUESTIONS
export const sendQuestion = (data) => API.post('/acc-objects/send-question',data);
export const sendMultipleQuestion = (data) => API.post('/acc-objects/send-multiple-question',data);
export const sendRentACarQuestion = (data) => API.post('/rent-a-car/send-question',data);

//RESTAURANTS
export const addNewRestaurant = (data) => API.post('/restaurants/add-new-restaurant',data);
export const getRestaurantsTableData = () => API.get('/restaurants/restaurants-table');
export const deleteRestaurant = (data) => API.post('/restaurants/delete-restaurant',data);
export const getRestaurantDataEdit = (restaurantId) => API.get(`/restaurants/get-restaurant-edit/${restaurantId}`);
export const updateRestaurant = (data) => API.post('/restaurants/update-restaurant',data);
export const getAllRestaurants = (type) => API.get(`/restaurants/get-all-restaurants/${type}`);
export const makeReservation = (data) => API.post('/restaurants/make-reservation',data);
export const getRestaurant = (restaurantId) => API.get(`/restaurants/get-restaurant-data/${restaurantId}`);
export const reservationRestaurantsDisplay = () => API.get('/restaurants/get-restaurants-for-reservation');

//RENT A CAR
export const addNewCar = (data) => API.post('/rent-a-car/add-new-car',data);
export const getOwnerCarsTable = () => API.get('/rent-a-car/get-owner-cars-table');
export const getCarDataForEdit = (carId) => API.get(`/rent-a-car/get-car-data-for-edit/${carId}`);
export const updateCarData = (carData) => API.post('/rent-a-car/update-car-data',carData);
export const getAllCars = () => API.get(`/rent-a-car/get-all-cars/`);
export const deleteCar = (carData) => API.post('/rent-a-car/delete-car',carData);
export const getCarData = (carId) => API.get(`/rent-a-car/get-car-data/${carId}`);

//TOURS
export const addNewTour = (data) => API.post('/tours/add-new-tour',data);
export const getToursTable = () => API.get('/tours/tours-table');
export const getTourDataForEdit = (tourId) => API.get(`/tours/get-tour-data-for-edit/${tourId}`);
export const updateTour = (data) => API.post('/tours/update-tour',data);
export const deleteTour = (tourData) => API.post('/tours/delete-tour/',tourData);
export const getAllTours = () => API.get('/tours/all-tours');

//EVENTS
export const addNewEvent = (data) => API.post('/events/add-new-event',data);
export const getEventsTable = () => API.get('/events/events-table');
export const getEventDataForEdit = (eventId) => API.get(`/events/get-event-data-for-edit/${eventId}`);
export const updateEvent = (data) => API.post('/events/update-event',data);
export const deleteEvent = (eventData) => API.post('/events/delete-event/',eventData);
export const getAllEvents = () => API.get('/events/all-events');


