import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { checkJwtTokenAction, emptyRegularAndSuperAdminCheck, emptyJwtTokenAction } from './actions/users_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { GlobalContext } from './App';

const CheckLoginRoute = () => {

    const { loggedUserData } = useContext(GlobalContext);

    const dispatch = useDispatch();

    const jwtTokenCheckSelectorData = useSelector(state => state.jwtCheck);
    const { jwtToken } = jwtTokenCheckSelectorData;

    React.useEffect(() => {
        //dispatch(emptyRegularAndSuperAdminCheck());
        dispatch(checkJwtTokenAction());
    }, []);

    return (
        typeof jwtToken !== 'undefined'
            ?
            !jwtToken.success
                ?
                <Outlet />
                :
                <Navigate to={loggedUserData?.is_super_admin ? '/superAdminPanel' : '/ownerAdminPanel'} />
            :
            null
    );
};


export default CheckLoginRoute;
