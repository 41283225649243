import React from 'react';
import useStyles from './style';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Dialog,
    Toolbar,
    AppBar,
    TextField,
    Typography,
    IconButton,
    Paper,
    CircularProgress,
    LinearProgress,
    DialogContent,
    TableCell,
    TableBody,
    TableRow,
    TableContainer,
    Table,
    TableHead,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import { Slide } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { superAdminGetAllUsersAction, superAdminUpdateUserAction } from '../actions/users_actions';
import SuperAdminEditUserDialogMobile from './SuperAdminEditUserDialogMobile';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SuperAdminEditUsersDialog = ({ open, setOpen }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const inputRef = React.useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [showEditUserMobileDialog, setShowEditUserMobileDialog] = React.useState(false);

    const [userMobileData, setUserMobileData] = React.useState({});

    const superAdminGetAllUsersSelector = useSelector(state => state.superAdminAllUsersState);
    const { loading, allUsers } = superAdminGetAllUsersSelector;

    const superAdminUpdateUserSelector = useSelector(state => state.superAdminUpdateUserState);
    const { loading_update, superAdminUpdateUser } = superAdminUpdateUserSelector;

    const [users, setUsers] = React.useState([]);

    const [imageUserId, setImageUserId] = React.useState(null);

    React.useEffect(() => {
        if (open) dispatch(superAdminGetAllUsersAction());
    }, [open]);

    React.useEffect(() => {
        if (typeof allUsers !== 'undefined') {
            if (allUsers.success) {
                setUsers(allUsers.users);
            }
        }
    }, [allUsers]);

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleChange = (e, id) => {
        setUsers((prevState) => {
            return prevState.map((user) => {
                if (user.id === id) {
                    user[e.target.name] = e.target.value;
                    return user;
                } else {
                    return user;
                }
            });
        });
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        const avatar = await readFileAsBase64(file);
        setUsers((prevState) => {
            return prevState.map((user) => {
                if (user.id === imageUserId) {
                    user.avatar = avatar;
                    user.avatar_originalname = file.name;
                    return user;
                } else {
                    return user;
                }
            });
        });
    };

    const handleUpdateUser = (userId) => {
        const user = users.find(user => user.id === userId);
        dispatch(superAdminUpdateUserAction(user));
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#044F67' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Lista korisnika
                    </Typography>
                </Toolbar>
            </AppBar>



            <DialogContent style={{ marginTop: '80px' }}>
                {
                    loading_update
                        ?
                        <LinearProgress />
                        :
                        null
                }
                {
                    typeof superAdminUpdateUser !== 'undefined'
                        ?
                        superAdminUpdateUser.success
                            ?
                            <Alert severity="success" style={{ marginBottom: '20px' }}>
                                <AlertTitle>Korisnik ažuriran</AlertTitle>
                            </Alert>
                            :
                            <Alert severity="error" style={{ marginBottom: '20px' }}>
                                <AlertTitle>Greška</AlertTitle>
                            </Alert>
                        :
                        null
                }
                {
                    loading
                        ?
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress size={200} />
                        </div>
                        :
                        typeof allUsers !== 'undefined'
                            ?
                            allUsers.success
                                ?
                                !isMobile
                                ?
                                <TableContainer component={Paper} >
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Ime</TableCell>
                                                <TableCell align="center">Email</TableCell>
                                                <TableCell align="center">Telefon</TableCell>
                                                <TableCell align="center">Slika</TableCell>
                                                <TableCell align="center">Ažuriraj</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {users.map((user, index) => (

                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                                                    <TableCell variant='head' align='center'>
                                                        <TextField onChange={(e) => { handleChange(e, user.id) }} name='name' value={user.name} size='small' variant='outlined' style={{ marginRight: '5px' }} />
                                                        <TextField onChange={(e) => { handleChange(e, user.id) }} name='surname' value={user.surname} size='small' variant='outlined' />
                                                    </TableCell>
                                                    <TableCell variant='head' align='center'>
                                                        <TextField onChange={(e) => { handleChange(e, user.id) }} name='email' value={user.email} size='small' variant='outlined' />
                                                    </TableCell>
                                                    <TableCell variant='head' align='center'>
                                                        <TextField onChange={(e) => { handleChange(e, user.id) }} name='phone' value={user.phone} size='small' variant='outlined' />
                                                    </TableCell>
                                                    <TableCell variant='head' align='center'>
                                                        <img
                                                            onClick={() => {
                                                                inputRef.current.click();
                                                                setImageUserId(user.id);
                                                            }}
                                                            className={classes.userAvatar}
                                                            src={user.avatar_originalname ? user.avatar : process.env.REACT_APP_UPLOADS_PATH + user.avatar}
                                                            width='170'
                                                            height='110'
                                                            alt='Avatar' />
                                                        <input
                                                            onChange={handleImageChange}
                                                            accept='image/*'
                                                            type="file"
                                                            hidden
                                                            ref={inputRef}
                                                        />
                                                    </TableCell>
                                                    <TableCell variant='head' align='center'>
                                                        <EditIcon
                                                            onClick={() => {
                                                                handleUpdateUser(user.id);
                                                            }}
                                                            fontSize='medium'
                                                            style={{ cursor: 'pointer', color: '#26A65B' }} />
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <TableContainer component={Paper} >
                                    <Table size='medium'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Ime</TableCell>
                                                <TableCell align="center">Email</TableCell>
                                                <TableCell align="center">Edit</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {users.map((user, index) => (

                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                                                    <TableCell variant='head' align='center'>
                                                        <Typography variant='subtitle2'>
                                                            {user.name} {user.surname}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell variant='head' align='center'>
                                                        <Typography variant='subtitle2'>
                                                            {user.email}
                                                        </Typography>
                                                    </TableCell>
                                                    
                                                    <TableCell variant='head' align='center'>
                                                        <EditIcon
                                                            onClick={()=>{
                                                                setUserMobileData(user); 
                                                                setShowEditUserMobileDialog(true); 
                                                            }}
                                                            fontSize='medium'
                                                            style={{ cursor: 'pointer', color: '#26A65B' }} />
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Alert severity="error">
                                    <AlertTitle>Greška</AlertTitle>
                                    Neuspješno prikazivanje korisnika
                                </Alert>
                            :
                            null
                }
            </DialogContent>

            <SuperAdminEditUserDialogMobile
                open={showEditUserMobileDialog}
                setOpen={setShowEditUserMobileDialog}
                handleChange={handleChange}
                handleImageChange={handleImageChange}
                user={userMobileData}
                setUserMobileData={setUserMobileData}
                handleUpdateUser={handleUpdateUser}
                inputRef={inputRef}
                setImageUserId={setImageUserId}
            />

        </Dialog>
    );
};

export default SuperAdminEditUsersDialog;