import React from 'react';
import {
    Dialog,
    Toolbar,
    AppBar,
    Typography,
    Button,
    IconButton,
    Paper,
    Grid,
    FormLabel,
    TextField,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    Snackbar,
    LinearProgress,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import { Slide } from '@material-ui/core';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { srLatn } from "date-fns/locale";
import { ExpandMoreSharp } from '@material-ui/icons';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { getTourDataForEditAction, updateTourAction } from '../actions/tours_actions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const snackBarInitialState = {
    show: false,
    severity: 'info',
    snackTitle: '',
    snackMessage: ''
};

const tourInitialData = {
    tourId: '',
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    type: '',
    max_capacity: '',
    duration: '',
    language: '',
    price: '',
    contact_only_by_phone: true,
    email_for_reservation: '',
    website: '',
    imageGallery: [],
    otherPeriods: [],
    phoneNumbers: [],
    photosToRemove: [],
    periodsToRemove : []
};

const phoneNumbers = [
    {
        phoneNumber: '',
        viber: false,
        whatsapp: false
    },
    {
        phoneNumber: '',
        viber: false,
        whatsapp: false
    },
    {
        phoneNumber: '',
        viber: false,
        whatsapp: false
    }
];

const certainPeriodInitialData = {
    period_from: null,
    period_to: null,
    period_price: ''
};

const tourTypes = ['Grupna tura', 'Privatna tura'];

const tourLanguages = ['Crnogorski',
    'Engleski',
    'Ruski',
    'Njemački',
    'Italijanski',
    'Francuski',
    'Albanski',
    'Mađarski',
    'Slovenski',
    'Rumunski',
    'Češki',
    'Švedski',
    'Poljski'
];

const phones = [1, 2, 3];

const EditTourDialog = ({ editTourState, setEditTourState }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [certainPeriod, setCertainPeriod] = React.useState(certainPeriodInitialData);

    const [tourData, setTourData] = React.useState(tourInitialData);

    const [imageGalleryToBeDisplayed, setImageGalleryToBeDisplayed] = React.useState([]);

    const [mainPhotoIndex, setMainPhotoIndex] = React.useState(null);

    const [showSnackBar, setSnackBar] = React.useState({
        show: false,
        variant: 'info',
        message: ''
    });

    const handleCloseSnackBar = () => {
        setSnackBar({
            show: false,
            variant: 'info',
            message: ''
        });
    };

    const tourDataForEditSelector = useSelector(state => state.getTourDataForEditState);
    const { loading, tourDataForEdit } = tourDataForEditSelector;

    const updateTourSelector = useSelector(state => state.updateTourState);
    const { loading_update, updateTour } = updateTourSelector;

    const handleClose = () => {
        setEditTourState({
            show: false,
            tourId: null
        });
    };

    const handleImageChange = async (e) => {
        if (e.target.files) {
            const filesArray = await Promise.all(Array.from(e.target.files).map(async (file) => {
                return {
                    photo_id: null,
                    path: await readFileAsBase64(file),
                    photo_originalname: file.name,
                    is_main: false
                }
            }));
            setTourData((prevData) => {
                return {
                    ...prevData,
                    imageGallery: prevData.imageGallery.concat(filesArray)
                }
            });
            setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleRemoveImageFromGallery = (index, photo_id) => {
        setTourData((prevData) => {
            return {
                ...prevData,
                imageGallery: prevData.imageGallery.filter((photo, photoIndex) => photoIndex !== index),
                photosToRemove: [...prevData.photosToRemove, photo_id !== null ? photo_id : null]
            }
        });
        setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    const handleAddNewPeriod = () => {
        const otherPeriodsUpdated = [...tourData.otherPeriods, certainPeriod];
        setTourData({
            ...tourData,
            otherPeriods: otherPeriodsUpdated
        });
        setCertainPeriod(certainPeriodInitialData);
    };

    const handleRemoveCertainPeriod = (index, periodId) => {
        setTourData((prevData) => ({
            ...prevData,
            otherPeriods: [...prevData.otherPeriods].filter((period, indexPeriod) => indexPeriod !== index),
            periodsToRemove: [...prevData.periodsToRemove, periodId ?? periodId]
        }));
    };

    React.useEffect(() => {
        if (editTourState.tourId !== null) {
            dispatch(getTourDataForEditAction(editTourState.tourId));
        }
    }, [editTourState]);

    React.useEffect(() => {
        if (tourDataForEdit?.success) {

            const loadedData = { ...tourDataForEdit.tour };

            if (loadedData.tourPhoneNumbers.length > 0) {
                for (let i = 0; i < loadedData.tourPhoneNumbers.length; i++) {
                    phoneNumbers[i] = { ...loadedData.tourPhoneNumbers[i] };
                }
            }

            setTourData({
                ...tourData,
                tourId: loadedData.id,
                name: loadedData.name,
                address: loadedData.address,
                max_capacity: loadedData.max_capacity,
                contact_only_by_phone: loadedData.contact_only_by_phone,
                duration: loadedData.duration,
                email_for_reservation: loadedData.email_for_reservation,
                language: tourLanguages.findIndex(language => language === loadedData.language),
                latitude: loadedData.latitude,
                longitude: loadedData.longitude,
                website: loadedData.website,
                price: loadedData.price,
                type: tourTypes.findIndex(type => type === loadedData.type),
                phoneNumbers,
                imageGallery: [...loadedData.tourPhotoGallery],
                otherPeriods: [...loadedData.tourOtherPeriods]
            });
            setImageGalleryToBeDisplayed([...loadedData.tourPhotoGallery])

        }
    }, [tourDataForEdit]);


    React.useEffect(() => {
        if (typeof updateTour !== 'undefined') {
            if (updateTour?.success) {
                setSnackBar({
                    show: true,
                    variant: 'success',
                    message: 'Podaci ažurirani'
                })
            } else {
                setSnackBar({
                    show: false,
                    variant: 'error',
                    message: 'Greška u ažuriranju'
                });
            }
        }
    }, [updateTour]);

    const handleChange = (e) => {
        setTourData({
            ...tourData,
            [e.target.name]: e.target.value
        });
    };

    function AddMarkerToClick() {
        useMapEvents({
            click(e) {
                setTourData({
                    ...tourData,
                    latitude: e.latlng.lat,
                    longitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[tourData.latitude, tourData.longitude]} />
    };

    const handleUpdateTour = (e) => {
        e.preventDefault();
        console.log(tourData);
        dispatch(updateTourAction(tourData));
    };


    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };

    return (
        <Dialog
            fullScreen
            open={editTourState.show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#006442' }}>
                <Toolbar className={classes.accUnitToolbar}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CancelIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Ažuriranje ture
                        </Typography>
                    </div>
                    <div>
                        <Button
                            form="myForm"
                            type='submit'
                            style={{ backgroundColor: '#26A65B', color: 'white' }}
                            variant='contained'>
                            Sačuvaj
                        </Button>
                    </div>
                </Toolbar>
                {
                    loading_update
                        ?
                        <div>
                            <LinearProgress color='secondary' />
                        </div>
                        :
                        null
                }
            </AppBar>


            {
                loading
                    ?
                    <div style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>
                        <CircularProgress size={150} />
                    </div>
                    :
                    typeof tourDataForEdit !== 'undefined'
                        ?
                        tourDataForEdit.success
                            ?
                            <div className={classes.editObjectDiv}>

                                <form onSubmit={handleUpdateTour} className={classes.addNewObjectDiv} id='myForm'>

                                    <Paper elevation={7} className={classes.addNewObject_section1}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o turi</Typography></div>
                                        <Grid container spacing={4} className={classes.section1Form}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    id="objectName"
                                                    label="Naziv ture"
                                                    name="name"
                                                    fullWidth
                                                    type="text"
                                                    value={tourData.name}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InputLabel id="demo-simple-select-label">Tip ture</InputLabel>
                                                <Select
                                                    fullWidth
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    name='type'
                                                    value={tourData.type}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {
                                                        tourTypes.map((tourType, index) => {
                                                            return (
                                                                <MenuItem value={index} key={index}>
                                                                    {tourType}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel id="demo-simple-select-label">Jezik ture</InputLabel>
                                                <Select
                                                    fullWidth
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    name='language'
                                                    value={tourData.language}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {
                                                        tourLanguages.map((language, index) => {
                                                            return (
                                                                <MenuItem value={index} key={index}>
                                                                    {language}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    id="objectName"
                                                    label="Cijena po osobi"
                                                    name="price"
                                                    fullWidth
                                                    type="text"
                                                    value={tourData.price}
                                                    onChange={handleChange}
                                                />
                                            </Grid>


                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    id="objectName"
                                                    label="Max broj osoba"
                                                    name="max_capacity"
                                                    fullWidth
                                                    type="text"
                                                    value={tourData.max_capacity}
                                                    onChange={handleChange}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    id="objectName"
                                                    label="Trajanje ture"
                                                    name="duration"
                                                    fullWidth
                                                    type="text"
                                                    value={tourData.duration}
                                                    onChange={handleChange}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                                                <div className={classes.section1GalleryTitle}  >
                                                    <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        color='primary'
                                                    >
                                                        <AddIcon fontSize='small' />
                                                        <input
                                                            id='dugme'
                                                            type="file"
                                                            onChange={handleImageChange}
                                                            multiple
                                                            accept='image/*'
                                                            hidden
                                                        />
                                                    </Button>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                                        Izabrana glavna fotografija
                                                    </Typography>
                                                    {
                                                        checkSelectedImage(tourData.imageGallery) !== -1
                                                            ?
                                                            <DoneIcon style={{ color: '#26A65B' }} fontSize='large' />
                                                            :
                                                            <CloseIcon color='error' fontSize='large' />
                                                    }
                                                </div>
                                                <div className={classes.section1ImagePreviewDiv}>
                                                    {
                                                        imageGalleryToBeDisplayed.map((photo, index) => {
                                                            return (
                                                                <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                                    <img
                                                                        onClick={() => {
                                                                            const gallery = tourData.imageGallery.map((photo, indexG) => {
                                                                                if (index === indexG) {
                                                                                    photo.is_main = true;
                                                                                    return photo
                                                                                } else {
                                                                                    photo.is_main = false;
                                                                                    return photo;
                                                                                }
                                                                            });
                                                                            setTourData((prevData) => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    imageGallery: [...gallery]
                                                                                }
                                                                            });
                                                                        }}
                                                                        src={photo.photo_id === null ? photo.path : process.env.REACT_APP_UPLOADS_PATH + photo.path}
                                                                        width={210}
                                                                        alt={`Object ${index}`}
                                                                        style={{
                                                                            borderRadius: '10px',
                                                                            cursor: 'pointer',
                                                                            border: checkSelectedImage(tourData.imageGallery) === index ? '4px solid green' : '1px solid transparent'
                                                                        }} />
                                                                    <div key={2} className={classes.editImage} >
                                                                        <IconButton onClick={() => { handleRemoveImageFromGallery(index, photo.photo_id); }}>
                                                                            <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Paper>

                                    <Paper elevation={7} className={classes.addNewObject_section2}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={12} md={12}>

                                                <MapContainer
                                                    center={[42.207232, 18.943100]}
                                                    zoom={16}
                                                    tap={false}
                                                    className={classes.map}
                                                >
                                                    <TileLayer
                                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />

                                                    <AddMarkerToClick />
                                                </MapContainer>

                                            </Grid>

                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectLatitude"
                                                    label="Latituda"
                                                    name="latitude"
                                                    value={tourData.latitude}
                                                    onChange={handleChange}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectLongitude"
                                                    label="Longituda"
                                                    name="longitude"
                                                    value={tourData.longitude}
                                                    onChange={handleChange}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="objectAddress"
                                                    label="Adresa"
                                                    name="address"
                                                    value={tourData.address}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <Paper elevation={7} className={classes.addNewObject_section4}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Cijena po periodima</Typography></div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>

                                            <Grid item xs={6} md={12} style={{ margin: 'auto' }}>
                                                <Accordion elevation={1}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreSharp />}>
                                                        <Typography>Dodaj novi period</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <MuiPickersUtilsProvider locale={srLatn} utils={DateFnsUtils}>
                                                            <Grid container spacing={4}>
                                                                <Grid item xs={12} md={3}>
                                                                    <DatePicker
                                                                        required
                                                                        color='secondary'
                                                                        disablePast
                                                                        format="dd/MM/yyyy"
                                                                        disableToolbar
                                                                        autoOk
                                                                        variant='dialog'
                                                                        label="Od"
                                                                        helperText="Period od"
                                                                        value={certainPeriod.period_from}
                                                                        onChange={(e) => {
                                                                            setCertainPeriod((certainPeriod) => {
                                                                                return {
                                                                                    ...certainPeriod,
                                                                                    period_from: e
                                                                                }
                                                                            })
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <DateRangeTwoToneIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                    <DatePicker
                                                                        disabled={certainPeriod.period_from ? false : true}
                                                                        required={certainPeriod.period_from ? true : false}
                                                                        color='secondary'
                                                                        minDate={certainPeriod.period_from}
                                                                        disableToolbar
                                                                        disablePast
                                                                        format="dd/MM/yyyy"
                                                                        autoOk
                                                                        variant='dialog'
                                                                        label="Do"
                                                                        helperText="Period do"
                                                                        value={certainPeriod.period_to}
                                                                        onChange={(e) => {
                                                                            setCertainPeriod((certainPeriod) => {
                                                                                return {
                                                                                    ...certainPeriod,
                                                                                    period_to: e
                                                                                }
                                                                            })
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <DateRangeTwoToneIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        id="numOfGrownups"
                                                                        label="Cijena"
                                                                        name="numOfGrownups"
                                                                        type="number"
                                                                        value={certainPeriod.period_price}
                                                                        onChange={(e) => {
                                                                            setCertainPeriod({
                                                                                ...certainPeriod,
                                                                                period_price: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                    <Button onClick={handleAddNewPeriod} variant='contained'>
                                                                        <AddIcon />
                                                                        Dodaj
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </MuiPickersUtilsProvider>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <div className={classes.periodsDiv}>
                                                    {
                                                        tourData.otherPeriods.map((period, index) => {
                                                            return (
                                                                <div className={classes.singlePeriod} key={index} >
                                                                    <Typography variant='subtitle1'>
                                                                        Od: <strong>
                                                                            {typeof period.period_from === 'object'
                                                                                ?
                                                                                format(period.period_from, 'dd/MM/yyyy')
                                                                                :
                                                                                format(parseISO(period.period_from), 'dd/MM/yyyy')}
                                                                        </strong>
                                                                    </Typography>
                                                                    <Typography variant='subtitle1'>
                                                                        Do: <strong>
                                                                            {typeof period.period_to === 'object'
                                                                                ?
                                                                                format(period.period_to, 'dd/MM/yyyy')
                                                                                :
                                                                                format(parseISO(period.period_to), 'dd/MM/yyyy')}
                                                                        </strong>
                                                                    </Typography>
                                                                    <Typography variant='subtitle1'>
                                                                        Cijena: <strong>{period.period_price} &euro;</strong>
                                                                    </Typography>
                                                                    <IconButton onClick={() => {
                                                                        handleRemoveCertainPeriod(index,period.id);
                                                                    }} style={{ color: '#DC3023' }}>
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Grid>


                                        </Grid>
                                    </Paper>

                                    <Paper elevation={7} className={classes.addNewObject_section3}>
                                        <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt podaci</Typography></div>
                                        <Grid container spacing={4} className={classes.section2MapDiv}>
                                            <Grid item xs={12} md={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={tourData.contact_only_by_phone}
                                                                    onChange={(e) => {
                                                                        setTourData({
                                                                            ...tourData,
                                                                            contact_only_by_phone: true
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Da"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!tourData.contact_only_by_phone}
                                                                    onChange={(e) => {
                                                                        setTourData({
                                                                            ...tourData,
                                                                            contact_only_by_phone: false
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label="Ne"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            {
                                                phones.map((phone, index) => {
                                                    return (
                                                        <Grid item xs={12} md={12} key={index}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                required={index === 0 && tourData.contact_only_by_phone ? true : false}
                                                                id="numOfGrownups"
                                                                label={`Broj telefona ${phone}`}
                                                                name="phoneNum"
                                                                type="text"
                                                                value={tourData.phoneNumbers[index]?.phoneNumber}
                                                                onChange={(e) => {
                                                                    setTourData((prevData) => {
                                                                        prevData.phoneNumbers[index].phoneNumber = e.target.value;
                                                                        return { ...prevData }
                                                                    });
                                                                }}
                                                            />
                                                            <FormControl component="fieldset">
                                                                <FormGroup row>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={Boolean(tourData.phoneNumbers[index]?.viber)}
                                                                                onChange={(e) => {
                                                                                    setTourData((prevData) => {
                                                                                        prevData.phoneNumbers[index].viber = e.target.checked;
                                                                                        return { ...prevData }
                                                                                    });
                                                                                }}
                                                                            />
                                                                        }
                                                                        label="Viber"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={Boolean(tourData.phoneNumbers[index]?.whatsapp)}
                                                                                onChange={(e) => {
                                                                                    setTourData((prevData) => {
                                                                                        prevData.phoneNumbers[index].whatsapp = e.target.checked;
                                                                                        return { ...prevData }
                                                                                    });
                                                                                }}
                                                                            />
                                                                        }
                                                                        label="Whatsapp"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    id="restaurantEmail"
                                                    label="Email adresa"
                                                    name="email_for_reservation"
                                                    type="text"
                                                    value={tourData.email_for_reservation}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    id="restaurantWebSite"
                                                    label="Link vaseg web sajta"
                                                    name="website"
                                                    type="text"
                                                    value={tourData.website}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Paper>
                                </form>
                            </div>
                            :
                            <Alert severity="error" style={{ marginTop: '20%' }}>
                                <AlertTitle>Greška</AlertTitle>
                                Doslo je do greske
                            </Alert>
                        :
                        null
            }


            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                open={showSnackBar.show}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}>
                <Alert
                    variant='filled'
                    onClose={handleCloseSnackBar}
                    severity={showSnackBar.variant}
                    sx={{ width: '100%' }}>
                    {showSnackBar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default EditTourDialog;