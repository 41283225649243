import {
  Button,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  LinearProgress
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { addNewCarAction } from '../actions/rent_a_car_actions';
import { useDispatch, useSelector } from 'react-redux';
import useStyle from './style';

const newCarInitialData = {
  manufacturer: '',
  model: '',
  engine: '',
  year: '',
  type: '',
  num_of_seats: '',
  num_of_doors: '',
  cubic_capacity: '',
  kilowatts: '',
  transmission_type: '',
  fuel: '',
  one_to_three_days: '',
  four_to_seven_days: '',
  eight_to_fifteen_days: '',
  sixteen_to_thirty_days: '',
  thirtyone_to_fortyfive_days: '',
  fortyfive_plus_days: '',
  child_seat: '',
  gps_navigation: '',
  wifi_4g_mobile_router: '',
  insurance: '',
  deposit: '',
  vehicle_delivery: '',
  contact_only_by_phone: '',
  email_for_reservation: '',
  website: '',
  imageGallery: [],
  phoneNumbers: [
    {
      phoneNumber: '',
      viber: false,
      whatsapp: false
    },
    {
      phoneNumber: '',
      viber: false,
      whatsapp: false
    },
    {
      phoneNumber: '',
      viber: false,
      whatsapp: false
    }
  ]
};

const carModels = ['Audi', 'Mercedes', 'Bmw', 'Volkswagen', 'Citroen', 'Renault', 'Peugeot', 'Seat', 'Nissan', 'Jeep', 'Toyota', 'Škoda', 'Fiat', 'Ford', 'Honda'];

const carTypes = ['Limuzina', 'Hečbek', 'Karavan', 'Kupe', 'Kabriolet'];

const carFuelType = ['Benzin', 'Dizel', 'Benzin + Plin', 'Električno', 'Hibrid benzin', 'Hibrid dizel'];

const carTransmissionType = ['Manuelni', 'Automatski', 'Triptonik'];

const phones = [1, 2, 3];

const AddNewCar = ({ switchActivePanel }) => {

  const classes = useStyle();
  const dispatch = useDispatch();

  const [carData, setCarData] = React.useState(newCarInitialData);

  const [imageGalleryToBeDisplayed, setImageGalleryToBeDisplayed] = React.useState([]);

  const addNewCarSelector = useSelector(state => state.addNewCarState);
  const { loading, newCar } = addNewCarSelector;

  const [mainPhotoIndex, setMainPhotoIndex] = React.useState(null);

  const handleImageChange = (e) => {
    if (e.target.files) {
      setCarData((prevData) => {
        return {
          ...prevData,
          imageGallery: prevData.imageGallery.concat([...e.target.files])
        }
      });
      const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
      setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.concat(filesArray));
    }
  };

  const handleRemoveImageFromGallery = (index) => {
    setCarData((prevData) => {
      return {
        ...prevData,
        imageGallery: prevData.imageGallery.filter((photo, photoIndex) => photoIndex !== index)
      }
    });
    setImageGalleryToBeDisplayed((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
  };

  const handleChange = (e) => {
    setCarData({
      ...carData,
      [e.target.name]: e.target.value
    });
  };

  const handleAddNewCar = (e) => {
    e.preventDefault();
    const formData = new FormData();
    carData.imageGallery.forEach(photo => {
      formData.append('carPhoto', photo);
    });
    Object.keys(carData).forEach(key => {
      if (key === 'phoneNumbers' || key === 'imageGallery') return;
      formData.append(key, carData[key]);
    });
    formData.append('phoneNumbers', JSON.stringify(carData.phoneNumbers));
    formData.append('mainPhotoIndex', mainPhotoIndex);
    dispatch(addNewCarAction(formData));
  };

  const checkSelectedImage = (gallery) => {
    const index = gallery.findIndex((photo) => photo.is_main);
    return index;
  };

  return (
    <div className={classes.addNewObjectDiv}>

      <div style={{ marginBottom: '25px' }}>
        <Typography
          style={{ cursor: 'pointer' }}
          variant='h5'
          color='primary'
          onClick={() => { switchActivePanel('myCars'); }}>
          Odustani
        </Typography>
      </div>

      <form onSubmit={handleAddNewCar} className={classes.addNewObjectDiv}>

        <Paper elevation={7} className={classes.addNewObject_section1}>
          <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o automobilu</Typography></div>
          <Grid container spacing={4} className={classes.section1Form}>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Proizvođač</InputLabel>
              <Select
                fullWidth
                required
                labelId="demo-simple-select-label"
                name='manufacturer'
                value={carData.manufacturer}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {
                  carModels.map((carModel, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {carModel}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Tip</InputLabel>
              <Select
                fullWidth
                required
                labelId="demo-simple-select-label"
                name='type'
                value={carData.type}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {
                  carTypes.map((carType, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {carType}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                id="objectName"
                label="Model"
                name="model"
                fullWidth
                type="text"
                value={carData.model}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Oznaka"
                fullWidth
                name="engine"
                type="text"
                value={carData.engine}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Godište"
                fullWidth
                name="year"
                type="text"
                value={carData.year}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Broj sjedišta"
                fullWidth
                name="num_of_seats"
                type="text"
                value={carData.num_of_seats}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Broj vrata"
                fullWidth
                name="num_of_doors"
                type="text"
                value={carData.num_of_doors}
                onChange={handleChange}
              />
            </Grid>


            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Kubikaža"
                fullWidth
                name="cubic_capacity"
                type="text"
                value={carData.cubic_capacity}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Kilovata"
                fullWidth
                name="kilowatts"
                type="text"
                value={carData.kilowatts}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Mijenjač</InputLabel>
              <Select
                fullWidth
                required
                labelId="demo-simple-select-label"
                name='transmission_type'
                value={carData.transmission_type}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {
                  carTransmissionType.map((transmissionType, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {transmissionType}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Gorivo</InputLabel>
              <Select
                fullWidth
                required
                labelId="demo-simple-select-label"
                name='fuel'
                value={carData.fuel}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {
                  carFuelType.map((fuelType, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {fuelType}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>

            <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
              <div className={classes.section1GalleryTitle}>
                <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                <Button
                  variant="contained"
                  component="label"
                  color='primary'
                >
                  <AddIcon fontSize='small' />
                  <input
                    id='dugme'
                    type="file"
                    onChange={handleImageChange}
                    multiple
                    accept='image/*'
                    hidden
                  />
                </Button>
              </div>
              <div className={classes.section1ImagePreviewDiv}>
                {
                  imageGalleryToBeDisplayed.map((photo, index) => {
                    return (
                      <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                        <img
                          onClick={() => {
                            setMainPhotoIndex(index);
                            const gallery = carData.imageGallery.map((photo, indexG) => {
                              if (index === indexG) {
                                photo.is_main = true;
                                return photo
                              } else {
                                photo.is_main = false;
                                return photo;
                              }
                            });
                            setCarData((prevData) => {
                              return {
                                ...prevData,
                                imageGallery: [...gallery]
                              }
                            });
                          }}
                          src={photo}
                          width={210}
                          alt={`Object ${index}`}
                          style={{
                            borderRadius: '10px',
                            cursor: 'pointer',
                            border: checkSelectedImage(carData.imageGallery) === index ? '4px solid green' : '1px solid transparent'
                          }} />
                        <div key={2} className={classes.editImage} >
                          <IconButton onClick={() => { handleRemoveImageFromGallery(index); }}>
                            <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                          </IconButton>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={7} className={classes.addNewObject_section1}>
          <div className={classes.sectionTitle}><Typography variant='h5'>Broj dana - cijena po danu</Typography></div>
          <Grid container spacing={4} className={classes.section1Form}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="1 do 3 dana"
                fullWidth
                name="one_to_three_days"
                type="text"
                value={carData.one_to_three_days}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="4 do 7 dana"
                fullWidth
                name="four_to_seven_days"
                type="text"
                value={carData.four_to_seven_days}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="8 do 15 dana"
                fullWidth
                name="eight_to_fifteen_days"
                type="text"
                value={carData.eight_to_fifteen_days}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="16 do 30 dana"
                fullWidth
                name="sixteen_to_thirty_days"
                type="text"
                value={carData.sixteen_to_thirty_days}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="31 do 45 dana"
                fullWidth
                name="thirtyone_to_fortyfive_days"
                type="text"
                value={carData.thirtyone_to_fortyfive_days}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="45+ dana"
                fullWidth
                name="fortyfive_plus_days"
                type="text"
                value={carData.fortyfive_plus_days}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={7} className={classes.addNewObject_section1}>
          <div className={classes.sectionTitle}><Typography variant='h5'>Oprema u vozilu - cijena po danu</Typography></div>
          <Grid container spacing={4} className={classes.section1Form}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Sjedište za djecu"
                fullWidth
                name="child_seat"
                type="text"
                value={carData.child_seat}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="GPS navigacija"
                fullWidth
                name="gps_navigation"
                type="text"
                value={carData.gps_navigation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Wifi 4g mobilni ruter"
                fullWidth
                name="wifi_4g_mobile_router"
                type="text"
                value={carData.wifi_4g_mobile_router}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Paper>


        <Paper elevation={7} className={classes.addNewObject_section1}>
          <div className={classes.sectionTitle}><Typography variant='h5'>Ostalo - cijena po danu</Typography></div>
          <Grid container spacing={4} className={classes.section1Form}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Osiguranje"
                fullWidth
                name="insurance"
                type="text"
                value={carData.insurance}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Depozit"
                fullWidth
                name="deposit"
                type="text"
                value={carData.deposit}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Dostava vozila"
                fullWidth
                name="vehicle_delivery"
                type="text"
                value={carData.vehicle_delivery}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={7} className={classes.addNewObject_section1}>
          <div className={classes.sectionTitle}><Typography variant='h5'>Kontakt</Typography></div>
          <Grid container spacing={4} className={classes.section1Form}>
            <Grid item xs={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={carData.contact_only_by_phone}
                        onChange={(e) => {
                          setCarData({
                            ...carData,
                            contact_only_by_phone: true
                          });
                        }}
                      />
                    }
                    label="Da"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!carData.contact_only_by_phone}
                        onChange={(e) => {
                          setCarData({
                            ...carData,
                            contact_only_by_phone: false
                          });
                        }}
                      />
                    }
                    label="Ne"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {
              phones.map((phone, index) => {
                return (
                  <Grid item xs={12} md={12} key={index}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required={index === 0 && carData.contact_only_by_phone ? true : false}
                      id="numOfGrownups"
                      label={`Broj telefona ${phone}`}
                      name="phoneNum"
                      type="text"
                      value={carData.phoneNumbers[index].phoneNumber}
                      onChange={(e) => {
                        setCarData((prevData) => {
                          prevData.phoneNumbers[index].phoneNumber = e.target.value;
                          return { ...prevData }
                        });
                      }}
                    />
                    <FormControl component="fieldset">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={carData.phoneNumbers[index].viber}
                              onChange={(e) => {
                                setCarData((prevData) => {
                                  prevData.phoneNumbers[index].viber = e.target.checked;
                                  return { ...prevData }
                                });
                              }}
                            />
                          }
                          label="Viber"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={carData.phoneNumbers[index].whatsapp}
                              onChange={(e) => {
                                setCarData((prevData) => {
                                  prevData.phoneNumbers[index].whatsapp = e.target.checked;
                                  return { ...prevData }
                                });
                              }}
                            />
                          }
                          label="Whatsapp"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )
              })
            }
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email adresa"
                name="email_for_reservation"
                type="text"
                value={carData.email_for_reservation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                required
                label="Link vaseg web sajta"
                name="website"
                type="text"
                value={carData.website}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Paper>

        <div>
          {
            loading
              ?
              <LinearProgress />
              :
              typeof newCar !== 'undefined'
                ?
                newCar.success
                  ?
                  <Alert severity="success" style={{ marginBottom: '20px' }}>
                    <AlertTitle>Uspješno</AlertTitle>
                    Novi automobil dodat
                  </Alert>
                  :
                  <Alert severity="error" style={{ marginBottom: '20px' }}>
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno kreiranje automobila
                  </Alert>
                :
                null
          }
        </div>
        <div className={classes.addNewObjectButtons}>
          <Button
            type='submit'
            style={{ backgroundColor: '#26A65B', color: 'white' }} >
            Dodaj
          </Button>
          <Button
            onClick={() => { switchActivePanel('myCars'); }}
            variant='text'
            style={{ color: '#DC3023' }}>
            <CancelIcon />
            Odustani
          </Button>
        </div>

      </form>
    </div>
  );
};

export default AddNewCar