import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, Slide, Divider, LinearProgress, Grid } from '@material-ui/core';
import useStyles from './style';
import { AlertTitle, Alert } from '@material-ui/lab';
import { Carousel } from 'react-responsive-carousel';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { superAdminPreviewAccObjectAction } from '../actions/acc_objects_actions';
import SuperAdminPreviewAccUnitPhotos from './SuperAdminPreviewAccUnitPhotos';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const SuperAdminPreviewAccObject = ({ previewAccObjectId, show, setShow }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow({
            show: false,
            previewAccObjectId: null
        });
    };

    const [ showPreviewAccUnitPhotos, setShowPreviewAccUnitPhotos ] = React.useState({
        show : false,
        accUntitId : null,
        accUnitName : null
    });

    const previewAccObjectSelector = useSelector(state => state.previewAccObjectState);
    const { loading, previewAccObjectData: data } = previewAccObjectSelector;

    React.useEffect(() => {
        if (previewAccObjectId !== null) {
            dispatch(superAdminPreviewAccObjectAction(previewAccObjectId));
        }
    }, [previewAccObjectId]);

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ margin:'auto' }}>
                <AlertTitle>Greška</AlertTitle>
                Greška u prikazivanju objekta
            </Alert>
        );
    };

    return (
        <Dialog
            fullScreen
            open={show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#003171' }} >
                <Toolbar className={classes.previewToolbar}>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                        Pregled podataka objekta
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CancelIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {
                loading
                    ?
                    <div style={{
                        width: "50%",
                        margin: 'auto'
                    }}>
                        <Typography variant='h5'>Učitavanje podataka...</Typography>
                        <LinearProgress color='primary' />
                    </div>
                    :
                    typeof data !== 'undefined'
                        ?
                        data?.success
                            ?
                            <div className={classes.previewDiv}>
                                <div className={classes.previewGallery}>
                                    {
                                        data.accObject.photos.length > 0
                                            ?
                                            <Carousel
                                                emulateTouch
                                                className={classes.carousel}
                                                swipeable
                                                showStatus={false}>
                                                {
                                                    data.accObject.photos.map((photo, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <img src={process.env.REACT_APP_UPLOADS_PATH + photo} alt='Accommodation Object' />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                            :
                                            <Typography variant='h3'>
                                                Nema slika
                                            </Typography>
                                    }

                                </div>
                                <div className={classes.previewInfo}>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Ime</strong> </Typography>
                                        <Typography variant='h6'>{data.accObject.name}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Adresa</strong> </Typography>
                                        <Typography variant='h6'>{data.accObject.address}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Sajt</strong> </Typography>
                                        <Typography variant='h6'>{data.accObject.web_site}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Opis</strong> </Typography>
                                        <Typography variant='subtitle1'>{data.accObject.description}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='subtitle1'> <strong>Detalji plaćanja</strong> </Typography>
                                        <Typography variant='subtitle1'>{data.accObject.payment_data}</Typography>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <Typography><strong>Smještajne jedinice</strong></Typography>
                                        {
                                            data.accObject.accUnits.length > 0
                                            ?
                                            <Grid container spacing={4} style={{ marginTop: '20px' }}>
                                            {
                                                data.accObject.accUnits.map((unit, index) => {
                                                    return (
                                                        <Grid item key={index} xs={4} md={6}>
                                                            <Button
                                                                onClick={()=>{
                                                                    setShowPreviewAccUnitPhotos({
                                                                        show : true,
                                                                        accUnitName : unit.name,
                                                                        accUntitId : unit.id
                                                                    })
                                                                }} 
                                                                variant='outlined'>
                                                                {unit.name}
                                                            </Button>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        :
                                        <Typography variant='h4'>
                                            Nema smještajnih jedinica
                                        </Typography>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <ShowError />
                        :
                        null

            }
            <SuperAdminPreviewAccUnitPhotos
                show={showPreviewAccUnitPhotos.show}
                setShow={setShowPreviewAccUnitPhotos}
                accUnitName={showPreviewAccUnitPhotos.accUnitName}
                accUnitId={showPreviewAccUnitPhotos.accUntitId}/>
        </Dialog>
    );
};

export default SuperAdminPreviewAccObject;