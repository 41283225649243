import { Typography, CircularProgress } from '@material-ui/core';
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './style';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmailAction } from '../actions/users_actions';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const EmailVerification = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { token } = useParams();

    const verifyEmailSelectorData = useSelector(state => state.verifyEmail);
    const { loading, verifyEmailData } = verifyEmailSelectorData;

    React.useEffect(() => {
        try {
            const decode = jwt_decode(token);
            dispatch(verifyEmailAction({token}));
        } catch (error) {
            navigate('/register');
        }
    }, []);

    React.useEffect(()=>{
        if( typeof verifyEmailData !== 'undefined' ) {
            verifyEmailData?.success ? redirect('login') : redirect('register');
        };
    },[verifyEmailData]);

    const redirect = (route) => {
        setTimeout(()=>{
            navigate(`/${route}`);
        },4000);
    };

    return (
        <div className={classes.verificationDiv}>
            {
                loading
                    ?
                    <div className={classes.loading}>
                        <Typography variant='h5' style={{ marginBottom: '40px' }}>Verifikacija email-a</Typography>
                        <CircularProgress size={170} />
                    </div>
                    :
                    null
            }
            {
                typeof verifyEmailData !== 'undefined'
                    ?
                    !verifyEmailData.success
                        ?
                        <div>
                            <Alert severity="error" style={{ marginBottom: '20px' }}>
                                <AlertTitle>Neuspjela verifikacija</AlertTitle>
                                Bićete preusmjereni na stranicu za registraciju za 3 sekunde
                            </Alert>
                        </div>
                        :
                        <div>
                            <Alert severity="success" style={{ marginBottom: '20px' }}>
                                <AlertTitle>Uspješna verifikacija</AlertTitle>
                                Vaš email je uspješno verifikovan bićete preusmjereni na stranicu za prijavu za 3 sekunde
                            </Alert>
                        </div>
                    :
                    null
            }

        </div>
    )
}

export default EmailVerification;