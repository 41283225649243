import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@material-ui/core';
import useStyles from './style';

const EquipmentFilterDialog = ({ open, setOpen, equipmentFilter, setEquipmentFilter, currentLanguage }) => {


    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const switchAllItemsStatus = (newStatus) => {
        setEquipmentFilter((prevData)=>{
            const updatedFilters = prevData.map((dataLang)=>{
                return dataLang.map((item)=>{
                    return {
                        ...item,
                        status : newStatus
                    }
                })
            });
            return updatedFilters;
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Obilježite željenu opremljenost. <span onClick={()=>{switchAllItemsStatus(true);}} style={{ color : '#0ca6d8', cursor : 'pointer', marginLeft : '5px' }}>obilježi sve ?</span>
            </DialogTitle>
            
            <DialogContent className={classes.equipmentPickerDiv}>

                {
                    equipmentFilter[currentLanguage].map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {

                                    const updatedState = [...equipmentFilter].map((dataLang) => {
                                        return dataLang.map((upItem) => {
                                            if (upItem.equipmentName === item.equipmentName) {
                                                upItem.status = !upItem.status;
                                                return upItem;
                                            } else return upItem;
                                        });
                                    });

                                    setEquipmentFilter(updatedState);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    color: item.status ? '#0F79A1' : '#95A5A6',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                <img src={item.icon} width='20' alt='Item' style={{ marginRight: '10px' }} />
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}>
                                    {item.nameToDisplay}
                                </Typography>
                            </div>
                        )
                    })
                }

            </DialogContent>
            <DialogActions>
                <Button disableElevation variant='contained' onClick={()=>{switchAllItemsStatus(false);}}>Resetuj</Button>
                <Button style={{ backgroundColor: "#ce0b0b", color: 'white' }} onClick={handleClose}>Zatvori</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EquipmentFilterDialog