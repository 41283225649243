import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        borderRadius: '6px',
        '&:hover': {
            transform: 'scale(1.03)',
        },
        transition: "transform .5s ease-in-out",
        [theme.breakpoints.down('xs')]: {
            height : '100%',
            width : '100%',
            transition: "none",
            '&:hover': {
                transform: 'none',
            },
        }
    },
    eqDiv : {
        //border : '1px solid black',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        marginTop : '10px',
        width : '100%'
    },
    cardContent: {
        marginTop : '-10px',
        '&.MuiCardContent-root' : {
            height : '120px',
            [theme.breakpoints.down('xs')]: {
                height : '80px'
            }
        }
    },
    addToFavoritesIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'hsla(0,0%,100%,.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px',
        borderRadius: '50%'
    },
    restaurantTypes: {
        position: 'absolute',
        //border: '1px solid red',
        padding: '5px',
        left: '50%',
        top: '35%',
        transform: 'translate(-50%, -40%)',
        display  : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        width : '65%',
        cursor  :'pointer',
        [theme.breakpoints.down('xs')]: {
            display : "none"
        }
    },
    type: {
        background: 'rgba(0,0,0,0.7)',
        color: 'white',
        padding: '5px',
        borderRadius: '6px',
        margin : '5px'
    },
    isPopularMark: {
        backgroundColor: "white",
        position: 'absolute',
        top: '10px',
        left: '0',
        padding: '5px',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px'
    },
    buttonSearch: {
        [theme.breakpoints.down('xs')]: {

        }
    }
}));

export default useStyles;