import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    navigation: {
        //border : '2px solid black',
        position: 'relative',
        height: '120px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        [theme.breakpoints.down('xs')]: {
            height: '270px'
        }
    },
    innerContainer: {
        //border : '4px solid red',
        width: '100%',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    datePickerDiv: {
        //border : '1px solid red',
        width: '40%',
        margin: 'auto',
        padding: '5px',
        //marginTop: '30px',
        backgroundColor: 'white',
        borderRadius: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        // display : 'grid',
        // gridTemplateColumns : '40% 40% 20%',
        // alignItems : 'center'
    },
    pickerItem: {
        //border : '1px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor : 'pointer',
       // width : '100%',
        [theme.breakpoints.down('xs')]: {
            //border : '1px solid red',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '5px',
            marginBottom: '10px'
        }
    },
    dropdownImg: {
        marginRight: '10px',
        cursor: 'pointer',
        color: '#000000'
    },
    pickerText: {
        //border : '1px solid green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    searchButton: {
        backgroundColor: '#f79800',
        color: 'white',
        borderRadius: '20px',
    },
    test: {
        display: 'flex',
        flexDirection: 'column'
    },
    personNumPicker: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            marginTop: '10%'
        }
    },
    buttonSearch: {
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginTop: '8%'
        }
    },
    infoSection: {
        //border : '2px solid yellow',
        width: '87%',
        margin: '0 auto',
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            width: '98%',
        }
    },
    infoPart: {
        //border : '2px solid green',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },
    infoImg: {
        width: '85px',
        marginRight: '15px'
    },
    menu: {
        "& .MuiPaper-root": {
            padding: '6px',
            height: '250px',
            marginTop: '13px',
        }
    },
    menuItem: {
        //border : '2px solid green',
    },
    mobileGridSection: {
        margin: 'auto',
        padding: '5px',
        //backgroundColor: '#ECF0F1',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRadius: '0px'
        }
    },
    mobileFormField: {
        //border: '1px solid #D2D7D3',
        backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        width: '100%',
        cursor : 'pointer'
    },
}));

export default useStyles;