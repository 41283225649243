
const unitEquipment = [
    [
        'internet',
        'tv',
        'kablovska televizija',
        'radni sto',
        'klima uređaj',
        'pogled na more',
        'krevetac za bebe',
        'balkon',
        'pegla',
        'fen',
        'mašina za suđe',
        'mašina za veš',
        'kuhinja',
        'šporet',
        'frižider'
    ],
    [
        'internet',
        'tv',
        'cable tv',
        'work desk',
        'air conditioner',
        'sea view',
        'baby crib',
        'balcony',
        'iron',
        'hair dryer',
        'dishwasher',
        'washing machine',
        'kitchen',
        'stove',
        'fridge'
    ],
    [
        'Интернет',
        'телевидение',
        'кабельное телевидение',
        'стол письменный',
        'кондиционер',
        'вид на море',
        'детская кроватка',
        'балкон',
        'железо',
        'фен',
        'посудомоечная машина',
        'стиральная машина',
        'кухня',
        'плита',
        'холодильник'
    ],
    [
        'internet',
        'Fernseher',
        'Kabelfernsehen',
        'Schreibtisch',
        'Klimaanlage',
        'Meerblick',
        'Babybett',
        'Balkon',
        'Eisen',
        'Haartrockner',
        'Geschirrspüler',
        'Waschmaschine',
        'die Küche',
        'Kocher',
        'Kühlschrank'
    ],
    [
        'internet',
        'tv',
        'tv via cavo',
        'scrivania',
        'aria condizionata',
        'Vista mare',
        'culla',
        'balcone',
        'ferro',
        'asciugacapelli',
        'lavastoviglie',
        'lavatrice',
        'la cucina',
        'fornello',
        'frigo'
    ],
    [
        'internet',
        'la télé',
        'télévision par câble',
        'pupitre',
        'climatisation',
        'vue mer',
        'lit bébé',
        'balcon',
        'fer à repasser',
        'sèche-cheveux',
        'Lave-vaisselle',
        'Machine à laver',
        'la cuisine',
        'cuisinier',
        'réfrigérateur'
    ],
    [
        'internet',
        'tv',
        'televizion kabllor',
        'tavolinë',
        'kondicioner',
        'pamje nga deti',
        'shtrat i fëmijës',
        'ballkon',
        'hekuri',
        'tharese flokesh',
        'pjatalarëse',
        'makinë larëse',
        'kuzhina',
        'tenxhere',
        'frigorifer'
    ],
    [
        'internet',
        'tv',
        'kábel tv',
        'asztal',
        'légkondíciónálás',
        'tengerre néző',
        'gyermekágy',
        'erkély',
        'vas',
        'hajszárító',
        'mosogatógép',
        'mosógép',
        'a konyha',
        'tűzhely',
        'hűtőgép'
    ],
    [
        'internet',
        'tv',
        'kabelsko televizijo',
        'pisalno mizo',
        'klima',
        'pogled na morje',
        'otroška posteljica',
        'balkon',
        'železo',
        'sušilnik za lase',
        'pomivalni stroj',
        'pralni stroj',
        'kuhinja',
        'kuhar',
        'hladilnik'
    ],
    [
        'internet',
        'tv',
        'televiziune prin cablu',
        'birou',
        'aer conditionat',
        'vedere la mare',
        'pătuț pentru copii',
        'balcon',
        'fier',
        'uscător de păr',
        'spalator de vase',
        'mașină de spălat',
        'bucataria',
        'aragaz',
        'frigider'
    ],
    [
        'internet',
        'televize',
        'kabelová televize',
        'lavice',
        'klimatizace',
        'výhled na moře',
        'dětská postýlka',
        'balkón',
        'žehlička',
        'fén',
        'myčka',
        'pračka',
        'kuchyně',
        'sporák',
        'lednička'
    ],
    [
        'internet',
        'tv',
        'kabel-tv',
        'skrivbord',
        'luftkonditionering',
        'havsutsikt',
        'barnsäng',
        'balkong',
        'järn',
        'hårtork',
        'diskmaskin',
        'tvättmaskin',
        'köket',
        'spis',
        'kylskåp'
    ],
    [
        'internet',
        'tv',
        'telewizja kablowa',
        'biurko',
        'klimatyzacja',
        'widok na morze',
        'łóżeczko dla dziecka',
        'balkon',
        'żelazo',
        'suszarka do włosów',
        'zmywarka',
        'pralka',
        'kuchnia',
        'kuchenka',
        'lodówka'
    ],
];

export default unitEquipment;