import {
    Button,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    Paper,
    MenuItem,
    Select,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    LinearProgress
} from '@material-ui/core'
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import AddAccUnitDialog from './AddAccUnitDialog';
import { useDispatch, useSelector } from 'react-redux';
import { addNewAccObjectAction } from '../actions/acc_objects_actions';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const accObjectInitialData = {
    objectName: '',
    objectDescription: '',
    objectPhotoGallery: [],
    objectLatitude: '',
    objectLongitude: '',
    objectAddress: '',
    objectCityCenterDistance: '',
    objectBeachDistance: '',
    objectStoreDistance: '',
    objectRestaurantDistance: '',
    objectPriceType: '',
    objectPrice: '',
    objectContactOnlyByPhone: '',
    objectPhoneNumbers: [
        {
            phone_number: '',
            viber: false,
            whatsapp: false
        },
        {
            phone_number: '',
            viber: false,
            whatsapp: false
        },
        {
            phone_number: '',
            viber: false,
            whatsapp: false
        }
    ],
    objectEmailAddress: '',
    objectWebsite: '',
    objectCategory: '',
    objectEquipment: {
        internet: false,
        parking: false,
        air_conditioner: false,
        seaview: false,
        pool: false,
        restaurant: false,
        reception: false,
        baby_crib: false,
        disability_friendly: false
    },
    objectAccUnits: [],
    objectAdvancePayment: '',
    objectAdvanceAmount: '',
    objectNonRefundableAdvance: '',
    objectPaymentData: ''
};

const priceTypes = ['Cijena na upit', 'Po osobi', 'Noćenje'];

const accommodationCategory = ['1', '2', '3', '4', '5'];

const equipment = [
    'Internet',
    'Parking',
    'Klima',
    'Pogled na more',
    'Bazen',
    'Restoran',
    'Recepcija',
    'Krevetac za bebe',
    'Prilagođenost invalidima',
];

const advancePercentage = ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];

const AddNewObject = ({ switchActivePanel }) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const addNewAccObjectSelectorData = useSelector(state => state.addNewAccObject);
    const { loading, newAccObject } = addNewAccObjectSelectorData;

    const [editUnit, setEditUnit] = React.useState({
        editState: false,
        editUnitData: {},
        editUnitIndex: null
    });

    const [accObjectData, setAccObjectData] = React.useState(accObjectInitialData);

    const [imageGallery, setImageGallery] = React.useState([]);

    const [showAddUnitDialog, setShowAddUnitDialog] = React.useState(false);

    const [mainPhotoIndex, setMainPhotoIndex] = React.useState(null);
 
    const handleImageChange = (e) => {

        if (e.target.files) {
            // e.target.files.forEach((file)=>{ file.is_main = false });
            [...e.target.files].forEach((file) => { 
                Object.defineProperty(file,'is_main',{
                    value : false,
                    writable : true
                });
            });
            setAccObjectData((prevData) => {
                return {
                    ...prevData,
                    objectPhotoGallery: prevData.objectPhotoGallery.concat([...e.target.files])
                }
            });
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setImageGallery((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const handleRemoveImageFromGallery = (index) => {
        setAccObjectData((prevData) => {
            return {
                ...prevData,
                objectPhotoGallery: prevData.objectPhotoGallery.filter((photo, photoIndex) => photoIndex !== index)
            }
        });
        setImageGallery((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    function AddMarkerToClick() {

        useMapEvents({
            click(e) {
                setAccObjectData({
                    ...accObjectData,
                    objectLatitude: e.latlng.lat,
                    objectLongitude: e.latlng.lng
                });
            }
        });
        return <Marker position={[accObjectData.objectLatitude, accObjectData.objectLongitude]} />

    };

    const handleRemoveUnit = (index) => {
        const updatedAccObjectUnits = [...accObjectData.objectAccUnits].filter((unit, unitIndex) => unitIndex !== index);
        setAccObjectData({
            ...accObjectData,
            objectAccUnits: updatedAccObjectUnits
        });
    };

    const handleEditUnit = (index) => {
        setShowAddUnitDialog(true);
        setEditUnit({
            editState: true,
            editUnitData: { ...accObjectData.objectAccUnits[index] },
            editUnitIndex: index
        });
    };

    const handleChange = (e) => {
        setAccObjectData({
            ...accObjectData,
            [e.target.name]: e.target.value
        });
    };

    const getKeyName = (index) => {
        return Object.keys(accObjectData.objectEquipment)[index];
    };

    const handleAddObjectFormSubmit = (e) => {
        e.preventDefault();

        const data = new FormData();

        data.append('name', accObjectData.objectName);
        data.append('description', accObjectData.objectDescription);
        data.append('address', accObjectData.objectAddress);
        data.append('latitude', accObjectData.objectLatitude);
        data.append('longitude', accObjectData.objectLongitude);
        data.append('to_beach', accObjectData.objectBeachDistance);
        data.append('to_store', accObjectData.objectStoreDistance);
        data.append('to_restaurant', accObjectData.objectRestaurantDistance);
        data.append('to_city_center', accObjectData.objectCityCenterDistance);
        data.append('category', accObjectData.objectCategory);
        data.append('price_type', accObjectData.objectPriceType);
        data.append('minimal_price_from', accObjectData.objectPrice);
        data.append('contact_only_by_phone', accObjectData.objectContactOnlyByPhone);
        data.append('contact_email', accObjectData.objectEmailAddress);
        data.append('web_site', accObjectData.objectWebsite);
        data.append('advance_payment', accObjectData.objectAdvancePayment);
        data.append('advance_amount', accObjectData.objectAdvanceAmount);
        data.append('non_refundable_advance', accObjectData.objectNonRefundableAdvance);
        data.append('payment_data', accObjectData.objectPaymentData);
        data.append('mainPhotoIndex',mainPhotoIndex);

        data.append('acc_object_equipment', JSON.stringify(accObjectData.objectEquipment));
        data.append('acc_object_phone_numbers', JSON.stringify(accObjectData.objectPhoneNumbers));

        data.append('acc_units', JSON.stringify(accObjectData.objectAccUnits));

        accObjectData.objectAccUnits.forEach((unit, unitIndex) => {
            unit.photoGallery.map(photo => {
                data.append(`${unit.unitName}`, photo);
            });
        });

        accObjectData.objectPhotoGallery.forEach(photo => {
            data.append('objectPhotos', photo);
        });

        dispatch(addNewAccObjectAction(data));

    };

    const checkSelectedImage = (gallery) => {
        const index = gallery.findIndex((photo) => photo.is_main);
        return index;
    };

    return (
        <div className={classes.addNewObjectDiv}>
            <div style={{ marginBottom: '25px' }}>
                <Typography
                    style={{ cursor: 'pointer' }}
                    variant='h5'
                    color='primary'
                    onClick={() => { switchActivePanel('myObjects'); }}>
                    Odustani
                </Typography>
            </div>
            <form onSubmit={handleAddObjectFormSubmit} className={classes.addNewObjectDiv}>
                <Paper elevation={7} className={classes.addNewObject_section1}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Osnovni podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section1Form}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                required
                                id="objectName"
                                label="Naziv objekta"
                                name="objectName"
                                type="text"
                                value={accObjectData.objectName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Opis objekta"
                                fullWidth
                                variant="outlined"
                                multiline
                                name='objectDescription'
                                value={accObjectData.objectDescription}
                                onChange={handleChange}
                                minRows={6}
                                maxRows={8}
                                required
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
                            <div className={classes.section1GalleryTitle}  >
                                <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija fotografija</Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color='primary'
                                >
                                    <AddIcon fontSize='small' />
                                    <input
                                        id='dugme'
                                        type="file"
                                        onChange={handleImageChange}
                                        multiple
                                        accept='image/*'
                                        hidden
                                    />
                                </Button>
                            </div>
                            <div style={{ display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent: 'center' }}> 
                            <Typography style={{ marginLeft: '10px' }} variant='h6'>
                                    Izabrana glavna fotografija
                                </Typography>
                                {
                                    checkSelectedImage(accObjectData.objectPhotoGallery) !== -1
                                    ?
                                    <DoneIcon style={{ color : '#26A65B' }} fontSize='large'/>
                                    :
                                    <CloseIcon color='error' fontSize='large'/>
                                }
                            </div>
                            <div className={classes.section1ImagePreviewDiv}>
                                {
                                    imageGallery.map((photo, index) => {
                                        return (
                                            <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
                                                <img
                                                    onClick={() => {
                                                        setMainPhotoIndex(index);
                                                        const gallery = accObjectData.objectPhotoGallery.map( (photo, indexG) => {
                                                            if( index === indexG ) { 
                                                                photo.is_main = true;
                                                                return photo
                                                            }else {
                                                                photo.is_main = false;
                                                                return photo;
                                                            }
                                                        });
                                                        setAccObjectData((prevData) => {
                                                            return {
                                                                ...prevData,
                                                                objectPhotoGallery : [...gallery]
                                                            }
                                                        });
                                                    }}
                                                    src={photo}
                                                    width={210}
                                                    alt={`Object ${index}`}
                                                    style={{
                                                        borderRadius: '10px',
                                                        cursor: 'pointer',
                                                        border: checkSelectedImage(accObjectData.objectPhotoGallery) === index ? '4px solid green' : '1px solid transparent'
                                                    }} />
                                                <div key={2} className={classes.editImage} >
                                                    <IconButton onClick={() => { handleRemoveImageFromGallery(index); }}>
                                                        <CancelIcon fontSize='large' style={{ color: '#CF000F' }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>

                    </Grid>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section2}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Podaci o lokaciji</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>

                            <MapContainer
                                center={[42.207232, 18.943100]}
                                zoom={16}
                                tap={false}
                                className={classes.map}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />

                                <AddMarkerToClick />
                            </MapContainer>

                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectLatitude"
                                label="Latituda"
                                name="objectLatitude"
                                value={accObjectData.objectLatitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectLongitude"
                                label="Longituda"
                                name="objectLongitude"
                                value={accObjectData.objectLongitude}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectAddress"
                                label="Adresa"
                                name="objectAddress"
                                value={accObjectData.objectAddress}
                                onChange={handleChange}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectCityCenterDistance"
                                label="Do centra grada (m)"
                                value={accObjectData.objectCityCenterDistance}
                                onChange={handleChange}
                                name="objectCityCenterDistance"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectBeachDistance"
                                label="Do plaže (m)"
                                value={accObjectData.objectBeachDistance}
                                onChange={handleChange}
                                name="objectBeachDistance"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectStoreDistance"
                                label="Do prodavnice (m)"
                                value={accObjectData.objectStoreDistance}
                                onChange={handleChange}
                                name="objectStoreDistance"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectRestaurantDistance"
                                label="Do restorana (m)"
                                value={accObjectData.objectRestaurantDistance}
                                onChange={handleChange}
                                name="objectRestaurantDistance"
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section3}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Ostali podaci</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={6} md={3}>
                            <InputLabel id="demo-simple-select-label">Tip cijene</InputLabel>
                            <Select
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                name='objectPriceType'
                                value={accObjectData.objectPriceType}
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {
                                    priceTypes.map((priceType, index) => {
                                        return (
                                            <MenuItem value={index} key={index}>
                                                {priceType}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <TextField
                                disabled={accObjectData.objectPriceType === 0 || accObjectData.objectPriceType === '' ? true : false}
                                fullWidth
                                variant="outlined"
                                required={accObjectData.objectPriceType === 0 || accObjectData.objectPriceType === '' ? true : false}
                                id="objectPrice"
                                label="Cijena u eurima"
                                name="objectPrice"
                                value={accObjectData.objectPrice}
                                onChange={handleChange}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Kontakt samo telefonom?</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectContactOnlyByPhone}
                                                onChange={(e) => {
                                                    setAccObjectData({
                                                        ...accObjectData,
                                                        objectContactOnlyByPhone: true
                                                    });
                                                }}
                                            />
                                        }
                                        label="Da"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!accObjectData.objectContactOnlyByPhone}
                                                onChange={(e) => {
                                                    setAccObjectData({
                                                        ...accObjectData,
                                                        objectContactOnlyByPhone: false
                                                    });
                                                }}
                                            />
                                        }
                                        label="Ne"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required={accObjectData.objectContactOnlyByPhone ? true : false}
                                id="numOfGrownups"
                                label="Broj telefona 1"
                                name="numOfGrownups"
                                type="text"
                                value={accObjectData.objectPhoneNumbers[0].phone_number}
                                onChange={(e) => {
                                    setAccObjectData((prevData) => {
                                        prevData.objectPhoneNumbers[0].phone_number = e.target.value;
                                        return { ...prevData }
                                    });
                                }}
                            />
                            <FormControl component="fieldset">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectPhoneNumbers[0].viber}
                                                onChange={(e) => {
                                                    setAccObjectData((prevData) => {
                                                        prevData.objectPhoneNumbers[0].viber = e.target.checked;
                                                        return { ...prevData }
                                                    });
                                                }}
                                            />
                                        }
                                        label="Viber"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectPhoneNumbers[0].whatsapp}
                                                onChange={(e) => {
                                                    setAccObjectData((prevData) => {
                                                        prevData.objectPhoneNumbers[0].whatsapp = e.target.checked;
                                                        return { ...prevData }
                                                    });
                                                }}
                                            />
                                        }
                                        label="Whatsapp"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="numOfGrownups"
                                label="Broj telefona 2"
                                name="numOfGrownups"
                                type="text"
                                value={accObjectData.objectPhoneNumbers[1].phone_number}
                                onChange={(e) => {
                                    setAccObjectData((prevData) => {
                                        prevData.objectPhoneNumbers[1].phone_number = e.target.value;
                                        return { ...prevData }
                                    });
                                }}
                            />
                            <FormControl component="fieldset">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectPhoneNumbers[1].viber}
                                                onChange={(e) => {
                                                    setAccObjectData((prevData) => {
                                                        prevData.objectPhoneNumbers[1].viber = e.target.checked;
                                                        return { ...prevData }
                                                    });
                                                }}
                                            />
                                        }
                                        label="Viber"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectPhoneNumbers[1].whatsapp}
                                                onChange={(e) => {
                                                    setAccObjectData((prevData) => {
                                                        prevData.objectPhoneNumbers[1].whatsapp = e.target.checked;
                                                        return { ...prevData }
                                                    });
                                                }}
                                            />
                                        }
                                        label="Whatsapp"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="numOfGrownups"
                                label="Broj telefona 3"
                                name="numOfGrownups"
                                type="text"
                                value={accObjectData.objectPhoneNumbers[2].phone_number}
                                onChange={(e) => {
                                    setAccObjectData((prevData) => {
                                        prevData.objectPhoneNumbers[2].phone_number = e.target.value;
                                        return { ...prevData }
                                    });
                                }}
                            />
                            <FormControl component="fieldset">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectPhoneNumbers[2].viber}
                                                onChange={(e) => {
                                                    setAccObjectData((prevData) => {
                                                        prevData.objectPhoneNumbers[2].viber = e.target.checked;
                                                        return { ...prevData }
                                                    });
                                                }}
                                            />
                                        }
                                        label="Viber"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectPhoneNumbers[2].whatsapp}
                                                onChange={(e) => {
                                                    setAccObjectData((prevData) => {
                                                        prevData.objectPhoneNumbers[2].whatsapp = e.target.checked;
                                                        return { ...prevData }
                                                    });
                                                }}
                                            />
                                        }
                                        label="Whatsapp"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectEmailAddress"
                                label="Email adresa na koju primate poruke"
                                name="objectEmailAddress"
                                type="text"
                                value={accObjectData.objectEmailAddress}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                id="objectWebsite"
                                label="Link vaseg web sajta"
                                name="objectWebsite"
                                type="text"
                                value={accObjectData.objectWebsite}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <InputLabel id="demo-simple-select-label-cat">Kategorizacija</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label-cat"
                                value={accObjectData.objectCategory}
                                name='objectCategory'
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem value={0}>
                                    Nije kategorisan
                                </MenuItem>
                                {
                                    accommodationCategory.map((category, index) => {
                                        return (
                                            <MenuItem value={index + 1} key={index}>
                                                {category}
                                                <StarIcon fontSize='small' style={{ color: 'gold' }} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section4}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Oprema objekta</Typography></div>
                    <div className={classes.equipmentDiv}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <div className={classes.equipment}>
                                    {
                                        equipment.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                accObjectData.objectEquipment[getKeyName(index)]
                                                            }
                                                            onChange={(e) => {
                                                                const keyName = getKeyName(index);
                                                                const updatedEquipment = {
                                                                    ...accObjectData.objectEquipment,
                                                                    [keyName]: e.target.checked
                                                                };
                                                                setAccObjectData((prevData) => {
                                                                    return {
                                                                        ...prevData,
                                                                        objectEquipment: updatedEquipment
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    label={item}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>
                        </FormControl>
                    </div>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section4}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Smještajne jedinice</Typography></div>
                    <div className={classes.section2MapDiv} style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => {
                                setShowAddUnitDialog(true);
                            }}
                            variant='contained'
                            style={{ color: '#317589' }}>
                            <AddIcon />
                            Dodaj
                        </Button>
                    </div>
                    <div className={classes.accUnits}>
                        {
                            accObjectData.objectAccUnits.map((unit, index) => {
                                return (
                                    <div className={classes.singleUnit} key={index}>
                                        <Typography variant='subtitle1'>{unit.unitName}</Typography>
                                        <IconButton
                                            onClick={() => {
                                                handleEditUnit(index);
                                            }}
                                            style={{ marginLeft: '10px', color: '#26A65B' }}>
                                            <EditIcon fontSize='medium' />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                handleRemoveUnit(index);
                                            }}
                                            style={{ marginLeft: '10px', color: '#DC3023' }}>
                                            <CancelIcon fontSize='medium' />
                                        </IconButton>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Paper>
                <Paper elevation={7} className={classes.addNewObject_section4}>
                    <div className={classes.sectionTitle}><Typography variant='h5'>Uslovi rezervacije</Typography></div>
                    <Grid container spacing={4} className={classes.section2MapDiv}>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Avans obavezan?</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(accObjectData.objectAdvancePayment)}
                                                onChange={(e) => {
                                                    setAccObjectData({
                                                        ...accObjectData,
                                                        objectAdvancePayment: true
                                                    });
                                                }}
                                            />
                                        }
                                        label="Da"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accObjectData.objectAdvancePayment === '' ? false : !Boolean(accObjectData.objectAdvancePayment)}
                                                onChange={(e) => {
                                                    setAccObjectData({
                                                        ...accObjectData,
                                                        objectAdvancePayment: false,
                                                        objectAdvanceAmount: ''
                                                    });
                                                }}
                                            />
                                        }
                                        label="Ne"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <InputLabel id="demo-simple-select-label">Iznos avansa</InputLabel>
                            <Select
                                fullWidth
                                disabled={accObjectData.objectAdvancePayment ? false : true}
                                required={accObjectData.objectAdvancePayment ? true : false}
                                labelId="demo-simple-select-label"
                                value={accObjectData.objectAdvanceAmount}
                                name='objectAdvanceAmount'
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {
                                    advancePercentage.map((percent, index) => {
                                        return (
                                            <MenuItem value={percent} key={index}>
                                                {percent} &#37;
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Avans nepovratan kod otkazivanja?</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={accObjectData.objectAdvancePayment ? false : true}
                                                checked={Boolean(accObjectData.objectNonRefundableAdvance)}
                                                onChange={(e) => {
                                                    setAccObjectData({
                                                        ...accObjectData,
                                                        objectNonRefundableAdvance: true
                                                    });
                                                }}
                                            />
                                        }
                                        label="Da"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={accObjectData.objectAdvancePayment ? false : true}
                                                checked={accObjectData.objectNonRefundableAdvance === '' ? false : !Boolean(accObjectData.objectNonRefundableAdvance)}
                                                onChange={(e) => {
                                                    setAccObjectData({
                                                        ...accObjectData,
                                                        objectNonRefundableAdvance: false
                                                    });
                                                }}
                                            />
                                        }
                                        label="Ne"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Podaci za uplatu"
                                fullWidth
                                variant="outlined"
                                multiline
                                minRows={6}
                                maxRows={8}
                                required
                                type='text'
                                name='objectPaymentData'
                                value={accObjectData.objectPaymentData}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <div>
                    {
                        loading
                            ?
                            <LinearProgress />
                            :
                            typeof newAccObject !== 'undefined'
                                ?
                                newAccObject.success
                                    ?
                                    <Alert severity="success" style={{ marginBottom: '20px' }}>
                                        <AlertTitle>Uspješno</AlertTitle>
                                        Novi objekat dodat
                                    </Alert>
                                    :
                                    <Alert severity="error" style={{ marginBottom: '20px' }}>
                                        <AlertTitle>Greška</AlertTitle>
                                        Neuspješno kreiranje objekta
                                    </Alert>
                                :
                                null
                    }
                </div>
                <div className={classes.addNewObjectButtons}>
                    <Button
                        type='submit'
                        style={{ backgroundColor: '#26A65B', color: 'white' }} >
                        Dodaj
                    </Button>
                    <Button
                        onClick={() => { switchActivePanel('myObjects'); }}
                        variant='text'
                        style={{ color: '#DC3023' }}>
                        <CancelIcon />
                        Odustani
                    </Button>
                </div>
            </form>
            <AddAccUnitDialog
                show={showAddUnitDialog}
                setShow={setShowAddUnitDialog}
                accObjectData={accObjectData}
                setAccObjectData={setAccObjectData}
                editUnit={editUnit}
                setEditUnit={setEditUnit} />
        </div>
    );
};

export default AddNewObject