import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    adminToolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bigPanelDiv: {
        //border : '2px solid black',
        display: 'grid',
        gridTemplateColumns: '15% 85%',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '100%',
        }
    },
    sidePanel: {
        border: '1px solid transparent',
        backgroundColor: '#264348',
        minHeight: '100vh',
        height: '100%',
        color: 'white',
        position: 'sticky',

    },
    sidePanelList: {
        position: 'sticky',
        top: '50px'
    },
    listItem: {
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'row',
        padding: '7px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '25px',
        transition: '0.2s',
        '&:hover': {
            backgroundColor: '#317589',
            color: 'white'
        },
        [theme.breakpoints.down('xs')]: {
            '&:hover': {
                backgroundColor: 'white',
                color: 'orangered'
            },
        }
    },
    listItemActive: {
        backgroundColor: '#317589'
    },
    activePanel: {
        //border : '2px solid green',
        padding: '5%',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
            marginTop: '40px'
        }
    },
    myObjectsDiv: {

    },
    myObjectsFirstDiv: {
        //border : '4px solid red',
        display: 'grid',
        gridTemplateColumns: '25% 75%',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            width: '60%',
            margin: 'auto',
            gridTemplateColumns: '1fr',
        }
    },
    myObjectsTable: {
        width: '100%',
        margin: 'auto',
        marginTop: '25px',
        overflow: 'auto',
    },
    table: {
        width: '100%',
        minWidth: '1000px'
    },
    contactQuestions: {
        //border : '3px solid black'
    },
    contactQuestionsSearchDiv: {
        //border : '4px solid green',
        width: '80%',
        margin: 'auto'
    },
    contactQuestionsTableDiv: {
        width: '100%',
        margin: 'auto',
        marginTop: '25px',
        overflow: 'auto',
    },
    container: {
        width: '80%',
        margin: 'auto',
        backgroundColor: '#F2F1EF',
        borderRadius: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '98%'
        }
    },
    previewImageDiv: {
        //border : '1px solid black',
        position: 'relative',
        width: '25%',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '50%'
        }
    },
    editImage: {
        position: 'absolute',
        top: '0',
        right: '0'
    },
    image: {
        width: "100%",
        borderRadius: '10px',
    },
    finishButtons: {
        marginTop: '25px',
        width: '35%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '70%',
            margin: 'auto',
            marginTop: '25px',
        }
    },
    addNewObjectDiv: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '3rem',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            margin: 'auto'
        }
    },
    sectionTitle: {
        backgroundColor: '#264348',
        color: 'white',
        padding: '5px',
        marginTop: '-1px',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        [theme.breakpoints.down('xs')]: {
            marginRight: '-1px',
            marginLeft: '-1px'
        }
    },
    addNewObject_section1: {
        border: '1px solid #264348',
        paddingBottom: "10px"
    },
    section1Form: {
        width: '85%',
        margin: "auto",
        marginTop: '25px',
    },
    section1GalleryTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '5px'
    },
    section1ImagePreviewDiv: {
        //border : '1px solid black',
        padding: "5px",
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        }
    },
    addNewObject_section2: {
        border: '1px solid #264348',
        paddingBottom: "10px"
    },
    section2MapDiv: {
        width: '90%',
        margin: 'auto',
        marginTop: '25px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    map: {
        height: '50vh',
        borderRadius: '10px',
    },
    addNewObject_section3: {
        border: '1px solid #264348',
        paddingBottom: "10px"
    },
    addNewObject_section4: {
        border: '1px solid #264348',
        paddingBottom: "10px"
    },
    equipmentDiv: {
        width: '80%',
        margin: 'auto',
        marginTop: '25px',
    },
    equipment: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr 1fr',
            width: '90%',
        }
    },
    accUnits: {
        marginTop: '20px',
        display: 'grid',
        width: '80%',
        margin: 'auto',
        gridTemplateColumns: '1fr',
        gap: '1rem'
    },
    singleUnit: {
        backgroundColor: '#F2F1EF',
        marginBottom: '15px',
        padding: '10px',
        display: 'flex',
        borderRadius: '10px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    addNewObjectButtons: {
        width: '30%',
        display: 'grid',
        marginBottom: '25px',
        gridTemplateColumns: '1fr 1fr',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            width: '70%',
        }
    },
    addAccUnitSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '80%',
        margin: 'auto',
        marginTop: '5%',
        marginBottom: '25px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '20%',
            width: '95%'
        }
    },
    accUnitToolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    periodsDiv: {
        width: '60%',
        margin: "auto",
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }
    },
    singlePeriod: {
        backgroundColor: '#F2F1EF',
        padding: '7px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    avatarBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap : '30x',
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    users: {
        //border : '4px solid green',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: 'center',
        cursor: 'pointer',
        transition: '0.5s',
        '&:hover': {
            color: '#f50057'
        },
    },
    userAvatar: {
        cursor: 'pointer',
        '&:hover': {
           filter:  'brightness(50%)'
        }
    },
    linearProgress: {
        //border : '2px solid green',
        marginTop: '10%',
        width: '80%',
        margin: 'auto',
        padding: '10px'
    },
    editObjectDiv: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '80%',
        margin: 'auto',
        marginTop: '7%',
        marginBottom: '25px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '20%',
            width: '95%'
        }
    },
    superAdminObjectsTableDiv: {
        //border : '2px solid black',
        width: '95%',
        margin: 'auto',
        marginTop: '1%',
        [theme.breakpoints.down('xs')]: {
            width: '98%',
            marginTop: '9%',
        }
    },
    objectDialog: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '1rem'
    },
    superAdminSearchSection: {
        width: "80%",
        margin: 'auto',
        marginTop: '2%',
        [theme.breakpoints.down('xs')]: {
            width: '85%',
            marginTop: '9%',
        }
    },
    superAdminPagination: {
        width: '70%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '85%',
        }
    },
    superAdminLoadingTable: {
        height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    ////////////////////////////////////////////////////////////////
    viewQuestionContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr'
        }
    },
    viewQuestionSection: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr'
        }
    },
    viewQuestionBigRow: {
        //border : '1px solid black',
        padding: '3px'
    },
    viewQuestionRightSide : {
        display : 'flex',
        flexDirection : 'column',
        gap : '10px'
    },
    viewQuestionRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: "7px"
    },
    /////////////////////////////////////////////////////////
    previewToolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    previewDiv: {
        //border : '5px solid red',
        width: '90%',
        margin: 'auto',
        marginTop: '6%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: "16%",
            width: '95%',
            gap: '1rem',
        }
    },
    previewGallery: {
        //border : '4px solid green',
        [theme.breakpoints.down('xs')]: {
            // display : 'flex',
            // justifyContent : 'center',
            // alignItems : 'center',
            // width : '30%',
            // margin : 'auto',
            // border : '10px solid green'
        },
    },
    carousel: {
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: '10%',
            // width : '100%'
        },
    },
    previewInfo: {
        //border : '4px solid blue'
    },
    previewAccUnitPhotos: {
        //border: '2px solid red',
        width: '80%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    carouselUnit: {
        width: '80%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    editRestaurant: {
        width: '70%',
        margin: 'auto',
        marginTop: '7%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '3rem',
        marginBottom: '7%',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            margin: 'auto',
            marginTop: '15%'
        }
    },
    questionsDiv : {
        //border : '2px solid red'
    },
    questionTypesDiv : {
        //border : '2px solid blue',
        display : 'grid',
        gridTemplateColumns : 'repeat(5,1fr)',
        gap : '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns : '1fr 1fr',
            width : '80%',
            margin : 'auto'
        }
    },
}));

export default useStyles;