import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    verificationDiv : {
        width : '70%',
        margin : 'auto',
        height : '99vh',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        [theme.breakpoints.down('xs')]: {
            width : '90%',
        }
    },
    loading : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center'
    }
}));

export default useStyles;