import {
    Button,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    TableCell,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    TableBody,
    LinearProgress
} from '@material-ui/core'
import React, { useReducer } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './style';
import { Search } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsForOwnerAction } from '../actions/acc_objects_actions';
import { format, parseISO } from 'date-fns';
import ViewQuestionDetailsDialog from './ViewQuestionDetailsDialog';
import { initialState, questionsReducer } from '../reducers/questionsReducer';
import CarQuestionDetails from './CarQuestionDetails';

const questionTypes = ['Objekti', 'Restorani', 'Rent a car', 'Ture', 'Događaji'];

const ContactQuestions = () => {

    const classes = useStyles();
    const dispatchRedux = useDispatch();

    const [state, dispatch] = useReducer(questionsReducer, initialState);

    const [showDialog, setShowDialog] = React.useState({
        show: false,
        data: {}
    });

    const ownerQuestionsSelector = useSelector(state => state.getQuestionsForOwnerState);
    const { loading, questionsForOwnerData: data } = ownerQuestionsSelector;

    const ShowError = () => {
        return (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
                <AlertTitle>Greška</AlertTitle>
                Neuspješno prikazivanje upita
            </Alert>
        )
    };

    const ShowLoading = () => {
        return (
            <div style={{ margin: '20px' }}>
                <Typography variant='subtitle2' color='primary'>Učitavanje upita...</Typography>
                <LinearProgress color='primary' />
            </div>
        );
    };

    const AccObjectsQuestions = () => {

        return (
            <div className={classes.contactQuestions}>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        typeof data !== 'undefined'
                            ?
                            data?.success
                                ?
                                <div className={classes.contactQuestionsTableDiv}>
                                    <TableContainer component={Paper} className={classes.table} >
                                        <Table>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor : '#F2F1EF' }}>
                                                    <TableCell>Ime i prezime</TableCell>
                                                    <TableCell align="left">Email</TableCell>
                                                    <TableCell align="left">Objekat</TableCell>
                                                    <TableCell align="left">Smjestajna jedinica</TableCell>
                                                    <TableCell align="left">Datum dolaska</TableCell>
                                                    <TableCell align="left">Datum odlaska</TableCell>
                                                    <TableCell align="left">Poslato datuma</TableCell>
                                                    <TableCell align="left">Detalji</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.contactQuestions.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row"> {row.contact_name} {row.contact_surname} </TableCell>
                                                        <TableCell align="left">{row.contact_email}</TableCell>
                                                        <TableCell align="left">{row.accObjectName}</TableCell>
                                                        <TableCell align="left">{row.accUnitName}</TableCell>
                                                        <TableCell align="left">{row.arrival_date}</TableCell>
                                                        <TableCell align="left">{row.departure_date}</TableCell>
                                                        <TableCell align="left">{format(parseISO(row.date_asked), 'dd/MM/yyyy/HH:mm:ss')}</TableCell>
                                                        <TableCell align="left">
                                                            <VisibilityIcon
                                                                onClick={() => {
                                                                    setShowDialog({
                                                                        show: true,
                                                                        data: { ...row }
                                                                    });
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                                color='primary' />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                :
                                <ShowError />
                            :
                            null
                }
                <ViewQuestionDetailsDialog show={showDialog.show} setShow={setShowDialog} data={showDialog.data} />
            </div>
        )
    };

    const RestaurantsQuestions = () => {
        return (
            <Typography>
                RESTAURANTS QUESTIONS
            </Typography>
        )
    };

    const RentACarQuestions = () => {
        return (
            <div className={classes.contactQuestions}>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        typeof data !== 'undefined'
                            ?
                            data?.success
                                ?
                                <div className={classes.contactQuestionsTableDiv}>
                                    <TableContainer component={Paper} className={classes.table} >
                                        <Table>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor : '#F2F1EF' }}>
                                                    <TableCell align='left'>Ime i prezime</TableCell>
                                                    <TableCell align="left">Email</TableCell>
                                                    <TableCell align="left">Auto</TableCell>
                                                    <TableCell align="left">Preuzimanje</TableCell>
                                                    <TableCell align="left">Vraćanje</TableCell>
                                                    <TableCell align="left">Poslato datuma</TableCell>
                                                    <TableCell align="left">Detalji</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.contactQuestions.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row"> {row.contact_name} {row.contact_surname} </TableCell>
                                                        <TableCell align="left">{row.contact_email}</TableCell>
                                                        <TableCell align="left">{row.manufacturer} {row.model} {row.engine} {row.year}</TableCell>
                                                        <TableCell align="left">{row.pick_up_date}</TableCell>
                                                        <TableCell align="left">{row.drop_off_date}</TableCell>
                                                        <TableCell align="left">{format(parseISO(row.date_asked), 'dd/MM/yyyy/HH:mm:ss')}</TableCell>
                                                        <TableCell align="left">
                                                            <VisibilityIcon
                                                                onClick={() => {
                                                                    setShowDialog({
                                                                        show: true,
                                                                        data: { ...row }
                                                                    });
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                                color='primary' />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                :
                                <ShowError />
                            :
                            null
                }
                <CarQuestionDetails show={showDialog.show} setShow={setShowDialog} data={showDialog.data} />
            </div>
        )
    };

    const ToursQuestions = () => {
        return (
            <Typography>
                TOURS QUESTIONS
            </Typography>
        )
    };

    const EventsQuestions = () => {
        return (
            <Typography>
                EVENTS QUESTIONS
            </Typography>
        )
    };

    React.useEffect(() => {
        switch (state.currentDisplay) {
            case 0: dispatchRedux(getQuestionsForOwnerAction(0));
            case 1: return <RestaurantsQuestions />
            case 2: dispatchRedux(getQuestionsForOwnerAction(2));
            case 3: return <ToursQuestions />
            case 4: return <EventsQuestions />
            default: return <AccObjectsQuestions />
        }
    }, [state.currentDisplay]);

    const getQuestionsForType = () => {
        switch (state.currentDisplay) {
            case 0: return <AccObjectsQuestions />
            case 1: return <RestaurantsQuestions />
            case 2: return <RentACarQuestions />
            case 3: return <ToursQuestions />
            case 4: return <EventsQuestions />
            default: return <AccObjectsQuestions />
        }
    };

    return (
        <div className={classes.questionsDiv}>
            <div className={classes.questionTypesDiv}>
                {
                    questionTypes.map((type, index) => {
                        return (
                            <Button
                                onClick={() => {
                                    dispatch({
                                        type: 'CHANGE_CURRENT_DISPLAY',
                                        payload: index
                                    });
                                }}
                                key={index}
                                disableElevation
                                variant={index === state.currentDisplay ? 'contained' : 'outlined'}>
                                {type}
                            </Button>
                        )
                    })
                }
            </div>
            {
                getQuestionsForType()
            }
        </div>
    );
};

export default ContactQuestions;